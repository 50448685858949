
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "M",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "T",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "W",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "T",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "F",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "S",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "S",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },

];

const NewBar = ()=> {
  return (
        <div className="top-bar-chart" style={{backgroundColor:"#000", width:"50%",height:"30rem",marginBottom:"20px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={400}
                    height={450}
                    data={data}
                >
                    <CartesianGrid strokeDasharray="0 1" fill="#000" />
                    <XAxis dataKey="name" tickLine={false} />
                    <Tooltip cursor={{ fill: "transparent" }} />
                    <Bar dataKey="pv" barSize={10} radius={[10, 10, 0, 0]} fill="#47E25E" strokeWidth={10} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


export default NewBar;