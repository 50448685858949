import { Dialog, CircularProgress, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { localServer, Path, PathCustom } from "../Path/Path";
import axios from "axios";
import { Print_Service } from "../components/printService";
import { BsFillCheckCircleFill } from "react-icons/bs";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function BulkPrintingModal({
	heading,
	subheading,
	subHeadingValue,
	modalOpen,
	closeHandler,
	data,
	HeadAndRow,
	tableTitle,
	printer,
	zplData,
}) {
	const [quantities, setQuantities] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [errorMessages, setErrorMessages] = useState([]);
	const [epc, setEpc] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [loading, setLoading] = useState(false);
	const [progressModalOpen, setProgressModalOpen] = useState(false);
	const [progressData, setProgressData] = useState([]);

	useEffect(() => {
		if (data) {
			const initialQuantities = (Array.isArray(data) ? data : [data]).map(
				(item) => item.Item_Quantity || ""
			);
			setQuantities(initialQuantities);
			setErrorMessages(new Array(data.length).fill(""));
			setSelectedItems([]);
		}
		if (!modalOpen) {
			setSelectedItems([]);
			setSelectAll(false);
		}
	}, [data, modalOpen]);

	const handleQuantityChange = (index, value) => {
		const newQuantities = [...quantities];
		newQuantities[index] = Number(value);
		setQuantities(newQuantities);

		const updatedData = [...data];
		updatedData[index].Item_Quantity = Number(value);

		setSelectedItems((prevSelectedItems) => {
			const updatedSelectedItems = [...prevSelectedItems];
			const selectedItemIndex = updatedSelectedItems.findIndex(
				(item) => item.Article === updatedData[index].Article
			);

			if (selectedItemIndex > -1) {
				updatedSelectedItems[selectedItemIndex].Item_Quantity = Number(value);
			}

			return updatedSelectedItems;
		});
	};

	const handleCheckboxChange = (item, index) => {
		setSelectedItems((prevSelectedItems) => {
			const newSelectedItems = [...prevSelectedItems];
			const itemIndex = newSelectedItems.findIndex(
				(selectedItem) => selectedItem.Article === item.Article
			);

			if (itemIndex > -1) {
				newSelectedItems.splice(itemIndex, 1);
			} else {
				const updatedItem = { ...item };
				if (!updatedItem.Item_Quantity || quantities[index] === "") {
					const newQuantities = [...quantities];
					newQuantities[index] = newQuantities[index] || 1;
					setQuantities(newQuantities);

					updatedItem.Item_Quantity = Number(newQuantities[index]);
				} else {
					updatedItem.Item_Quantity = Number(quantities[index]);
				}

				newSelectedItems.push(updatedItem);
			}

			return newSelectedItems;
		});
	};

	const handleSelectAll = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			const newSelectedItems = [...data];
			const newQuantities = [...quantities];

			newSelectedItems.forEach((item, index) => {
				if (!item.Item_Quantity && newQuantities[index] === "") {
					newQuantities[index] = 1;
				}
				item.Item_Quantity = Number(newQuantities[index]);
			});

			setSelectedItems(newSelectedItems);
			setQuantities(newQuantities);
		}
		setSelectAll(!selectAll);
	};

	const isItemSelected = (item) => {
		return selectedItems.some(
			(selectedItem) => selectedItem.Article === item.Article
		);
	};

	const replaceAll = (str, find, replace) => {
		var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
		return str.replace(new RegExp(escapedFind, "g"), replace);
	};

	const updateProgress = (barcode, chunkSizeProcessed) => {
		console.log(
			`Updating progress for barcode: ${barcode}, printed: ${chunkSizeProcessed}`
		);
		setProgressData((prevProgressData) => {
			const updatedProgressData = prevProgressData.map((item) => {
				if (item.barcode === barcode) {
					const newPrintedCount = item.printed + chunkSizeProcessed;
					return {
						...item,
						printed: newPrintedCount,
						status:
							newPrintedCount >= item.quantity ? "Completed" : item.status,
					};
				}
				return item; // Keep other items unchanged
			});
			console.log("Updated progress data:", updatedProgressData); // Debugging line
			return updatedProgressData;
		});
	};

	// Define an async function to process chunks
	const processChunks = async (item, chunkSize, updatedBarcode) => {
		try {
			const getEPCs = await axios.post(
				`${localServer}store/seq/sequence-new?type=DEV&increment=${chunkSize}&sku=${updatedBarcode}`
			);

			if (getEPCs?.data.success === false) {
				toast.error("Error getting EPCs");
				return; // Exit early if there's an error
			}

			let epcs = getEPCs?.data?.data;

			for (let i = 0; i < epcs?.length; i++) {
				// console.log(
				// 	`Currently on item no: ${i} for epc: ${epcs[i]} and barcode: ${item?.Barcode}`
				// );
				setEpc(epc[i]);

				// Replace variables in the ZPL template
				let updatedZpl = zplData?.zplBody;
				updatedZpl = await replaceAll(updatedZpl, "${serialNumber}", epc);
				updatedZpl = await replaceAll(updatedZpl, "${Date}", new Date());
				updatedZpl = await replaceAll(
					updatedZpl,
					"${ItemBarCode}",
					item?.Barcode
				);
				updatedZpl = await replaceAll(updatedZpl, "${ItemId}", item?.Reference);
				updatedZpl = await replaceAll(
					updatedZpl,
					"${MafAttrBrandSeason}",
					item?.Season_Code
				);
				updatedZpl = await replaceAll(
					updatedZpl,
					"${MafDescription}",
					item?.DESC_EN
				);
				updatedZpl = await replaceAll(
					updatedZpl,
					"${OriginalSellingPrice}",
					item?.AED_PRICE
				);
				updatedZpl = await replaceAll(updatedZpl, "${barcode}", item?.Article);

				// Prepare the body for the POST request
				const body = {
					apiVersion: "2.0",
					group: "NESTO",
					user: "NESTO",
					location: "MOE",
					thingTypeCode: "ITEM",
					tagId: epcs[i],
					barcode: item?.Barcode,
					articleNumber: item?.Article,
					invoiceNumber: "",
					deviceId: "",
					sourceModule: "Printing",
					printerId: "PR-001",
					zpl: zplData?.zplName,
					source: "Printing_APP",
					zone: "zone_1",
					userName: "",
					comment: "New comment",
					TextValue: "",
					FriendlyName: "",
					CategoryFriendlyName: "",
					ItemBarCode: item?.Barcode,
					ItemId: "",
					MafAttrBrandOne: "",
					MafAttrBrandSeason: item?.Season_Code,
					MafDescription: item?.DESC_EN,
					MafInventColorId: "",
					MafInventSizeId: "",
					OrigCountryRegionId: "",
					RetailSeasonCode: "",
					SAB_ProductName: "",
					SenderId: "",
					OriginalSellingPrice: item?.AED_PRICE,
				};

				// Print the item (simulated here)
				// console.log(`EPC No: ${i} processed with value: ${epcs[i]}`);
				Print_Service.print(printer, updatedZpl);
				await axios.post(`${PathCustom}store/printed-items`, body);
			}

			// updateProgress(item?.Barcode, epcs.length);
			epcs = [];
		} catch (error) {
			console.error("Error processing item:", error);
			toast.error("An error occurred while processing the item.");
		}
	};

	// Main print handler
	const printHandler = async () => {
		if (selectedItems.length === 0) {
			toast.error("Please select at least one item to print.");
		} else if (zplData === null) {
			toast.error("Please select ZPL first.");
    }
    // else if (printer === null) {
		// 	toast.error("Please select printer first.");
    // }
    else {
			setProgressModalOpen(true); // Show the progress modal

			for (const item of selectedItems) {
				const loopValue = Number(item?.Item_Quantity);

				if (loopValue === 0) {
					toast.error(
						"The quantity for the selected item should be greater than 0."
					);
					continue;
				}

				const currentProgress = {
					barcode: item?.Barcode,
					quantity: loopValue,
					printed: 0,
					status: "In-Progress",
				};

				setProgressData((prev) => [...prev, currentProgress]);

				let updatedBarcode = item?.Barcode;
				const barcodeLength = updatedBarcode?.toString().trim().length;

				// Pad the barcode to 14 digits
				if (barcodeLength < 14) {
					updatedBarcode = updatedBarcode?.toString().padStart(14, "0");
				}

				const CHUNK_SIZE = loopValue > 100 ? 100 : loopValue; // Set chunk size
				let totalProcessed = 0;

				for (let j = 0; j < loopValue; j += CHUNK_SIZE) {
					const chunkSize = Math.min(CHUNK_SIZE, loopValue - j);

					// Await the processing of each chunk
					await processChunks(item, chunkSize, updatedBarcode);

					totalProcessed += chunkSize;
					// console.log(
					// 	`Total items processed for barcode ${item?.Barcode}: ${totalProcessed}`
					// );
					updateProgress(item?.Barcode, totalProcessed);
					totalProcessed = 0;
				}

				// console.log(`Item fully processed with barcode: ${item?.Barcode}`);
			}

      // setLoading(false);
      setSelectedItems([]);
      setSelectAll(false);
		}
	};

	const ProgressModal = () => {
		const dialogStyles = {
			width: "800px",
			height: "600px",
			maxWidth: "none",
			overflow: "hidden",
		};

		const tableContainerStyles = {
			maxHeight: "500px",
			overflowY: "auto",
			marginTop: "20px",
		};

		const isPrintingInProgress = progressData.some(
			(item) => item.status === "In-Progress"
		);

		const allPrintingCompleted = progressData.every(
			(item) => item.status === "Completed"
		);

		return (
			<Dialog
				open={progressModalOpen}
				onClose={() => {
					if (!isPrintingInProgress) {
						setProgressModalOpen(false);
					}
				}}
				disableEscapeKeyDown
				disableBackdropClick
				PaperProps={{
					style: dialogStyles,
				}}
			>
				<div style={{ padding: "20px" }}>
					<Typography variant="h5">Printing Progress</Typography>
					{progressData.length > 0 ? (
						<div style={tableContainerStyles}>
							<table className="table table-dark">
								<thead>
									<tr>
										<th className="text-center">Barcode</th>
										<th className="text-center">Printed</th>
										<th className="text-center">Quantity</th>
										<th className="text-center">Status</th>
										<th className="text-center">Progress</th>
									</tr>
								</thead>
								<tbody>
									{progressData.map((item, index) => (
										<tr key={index}>
											<td className="text-center">{item.barcode}</td>
											<td className="text-center">{item.printed ?? 0}</td>
											<td className="text-center">{item.quantity ?? 0}</td>
											<td className="text-center">{item.status ?? ""}</td>
											<td className="text-center">
												{item.status && item.status === "In-Progress" ? (
													<CircularProgress
														variant="determinate"
														value={
															item.quantity > 0
																? (item.printed / item.quantity) * 100
																: 0
														}
														size="30px"
													/>
												) : (
													<BsFillCheckCircleFill size={"2em"} />
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<Typography variant="h6">No progress data available.</Typography>
					)}
					{isPrintingInProgress && (
						<Typography variant="body2" style={{ color: "red" }}>
							Printing is in progress. Please wait...
						</Typography>
					)}
					{/* Button at the bottom center */}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "20px",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							disabled={isPrintingInProgress} // Disable while printing
							onClick={() => {
								// Handle button click action here, for example, closing the dialog
								setProgressModalOpen(false); // Close the dialog when button is clicked
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</Dialog>
		);
	};

	return (
		<div>
			<Loader loading={loading} />
			<Dialog
				fullScreen
				open={modalOpen}
				onClose={closeHandler}
				TransitionComponent={Transition}
				PaperProps={{
					style: { backgroundColor: "#383838" },
				}}
			>
				<AppBar sx={{ position: "relative" }}>
					<Toolbar style={{ background: "#272727" }}>
						<IconButton
							edge="start"
							color="inherit"
							onClick={closeHandler}
							aria-label="close"
						>
							<IoMdClose />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{heading}
						</Typography>
					</Toolbar>
				</AppBar>

				<div className="dashboard-main" style={{ margin: "10px" }}>
					<div className="dashboard-top">
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<p
								className="stock-count"
								style={{ fontSize: "25px", margin: 0 }}
							>
								{tableTitle}
							</p>
						</div>
					</div>

					<div
						style={{
							marginTop: "10px",
							overflowX: "scroll",
							height: "600px",
							color: "red",
						}}
					>
						{subheading && subHeadingValue && (
							<div className="form-group" style={{ marginLeft: "15px" }}>
								<p className="sub-heading">
									{subheading} : {subHeadingValue}
								</p>
							</div>
						)}

						<table data-table-theme="dark zebra" style={{ marginTop: "20px" }}>
							<thead>
								<tr style={{ background: "#373E43" }}>
									{HeadAndRow?.map((item, index) => (
										<th
											key={index}
											style={{
												background: "#000",
												color: "#fff",
												textAlign: "center",
											}}
										>
											{item?.header || "--"}
										</th>
									))}
									<th
										style={{
											background: "#000",
											color: "#fff",
											height: "20px",
											width: "80px",
											textAlign: "center",
										}}
									>
										<Checkbox
											style={{ color: "white" }}
											checked={selectAll}
											onChange={handleSelectAll}
										/>
										Print
									</th>
								</tr>
							</thead>

							<tbody>
								{(Array.isArray(data) ? data : [data])?.map(
									(item, index) =>
										item && (
											<tr key={index}>
												{HeadAndRow?.map((name, idx) => (
													<td key={idx}>
														{name?.header === "Quantity" ? (
															<>
																<input
																	value={quantities[index]}
																	onChange={(e) =>
																		handleQuantityChange(index, e.target.value)
																	}
																	style={{ width: "50px" }}
																	min={1}
																/>
																{errorMessages[index] && (
																	<p style={{ color: "red", fontSize: "12px" }}>
																		{errorMessages[index]}
																	</p>
																)}
															</>
														) : (
															item[name?.column] || "--"
														)}
													</td>
												))}
												<td>
													<Checkbox
														style={{ color: "white" }}
														checked={isItemSelected(item)}
														onChange={() => handleCheckboxChange(item, index)}
													/>
												</td>
											</tr>
										)
								)}
							</tbody>
						</table>
					</div>

					<ProgressModal />

					<div className="button-container">
						<button onClick={() => printHandler()} className="print-btn">
							<p className="print-text">Initiate Printing</p>
						</button>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
