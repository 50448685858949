
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "12:00",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "6:00",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "12:00",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "6:00",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
];

const MusicChart = () => {
  return (
        <div className="top-music-chart" style={{backgroundColor:"#000",width:"100%", height:"30rem",marginTop:"20px"}}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={1000}
                    height={300}
                    data={data}
                    barCategoryGap={-0}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="0 1" />
                    <XAxis dataKey="name" axisLine={false} tickSize={10} tickLine={false} tick={{ fill: '#fff'}} interval={0} />
                    <Tooltip cursor={{ fill: "transparent" }} />
                    <Bar dataKey="pv" stackId="a" fill="#E79299" barSize={15} radius={[0, 0, 10, 10]} />
                    <Bar dataKey="uv" stackId="a" fill="#E8515B" barSize={15} radius={[10, 10, 0, 0]} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}


export default MusicChart;