import { Button } from "@material-ui/core";
import React from "react";

export default function ListViewModal({ selectedTags, onClose }) {
  const handleOutsideClick = (e) => {
    if (e.target.id === "close-modal") {
      onClose();
    }
  };
  return (
    <div
      id="close-modal"
      onClick={handleOutsideClick}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ backgroundColor: "white", borderRadius: 8, padding: 15 }}>
        <div
          style={{
            height: "400px",
            width: "400px",
            overflow: "scroll",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {selectedTags?.length &&
            selectedTags.map((elem) => <p style={{ color: "#000" }}>{elem}</p>)}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );
}
