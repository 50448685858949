import React, { Component } from "react";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
export default class Printerinfo extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Printer Info</p></div>
                    
                </div>
                <div className="printer-bottom">

                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >ID</th>
                                    <th>Name</th>
                                    <th>IP</th>
                                    <th>Port</th>
                                    <th>Store Name</th>



                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>



                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>







                </div>
            </div>


        )
    }
}