import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import { ibtData } from "../StaticData/IBT";
export default class TransferCancellation extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Transfer Cancellation IBT Data</p></div>

                </div>
                <div className="transfer-bottom">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "720px" }}>
                        <p className="filter-text">Filters</p>
                        <Dropdown
                            placeholder={"All Stores"}
                        />
                        <MyInput
                            type="date"
                        />
                        <MyInput

                            placeholder={"IBT"}
                        ></MyInput>
                    </div>



                    <button className="run-btn"><p className="run-text">Search</p></button>






                </div>
                <div style={{ marginTop: '20px', overflow: 'auto', height: '600px' }}>
                    <table data-table-theme="dark zebra">
                        <thead>
                            <tr style={{ background: "#373E43" }}>
                                <th >ASN</th>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Packed Items</th>
                                <th>Transfer Items</th>
                                <th>Recieved Items </th>
                                <th>Status </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                ibtData.aaData.splice(0, 11).map((data, index) =>
                                    <tr>
                                        <td>{data.asn}</td>
                                        <td>{data.source}</td>
                                        <td>{data.destination}</td>
                                        <td><a href='/viewibtdetails' >9</a></td>
                                        <td><a href='/viewibtdetails' >2</a></td>
                                        <td><a href='/viewibtdetails' >0</a></td>
                                        <td>{data.status}</td>

                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </div>


        )
    }
}