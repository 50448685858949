import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'
import Dropdown from '../components/Dropdown';
import { getPoByOrderId, getPrintingPo, getRtvPo } from '../Path/Path';
import { ibtData } from './StaticData/IBT';
import Loader from '../components/Loader';

const Po = () => {
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [poDataList, setPoDataList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [ibtData, setIbtData] = useState([])
    const [ibtDataFilter, setIbtDataFilter] = useState([])
    const [tableData, setTableData] = useState({})
    const [poNumberVal, setpoNumberVal] = useState('')
    const [run, setRun] = useState(false)
    const [loading, setLoading] = useState(false)
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const [source, setSource] = useState('')
    const [destination, setDestination] = useState('')
    const [asn, setAsn] = useState('')
    useEffect(() => {
        // 
    }, []);
    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const getPo = await getRtvPo()
                .then((async res => {
                    setLoading(false)
                    await setIbtData(res.data)
                    await setIbtDataFilter(res.data)
                }))
                .catch((err => {
                    toast.error('Something Went Wrong')
                    setLoading(false)
                }))
            console.log(getPo.data);



        }
        fetchData();
    }, []);
    const poNumbers = ["POAE-00046315",

        "POAE-00043888",

        "POAE-00043872",

        "TOAE-00017535",

        "TOAE-00017537"]
    const poHandler = (data) => {
        setpoNumberVal(data)
        setPoDataList(poData.filter((item => item.po_number === data)))
        console.log(poData.filter((item => item.po_number === data)));
        console.log(data);
    }
    const filterFunction = async () => {
        const filterArray = ibtDataFilter.filter((
            item => item?.po_number?.toLowerCase()?.includes(asn?.toLowerCase())
                &&
                item?.source?.toLowerCase()?.includes(source?.toLowerCase())
                &&
                item?.destination?.toLowerCase()?.includes(destination?.toLowerCase())
        ))
        await setIbtData(filterArray)
    }


    return (
        <>
            <Loader loading={loading} />
            <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>
            <div className='dashboard-main'>
                <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                    <div>
                        <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Supply Chain (RTV/PO/TO)</p>
                    </div>
                    <div>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>


                </div>
                <div className='dashboard-bottom' style={{ marginTop: "0.5%" }}>
                    <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', gridColumnGap: '10px' }}>

                            <input
                                className='drop-input'
                                // defaultValue={invoiceNumberVal}
                                placeholder="From Date"
                                type={'date'}
                                onChange={(data) => setinvoiceNumberVal(data.target.value)}
                            />
                            <input
                                className='drop-input'
                                type={'date'}
                                // defaultValue={invoiceNumberVal}
                                placeholder="To Date"
                                onChange={(data) => setinvoiceNumberVal(data.target.value)}
                            />
                            <input
                                className='drop-input'

                                defaultValue={asn}
                                placeholder="Asn"
                                onChange={(data) => setAsn(data.target.value)}
                            />
                            <input
                                className='drop-input'

                                defaultValue={source}
                                placeholder="Source"
                                onChange={(data) => setSource(data.target.value)}
                            />
                            <input
                                className='drop-input'

                                defaultValue={destination}
                                placeholder="Destination"
                                onChange={(data) => setDestination(data.target.value)}
                            />
                        </div>

                        <button onClick={() => filterFunction()} className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                    <div style={{ marginTop: '20px', overflow: 'auto', height: '600px' }}>
                        <table data-table-theme="dark zebra">
                            <thead>
                                <tr style={{ background: "#373E43" }}>
                                    <th >ASN</th>
                                    <th >Invoice Number</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Packed Items</th>
                                    <th>Transfer Items</th>
                                    <th>Received Items </th>
                                    <th>Status </th>
                                    <th>Packing Date </th>
                                    <th>Packing Remarks </th>
                                    <th>Shipping Date </th>
                                    <th>Shipping Remarks </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ibtData.map((data, index) =>
                                        <tr>
                                            <td>{data?.po_number}</td>
                                            <td>{data?.invoice_number}</td>
                                            {console.log(data?.invoice_number)}
                                            <td>{data?.source}</td>
                                            <td>{data?.destination}</td>
                                            <td><NavLink to='/viewibtdetails' state={{ data: data?.items?.filter((item => item.process_status === 'packed')), asn: data?.po_number, date: data?.event_date }} >{data?.items?.filter((item => item.process_status === 'packed')).length}</NavLink></td>
                                            <td><NavLink to='/viewibtdetails' state={{ data: data?.items?.filter((item => item.process_status === 'transfered')) }} >{data?.items?.filter((item => item.process_status === 'transfered')).length}</NavLink></td>
                                            <td><NavLink to='/viewibtdetails' state={{ data: data?.items?.filter((item => item.process_status === 'recievied')), asn: data?.po_number }} >{data?.items?.filter((item => item.process_status === 'recievied')).length}</NavLink></td>
                                            <td>{data?.status}</td>
                                            <td>{data?.event_date}</td>
                                            <td>{data?.event_remarks}</td>
                                            <td>{data?.shipping_date}</td>
                                            <td>{data?.shipping_remarks}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Po;
