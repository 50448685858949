import { TextField, Autocomplete, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { withRouter } from "../components/withRouter";
import PurchaseOrder from "./PurchaseOrder";
import PermissionsModal from "../components/PermissionsModal";
import moment from "moment";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";
import { toast } from "react-toastify";

const TransferOrderItems = () => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { item , filter } = location.state || {};

  useEffect(() => {
    setItemsArray(item || []);
  }, [item]);

  const displayLength = isSearched ? filteredItems.length : itemsArray.length;

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    color: "#dddddd",
  };

  const thStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    color: "white",
    backgroundColor: "black",
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const tdStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    color: "white",
  };

     useEffect(() => {
       if (inputValue === "") {
         setFilteredItems(itemsArray);
         setIsSearched(false);
       }
     }, [inputValue, itemsArray]);

  const searchArticleCode = () => {
    if (inputValue === "") {
      toast.error("Enter value to search");
    } else {
      const result = itemsArray.filter(
        (item) => item.Article_Code === inputValue
      );
      console.log("result", result);
      setFilteredItems(result);
      setIsSearched(true);
    }
  };

  const onBarcodeClick = (e, barcode) => {
    e.preventDefault();
    const item = itemsArray.find((item) => item.Barcode === barcode);
    if (item && item.tagIDs) {
      setSelectedTags(item.tagIDs);
    } else {
      setSelectedTags([]);
    }
    setVisible(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{
                backgroundColor: "blue",
                width: "50px",
                borderRadius: "5px",
                color: "white",
                textTransform: "none",
              }}
            >
              Back
            </Button>
            <p
              className="stock-count"
              style={{ fontSize: "20px", margin: "10px" }}
            >
              TO Item Details
            </p>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div>
            <p>Filters</p>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "80px",
                  gridRowGap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={itemsArray
                    .filter((item) => item?.Article_Code !== "")
                    .map((item) => item.Article_Code)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) => setInputValue(v === null ? "" : v)}
                  onInputChange={(e) =>
                    setInputValue(
                      e.target?.value === 0 || e.target?.value === undefined
                        ? ""
                        : e.target?.value
                    )
                  }
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Article Code" />
                  )}
                />
              </div>
              <Button
                variant="contained"
                onClick={searchArticleCode}
                sx={{
                  textTransform: "none",
                  width: 150,
                  borderRadius: "8px",
                }}
                className="run-btn"
              >
                <p className="run-text">Search</p>
              </Button>
            </div>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Article Code</th>
                  <th style={thStyle}>Article Description</th>
                  <th style={thStyle}>Original Location</th>
                  <th style={thStyle}>Delivery Item</th>
                  <th style={thStyle}>Barcode</th>
                  <th style={thStyle}>Description</th>
                  <th style={thStyle}>OBD UOM</th>
                  <th style={thStyle}>Delivery Qty.</th>
                  <th style={thStyle}>OBD Source Site</th>
                  <th style={thStyle}>OBD Storage Location</th>
                  <th style={thStyle}>Process Status</th>
                  {filter == "received" && (
                    <th style={thStyle}>Receiving Processed Qty.</th>
                  )}
                  {filter == "packed" && (
                    <th style={thStyle}>Packing Processed Qty.</th>
                  )}
                  {filter == "shipped" && (
                    <th style={thStyle}>Shipping Processed Qty.</th>
                  )}
                  {filter == "received" && (
                    <th style={thStyle}>Receiving Date</th>
                  )}
                  {filter == "packed" && <th style={thStyle}>Packing Date</th>}
                  {filter == "shipped" && (
                    <th style={thStyle}>Shipping Date</th>
                  )}
                  <th style={thStyle}>Add Char 1</th>
                  <th style={thStyle}>Add Char 2</th>
                  <th style={thStyle}>Add Char 3</th>
                  <th style={thStyle}>Add Char 4</th>
                </tr>
              </thead>
              <tbody>
                {(isSearched ? filteredItems : itemsArray).map(
                  (itemDetail, index) => (
                    <tr key={index}>
                      <td style={tdStyle}>{itemDetail.Article_Code || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Article_Desc || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.original_location || "--"}
                      </td>
                      <td style={tdStyle}>{itemDetail.Deliv_Item || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Barcode || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Desc || "--"}</td>
                      <td style={tdStyle}>{itemDetail.OBD_UOM || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Delivery_Qty || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.OBD_Source_Site || "--"}
                      </td>
                      <td style={tdStyle}>
                        {itemDetail.OBD_Storage_Location || "--"}
                      </td>
                      <td style={tdStyle}>
                        {capitalizeFirstLetter(
                          itemDetail.process_status || "--"
                        )}
                      </td>
                      {filter == "received" && (
                        <td style={tdStyle}>
                          {itemDetail.receivingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.Barcode)
                              }
                            >
                              {itemDetail.receivingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}

                      {filter == "packed" && (
                        <td style={tdStyle}>
                          {itemDetail.packingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.Barcode)
                              }
                            >
                              {itemDetail.packingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}

                      {filter == "shipped" && (
                        <td style={tdStyle}>
                          {itemDetail.shippingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.Barcode)
                              }
                            >
                              {itemDetail.shippingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}
                      {filter == "received" && (
                        <td style={tdStyle}>
                          {itemDetail.receiving_date
                            ? moment(
                                itemDetail.receiving_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.receiving_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      {filter == "packed" && (
                        <td style={tdStyle}>
                          {itemDetail.packing_date
                            ? moment(
                                itemDetail.packing_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.packing_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      {filter == "shipped" && (
                        <td style={tdStyle}>
                          {itemDetail.shipping_date
                            ? moment(
                                itemDetail.shipping_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.shipping_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      <td style={tdStyle}>{itemDetail.Add_Char1 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char2 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char3 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char4 || "--"}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1px",
              color: "white",
            }}
          >
            <h3>List Length: {displayLength}</h3>
          </div>
        </div>
      </div>
      <PermissionsModal
        open={visible}
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        permissions={selectedTags}
      />
    </>
  );
};

export default withRouter(TransferOrderItems);
