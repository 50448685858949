import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editManageTagScreen, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import Slider from 'react-rangeslider';
import { MdViewSidebar } from 'react-icons/md'
import { getChangeStatus, editChangeStatus } from '../Path/Path';

class ChangeStatus extends Component {
    state = {
        percent: 0,
        imageLink: '',
        value: 100,
        status: '',
        text: "",
        filterText: "",
        description: "",
        buttonText: "",
        noteText: "",
        newButtonText: "",
        mainButtonText: "",
        sliderValue: "",
        newButtonText3: "",
        centerText: "",
        slider: true,
        inStoreGi: '',
        showModal: false,
        header: '',
        data: [
            {
                "slider": {
                    "show": false,
                    "value": 0
                },
                "centerMain": {
                    "text": "items",
                    "buttonText": "Clear"
                },
                "header": {
                    "text": "Change Status"
                },
                "filter": {
                    "text": "Damaged",
                    "button": ""
                },
                "description": {
                    "text": "Zones and Disposition"
                },
                "notes": {
                    "buttonText": "Notes",
                    "icon": ""
                },
                "notesPopup": {
                    "text": "Notes",
                    "buttonText": "OK",
                    "icon": ""
                },
                "mainButton": {
                    "text": "Sync",
                    "bgColor": ""
                }
            }
        ]
    }


    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }

    sendingData = async (onClose, value) => {
        const usedData = this.state.data[0]
        const bodyStatus = {

            "slider": {
                "show": this.state.slider === false && this.state.usedData[0].slider.show === false ? this.state.usedData[0].slider.show : this.state.slider,
                "value": this.state.sliderValue !== '' ? this.state.sliderValue : usedData.slider.value,
            },
            "centerMain": {
                "text": this.state.centerText !== '' ? this.state.centerText : usedData.centerMain.text,
                "buttonText": this.state.newButtonText3 !== '' ? this.state.newButtonText3 : usedData.centerMain.buttonText,
            },
            "header": {
                "text": this.state.text !== '' ? this.state.text : usedData.header.text,
            },
            "filter": {
                "text": this.state.filterText !== '' ? this.state.filterText : usedData.filter.text,
                "button": ""
            },
            "description": {
                "text": this.state.description !== '' ? this.state.description : usedData.description.text,
            },
            "notes": {
                "buttonText": this.state.buttonText !== '' ? this.state.buttonText : usedData.notes.buttonText,
                "icon": ""
            },
            "notesPopup": {
                "text": this.state.noteText !== '' ? this.state.noteText : usedData.notesPopup.text,
                "buttonText": this.state.newButtonText !== '' ? this.state.newButtonText : usedData.notesPopup.text,
                "icon": ""
            },
            "mainButton": {
                "text": this.state.mainButtonText !== '' ? this.state.mainButtonText : usedData.mainButton.text,
                "bgColor": ""
            }
        }


        console.log(bodyStatus);
        const editing = await editChangeStatus(bodyStatus)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }





    changeStatusHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Header Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Main Heading'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.text !== '' ? this.state.text : this.state.data[0].header.text} defaultValue={this.state.text !== '' ? this.state.text : this.state.data[0].header.text} onChange={(e) => this.setState({ text: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    damagedHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Text`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.filterText !== '' ? this.state.filterText : this.state.data[0].filter.text} defaultValue={this.state.filterText !== '' ? this.state.filterText : this.state.data[0].filter.text} onChange={(e) => this.setState({ filterText: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    descriptionHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Text`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.description !== '' ? this.state.description : this.state.data[0].description.text} defaultValue={this.state.description !== '' ? this.state.description : this.state.data[0].description.text} onChange={(e) => this.setState({ description: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    notesHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Text`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.noteText !== '' ? this.state.noteText : this.state.data[0].notes.buttonText} defaultValue={this.state.noteText !== '' ? this.state.noteText : this.state.data[0].notes.buttonText} onChange={(e) => this.setState({ noteText: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    itemHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Text`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.centerText !== '' ? this.state.centerText : this.state.data[0].centerMain.text} defaultValue={this.state.centerText !== '' ? this.state.centerText : this.state.data[0].centerMain.text} onChange={(e) => this.setState({ centerText: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    clearHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Text`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.newButtonText3 !== '' ? this.state.newButtonText3 : this.state.data[0].centerMain.buttonText} defaultValue={this.state.newButtonText3 !== '' ? this.state.newButtonText3 : this.state.data[0].centerMain.buttonText} onChange={(e) => this.setState({ newButtonText3: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    sliderHandler = (value, visible) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.sendingData() }} onClose={() => onClose()} heading={`Change Slider Visibility`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.slider === false && this.state.data[0].slider.show === false ? this.state.data[0].slider.show : this.state.slider}
                                            onChange={(e) => this.setState({ slider: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >

                );
            }
        });
    }

    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={`Change Button Text`}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <React.Fragment>
                        <Form.Label>{'Title Text'}</Form.Label>
                        <Form.Control
                            type={'text'} id={value} placeholder={this.state.mainButtonText !== '' ? this.state.mainButtonText : this.state.data[0].mainButton.text} defaultValue={this.state.mainButtonText !== '' ? this.state.mainButtonText : this.state.data[0].mainButton.text} onChange={(e) => this.setState({ mainButtonText: e.target.value })}
                        />
                    </React.Fragment>
                </Form.Group>
                    </CustomModal >
                );
            }
        });





        // confirmAlert({
        //     customUI: ({ onClose }) => {
        //         return (
        //             <div className='custom-ui'>
        //                 <h1>Change {value} </h1>
        //                 <div>
        //                     <input type={'text'} id={value} placeholder={value} className="custom-input" onChange={(e) => this.setState({ [value]: e.target.value })} />
        //                 </div>
        //                 <button onClick={() => this.closeHandler(onClose, value)}>No</button>
        //                 <button
        //                     onClick={() => {
        //                         onClose();
        //                     }}
        //                 >
        //                     Save
        //                 </button>
        //             </div >
        //         );
        //     }
        // });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getChangeStatus();
        this.setState({ data: data })
        console.log(this.state.data[0].filter);
    };
    render() {
        const value = this.state.value
        // console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Change Status</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Change Status</h1>
                                <div style={{ width: "100%" }}>
                                    <Slider
                                        className='slider-style'
                                        min={0}
                                        max={100}
                                        disabled={true}
                                        value={value}
                                    // onChangeStart={this.handleChangeStart}
                                    // onChange={this.handleChange}
                                    // onChangeComplete={this.handleChangeComplete}
                                    />
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>0%</p>
                                    </div>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>Reader Power 99%</p>
                                    </div>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>100%</p>
                                    </div>

                                </div>
                                <div style={{ width: "100%", borderRadius: "5px", background: "#3F4551", height: "35px", marginBottom: "2%", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                    <div style={{ marginTop: "4px", marginRight: "7px" }}>
                                        <input type="radio" id="test2" name="radio-group"></input>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0px", color: " rgb(207, 205, 205)" }}>Damaged</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>Zone and Disposition</p>
                                    </div>
                                    <div>
                                        <div>
                                            <p style={{ color: " rgb(207, 205, 205)", marginBottom: "0px" }}>Notes</p>
                                        </div>
                                        <div>
                                            <MdViewSidebar className='ri-icon' />
                                        </div>

                                    </div>


                                </div>

                                <div>
                                    <div>  <p style={{ color: " rgb(207, 205, 205)", marginBottom: "0px" }}> item 0</p></div>

                                </div>



                                <div className='cycle-light'>
                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>



                                        <div>  <p className='cycle-department'>Clear</p></div>

                                    </div>



                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", marginBottom: "50%" }}>
                                        <p style={{ fontSize: "80px", fontWeight: "1200px", fontStyle: "bold" }}>0</p>
                                    </div>




                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>Sync</button>
                                </div>


                            </div>
                        </div>
                        <div className='production-main-child'>
                            Change Status
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading' onClick={() => this.changeStatusHandler("change-status")} >{this.state.text !== '' ? this.state.text : this.state.data[0]?.header?.text}</h1>
                                <div style={{ width: "100%" }} onClick={() => this.sliderHandler('slider')}>
                                    {this.state.data[0]?.slider?.show === false && this.state.slider === false ?
                                        <h1 style={{ margin: 10, fontSize: '16px', color: 'red' }}>Slider Off</h1>

                                        :
                                        <Slider

                                            className='slider-style'
                                            min={0}
                                            max={100}
                                            // disabled={true}
                                            value={value}
                                        // onChangeStart={this.handleChangeStart}
                                        // onChange={this.handleChange}
                                        // onChangeComplete={this.handleChangeComplete}
                                        />
                                    }
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>0%</p>
                                    </div>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>Reader Power 99%</p>
                                    </div>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }}>100%</p>
                                    </div>

                                </div>
                                <div style={{ width: "100%", borderRadius: "5px", background: "#3F4551", height: "35px", marginBottom: "2%", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                    <div style={{ marginTop: "4px", marginRight: "7px" }}>
                                        <input type="radio" id="test2" name="radio-group"></input>
                                    </div>
                                    <div>
                                        <p style={{ margin: "0px", color: " rgb(207, 205, 205)" }} onClick={() => this.damagedHandler("damaged")} >{this.state.filterText !== '' ? this.state.filterText : this.state.data[0]?.filter?.text}</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <p style={{ color: " rgb(207, 205, 205)" }} onClick={()=> this.descriptionHandler("description")}>{this.state.description !== '' ? this.state.description : this.state.data[0]?.description?.text}</p>
                                    </div>
                                    <div>
                                        <div>
                                            <p style={{ color: " rgb(207, 205, 205)", marginBottom: "0px" }} onClick={()=> this.notesHandler("notes")}>{this.state.noteText !== '' ? this.state.noteText : this.state.data[0]?.notes?.buttonText}</p>
                                        </div>
                                        <div>
                                            <MdViewSidebar className='ri-icon' />
                                        </div>

                                    </div>


                                </div>

                                <div>
                                    <div>  <p style={{ color: " rgb(207, 205, 205)", marginBottom: "0px" }} onClick={()=> this.itemHandler("items")}>{this.state.centerText !== '' ? this.state.centerText : this.state.data[0]?.centerMain?.text}</p></div>

                                </div>



                                <div className='cycle-light'>
                                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>



                                        <div>  <p className='cycle-department' onClick={()=> this.clearHandler("clear")}>{this.state.newButtonText3 !== '' ? this.state.newButtonText3 : this.state.data[0]?.centerMain?.buttonText}</p></div>

                                    </div>



                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center", marginBottom: "50%" }}>
                                        <p style={{ fontSize: "80px", fontWeight: "1200px", fontStyle: "bold" }}>0</p>
                                    </div>




                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>{this.state.mainButtonText !== '' ? this.state.mainButtonText : this.state.data[0]?.mainButton?.text}</button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(ChangeStatus)