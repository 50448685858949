import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editManageTagScreen, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { IoIosBarcode } from 'react-icons/io';


class Destination extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        data: [
            {
                "header": {
                    "text": "Manage Tags"
                },
                "menu": [
                    {
                        "text": "1234567890",
                        "icon": "",
                    },
                    {
                        "text": "In Store GI",
                        "icon": "",
                    }
                ],

            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        console.log(this.state.data[0]);
        const bodyStatus = {
            menu: [
                {
                    text: this.state.status,

                },
                {
                    text: this.state?.data[0]?.menu[1]?.text,

                },
            ],
        }
        const bodyHeader = {
            header: {
                text: this.state.header
            }
        }
        const bodyInStoreGi = {
            menu: [
                {
                    text: this.state?.data[0]?.menu[0]?.text,

                },
                {
                    text: this.state.inStoreGi,

                },
            ],
        }
        console.log(bodyStatus);
        console.log(bodyHeader);
        console.log(bodyInStoreGi);
        const editing = await editManageTagScreen(value === 'status' ? bodyStatus : value === 'InStoreGi' ? bodyInStoreGi : bodyHeader)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => this.closeHandler(onClose, value)} heading={value === 'status' ? "Change Status" : value === 'inStoreGi' ? "Change In Store Gi" : "Change Header"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {value === 'status' ?
                                <React.Fragment>
                                    <Form.Label>{'Status'}</Form.Label>
                                    <Form.Control
                                        type="text"


                                        id={value}
                                        defaultValue={this.state.status}
                                        placeholder={this.state.status === '' ? 'Status' : this.state.status}
                                        onChange={(e) => this.setState({ status: e.target.value })}
                                    />
                                </React.Fragment>
                                :
                                value === 'inStoreGi' ?
                                    < React.Fragment >
                                        <Form.Label>{'In Store Gi'}</Form.Label>
                                        <Form.Control


                                            type={'text'}
                                            id={value}
                                            defaultValue={this.state.inStoreGi}
                                            placeholder={this.state.inStoreGi === '' ? 'In Store Gi' : this.state.inStoreGi}
                                            onChange={(e) => this.setState({ inStoreGi: e.target.value })}
                                        />
                                    </React.Fragment>
                                    :
                                    < React.Fragment >
                                        <Form.Label>{'Header'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id={value}
                                            defaultValue={this.state.header}
                                            placeholder={this.state.header === '' ? 'Header' : this.state.header}
                                            onChange={(e) => this.setState({ header: e.target.value })}
                                        />
                                    </React.Fragment>
                            }
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getManageTagScreen()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Destination</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Destination</h1>
                                <div style={{ minWidth: '250px', width: '100%', marginTop: 10,display:'flex',alignItems:'center' }} className="custom-input" >
                                    <FaSearch style={{marginRight:'20px'}}/>
                                Search Location
                                </div>
                                  {/* <input disabled type={'text'} id={'asnValue'} placeholder={'Type or Scan SKU'} style={{ minWidth: '250px', width: '100%', marginTop: 70 }} className="custom-input" /> */}

                            </div>
                        </div>
                        <div className='production-main-child'>
                            Encode Tag Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Destination</h1>
                                <div style={{ minWidth: '250px', width: '100%', marginTop: 10,display:'flex',alignItems:'center' }} className="custom-input" >
                                    <FaSearch style={{marginRight:'20px'}}/>
                                Search Location
                                </div>
                                  {/* <input disabled type={'text'} id={'asnValue'} placeholder={'Type or Scan SKU'} style={{ minWidth: '250px', width: '100%', marginTop: 70 }} className="custom-input" /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Destination)