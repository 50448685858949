import React from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
    ResponsiveContainer
} from "recharts";


const data = [
    {
      name: "January",
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: "February",
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
    {
      name: "March",
      uv: 2000,
      pv: 9800,
      amt: 2290
    },
    {
      name: "April",
      uv: 2780,
      pv: 3908,
      amt: 2000
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
      amt: 2500
    },
    {
      name: "July",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
    {
      name: "August",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
    {
      name: "September",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
    {
      name: "October",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
    {
      name: "November",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
    {
      name: "December",
      uv: 3490,
      pv: 4300,
      amt: 2100
    },
  
  ];

const ComposedBar = () => {
    return (
        <div className='composed' style={{ backgroundColor: "#000" ,width:"100%",height:"30rem",marginTop:"20px" }}>
            <ResponsiveContainer>

                <ComposedChart width={730} height={250} data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 30
                }}
                >
                    <XAxis dataKey="name" tickLine={false} angle={-45} tick={{ fill: '#fff'}} dy={15} />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <Bar dataKey="pv" barSize={20} fill="#46E258" radius={[10, 10, 0, 0]} label={{ position: "top", fill: "#fff" }}
                    >
                    </Bar>
                    <Line type="monotone" dataKey="uv" stroke="#B4B4B4" dot={false} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ComposedBar;
