import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput";


export default class EnterpriseReportOld extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Stock Summary Details</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div className="drop-data" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "55%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div> <p className="filter-text">Filters</p></div>
                            <MyInput
                                type="date"
                            />
                            <MyInput
                                type="date"
                            ></MyInput>
                            <Dropdown
                                value={"All Stores"}
                            />

                        </div>
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Date</th>
                                    <th>Store Name</th>
                                    <th>on Hand</th>
                                    <th>Count</th>
                                    <th>Item Count Accuracy</th>
                                    <th>Operational Accuracy</th>
                                    <th>On Hand Matching</th>
                                    <th>Unders</th>
                                    <th>Overs</th>
                                    <th>Critical Out of Stock</th>
                                    <th>Front Store</th>
                                    <th>Back Store</th>


                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>Cell 5</td>
                                            <td>Cell 5</td>



                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}