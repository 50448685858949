import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editEncodeScreen, editManageTagScreen, getEncodeScreen, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { IoIosBarcode } from 'react-icons/io';


class EncodeTag extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        clear: '',
        barcodeMode: false,
        placeholder1: "",
        placeholder2: "",
        placeholder3: "",
        placeholder4: "",
        data: [
            {
                "slider": {
                    "value": false
                },
                "barcode": {
                    "icon": "",
                    "value": false
                },
                "resetButton": {
                    "text": "clear",
                    "bgColor": ""
                },
                "input": {
                    "placeholder1": "Type or Scan SKU",
                    "placeholder2": "Scan Printed EPC",
                    "placeholder3": "New EPC",
                    "placeholder4": "Verify EPC by pressing trigger"
                },
                "buttonMain": {
                    "text": "Encode",
                    "bgColor": "#fff"
                },
                "header": {
                    "text": "Encode Tag"
                },
                "_id": "63030a4d912e0105e9df1fc0",
                "createdAt": "2022-08-22T04:47:09.649Z",
                "updatedAt": "2022-08-22T04:47:09.649Z",
                "__v": 0
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        const values = this.state.data[0]
        const body = {
            "barcode": {
                "icon": "",
                "value": this.state.barcodeMode === false && values.barcode.value === false ? values.barcode.value : this.state.barcodeMode
            },
            "resetButton": {
                "text": this.state.clear !== '' ? this.state.clear : values.resetButton.text,
                "bgColor": ""
            },
            "input": {
                "placeholder1": this.state.placeholder1 !== '' ? this.state.placeholder1 : values.input.placeholder1,
                "placeholder2": this.state.placeholder2 !== '' ? this.state.placeholder2 : values.input.placeholder2,
                "placeholder3": this.state.placeholder3 !== '' ? this.state.placeholder3 : values.input.placeholder3,
                "placeholder4": this.state.placeholder4 !== '' ? this.state.placeholder4 : values.input.placeholder4
            },
            "header": {
                "text": this.state.header !== '' ? this.state.header : values.header.text
            },
        }

        const editing = await editEncodeScreen(body)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    headerHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Header Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Header Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ header: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    buttonHandler = (value, visible) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Barcode Mode"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.barcodeMode !== '' ? this.state.barcodeMode : visible}
                                            onChange={(e) => this.setState({ barcodeMode: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    inputHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Header Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Header Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state[value] !== '' ? this.state[value] : this.state.data[0].input[value]}
                                    placeholder={this.state[value] !== '' ? this.state[value] : this.state.data[0].input[value]}
                                    onChange={(e) => this.setState({ [value]: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    clearHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Clear Button Text"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Clear Button Text'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.clear}
                                    placeholder={this.state.clear !== '' ? this.state.clear : this.state.data[0].resetButton.text}
                                    onChange={(e) => this.setState({ clear: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getEncodeScreen()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Encode Tag</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Encode Tag</h1>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: 20 }}>
                                    <button style={{ fill: 'white', color: 'white', cursor: 'pointer' }} className="barcode-btn">
                                        <IoIosBarcode size={30} />
                                    </button>
                                    <button style={{ fill: 'white', color: 'white', cursor: 'pointer', backgroundColor: '#d54545' }} className="barcode-btn">
                                        clear
                                    </button>
                                </div>
                                <input disabled type={'text'} id={'asnValue'} placeholder={'Type or Scan SKU'} style={{ minWidth: '250px', width: '100%', marginTop: 70 }} className="custom-input" />
                                <input disabled type={'text'} id={'asnValue'} placeholder={'Scan Printed EPC'} style={{ minWidth: '250px', width: '100%', marginTop: 30 }} className="custom-input" />
                                <input disabled type={'text'} id={'asnValue'} placeholder={'New EPC'} style={{ minWidth: '250px', width: '100%', marginTop: 30 }} className="custom-input" />
                                <input disabled type={'text'} id={'asnValue'} placeholder={'Verify EPC by pressing trigger'} style={{ minWidth: '250px', width: '100%', marginTop: 30 }} className="custom-input" />
                                <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 100 }}>Encode</button>
                            </div>
                        </div>
                        <div className='production-main-child'>
                            Encode Tag Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 style={{ cursor: 'pointer' }} onClick={() => this.headerHandler('header')} className='screen-heading'>{this.state.header !== '' ? this.state.header : this.state.data[0].header.text}</h1>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: 20 }}>
                                    <button onClick={() => this.buttonHandler('barcodeMode', this.state.data[0].barcode.value)} style={{ fill: 'white', color: 'white', cursor: 'pointer', backgroundColor: this.state.barcodeMode === false && this.state.data[0].barcode.value === false ? 'red' : null }} className="barcode-btn">
                                        <IoIosBarcode size={30} />
                                    </button>
                                    <button onClick={() => this.clearHandler('clearHandler')} style={{ fill: 'white', color: 'white', cursor: 'pointer', backgroundColor: '#d54545', minWidth: '50px' }} className="barcode-btn">
                                        {this.state.clear !== '' ? this.state.clear : this.state.data[0].resetButton.text}
                                    </button>
                                </div>
                                <input onClick={() => this.inputHandler('placeholder1')} type={'text'} id={'asnValue'} value={this.state.placeholder1 !== '' ? this.state.placeholder1 : this.state.data[0].input.placeholder1} style={{ minWidth: '250px', width: '100%', cursor: 'pointer', marginTop: 70 }} className="custom-input" />
                                <input onClick={() => this.inputHandler('placeholder2')} type={'text'} id={'asnValue'} value={this.state.placeholder2 !== '' ? this.state.placeholder2 : this.state.data[0].input.placeholder2} style={{ minWidth: '250px', width: '100%', cursor: 'pointer', marginTop: 30 }} className="custom-input" />
                                <input onClick={() => this.inputHandler('placeholder3')} type={'text'} id={'asnValue'} value={this.state.placeholder3 !== '' ? this.state.placeholder3 : this.state.data[0].input.placeholder3} style={{ minWidth: '250px', width: '100%', cursor: 'pointer', marginTop: 30 }} className="custom-input" />
                                <input onClick={() => this.inputHandler('placeholder4')} type={'text'} id={'asnValue'} value={this.state.placeholder4 !== '' ? this.state.placeholder4 : this.state.data[0].input.placeholder4} style={{ minWidth: '250px', width: '100%', cursor: 'pointer', marginTop: 30 }} className="custom-input" />
                                <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 100 }}>Encode</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(EncodeTag)