import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editLocateItem2, editManageTagScreen, getLocateItem2, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa'


class LocateItem extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        data: [
            {
                "header": {
                    "text": "Locate Item"
                },
                "button": {
                    "text": "Look Up"
                },
            }
        ],
        lookupBtnText: ""
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
       
        const bodyHeader = {
            header: {
                text: this.state.header
            }
        }
        const bodyLookupBtn = {
            button: {
                text: this.state.lookupBtnText
            }
        }

        const editing = await editLocateItem2(value === 'header' ? bodyHeader : value === 'lookupBtnText' && bodyLookupBtn)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => this.closeHandler(onClose, value)} heading={value === 'status' ? "Change Status" : value === 'inStoreGi' ? "Change In Store Gi" : "Change Header"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {value === 'lookupBtnText' ?
                                <React.Fragment>
                                    <Form.Label>{'Look up'}</Form.Label>
                                    <Form.Control
                                        type="text"


                                        id={value}
                                        defaultValue={this.state.lookupBtnText === "" ? this.state.data[0].button?.text : this.state.lookupBtnText}
                                        placeholder={this.state.lookupBtnText === '' ? 'Look up' : this.state.lookupBtnText}
                                        onChange={(e) => this.setState({ lookupBtnText: e.target.value })}
                                    />
                                </React.Fragment>
                                :

                                < React.Fragment >
                                    <Form.Label>{'Header'}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id={value}
                                        defaultValue={this.state.header === '' ? this.state.data[0].header.text : this.state.header}
                                        placeholder={this.state.header === '' ? 'Header' : this.state.header}
                                        onChange={(e) => this.setState({ header: e.target.value })}
                                    />
                                </React.Fragment>
                            }
                        </Form.Group>
                    </CustomModal >
                );
            }
        });


        // confirmAlert({
        //     customUI: ({ onClose }) => {
        //         return (
        //             <div className='custom-ui'>
        //                 <h1>Change {value} </h1>
        //                 <div>
        //                     <input type={'text'} id={value} placeholder={value} className="custom-input" onChange={(e) => this.setState({ [value]: e.target.value })} />
        //                 </div>
        //                 <button onClick={() => this.closeHandler(onClose, value)}>No</button>
        //                 <button
        //                     onClick={() => {
        //                         onClose();
        //                     }}
        //                 >
        //                     Save
        //                 </button>
        //             </div >
        //         );
        //     }
        // });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getLocateItem2()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Locate Items 1</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Locate Item</h1>
                                <button className='screen-btn' style={{ fill: 'white', width: '100%', color: 'white', fontSize: '16px', cursor: 'pointer' }}><FaSearch style={{ marginRight: '20px', marginLeft: 20 }} size={30} fill="color" color='white' />{"Look Up"}</button>

                            </div>
                        </div>
                        <div className='production-main-child'>
                            Locate Item
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 style={{ cursor: 'pointer' }} onClick={() => this.buttonHandler('header')} className='screen-heading'>{this.state.header !== "" ? this.state.header : this.state.data[0]?.header?.text}</h1>
                                <button onClick={() => this.buttonHandler('lookupBtnText')} className='screen-btn' style={{ fill: 'white', width: '100%', color: 'white', fontSize: '16px', cursor: 'pointer' }}><FaSearch style={{ marginRight: '20px', marginLeft: 20 }} size={30} fill="color" color='white' />{this.state.lookupBtnText !== "" ? this.state.lookupBtnText : this.state.data[0].button?.text}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(LocateItem)