import { Autocomplete, TextField } from '@mui/material'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState, useRef } from 'react'
import Barcode from 'react-barcode'
import { toast } from 'react-toastify'
import Dropdown from '../../components/Dropdown'
import DropDownPrint from '../../components/DropDownPrint'
import DropdownZpl from '../../components/DropDownZpl'
import Loader from '../../components/Loader'
import { CustomModal } from '../../components/Modal'
import PrintingModal from '../../components/PrintingModal'
import { Print_Service } from '../../components/printService'
import { getPrintedEPC, getPrintingPo, Innovent_Path, localServer, Path, PathCustom, reqInstance } from '../../Path/Path'
import virgniImage from '../../assets/virginLogi.png'
import Capture from './Capture'
import "./css/tagPrinting.css"

const TagPrinting = () => {
    const [poNumberVal, setpoNumberVal] = useState('')
    const [zplVal, setZplVal] = useState('')
    const [loading, setLoading] = useState(false)
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [zplList, setZplList] = useState([])
    const [zplBody, setZplBody] = useState('')
    const [poDataList, setPoDataList] = useState([])
    const [countAgain, setCountAgain] = useState(true)
    const [invoiceList, setInvoiceList] = useState([])
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const [deviceList, setDevices] = React.useState([]);
    const [deviceListFilter, setDevicesFilter] = React.useState([]);
    const [printer, setPrinter] = React.useState(null);
    const [lookValue, setLookValue] = React.useState(null);
    const [printerVal, setPrinterVal] = React.useState(null);
    const [inputIndex, setInputIndex] = React.useState('');
    const [captureIndex, setCaptureIndex] = React.useState(null);
    const [captureValue, setCaptureValue] = React.useState(null);
    const [invoiceParent, setInvoiceParent] = React.useState(0);
    const [NewIndex, setNewIndex] = React.useState(null);
    const [printedEPC, setprintedEPC] = React.useState([]);
    const [userData, setUserData] = React.useState({});
    const [storeArray, setStoreArray] = React.useState([])
    const [datadata, setDataData] = React.useState({});
    const [userNAME, setUserNAME] = React.useState('');

    const [zebraPrinter, setZebraPrinter] = React.useState(null);
    const childRef = useRef();
    const poNumber = (e) => {
        console.log(e.target.value);
    }
    useEffect(() => {
        async function getLoginData(params) {
            const data = await localStorage.getItem('loginData')
            const parseData = JSON.parse(data)
            const token = parseData?.token
            if (token) {
                const decodetoken = jwtDecode(token)
                setUserData(decodetoken?.payload?.siteId?.storecode)
                setDataData(decodetoken?.payload?.siteId)
                setUserNAME(decodetoken?.payload?.userName)
            }

        }
        getLoginData()
    }, [])
    useEffect(() => {
        window.BrowserPrint.getLocalDevices(function (deviceList) {
            setDevices(deviceList.printer)
            setDevicesFilter(deviceList.printer)
        }, (err) => {
            console.log(err)
        })
    }, [])
    const handleInvoiceQTY = (e, index) => {
        setInvoiceParent(e)
        setInputIndex(index)
    }
    const handleIndex = (index, e) => {

        setInputIndex(index)
        setInvoiceParent(e)
    }
    const gettingIndex = (value) => {
        // setCaptureIndex(index)
        setCaptureValue(value)
    }
    const writeIframe = (str) => {
        var doc = document.getElementById('info').contentWindow.document;
        doc.open();
        doc.write(str);
        doc.close()
    }
    const handlePrint = () => {
        let networkCallResponse = `
        ^XA
    
    ^FX Top section with square logo
    ^CF0,20
    ^FO10,10^GB35,35,35^FS
    ^FO18,18^FR^GB35,35,35^FS
    ^FO23,23^GB16,16,16^FS
    
    ^FX Top section with square name and address
    ^FO70,13^FDDriscolls, Inc.^FS
    ^CFA,19
    ^FO70,35^FDWatsonville^FS
    ^FO70,55^FDCalifornia^FS
    ^FO70,75^FDUnited States (USA)^FS
    ^FO10,100^GB700,1,1^FS
    
    ^FX Second section with barcode.
    ^BY2,2,35
    ^FO60,110^BC^FD12345678^FS
    
    ^XZ
        `
        Print_Service.print(printer, networkCallResponse)
    }
    const handleChange = (event) => {
        setPrinter(event.target.value);
    };
    const invoiceNumber = (e) => {
        console.log(e.target.value);
    }

    const checkBox = (e) => {
        console.log(e.target.value);
    }
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const getPo = await getPrintingPo()
            const zpl = await axios.get(`${localServer}store/zpl/get`)
            setZplList(zpl.data)
            let poList = getPo?.map((item => item?.po_number))
            let inList = getPo?.map((item => item?.SKU_original))
            console.log(getPo);
            const filterPoList = poList.filter(function (item, pos) {
                return poList.indexOf(item) == pos;
            })

            const filterInList = inList.filter(function (item, pos) {
                return inList.indexOf(item) == pos;
            })
            await setPoData(getPo)
            await setPoList(filterPoList)
            await setInvoiceList(filterInList)

            // const uniqueVal = await axios.post(`${PathCustom}/store/sequence`, { "count": 100 })
            // console.log(uniqueVal?.data?.data, 'uniqueVal');
            setLoading(false)

        }

        fetchData();
    }, []);
    useEffect(() => {

        async function fetchData() {
            const compareVal = localStorage.getItem("countStart")
            const compareVal2 = localStorage.getItem("count")

            if (compareVal === compareVal2) {
                const uniqueVal = await axios.post(`${PathCustom}/store/sequence`, { "count": 100 })
                console.log(uniqueVal?.data?.data, 'uniqueVal');
                await localStorage.setItem("count", uniqueVal?.data?.data?.end)
                await localStorage.setItem("countStart", uniqueVal?.data?.data?.start)
                await setCountAgain(true)
                setLoading(false)
            }
            // asd
        }
        fetchData();
        gettingStores()
    }, [countAgain]);
    const getPrintedEPCABC = async () => {
        const sendingData = await poData.filter((item => item.po_number === poNumberVal))
        const body = sendingData.map((item => item?.SKU_original))
        console.log(body, 'body');
        const printedEPCData = await getPrintedEPC(body)
        console.log(printedEPCData?.data);
        await setprintedEPC(printedEPCData?.data)
    }
    const gettingStores = async () => {
        let data = null
        await reqInstance.get(`${Innovent_Path}STORES`)
            .then((async res => {
                data = await res?.data?.results
            }))

            .catch((err => {
                console.log(err);
            }))

        await setStoreArray(data)
    }
    useEffect(() => {
        console.log(storeArray, 'storeArray');
    }, [storeArray])

    const poHandler = async (data) => {
        setLoading(true)
        setpoNumberVal(data)



        const sendingData = await poData.filter((item => item.po_number === data))
        const body = sendingData.map((item => item?.SKU_original))
        console.log(body, 'body');
        const printedEPCData = await getPrintedEPC(body)
        console.log(printedEPCData, 'printedEPCData');
        console.log(printedEPCData?.data);
        await setprintedEPC(printedEPCData?.data)

        setPoDataList(poData.filter((item => item.po_number === data)))
        console.log(poData.filter((item => item.po_number === data)));
        console.log(data);
        setInvoiceParent(null)
        setCaptureValue(null)
        setLoading(false)
    }
    const printHandler = (data) => {
        setPrinter(data)
        setPrinterVal(data?.name)
        // setDevices(deviceListFilter.filter((item => item.name === data.name)))
        // console.log(deviceListFilter.filter((item => item.name === data.name)));
        console.log(data);
    }
    const zplHandler = (data) => {

        setZplVal(data.zplName)

        setZplBody(data.zplBody)
    }
    const inputFunction = (data) => {
        // setLookValue(data.target.value)
        setInvoiceParent(data.target.value)

        // setInputIndex(index)

    }
    const printBarcode = async () => {
        if (poNumberVal === '' || invoiceNumberVal === '') {
            toast.error("Please Add Po Number and Invoice Number")
        } else {

            // var doc = document.getElementById('printf').innerHTML;
            let printContents = document.getElementById('printff').innerHTML;
            // let originalContents = document.body.innerHTML;
            // document.body.innerHTML = printContents;
            // window.print();
            // document.body.innerHTML = originalContents;
            const poBody =
            {
                "ASN": `${poNumberVal}|${invoiceNumberVal}`,
                "process": "packing"
            }
            // const poDetails = reqInstance.post(`https://virgin.innovent.site/innovent/api/picking-orderid`, poBody)
            // console.log(poDetails, 'poDetails');
            var doc = await document.getElementById('printfff').contentWindow.document;
            doc.open();
            doc.write(printContents);
            doc.close()
            window.frames["printfff"].focus()
            window.frames["printfff"].print()
            // doc.open();

            // doc.close()
            // window.frames["printf"].focus()
            // window.frames["printf"].print()
        }
    }
    return (
        <>
            <PrintingModal onClick={() => setCaptureValue(false)} onSave={() => childRef.current.getAlert()} open={captureValue} >
                <div style={{ display: 'flex', height: 300, gridColumnGap: '20px' }} >

                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size={'small'}
                        getOptionLabel={(option) => option?.zplName}
                        options={zplList}
                        onChange={(e, v) => zplHandler(v === null ? '' : v)}
                        sx={{ width: 170 }}
                        renderInput={(params) => <TextField
                            {...params} label="Zpl" />}
                    />


                    <TextField
                        sx={{ width: 170 }}
                        defaultValue={invoiceParent}
                        value={invoiceParent}
                        placeholder="Print Count"
                        size={'small'}
                        onChange={(data) => inputFunction(data)} id="outlined-basic" label="Print Count" variant="outlined" />


                    <Autocomplete
                        disablePortal

                        id="combo-box-demo"
                        size={'small'}
                        getOptionLabel={(option) => option?.name}
                        options={deviceList}
                        onChange={(e, v) => printHandler(v === null ? '' : v)}
                        sx={{ width: 170 }}
                        renderInput={(params) => <TextField
                            {...params} label="Printer" />}
                    />



                </div>
            </PrintingModal>
            <Loader loading={loading} />
            <div id="printff" name="printff" style={{ display: 'none', justifyContent: 'center' }} >

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={virgniImage} height={300} width={300} />
                    <h1 style={{ fontWeight: 'bold' }}>Product Receipt </h1>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Purchase Order</h3>
                        <h3>{poNumberVal}</h3>

                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Invoice Number</h3>
                        <h3>{invoiceNumberVal}</h3>

                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Date</h3>
                        <h3>{new Date().toLocaleString()}</h3>

                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Destination</h3>
                        <h3>{storeArray.filter((item => item?.StoreID === poDataList?.filter((item => item?.po_number === poNumberVal))[0]?.destination))[0]?.StoreID + ' ' + storeArray.filter((item => item?.StoreID === poDataList?.filter((item => item?.po_number === poNumberVal))[0]?.destination))[0]?.StoreName}</h3>

                    </div>
                    {/* <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Store Name</h3>
                        <h3>{datadata?.storename}</h3>

                    </div> */}
                </div>
                <Barcode width={1} value={`${poNumberVal}|${invoiceNumberVal}`} />
            </div>
            <iframe id="printfff" name="printfff" style={{ display: 'none', justifyContent: 'center' }} >

            </iframe>
            <div style={{ minHeight: '90vh' }} className='dashboard-main'>
                <div className='dashboard-top'>
                    <div>
                        <p className='stock-count'>Tag Printing</p>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>
                </div>
                <div className='dashboard-bottom' style={{ marginTop: "1%" }}>
                    <div style={{ display: 'flex', width: '100%', gridColumnGap: '1rem', flexWrap: 'wrap' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            options={poList.reverse()}
                            onChange={(e, v) => poHandler(v === null ? '' : v)}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField
                                {...params} label="PO Number" />}
                        />
                        <TextField
                            sx={{ width: 200 }}
                            defaultValue={invoiceNumberVal}
                            placeholder="Invoice Number"
                            size={'small'}
                            onChange={(data) => setinvoiceNumberVal(data.target.value)} id="outlined-basic" label="Invoice Number" variant="outlined" />
                        <div className='check-box'>
                            <button onClick={() => printBarcode()} className="button-custom" >Print Product Receipt</button>
                        </div>
                    </div>



                </div>
                <div style={{ height: '79vh', overflowY: 'auto', width: '100%' }}>
                    <div className='line-line'>
                        <span>PO Items</span>
                    </div>
                    {poDataList.map((item, index) =>
                        <div key={index}>
                            <Capture
                                ref={childRef}
                                key={index}
                                zplBody={zplBody}
                                skuOriginal={item?.SKU_original}
                                changeCount={() => setCountAgain(false)}
                                changeCountChecking={() => setCountAgain(true)}
                                sku={poDataList.map((item => item.SKU))[index]?.substring(0, 13)}
                                skuList={poDataList}
                                description={item?.description}
                                inin={index}
                                storecode={userData}
                                username={userNAME}
                                poNumberVal={poNumberVal}
                                invoiceNumberVal={invoiceNumberVal}
                                loading={() => setLoading(true)}
                                loadingFalse={() => setLoading(false)}
                                vendor_name={item?.vendor_name}
                                actual_qty={item?.actual_qty}
                                getPrintedEPCABC={() => getPrintedEPCABC()}
                                indexData={{ index: index, inputIndex: inputIndex }}
                                remainingEPC={printedEPC?.filter((data => data?._id === item?.SKU_original))}
                                // settingIndex={(value) => settingIndex(false)}
                                gettingIndex={(value) => gettingIndex(value)}
                                setInvoiceqtyValParent={(e, indexData) => handleInvoiceQTY(e, indexData)}
                                setIndex={(indexData, e) => handleIndex(indexData, e)}
                                getInvoiceqtyValParent={Number(invoiceParent)}
                                poqty={item?.QTY}
                                loopValue={Number(lookValue) || 1}
                                gettingIndexs={NewIndex}
                                setNewIndex={() => setNewIndex(index)}
                                selectedPrinter={printer}
                                // invoiceqty={item?.SKU}
                                // receivedqty={item?.SKU}
                                delta={Number(item?.delta_qty)}
                                linenumber={item?.line_number}
                            />
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default TagPrinting;
