/* eslint no-restricted-globals: "off" */
const bigInt = require('big-integer');
var hexToBinary = require('hex-to-binary');
const header = '00110000';
const partition = {
  bits: {
    company: [40, 37, 34, 30, 27, 24, 20],
    reference: [4, 7, 10, 14, 17, 20, 24]
  },
  digits: {
    company: [12, 11, 10, 9, 8, 7, 6],
    reference: [1, 2, 3, 4, 5, 6, 7]
  }
};

function BitsHelper(val, len, valbase = 16) {
  this.val = val;
  this.bitlength = len;

  this.bits = bigInt(val, valbase).toString(2);
  this.bits = Array(len - this.bits.length + 1).join('0') + this.bits;
}
function encode(sku, serial, companyLength = 7, filter = 1) {
  const header = '001011011101';
  console.log(sku)
  let itemNumber = String(ConvertBase(sku).from(10).to(2))
  for (var i = itemNumber.length; i < 50; i++) {
    itemNumber = "0" + itemNumber
  }
  console.log(itemNumber)
  serial = String(ConvertBase(serial).from(10).to(2))
  for (var j = serial.length; j < 34; j++) {
    serial = "0" + serial
  }
  console.log(serial)
  return bigInt(header + itemNumber + serial, 2).toString(16).toUpperCase();
}

function getCheckDigit(key) {
  const paddedKey = key.padStart(18, '0')
  const numbers = paddedKey.split('').map(n => parseInt(n))
  const sum = numbers.reduce((acc, n, i) => {
    acc += i % 2 ? n * 3 : n;
    return acc
  }, 0)
  const next = Math.ceil(sum / 10) * 10;
  return next - sum;
}
// const convertToDec = (bin) => {
//   let length = bin.length;
//   let pos = length - 1;

//   for (let i = 0; i <= length - 1; i++) {
//     if (parseInt(bin.charAt(i)) !== 0 && parseInt(bin.charAt(i)) !== 1) {
//       // alert("Por favor, digite apenas 0 e 1.");
//       return;
//     } else {
//       value = parseInt(bin.charAt(i)) * Math.pow(2, pos);
//       convertedArr.push(value);
//       pos -= 1;
//       conversion = convertedArr.reduce((acc, curr) => {
//         return acc + curr;
//       }, 0);
//     }
//   }
//   return conversion;
// };
function hex2bin(hex) {

  let val = hexToBinary(hex)
  console.log(val.toString())
  return val.replace("undefined", "")
  // val = val.toString(2)
  // if (val.length < 96) {
  //   for (var i = val.length; i < 96; i++) {
  //     val = "0" + val
  //   }
  // }
}

function ConvertBase(num) {
  return {
    from: function (baseFrom) {
      return {
        to: function (baseTo) {
          return parseInt(num, baseFrom).toString(baseTo);
        }
      };
    }
  };
};

function parse(epc) {
  const bin = hex2bin(epc)
  // console.log(epc)
  // console.log(bin)
  // console.log(bin.length)
  let itemNumber = bin.slice(12, 62)
  // console.log(itemNumber)
  // console.log(
  //   ConvertBase(itemNumber).from(2).to(10)
  // )
  itemNumber = ConvertBase(itemNumber).from(2).to(10)
  const parts = {
    Header: undefined,
    Filter: undefined,
    Partition: undefined,
    CompanyPrefix: undefined,
    ItemReference: undefined,
    SerialNumber: undefined,
    SerialReference: undefined,
    LocationReference: undefined,
    Extension: undefined,
    AssetType: undefined,
    IndividualAssetReference: undefined,
    ServiceReference: undefined,
    DocumentType: undefined,
    ManagerNumber: undefined,
    ObjectClass: undefined,
    CAGEOrDODAAC: undefined,
    CheckDigit: undefined,
    Sku: undefined
  };

  // // initialize the bit helper
  // const bh = new BitsHelper(epc, 96);

  // // make sure the incoming value is really SGTIN by checking the header
  // if (bh.bits.slice(0, 8) !== header)
  //   throw new Error(epc + ' is not a valid SGTIN.');

  // // ok, looks good.  parse the stuff we'll need to figure out the rest
  // parts.Header = bh.bits.slice(0, 8);
  // parts.Filter = parseInt(bh.bits.slice(8, 11), 2);
  // parts.Partition = parseInt(bh.bits.slice(11, 14), 2);
  // // find the end of the company portion by calculating the number of bits
  // // and adding it to the starting offset
  // const companyPrefixEnd = 14 + partition.bits.company[parts.Partition];

  // // get the company value from the bits, convert to a string
  // let company = parseInt(bh.bits.slice(14, companyPrefixEnd), 2).toString();
  // // pad the string with leading zeros to make-up the length according to the calculate length
  // company = Array(partition.digits.company[parts.Partition] - company.length + 1).join('0') + company;

  // parts.CompanyPrefix = company;

  // let item = parseInt(bh.bits.slice(companyPrefixEnd, companyPrefixEnd + partition.bits.reference[parts.Partition]), 2).toString();
  // item = Array(partition.digits.reference[parts.Partition] - item.length + 1).join('0') + item;

  // parts.ItemReference = item;
  // parts.SerialNumber = parseInt(bh.bits.slice(58), 2);
  // const gs1Key = `${parts.ItemReference.substr(0, 1)}${parts.CompanyPrefix}${parts.ItemReference.substr(1)}`
  // parts.CheckDigit = getCheckDigit(gs1Key);
  parts.Sku = itemNumber
  return parts
}

module.exports = {
  parse,
  encode
}


