import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { withRouter } from "../components/withRouter";
class Dashboard extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Stock Count Summary</p></div>
                    <div>
                        <button className="refresh-btn"><p className="refresh-text">Refresh</p></button>
                    </div>
                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div className="drop-data">
                        <Dropdown
                            value={"Store Id"}
                        />
                        <Dropdown
                            value={"All Department"}
                        />
                        <Dropdown
                            value={"All Colors"}
                        />
                        <Dropdown
                            value={"All Sizes"}
                        />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Dropdown />
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <p className="show">Show Overs</p>
                                <input type="checkbox" size={30}></input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="run-btn"><p className="run-text">Run</p></button>
                    </div>
                </div>
            </div>


        )
    }
}
export default withRouter(Dashboard)
