import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { withRouter } from "../components/withRouter";
import MyInput from "../components/MyInput";
import { ibtDetail } from "./StaticData/IBT";
import { TextField } from "@mui/material";
import { AiOutlinePlusCircle } from 'react-icons/ai'
import _ from "lodash";
import { NavLink } from "react-router-dom";

class POCreatedDetail extends Component {
    state = {
        poNumbers: ["POAE-00046315",

            "POAE-00043888",

            "POAE-00043872",

            "TOAE-00017533",

            "TOAE-00015537",
            "TOAE-00012535",

            "TOAE-00018537",
            "TOAE-00011535",

            "TOAE-00012537"],
        detail: [],
        detailFilter: [],
        epc: '',
        allData: [],
        sendNext: [],

        selectedBarcode: null
    }

    async componentDidMount() {
        const detail = this.props?.location?.state?.data.length !== 0 ? this.props.location.state.data : []
        let dataa = _.groupBy(detail, "ItemBarCode")
        const headings = Object.keys(dataa) ? Object.keys(dataa) : []
        const datadata = headings?.map((item => {
            return {
                name: item,
                Count: dataa[item]?.length,
                SKU: dataa[item][0]?.SKU,
                SKU_original: dataa[item][0]?.SKU_original,
                Quantity: dataa[item][0]?.Quantity,
                original_location: dataa[item][0]?.original_location,
            }
        }))
        console.log(datadata, "datadata")
        this.setState({ detail: datadata, detailFilter: datadata, allData: detail })
    }
    searchFilter = async () => {
        console.log(this.state.detail, 'asdasd');
        const filterData = await this.state.detailFilter.filter((item => item?.SKU_original?.toLowerCase()?.includes(this.state.epc?.toLowerCase())))
        await this.setState({ detail: filterData })
    }

    render() {
        const detail = this.props?.location?.state?.data?.length !== 0 ? this.props?.location?.state?.data : false
        const asn = this.props?.location?.state?.asn ? this.props?.location?.state?.asn : false
        const date = this.props?.location?.state?.date ? this.props.location?.state?.date : false

        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">View ASN Details</p></div>
                </div>
                <div className="dashboard-bottom">

                    {
                        this.state.selectedBarcode === null &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                            <div style={{ display: 'flex', gridColumnGap: '15px', marginBottom: '10px' }}>

                                <TextField
                                    sx={{ width: 300 }}
                                    defaultValue={this.state.epc}
                                    value={this.state.epc}
                                    placeholder="SKU"
                                    size={'small'}
                                    onChange={(data) => this.setState({ epc: data.target.value })}
                                    // disabled
                                    id="outlined-basic" label="SKU" variant="outlined" />
                            </div>
                            <button onClick={() => this.searchFilter()} className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    }


                    <div >
                        {
                            this.state.selectedBarcode ?
                                <table data-table-theme="dark zebra">
                                    {
                                        this.state?.selectedBarcode?.tagIDs?.length !== 0 ?
                                            <React.Fragment>
                                                <thead>
                                                    <tr style={{ background: "#373E43" }}>

                                                        <th>EPC</th>



                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state?.selectedBarcode?.tagIDs?.map((data, index) =>
                                                            <tr>
                                                                <td>{data}</td>



                                                                {/* <td>{data?.original_location}</td> */}
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <thead>
                                                    <tr style={{ background: "#373E43" }}>
                                                        <th >No Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{"No Data"}</td>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                    }
                                </table>
                                :
                                <table data-table-theme="dark zebra">
                                    {
                                        this.state?.detail.length !== 0 ?
                                            <React.Fragment>
                                                <thead>
                                                    <tr style={{ background: "#373E43" }}>

                                                        <th>SKU_original</th>
                                                        <th>Barcode</th>
                                                        <th>original_location</th>
                                                        <th>Quantity</th>



                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        this.state.detail?.map((data, index) =>
                                                            <tr>
                                                                <td>{data?.SKU_original}</td>
                                                                <td>{data?.name}</td>
                                                                <td>{data?.original_location}</td>
                                                                <td  >{data?.Quantity} </td>



                                                                {/* <td>{data?.original_location}</td> */}
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <thead>
                                                    <tr style={{ background: "#373E43" }}>
                                                        <th >No Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{"No Data"}</td>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                    }
                                </table>
                        }

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {
                                this.state.selectedBarcode ?
                                    <h1 onClick={() => this.setState({ selectedBarcode: null })} style={{ color: 'white', fontSize: '15px', marginTop: '10px' }}>
                                        Cancel
                                    </h1>
                                    :
                                    <h1 style={{ color: 'white', fontSize: '15px', marginTop: '10px' }}>
                                        ASN length ({detail?.length})
                                    </h1>
                            }
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
export default withRouter(POCreatedDetail)