import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit, TbEmphasis } from 'react-icons/tb'
import { IoIosBarcode } from 'react-icons/io'
import { FaSearch } from 'react-icons/fa'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import receivingLogo from '../assets/packing.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Slider from 'react-rangeslider'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter'
import { editPacking, editTransferOutDetail, getPacking, getTransferOutDetail } from '../Path/Path'
import { AiOutlineBars } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import "../css/Packing.css"
import { CustomModal } from '../components/Modal'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

class TransferDetailTag extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        value: 100,
        asnValue: '',
        imageLinkBg: '',
        manualHeading: '',
        powerReader: true,
        header: '',
        slider: false,
        foundMissed: '',
        foundExpected: '',
        foundExtra: '',
        extra: '',
        destination: '',
        notes: '',
        productsButton: '',
        rfidButton: '',
        filterButton: '',
        validateButton: '',
        receiving: [
            {
                "header": {
                    "text": "Transfer"
                },
                "slider": {
                    "show": false,
                    "value": 99
                },
                "foundMissed": {
                    "text": "Missed"
                },
                "foundExpected": {
                    "text": "Expected"
                },
                "foundExtra": {
                    "text": "Extra"
                },
                "extra": {
                    "text": "Extra"
                },
                "destination": {
                    "text": "Destination"
                },
                "notes": {
                    "buttonText": "Notes",
                    "icon": ""
                },
                "productsButton": {
                    "text": "Products"
                },
                "rfidButton": {
                    "text": "RFID"
                },
                "filterButton": {
                    "value": 0
                },
                "validateButton": {
                    "text": "Validate and Send"
                },
            }
        ],
    }
    handleChangeStart = () => {
        console.log('Change event started')
    };

    handleChange = value => {
        this.setState({
            value: value
        })
    };

    handleChangeComplete = () => {
        console.log('Change event completed')
    };
    closeHandler = (onClose, value) => {
        this.setState({ [value]: '' })
        onClose()
    }
    closeBarcodeHandler = (onClose, btnName, valueBtn) => {
        this.setState({ [btnName]: valueBtn })
        onClose()
    }
    headerHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Header Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Main Heading'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.header !== '' ? this.state.header : this.state.receiving[0].header.text} defaultValue={this.state.header !== '' ? this.state.header : this.state.receiving[0].header.text} onChange={(e) => this.setState({ header: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    sliderHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Slider Visibility`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.slider === false && this.state.receiving[0].slider.show === false ? this.state.receiving[0].slider.show : this.state.slider}
                                            onChange={(e) => this.setState({ slider: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    foundMissedHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.foundMissed !== '' ? this.state.foundMissed : this.state.receiving[0].foundMissed.text} onChange={(e) => this.setState({ foundMissed: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    foundExpectedHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.foundExpected !== '' ? this.state.foundExpected : this.state.receiving[0].foundExpected.text} onChange={(e) => this.setState({ foundExpected: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    foundExtraHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.foundExtra !== '' ? this.state.foundExtra : this.state.receiving[0].foundExtra.text} onChange={(e) => this.setState({ foundExtra: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    extraHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (

                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.extra !== '' ? this.state.extra : this.state.receiving[0].extra.text} onChange={(e) => this.setState({ extra: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >

                )
            }
        })
    }
    destinationHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.destination !== '' ? this.state.destination : this.state.receiving[0].destination.text} onChange={(e) => this.setState({ destination: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    notesHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.notes !== '' ? this.state.notes : this.state.receiving[0].notes.buttonText} onChange={(e) => this.setState({ notes: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    productsButtonHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.productsButton !== '' ? this.state.productsButton : this.state.receiving[0].productsButton.text} onChange={(e) => this.setState({ productsButton: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    rfidButtonHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.rfidButton !== '' ? this.state.rfidButton : this.state.receiving[0].rfidButton.text} onChange={(e) => this.setState({ rfidButton: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    filterButtonHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.filterButton !== '' ? this.state.filterButton : this.state.receiving[0].filterButton.value} onChange={(e) => this.setState({ filterButton: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    validateButtonHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change  Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} defaultValue={this.state.validateButton !== '' ? this.state.validateButton : this.state.receiving[0].validateButton.text} onChange={(e) => this.setState({ validateButton: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                )
            }
        })
    }
    imageHandler = (files, stateName) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ [stateName]: url, percent: 0 })
                    });
                }
            );
        }

    }
    editScreen = async () => {
        const usedData = this.state.receiving[0]
        const body = {
            "header": {
                "text": this.state.header !== '' ? this.state.header : usedData.header.text
            },
            "slider": {
                "show": this.state.receiving[0]?.slider?.show === false && this.state.slider === false ? this.state.receiving[0]?.slider?.show : this.state.slider,
            },
            "foundMissed": {
                "text": this.state.foundMissed !== '' ? this.state.foundMissed : usedData.foundMissed.text
            },
            "foundExpected": {
                "text": this.state.foundExpected !== '' ? this.state.foundExpected : usedData.foundExpected.text
            },
            "foundExtra": {
                "text": this.state.foundExtra !== '' ? this.state.foundExtra : usedData.foundExtra.text
            },
            "extra": {
                "text": this.state.extra !== '' ? this.state.extra : usedData.extra.text
            },
            "destination": {
                "text": this.state.destination !== '' ? this.state.destination : usedData.destination.text
            },
            "notes": {
                "buttonText": this.state.notes !== '' ? this.state.notes : usedData.notes.buttonText,
            },
            "productsButton": {
                "text": this.state.productsButton !== '' ? this.state.productsButton : usedData.productsButton.text
            },
            "rfidButton": {
                "text": this.state.rfidButton !== '' ? this.state.rfidButton : usedData.rfidButton.text
            },
            "validateButton": {
                "text": this.state.validateButton !== '' ? this.state.validateButton : usedData.validateButton.text
            },
        }
        console.log(body);
        await editTransferOutDetail(body).then(
            () => {
                toast.success('Data Updated')
            }
        ).catch((err) => {
            toast.success('Something Went Wrong')
            console.log(err);
        })
    }
    async componentDidMount() {
        const receiving = await getTransferOutDetail()
        this.setState({ receiving: receiving })
        await console.log(receiving, 'receiving');
    }
    render() {
        const value = this.state.value
        const usedData = this.state.receiving[0]
        const asnValue = this.state.asnValue
        console.log(this.state);
        return (
            <div className="dashboard-main-mine">
                <div className="dashboard-top-mine">
                    <div> <p className="stock-count">Transfer Items Details</p></div>
                    <div>
                        <button style={{ marginTop: '0%' }} onClick={() => this.editScreen()} className="refresh-btn"><p className="refresh-text">Save</p></button>
                    </div>
                </div>
                <div className='production-main-div'>
                    <div className='production-main-child'>
                        Default Screen
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 onClick={() => this.mainHeadingHandler('mainHeading')} className='screen-heading'>Transfer (Asd)</h1>
                            <div className='slider' style={{ width: '100%', }} >
                                <Slider
                                    className='slider-style'
                                    min={0}
                                    max={100}
                                    disabled={true}
                                    value={value}
                                // onChangeStart={this.handleChangeStart}
                                // onChange={this.handleChange}
                                // onChangeComplete={this.handleChangeComplete}
                                />
                            </div>
                            <div className="percentNumber">
                                <p className="percent">0%</p>
                                <p className="percent">Reader Power:99 %</p>
                                <p className="percent">100%</p>
                            </div>
                            <div className="Packing-order">
                                <h2><GoLocation className='goloc3' />&nbsp;Destination:</h2>
                                <span style={{ marginLeft: "auto" }}>Extra:</span>
                            </div>
                            <div className="num2" style={{ marginTop: "3%" }}>
                                <h1 className="zero1">0</h1>
                                <div className="iconPara2">
                                    <p>Notes</p>
                                    <AiOutlineBars className='ri-icon' />
                                </div>
                            </div>
                            <div className='empty'></div>
                            <div className='missed'>
                                <p>Missed: 1</p>
                                <p>Expected: 1</p>
                                <p> <span style={{ color: "red" }}>Extra:</span> 0</p>
                            </div>
                            <div className='area-top2'>
                                <div className='area-bottom'>
                                    <button className="run-btn btn-col"><p className="run-text">Products</p></button>
                                    <button className="run-btn btn-col"><p className="run-text">RFID</p></button>
                                </div>
                            </div>
                            <button className="run-btn len"><p className="run-text">Validate and Send</p></button>
                        </div>
                    </div>
                    <div className='production-main-child'>
                        Transfer Out Detail Screen
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 onClick={() => this.headerHandler('header')} className='screen-heading'>{this.state.header !== '' ? this.state.header : usedData.header.text}</h1>
                            <div onClick={() => this.sliderHandler('slider')} className='slider' style={{ width: '100%', }} >
                                {this.state.receiving[0]?.slider?.show === false && this.state.slider === false ?
                                    <h1 style={{ margin: 10, fontSize: '16px', color: 'red' }}>Slider Off</h1>
                                    :
                                    <Slider

                                        className='slider-style'
                                        min={0}
                                        max={100}
                                        // disabled={true}
                                        value={value}
                                    // onChangeStart={this.handleChangeStart}
                                    // onChange={this.handleChange}
                                    // onChangeComplete={this.handleChangeComplete}
                                    />
                                }
                            </div>
                            <div className="percentNumber">
                                <p className="percent">0%</p>
                                <p className="percent">Reader Power:99 %</p>
                                <p className="percent">100%</p>
                            </div>
                            <div className="Packing-order">
                                <h2 onClick={() => this.destinationHandler('destination')}><GoLocation className='goloc3' />&nbsp;{this.state.destination !== '' ? this.state.destination : usedData.destination.text}:</h2>
                                <span onClick={() => this.extraHandler('extra')} style={{ marginLeft: "auto" }}>{this.state.extra !== '' ? this.state.extra : usedData.extra.text}:</span>
                            </div>
                            <div className="num2" style={{ marginTop: "3%" }}>
                                <h1 className="zero1">0</h1>
                                <div onClick={() => this.notesHandler('notes')} className="iconPara2">
                                    <p>{this.state.notes !== '' ? this.state.notes : usedData.notes.buttonText}</p>
                                    <AiOutlineBars className='ri-icon' />
                                </div>
                            </div>
                            <div className='empty'></div>
                            <div className='missed'>
                                <p onClick={() => this.foundMissedHandler('foundMissed')}>{this.state.foundMissed !== '' ? this.state.foundMissed : usedData.foundMissed.text}: 1</p>
                                <p onClick={() => this.foundExpectedHandler('foundExpected')}>{this.state.foundExpected !== '' ? this.state.foundExpected : usedData.foundExpected.text}: 1</p>
                                <p onClick={() => this.foundExtraHandler('foundExtra')}>  <span style={{ color: "red" }}>{this.state.foundExtra !== '' ? this.state.foundExtra : usedData.foundExtra.text}:</span> 0</p>
                            </div>
                            <div className='area-top2'>
                                <div className='area-bottom'>
                                    <button onClick={() => this.productsButtonHandler('productsButton')} className="run-btn btn-col"><p className="run-text">{this.state.productsButton !== '' ? this.state.productsButton : usedData.productsButton.text}</p></button>
                                    <button onClick={() => this.rfidButtonHandler('rfidButton')} className="run-btn btn-col"><p className="run-text">{this.state.rfidButton !== '' ? this.state.rfidButton : usedData.rfidButton.text}</p></button>
                                </div>
                            </div>
                            <button onClick={() => this.validateButtonHandler('validateButton')} className="run-btn len"><p className="run-text">{this.state.validateButton !== '' ? this.state.validateButton : usedData.validateButton.text}</p></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TransferDetailTag)

