import React from "react"
import { Component } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { BsDownload } from "react-icons/bs"
import Papa from 'papaparse';
import { json } from "react-router-dom";
import { uploaddata } from "../Path/Path";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

export default class TargetStockCount extends Component {
    state = {
        uploadFile: "",
        type: "",
        reference: "",
        jsonUploadFile: ""
    }
    handler = async (e) => {
        this.setState({ uploadFile: e.target.files[0] })

        await Papa.parse(e.target.files[0], {
            header: true,
            complete: results => {
                // var valueArr = results.data.map(function (item) { return item.barcode });
                // var isDuplicate = valueArr.some(function (item, idx) {
                //     return valueArr.indexOf(item) != idx
                // });
                // if (valueArr) {
                //     toast.error("Barcodes Should be Unique")
                // } else {
                // const barcodes = results.data.map(o => o.barcode)
                // const filtered = results.data.filter(({ barcode }, index) => !barcodes.includes(barcode, index + 1))
                let filteredhehe = results.data.filter((item => item.sku !== '' || item.barcode !== ''))
                filteredhehe = filteredhehe.map(function (item) {
                    delete item[""];
                    return item;
                });
                filteredhehe = filteredhehe.map((item => { return { ...item, name: this.state.reference, type: this.state.type } }))
                filteredhehe = filteredhehe.filter((item => item.barcode !== ""))
                filteredhehe = filteredhehe.filter((item => item.sku !== ""))
                this.setState({ jsonUploadFile: filteredhehe })
                // }

            },
        });

    }
    apihandle = async () => {
        if (
			!this.state.reference &&
			!this.state.type &&
			!this.state.jsonUploadFile
		) {
			toast.error("Please fill in all required fields before submitting.");
		} else if (!this.state.reference) {
			toast.error("Please enter reference number before submitting.");
		} else if (!this.state.type) {
			toast.error("Please select a File Type before submitting.");
		} else if (!this.state.jsonUploadFile) {
			toast.error("Please choose a file before submitting.");
		} else {
        const body = {
            name: this.state.reference,
            type: this.state.type,
            file: this.state.jsonUploadFile,
        }
        let datada = this.state.jsonUploadFile
        // if (!Array.isArray(datada)) {
        //   toast.error("No file selected", datada);
        //   return;
        // }
        var valueArr = datada.map(function (item) { return item.barcode });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });
        if (isDuplicate) {
            toast.error("Barcode Should be Unique")
        } else if (this.state.reference === "") {
            alert("Enter reference")
        }
        else if (this.state.type === "") {
            alert("Enter type")
        }
        else if (this.state.jsonUploadFile === "") {
            alert("Select file")
        }
        else {
            // await this.setState({ loading: true })
            await uploaddata(this.state.jsonUploadFile?.map((item => { return { ...item, sku: item?.barcode, barcode: item?.sku } })))
                .then((res) => {
                    toast.success('Data Uploaded')
                }).catch((err) => {
                    toast.error("Some barcode already exist")

                })

        }
    }

        console.log(JSON.stringify(this.state.jsonUploadFile), "body")
    }
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Targeted Stock Count</p>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                    </div>
                    {/* <button onClick={() => window.location.href = '/addZpl'} className="run-btn"><p className="run-text">Add Zpl</p></button> */}
                    <div style={{ display: "flex", alignItems: "center", width: "350px", justifyContent: "space-between" }}>
                        <CSVLink data={[{ barcode: '0000018409321', sku: '100193', qty: '23' }]} headers={[
                            { label: 'barcode', key: 'barcode' },
                            { label: 'sku', key: 'sku' },
                            { label: 'qty', key: 'qty' }]}
                            filename="sample_targetStockCount">
                            <div style={{ width: "160px", display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <p style={{ color: "black", fontSize: "16px" }}>Barcode, SKU, Qty</p>
                                </div>



                                <div>
                                    <BsDownload color="black" size={20} />
                                </div>

                            </div>
                        </CSVLink>
                        {/* <div style={{ width: "150px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: "black", fontSize: "16px" }}> List of EPCs, SKU</p>
                            </div>
                            <div>
                                <BsDownload color="black" size={20} />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="dashboard-bottom">
                    <p style={{ fontSize: "18px" }}>File Uploaded</p>
                    <div className="drop-data">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            freeSolo={true}
                            options={[
                            ]}


                            sx={{ width: 235 }}
                            renderInput={(p) => <TextField
                                onChange={(e) => this.setState({ reference: e.target.value })}
                                {...p} label="Reference" />}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            freeSolo={true}
                            options={[
                                "Barcode",
                                "EPC"
                            ]}
                            getop
                            onChange={(e, v) => this.setState({ type: v })}
                            sx={{ width: 235 }}
                            renderInput={(p) => <TextField
                                {...p} label="File Type" />}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            freeSolo={true}
                            options={[
                            ]}
                            // onChange={(e) => this.setState({uploadFile:e.target.value})}

                            sx={{ width: 235 }}
                            renderInput={(p) => <TextField
                                onChange={(e) => this.handler(e)}
                                type="file"
                                {...p} label="" />}
                        />
                        <button onClick={() => this.apihandle()} className="button-custom" >Run</button>
                    </div>
                </div>
            </div>
        )
    }
}
