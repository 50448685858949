import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editCycleCount2, editManageTagScreen, getCycleCount2, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';


class CycleCount2 extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        toSync: '',
        expected: '',
        remaining: '',
        read: '',
        extra: '',
        data: [
            {
                "header": {
                    "text": "Cycle Count"
                },
                "extra": {
                    "text": "Extra"
                },
                "toSync": {
                    "text": "To Sync"
                },
                "expected": {
                    "text": "Expected"
                },
                "remaining": {
                    "text": "Remaining"
                },
                "read": {
                    "text": "Read"
                },
                "field": {
                    "value": "SKU_original"
                },
                "filter": {
                    "value": "INCLUDE"
                },
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        const usedData = this.state.data[0]
        const body =
        {
            "header": {
                "text": this.state.header !== '' ? this.state.header : usedData.header.text
            },
            "extra": {
                "text": this.state.extra !== '' ? this.state.extra : usedData.extra.text
            },
            "toSync": {
                "text": this.state.toSync !== '' ? this.state.toSync : usedData.toSync.text
            },
            "expected": {
                "text": this.state.expected !== '' ? this.state.expected : usedData.expected.text
            },
            "remaining": {
                "text": this.state.remaining !== '' ? this.state.remaining : usedData.remaining.text
            },
            "read": {
                "text": this.state.read !== '' ? this.state.read : usedData.read.text
            },
        }
        const editing = await editCycleCount2(body)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    headerHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Header Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Header Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ header: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    extraHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Extra Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.extra !== '' ? this.state.extra : this.state.data[0].extra.text}
                                    // placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ extra: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    toSyncHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change To Sync Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.toSync !== '' ? this.state.toSync : this.state.data[0].toSync.text}
                                    // placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ toSync: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    expectedHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Expected Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.expected !== '' ? this.state.expected : this.state.data[0].expected.text}
                                    // placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ expected: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    remainingHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Remaining Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.remaining !== '' ? this.state.remaining : this.state.data[0].remaining.text}
                                    // placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ remaining: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    readHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={"Change Remaining Title"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state.read !== '' ? this.state.read : this.state.data[0].read.text}
                                    // placeholder={this.state.header !== '' ? this.state.header : this.state.data[0].header.text}
                                    onChange={(e) => this.setState({ read: e.target.value })}
                                />
                            </React.Fragment>

                        </Form.Group>
                    </CustomModal >
                );
            }
        });

    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getCycleCount2()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Cycle Count 2</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Cycle Count</h1>
                                <p className='user-locate-item' >User:Admin</p>
                                <p className='store-locate-item'>Store:0133456</p>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <div>  <p className='cycle-department'>Department</p></div>
                                        <div> <p className='cycle-department-bold'>All</p> </div>
                                    </div>
                                    <div>
                                        <div>  <p className='cycle-department'>Zone</p></div>
                                        <div> <p className='cycle-department-bold'>Sales Floor</p> </div>
                                    </div>
                                    <div>
                                        <button className='log-btn-cycle'><p className='log-text-cycle'>Logs</p></button>
                                    </div>
                                </div>
                                <div className='cycle-light'>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            <div> <p className='cycle-department'>Extra</p> </div>
                                            <div>  <p className='cycle-department'>0</p></div>
                                        </div>
                                        <div>
                                            <div> <p className='cycle-department'>To Sync</p> </div>
                                            <div>  <p className='cycle-department'>20</p></div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                        <div className='round-div'>
                                            <div>
                                                <p style={{ margin: "0px" }}>Read</p>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: "25px", fontWeight: "1200px", fontStyle: "bold" }}>0.00%</p>
                                            </div>
                                            <div>
                                                <p style={{ margin: "0px" }}>0</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            <div> <p className='cycle-department'>Expected</p> </div>
                                            <div>  <p className='cycle-department'>129499</p></div>
                                        </div>
                                        <div>
                                            <div> <p className='cycle-department'>Remaining</p> </div>
                                            <div>  <p className='cycle-department'>129499</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>Start</button>
                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#3E87A8', color: 'white', fontSize: '17px' }}>Save</button>
                                </div>

                            </div>
                        </div>
                        <div className='production-main-child'>
                            Cycle Count
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 onClick={() => this.headerHandler('header')} className='screen-heading'>{this.state.header !== '' ? this.state.header : this.state.data[0].header.text}</h1>
                                <p className='user-locate-item' >User:Admin</p>
                                <p className='store-locate-item'>Store:0133456</p>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <div>  <p className='cycle-department'>Department</p></div>
                                        <div> <p className='cycle-department-bold'>All</p> </div>
                                    </div>
                                    <div>
                                        <div>  <p className='cycle-department'>Zone</p></div>
                                        <div> <p className='cycle-department-bold'>Sales Floor</p> </div>
                                    </div>
                                    <div>
                                        <button className='log-btn-cycle'><p className='log-text-cycle'>Logs</p></button>
                                    </div>
                                </div>
                                <div className='cycle-light'>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            <div onClick={() => this.extraHandler('extra')}> <p className='cycle-department'>{this.state.extra !== '' ? this.state.extra : this.state.data[0].extra.text}</p> </div>
                                            <div>  <p className='cycle-department'>0</p></div>
                                        </div>
                                        <div>
                                            <div onClick={() => this.toSyncHandler('toSync')}> <p className='cycle-department'>{this.state.toSync !== '' ? this.state.toSync : this.state.data[0].toSync.text}</p> </div>
                                            <div>  <p className='cycle-department'>20</p></div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                        <div className='round-div'>
                                            <div onClick={() => this.readHandler('read')}>
                                                <p style={{ margin: "0px" }}>{this.state.read !== '' ? this.state.read : this.state.data[0].read.text}</p>
                                            </div>
                                            <div >
                                                <p style={{ fontSize: "25px", fontWeight: "1200px", fontStyle: "bold" }}>0.00%</p>
                                            </div>
                                            <div>
                                                <p style={{ margin: "0px" }}>0</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            <div onClick={() => this.expectedHandler('expected')}> <p className='cycle-department'>{this.state.expected !== '' ? this.state.expected : this.state.data[0].expected.text}    </p> </div>
                                            <div>  <p className='cycle-department'>129499</p></div>
                                        </div>
                                        <div>
                                            <div onClick={() => this.remainingHandler('remaining')}> <p className='cycle-department'>{this.state.remaining !== '' ? this.state.remaining : this.state.data[0].remaining.text}</p> </div>
                                            <div>  <p className='cycle-department'>129499</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>Start</button>
                                </div>
                                <div style={{ width: "100%", marginTop: "4%", marginBottom: "4%" }}>
                                    <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#3E87A8', color: 'white', fontSize: '17px' }}>Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CycleCount2)