import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editManageTagScreen, editMoreFilter, getManageTagScreen, getMoreFilter } from '../Path/Path';
import { toast } from 'react-toastify';
import { IoMdArrowDropdown } from "react-icons/io"
import { MdOutlineArrowForwardIos } from "react-icons/md"
import { ImCross } from "react-icons/im"

class MoreFilters extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        currentValue: '',
        data: [
            {
                "header": {
                    "text": "More Filters"
                },
                "button": {
                    "text": "Continue"
                },
                "filter": [
                    {
                        "text": "Brand",
                        "field": "brand",
                        "_id": "6308a266fe86b145f85d84b8"
                    },
                    {
                        "text": "Style",
                        "field": "style",
                        "_id": "6308a266fe86b145f85d84b9"
                    },
                    {
                        "text": "Color",
                        "field": "color",
                        "_id": "6308a266fe86b145f85d84ba"
                    },
                    {
                        "text": "Size",
                        "field": "size",
                        "_id": "6308a266fe86b145f85d84bb"
                    }
                ],
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value, indexOld) => {
        const dataStatus = this.state.data[0];
        console.log(dataStatus);
        let newArray = this.state.data[0].filter
        for (let index = 0; index < newArray.length; index++) {
            const element = newArray[index];
            if (index === indexOld) {
                element.text = this.state.currentValue
                element.field = this.state.currentValue
            }

        }
        let removedData = newArray.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        this.setState({
            data: [
                {
                    "header": {
                        "text": this.state.data[0].header.text
                    },
                    "button": {
                        "text": this.state.data[0].button.text
                    },
                    "filter": removedData
                },


            ]
        })
        let sendingData = this.state.data.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        const editing = await editMoreFilter(sendingData[0])
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    sendingDataNew = async (onClose, value, indexOld) => {
        let newArray = this.state.data[0].filter

        newArray = [...newArray, {
            "text": this.state.currentValue,
            "field": this.state.currentValue,
        }]
        let removedData = newArray.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        console.log(newArray, 'newArray')
        console.log(removedData, 'removedData')
        await this.setState({
            data: [
                {
                    "header": {
                        "text": this.state.data[0].header.text
                    },
                    "button": {
                        "text": this.state.data[0].button.text
                    },
                    "filter": removedData
                },


            ]
        })
        let sendingData = this.state.data.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        await console.log(sendingData, 'sendingData')
        const editing = await editMoreFilter(sendingData[0])
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    deletingNew = async (indexOld) => {
        let newArray = this.state.data[0].filter
        let removedData = newArray.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        await this.setState({
            data: [
                {
                    "header": {
                        "text": this.state.data[0].header.text
                    },
                    "button": {
                        "text": this.state.data[0].button.text
                    },
                    "filter": removedData.filter((item, index1) => indexOld !== index1),
                }
            ]
        })
        let sendingData = this.state.data.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        await console.log(sendingData, 'sendingData')
        const editing = await editMoreFilter(sendingData[0])
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))
    }
    buttonHandler = (value, index) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value, index)} onClose={() => this.closeHandler(onClose, value)} heading={'Add or Remove Filters'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            < React.Fragment >
                                <Form.Label>{'Header'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Header"
                                    type={'text'}
                                    id={value.text}
                                    defaultValue={value.text}
                                    // placeholder={this.state.header === '' ? 'Header' : this.state.header}
                                    onChange={(e) => this.setState({ currentValue: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    AddFilterHandler = (value, index) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingDataNew(onClose, value, index)} onClose={() => this.closeHandler(onClose, value)} heading={'Add or Remove Filters'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            < React.Fragment >
                                <Form.Label>{'Add Filter'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    pattern="[a-zA-Z0-9]+"
                                    // value={this.state.currentValue}
                                    placeholder="Value"
                             
                                    defaultValue={this.state.currentValue}
                                    // placeholder={this.state.header === '' ? 'Header' : this.state.header}
                                    onChange={(e) => this.setState({ currentValue: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getMoreFilter()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Cycle Count</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <div>
                                    <h1 className='screen-heading' style={{ fontSize: '25px' }}>Cycle Count</h1>
                                    <p className='user-locate-item' >User:Admin</p>
                                    <p className='store-locate-item'>Store:0133456</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "20%" }}>

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div>
                                                <p>Brand</p>
                                            </div>
                                            <div>
                                                <MdOutlineArrowForwardIos ></MdOutlineArrowForwardIos>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: '20px' }}>
                                            <div>
                                                <p>Style</p>
                                            </div>
                                            <div>
                                                <MdOutlineArrowForwardIos ></MdOutlineArrowForwardIos>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: '20px' }}>
                                            <div>
                                                <p>Color</p>
                                            </div>
                                            <div>
                                                <MdOutlineArrowForwardIos ></MdOutlineArrowForwardIos>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: '20px' }}>
                                            <div>
                                                <p>Size</p>
                                            </div>
                                            <div>
                                                <MdOutlineArrowForwardIos ></MdOutlineArrowForwardIos>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40%" }}>
                                            <button className='select-more-continue'><p className='select-more-text'>Continue</p></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='production-main-child'>
                            Add More Filters
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >

                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "20%" }}>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexDirection: "column", height: '400px', overflowY: 'auto' }}>
                                        {this.state.data[0].filter.map((item, index) =>
                                            <React.Fragment key={index}>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: '40px', marginTop: 20, cursor: 'pointer' }}>
                                                    <div onClick={() => this.buttonHandler(item, index)}>
                                                        <p>{item.text}</p>
                                                    </div>
                                                    <div>
                                                        <ImCross onClick={() => this.deletingNew(index)} ></ImCross>
                                                    </div>
                                                </div>
                                                <div className='line-cycle'></div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10%" }}>
                                        <button onClick={() => this.AddFilterHandler()} className='select-more-continue'><p className='select-more-text'>Add Filters</p></button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='production-main-child'>
                            Cycle Count
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <div>
                                    <h1 className='screen-heading' style={{ fontSize: '25px' }}>Cycle Count</h1>
                                    <p className='user-locate-item' >User:Admin</p>
                                    <p className='store-locate-item'>Store:0133456</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "20%" }}>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", width: "100%", flexDirection: "column", height: '400px', overflowY: 'auto' }}>
                                        {this.state.data[0].filter.map((item =>
                                            <React.Fragment>
                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: '40px', marginTop: '10px' }}>
                                                    <div>
                                                        <p>{item.text}</p>
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className='line-cycle'></div>
                                            </React.Fragment>))}
                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10%" }}>
                                        <button className='select-more-continue'><p className='select-more-text'>Continue</p></button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(MoreFilters)