import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'
import Dropdown from '../components/Dropdown';
import { getAsnDetail, getPoByOrderId, getPrintingPo, getRtvPo, getRtvPoWithParams, PathCustom } from '../Path/Path';

import { ibtData } from './StaticData/IBT';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Loader from '../components/Loader';
import { Autocomplete, TextField } from '@mui/material';
import { GrDocumentCsv } from 'react-icons/gr';
import moment from 'moment/moment';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Transfer = () => {
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [poDataList, setPoDataList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [ibtData, setIbtData] = useState([])
    const [ibtDataFilter, setIbtDataFilter] = useState([])
    const [tableData, setTableData] = useState({})
    const [poNumberVal, setpoNumberVal] = useState('')
    const [run, setRun] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [toDate, setToDate] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [sourceVal, setSourceVal] = useState('')
    const realSource = sourceVal?.source;
    const [source, setSource] = useState('')
    const [destination, setDestination] = useState('')
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const [filters, setFitlers] = useState({})
    const [pageData, setPageData] = useState({})
    const [asnData, setAsnData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [asnNumber, setAsnNumber] = useState("");
    const itemsPerPage = 10;


    async function fetchData(pageNumber = 1) {
        setLoading(true)
        const invoiceNumberValNew = invoiceNumberVal
        const sourceNew = source
        const destinationNew = destination
        const getPo = await axios.get(`${PathCustom}store/transfer?epc=${invoiceNumberValNew}&sku=${destinationNew}&bin=${sourceNew}`)
            .then((async res => {
                setLoading(false)
                // console.log(res?.data?.data?.data.filter((item => item.ASN.toLowerCase()?.includes('00125914'))), 'res.data');
                await setIbtData(res.data?.data?.data)
                await setIbtDataFilter(res.data?.data?.data);
                await setPageData(res.data?.data?.data)
                console.log(res.data?.data?.data, 'PutAway')

            }))
            .catch((err => {
                toast.error('Something Went Wrong')
                console.log(err, 'err');
                setLoading(false)
            }))
    }
    useEffect(() => {
        fetchData();
    }, []);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    const formatDateReverse = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = month;
        if (day.length < 2)
            day = day;

        return [month, day, year].join('-');
        // return console.log([year, month, day].join('-'))
    }


    const headers = [
        { label: "Created At", key: 'createdAt' },
        { label: "Updated At", key: 'updatedAt' },
        { label: "Bin", key: 'bin' },
        { label: "Epc", key: 'epc' },
        { label: "Facility", key: 'facility' },
        { label: "Line item", key: 'line_item' },
        { label: "Ref Number", key: 'refnumber' },
        { label: "Sku", key: 'sku' },

    ]
    const csvData = asnData?.map((item => {
        return {
            createdAt: formatDate(item?.createdAt) || '----',
            updatedAt: formatDate(item?.updatedAt) || '----',
            bin: item?.bin || '----',
            epc: item?.epc || '----',
            facility: item?.facility || '----',
            line_item: item?.line_item || '----',
            refnumber: item?.refnumber || '----',
            sku: item?.sku || '----',

        }
    }))


    // ------------- Pagination ------------------

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        setCurrentItems(asnData.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(asnData.length / itemsPerPage))
    }, [itemOffset, itemsPerPage, asnData])

    const handlePageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % asnData.length;
        setItemOffset(newOffset)
    }



    return (
        <>
            <Loader loading={loading} />
            <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>
            <div className='dashboard-main'>
                <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Transfer </p>
                        <CSVLink
                            filename='Reports/Transfer'
                            data={csvData}
                            headers={headers}
                        >
                            <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />
                        </CSVLink>
                    </div>
                    <div>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>
                </div>
                <div className='dashboard-bottom' style={{ marginTop: "0.5%" }}>
                    <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', gridColumnGap: '10px', gridRowGap: '10px', flexWrap: 'wrap' }}>

                            {/* <DesktopDatePicker
                                label="From Date"
                                clearable
                                showTodayButton
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={toDate}
                                onChange={(data) => setToDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={fromDate}
                                onChange={(data) => setFromDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            /> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={ibtDataFilter?.filter((item => item?.epc !== '')).map((item => item?.epc)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setAsnNumber(v === null ? '' : v)}
                                sx={{ width: 250 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Epc" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}
                                options={ibtDataFilter?.filter((item => item?.sku !== '')).map((item => item?.sku)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                getOptionLabel={(c) => c.source}
                                onChange={(e, v) => setSourceVal(v === null ? '' : v)}
                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Sku" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={ibtDataFilter?.filter((item => item?.transferorder !== '')).map((item => item?.transferorder)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setDestination(v === null ? '' : v)}

                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Transfer Order" />}
                            />


                        </div>

                        {/* <button
                            onClick={() =>
                              
                                fetchData()
                            }
                            className="run-btn"><p className="run-text" onClick={() => searchData()}>Search</p></button> */}
                    </div>
                    <div style={{ marginTop: '10px', overflowX: 'scroll', height: '100%' }}>
                        <table data-table-theme="dark zebra">
                            <thead>
                                <tr style={{ background: "#373E43" }}>
                                    <th>Created At</th>
                                    <th>Updated At</th>

                                    <th>Epc</th>
                                    <th>Sku </th>
                                    <th>Transfer Order</th>
                                    <th>Delivery Order</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Status</th>
                                    <th>Sloc</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ibtData?.map((data, index) =>
                                        <tr key={index}>
                                            <td>{formatDate(data?.createdAt)}</td>
                                            <td>{formatDate(data?.updatedAt)}</td>
                                            <td>{data?.epc}</td>
                                            <td>{data?.sku}</td>
                                            <td>{data?.transferorder}</td>
                                            <td>{data?.deliveryorder}</td>
                                            <td>{data?.source}</td>
                                            <td>{data?.destination}</td>
                                            <td>{data?.status}</td>
                                            <td>{data?.sloc}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>


                    </div>
                    <ReactPaginate
                        className='ul-main'
                        activeClassName='active-table-btn'
                        activeLinkClassName='active-table-btn-link'
                        previousClassName='previous-table-btn'
                        pageLinkClassName='page-link-btn'
                        nextClassName='next-table-btn'
                        previousLinkClassName='previous-table-btn-link'
                        nextLinkClassName='previous-table-btn-link'
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </>
    )
}

export default Transfer;
