import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit, TbEmphasis } from 'react-icons/tb'
import { IoIosBarcode } from 'react-icons/io'
import { FaSearch } from 'react-icons/fa'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import receivingLogo from '../assets/recieving.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Slider from 'react-rangeslider'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter'
import { editReceiving, getReceiving } from '../Path/Path'


class LookUp extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        value: 100,
        asnValue: '',
        imageLinkBg: '',
        manualHeading: '',
        powerReader: true,
        receiving: [],
    }
    handleChangeStart = () => {
        console.log('Change event started')
    };

    handleChange = value => {
        this.setState({
            value: value
        })
    };

    handleChangeComplete = () => {
        console.log('Change event completed')
    };
    closeHandler = (onClose, value) => {
        this.setState({ [value]: '' })
        onClose()
    }
    closeBarcodeHandler = (onClose, btnName, valueBtn) => {
        this.setState({ [btnName]: valueBtn })
        onClose()
    }
    buttonHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Change {value} </h1>
                        <div>
                            <input type={'text'} id={value} placeholder={value} className="custom-input" onChange={(e) => this.setState({ [value]: e.target.value })} />
                        </div>
                        <button onClick={() => this.closeHandler(onClose, value)}>No</button>
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Save
                        </button>
                    </div >
                );
            }
        });
    }
    mainHeadingHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Change {value} </h1>
                        <div>
                            <input type={'text'} id={value} placeholder={value} className="custom-input" onChange={(e) => this.setState({ [value]: e.target.value })} />
                        </div>
                        <button onClick={() => this.closeHandler(onClose, value)}>No</button>
                        <button
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Save
                        </button>
                    </div >
                );
            }
        });
    }
    buttonBarcodeHandler = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Change Barcode Value </h1>
                        <button onClick={() => this.closeBarcodeHandler(onClose, 'btnValue', true)}>On</button>
                        <button
                            onClick={() => this.closeBarcodeHandler(onClose, 'btnValue', false)}
                        >
                            Off
                        </button>
                    </div >
                );
            }
        });
    }
    buttonPowerHandler = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Power Reader Slider </h1>
                        <button onClick={() => this.closeBarcodeHandler(onClose, 'powerReader', true)}>Show</button>
                        <button
                            onClick={() => this.closeBarcodeHandler(onClose, 'powerReader', false)}
                        >
                            Hidden
                        </button>
                    </div >
                );
            }
        });
    }
    imageHandler = (files, stateName) => {
        console.log(files);
        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ [stateName]: url, percent: 0 })
                    });
                }
            );
        }

    }
    editScreen = async () => {
        const body = {
            slider: {
                value: this.state?.powerReader
            },
            barcode: {
                value: this.state?.btnValue
            },
            input: {
                placeholder: this.state?.asnValue
            },
            centerMain: {
                text: this.state?.manualHeading,
                icon: this?.state?.imageLinkBg,
            },
            header: {
                text: this.state?.mainHeading
            }
        }
        console.log(body);
        await editReceiving(body).then(
            () => {
                alert("Edited")
            }
        ).catch((err) => {
            alert('Something Went Wrong')
            console.log(err);
        })
    }
    async componentDidMount() {
        const receiving = await getReceiving()
        this.setState({ receiving: receiving })
        await console.log(receiving, 'receiving');
    }
    render() {
        const value = this.state.value
        const asnValue = this.state.asnValue
        console.log(this.state);
        return (
            <div className="dashboard-main-mine">
                <div className="dashboard-top-mine">
                    <div> <p className="stock-count">Look Up</p></div>
                    <div>
                        <button style={{ marginTop: '0%' }} onClick={() => this.editScreen()} className="refresh-btn"><p className="refresh-text">Save</p></button>
                    </div>
                </div>
                <div className='production-main-div'>
                    <div className='production-main-child'>
                        Default Screen
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 onClick={() => this.mainHeadingHandler('mainHeading')} className='screen-heading'>Look Up</h1>
                            {/* <div onClick={() => this.buttonPowerHandler()} className='slider' style={{ width: '100%', }} > */}
                            {/* </div> */}
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: "4%" }}>
                                <button  style={{ fill: 'white', color: 'white', cursor: 'pointer' }} className="barcode-btn">
                                    <IoIosBarcode size={30} />
                                </button>
                                <input type={'text'} id={'asnValue'} disabled placeholder={''} style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    <FaSearch size={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='production-main-child'>
                        Look Up
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 onClick={() => this.mainHeadingHandler('mainHeading')} className='screen-heading'>Look Up</h1>
                            {/* <div onClick={() => this.buttonPowerHandler()} className='slider' style={{ width: '100%', }} > */}
                            {/* </div> */}
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: "4%" }}>
                                <button onClick={() => this.buttonBarcodeHandler()} style={{ fill: 'white', color: 'white', cursor: 'pointer' }} className="barcode-btn">
                                    <IoIosBarcode size={30} />
                                </button>
                                <input type={'text'} id={'asnValue'} placeholder={''} style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    <FaSearch size={25} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(LookUp);