import { Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import _ from "lodash";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { PaginatedItemsOffline } from "../components/PaginatedOffline";
import BarGraph from "../Graphs/BarGraph";
import {
  localServer,
  PathCustom,
  PathCustomCycleCount,
  reqInstance
} from "../Path/Path";
export default class Operation extends Component {
  state = {
    data1: [],
    item: [],
    operationItems: [
      {
        Store: "00125",
        Department: "750",
        Date: "22-09-13",
        Accuracy: "0.00",
        UIA: "0.00",
        Expected: "39",
        Counted: "0",
        Overs: "0",
        Unders: "-0.39",
        ExpectedSF: "0",
        ExpectedSR: "0",
        Department: "perfume",
        Brand: "136-DG Men",
        Diff: "13",
      },
    ],
    loading: false,
    datesArray: [],
    SKU_Over: "",
    SKU_Real: "",
    SKU_Stock: "",
    data: "",
    storeName: [],
    extraSOH: [],
    dropdata: "",
    cronStatus: false,
    filter: [],
    mydate: "",
    counted: {},
    sohqty: 0,
    visible: false,
    itemsPerPage: 10,
    csvDataReal: [],
    csvDataOvers: [],
    departmentID: [],
    department: "",
    matched: {},
    csvDataStock: [],
    csvDataRealFake: [],
    csvDataOversFake: [],
    unders: { totalUnders: 0, undersData: [] },
    overs: { totalOvers: 0, oversData: [] },
    csvDataStockFake: [],
    createdDate: null,
    Color: "",
    Brand: "",
    store: "",
    Size: "",
    ColorList: [],
    BrandList: [],
    storeArray: [],
    SizeList: [],
    stats: {
      groupedSKU: [],
      dataOrderWise: [],
      uniqueArray: [],
    },
  };
  searchDataReal = () => {
    const filterArray = this.state.csvDataRealFake.filter((item) =>
      item?.SOHSKU?.includes(this.state.SKU_Real)
    );
    this.setState({ csvDataReal: filterArray });
  };
  searchDataOver = () => {
    const filterArray = this.state.csvDataOversFake.filter((item) =>
      item?.SOHSKU?.includes(this.state.SKU_Over)
    );
    this.setState({ csvDataOvers: filterArray });
  };
  searchDataStock = () => {
    const filterArray = this.state.csvDataStockFake.filter((item) =>
      item?.SOHSKU?.includes(this.state.SKU_Stock)
    );
    this.setState({ csvDataStock: filterArray });
  };
  isToday = (date) => {
    const today = this.formatDate(new Date());
    let newDate = date;
    // 👇️ Today's date

    if (today === newDate) {
      return true;
    }

    return false;
  };
  statsFunction = async () => {
    this.setState({ loading: true });
    const createdData =
      this.formatDate(this.state.createdDate) === "01/01/1970"
        ? ""
        : this.formatDate(this.state.createdDate);

    const rawBody = {
      StoreID: this.state.store,
      date: createdData,
      // 'isToday': this.isToday(createdData)
    };

    const getDescripency = await axios.post(
      `${PathCustomCycleCount}store/get/soh/stats?StoreID=${this.state.store}&DepartmentID=${this.state.department}&Color=${this.state.Color}&Brand=${this.state.Brand}&Size=${this.state.Size}`,
      rawBody
    );
    const discripencyData = await getDescripency?.data?.data?.SOHs;
    const extraSOH = await getDescripency?.data?.data?.extraSOH || [];
    let finalResult = []
    let uniqueCount = extraSOH?.map((item => item?.SKU));
    var count = {};
    uniqueCount.forEach(function (i) { count[i] = (count[i] || 0) + 1; });
    finalResult = await Object.entries(count).map((item => { return { sku: item[0], qty: item[1] } }))
    finalResult = await finalResult?.map((item => {
      return {
        scanned: item?.qty,
        SKU: item?.sku,
        barcodeOriginal: item?.sku,
        barcode: item?.sku,
        SOHSKU: item?.sku,
        SOHQTY: 0,
        matched: 0,
        DepartmentID: this.state.department,
        StoreID: this.state.store
      }
    }))
    this.setState({
      csvDataReal: [].concat(discripencyData, finalResult),
      csvDataRealFake: [].concat(discripencyData, finalResult),
      extraSOH: extraSOH,
    });
    var unders = { totalUnders: 0, undersData: [] };
    var overs = { totalOvers: 0, oversData: [] };
    var sohqty = 0;
    var matched = { totalMatched: 0, matchedData: [] };
    var counted = { totalCounted: 0, countedData: [] };
    for (let index = 0; index < discripencyData.length; index++) {
      const element = discripencyData[index];
      if (element?.overs !== 0) {
        overs = {
          totalOvers: overs?.totalOvers + element?.overs,
          oversData: [...overs.oversData, element],
        };
      }
      if (element?.matched !== 0) {
        for (let index = 0; index < element?.matched; index++) {
          // const element = array[index];
          matched = {
            totalMatched: matched.totalMatched + element?.matched,
            matchedData: [...matched.matchedData, element],
          };
        }
      }
      if (element?.SOHQTY !== 0 && element.matched !== 0) {
        if (element?.SOHQTY - element.matched !== 0) {
          unders = {
            totalUnders: element?.SOHQTY - element.matched + unders.totalUnders,
            undersData: [...unders.undersData, element],
          };
        }
      }
      if (element?.SOHQTY !== 0) {
        sohqty = sohqty + element?.SOHQTY;
      }
    }
    let emmprt = [];
    counted = await {
      totalCounted: matched.totalMatched + overs.totalOvers,
      countedData: emmprt.concat(matched.matchedData, overs.oversData),
    };
    this.setState({ counted: counted, sohqty: sohqty, matched: matched });
    var values = await overs.oversData
      .map(({ overs }) => overs)
      .sort((a, b) => b - a);
    // .slice(0, 20)
    var top20Overs = await overs.oversData.filter(({ overs }) =>
      values.includes(overs)
    );
    const mixedData = _.orderBy(
      discripencyData.map((item) => {
        return {
          ...item,
          unders: item.SOHQTY - item?.matched,
          Scanned: item?.SOHQTY + item?.overs,
          barcodeOriginal: item?.SKU?.replaceAll(";", "-"),
        };
      }),
      ["unders"],
      ["desc"]
    ).splice(0, 20);

    var valuesUnders = await mixedData
      .map(({ unders }) => unders)
      .sort((a, b) => b - a);
    // .slice(0, 20)
    var top20Unders = await mixedData.filter(({ unders }) =>
      valuesUnders.includes(unders)
    );
    this.setState({ csvDataOvers: top20Overs, csvDataOvers: top20Overs });
    this.setState({ csvDataStock: top20Unders, csvDataStockFake: top20Unders });
    this.setState({ loading: false });
  };
  gettingStores = async () => {
    let data = null;
    await reqInstance
      // .get("https://virgin.innovent.site/innovent/STORES")
        .get(`${localServer}store/site/get`)
        .then(async (res) => {
          data = await res?.data;
          console.log({res})
        })

        .catch((err) => {
          console.log(err);
        });

    await this.setState({ storeArray: data });
  };
  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
    // return console.log([year, month, day].join('-'))
  };
  async componentDidMount() {
    this.setState({ loading: true });
    await this.gettingStores();
    await this.gettingDates();
    await this.storeHandler();
    // this.statsFunction()
    this.setState({ loading: false });
  }
  // search = () => {
  //     const res = this.state.data.filter(i => i.storename === this.state.dropdata)
  //     this.setState({ filter: res })
  //     console.log(res, "resssssssssssssssss")
  //     this.setState({ visible: true })

  // }
  replaceAll = (str, find, replace) => {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, "g"), replace);
  };
  storeHandler = async (data) => {
    this.setState({ store: data });
    this.setState({ storeData: data });
    var raw = { StoreID: data?.StoreID };
    const filters = await axios.get(`${PathCustom}store/get/soh/filters`);
    const newFilters = filters?.data?.data;
    let newFiltersDta = [];
    let mangageFilter = []
    let brand = [];
    let color = [];
    let size = [];
    let style = [];

    var department = _.uniq(newFilters?.departments?.filter((item) => item));
    var brandList = _.uniq(newFilters?.brands?.filter((item) => item));
    var colorList = _.uniq(newFilters?.colors?.filter((item) => item));
    var sizeList = _.uniq(newFilters?.sizes?.filter((item) => item));
    var styleList = _.uniq(newFilters?.syles?.filter((item) => item));
    // for (let index = 0; index < newFilters.length; index++) {
    //     const element = newFilters[index];
    //     for (let index = 0; index < element.length; index++) {
    //         const elementTwo = element[index];
    //         console.log(elementTwo, 'elementTwo');
    //         newFiltersDta.push(elementTwo)

    //     }

    // }

    // var uniq = [...new Set(newFiltersDta)];
    console.log(department, brandList, colorList, sizeList, "zdfgfdgz");
    await this.setState({
      departmentID: department,
      BrandList: brandList,
      ColorList: colorList,
      SizeList: sizeList,
    });
    // console.log(un   iq, 'asd');
    this.setState({ loading: false });
  };
  departmentHandler = async (data) => {
    await this.setState({ department: data });
  };

  searchFunction = async () => {
    if (
      this.state.store === "" ||
       this.state.createdDate === null 
      // this.state.department === ""
    ) {
      toast.error("Please select Store and Date");
    } else {
      // const cronStatus = await getCronStatus()
      // await this.setState({ cronStatus: cronStatus?.data?.status })
      // console.log(cronStatus?.data?.status, "\cronStatus");
      this.statsFunction();
    }
  };
  gettingDates = async () => {
    let data = null;
    await reqInstance
      .get(`${PathCustom}store/get/soh/dates`)
      .then(async (res) => {
        data = await res?.data?.data;
      })

      .catch((err) => {
        console.log(err);
      });

    this.setState({ datesArray: data !== null ? data : [] });
  };
  shouldDisableDate = (date) => {
    const formatDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("/");
    };
    let datas = this.state.datesArray.map((item) => formatDate(item));

    return !datas.includes(formatDate(new Date(date).toLocaleString()));
  };
  render() {
    const headers = [
      "SOH Date",
      "Store ID",
      "Unique ID",
      "Barcode",
      "SOH SKU",
      "SOH Qty.",
      "Overs",
      "Unders",
      "Matched",
      "Scanned",
    ];
    const headersReal = [
      "SOH Date",
      "Store ID",
      "Unique ID",
      "Barcode",
      "SOH SKU",
      "SOH Qty.",
      "Scanned",
      "QTY-Variances",
      "Unders",
      "Overs",
      "Stock BUOM",
      "Stock Room",
      "Sales Floor",
    ];
    const colNamesReal = [
      "SOHDate",
      "StoreID",
      "UniqueID",
      "ItemBarCode",
      "SOHSKU",
      "SOHQTY",
      "scanned",
      "QTY_Variances",
      "unders",
      "overs",
      "Stock_Buom",
      "stockRoom",
      "salesFloor",
    ];
    const headersReaCSV = [
      { label: "SOH Date", key: "SOHDate" },
      { label: "Store ID", key: "StoreID" },
      { label: "Unique Id", key: "UniqueID" },
      { label: "Barcode", key: "ItemBarCode" },
      { label: "SOH SKU", key: "SOHSKU" },
      { label: "SOH Qty.", key: "SOHQTY" },
      { label: "Scanned", key: "scanned" },
      { label: "QTY-Variances", key: "QTY_Variances" },
      { label: "Unders", key: "unders" },
      { label: "Overs", key: "overs" },
      { label: "Stock BUOM", key: "Stock_Buom" },
      { label: "Stock Room", key: "stockRoom" },
      { label: "Sales Floor", key: "salesFloor" },
    ];
    // const headers2 = [

    //     "Department",
    //     "Brand",
    //     "SKU",
    //     "Expected",
    //     "Diff"

    // ]
    // const headers3 = [

    //     "SKU",
    //     "Dept.Name",
    //     "Brand",
    //     "Size",
    //     "Color",
    //     "Delta", ,

    // ]
    const colNames = [
      "SOHDate",
      "StoreID",
      "UniqueID",
      "ItemBarCode",
      "SOHSKU",
      "SOHQTY",
      "overs",
      "unders",
      "matched",
      "scanned",
    ];
    const csvColNames = [
      { label: "SOH Date", key: "SOHDate" },
      { label: "Store ID", key: "StoreID" },
      { label: "Unique ID", key: "UniqueID" },
      { label: "Barcode", key: "ItemBarCode" },
      { label: "SOH SKU", key: "SOHSKU" },
      { label: "SOH Qty.", key: "SOHQTY" },
      { label: "Overs", key: "overs" },
      { label: "Unders", key: "unders" },
      { label: "Matched", key: "matched" },
      { label: "Scanned", key: "scanned" },
    ];

    // const colNames2 = [
    //     "SOHSKU",
    //     "SKU",
    //     "DepartmentID",
    //     "Description",
    //     "SOHQTY",
    //     'matched',
    //     'overs',
    //     "unders",
    //     'matched',
    //     "Scanned"
    // ]
    const csvDataReal =
      this.state.csvDataReal.map((item) => {
        return {
          ...item,
          QTY_Variances: item?.SOHQTY - item?.scanned,
          itemMaster_itemBarcode: item?.itemMasterDetails?.ItemBarCode,
          unders: item.SOHQTY - item?.matched,
          Scanned: item?.scanned,
          barcodeOriginal: item?.SOHSKU?.replaceAll(";", "-"),
          stockRoom: item?.zone?.StockRoom
            ? item?.zone?.StockRoom
            : 0,
          salesFloor: item?.zone?.SalesFloor
            ? item?.zone?.SalesFloor
            : 0,
        };
      }) || [];
    const csvDataOvers = _.orderBy(
      this.state.csvDataOvers.map((item) => {
        return {
          ...item,
          itemMaster_itemBarcode: item?.product_number,
          unders: item.SOHQTY - item?.matched,
          Scanned: item?.SOHQTY + item?.overs,
          barcodeOriginal: item?.SKU?.replaceAll(";", "-"),
        };
      }),
      ["overs"],
      ["desc"]
    ).splice(0, 20);

    const csvDataStock = this.state.csvDataStock;
    return (
      <>
        <Loader loading={this.state.loading} />
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginTop: 20,
              marginBottom: 20,
              padding: "15px",
            }}
          >
            <div className="dashboard-top" style={{ alignItems: "center" }}>
              <div>
                {" "}
                <p
                  className="stock-count"
                  style={{ fontSize: "25px", margin: 0 }}
                >
                  Operation
                </p>
              </div>
              {/* {this.state.cronStatus ?
                                <div style={{ display: 'flex', alignItems: 'center', gridColumnGap: 20 }}>
                                    <span>Result May Vary <br /> Please Wait System is Updating</span>
                                    <MoonLoader color='black' size={20} loading={this.state.cronStatus} />
                                </div> : null
                            } */}
            </div>
            <div className="dashboard-bottom" style={{ width: "100%" }}>
              {/* <div> <p className="filter-text"> Store ID: {this.state.store === '' ? "No Store Selected" : this.state.store}</p></div> */}
              <div
                className="drop-data"
                style={{
                  gridRowGap: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gridColumnGap: "20px",
                    gridRowGap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={"small"}
                    options={this.state.storeArray
                      ?.map((item) => item?.Warehouse)
                      .reduce((noDupArr, entry) => {
                        if (noDupArr?.includes(entry)) {
                          return noDupArr;
                        } else {
                          return [...noDupArr, entry];
                        }
                      }, [])
                      .filter((item) => item !== null)}
                    onChange={(e, v) =>
                      this.storeHandler(v === null || v === undefined ? "" : v)
                    }
                    sx={{ width: 235 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Store Code" />
                    )}
                  />
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={"small"}
                    options={this.state.departmentID}
                    onChange={(e, v) =>
                      this.departmentHandler(
                        v === null || v === undefined ? "" : v
                      )
                    }
                    sx={{ width: 235 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Department" />
                    )}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={"small"}
                    options={this.state.ColorList}
                    onChange={(e, v) =>
                      this.setState({
                        Color: v === null || v === undefined ? "" : v,
                      })
                    }
                    sx={{ width: 235 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Color" />
                    )}
                  /> */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={"small"}
                    options={this.state.BrandList}
                    onChange={(e, v) =>
                      this.setState({
                        Brand: v === null || v === undefined ? "" : v,
                      })
                    }
                    sx={{ width: 235 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Brand" />
                    )}
                  />
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size={"small"}
                    options={this.state.SizeList}
                    onChange={(e, v) =>
                      this.setState({
                        Size: v === null || v === undefined ? "" : v,
                      })
                    }
                    sx={{ width: 235 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Size" />
                    )}
                  /> */}
                  <DesktopDatePicker
                    label="Created Date"
                    inputFormat="DD/MM/YYYY"
                    clearable
                    // shouldDisableDate={(date) => this.shouldDisableDate(date)}
                    // renderDay={(day, _value, DayComponentProps) => {
                    //     const isSelected =
                    //         !DayComponentProps.outsideCurrentMonth &&
                    //         this.state.datesArray.map((item => this.formatDate(item))).includes(this.formatDate(day));
                    //     return (
                    //         <Badge
                    //             key={day.toString()}
                    //             overlap="circular"
                    //             badgeContent={isSelected ? <BiScan size={13} /> : undefined}
                    //         >
                    //             <PickersDay {...DayComponentProps} />

                    //         </Badge>

                    //     );
                    // }}
                    // disablePast
                    disableFuture
                    size={"small"}
                    value={this.state.createdDate}
                    onChange={(data) => this.setState({ createdDate: data })}
                    componentsProps={{
                      actionBar: {
                        actions: ["clear"],
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: 170 }}
                        size={"small"}
                        {...params}
                      />
                    )}
                  />
                </div>
                <button
                  onClick={() => this.searchFunction()}
                  className="run-btn"
                >
                  <p className="run-text">Search</p>
                </button>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            {console.log(
              this.state?.matched?.matchedData?.filter(
                (item) => item?.DepartmentID === "BOOKS"
              ),
              "BOOKS"
            )}
            <BarGraph
              data={_.groupBy(this.state.matched?.matchedData, "DepartmentID")}
              totalLength={this.state.sohqty}
            />
          </div>
          {/* <div className="dashboard-main">

                        <div className="dashboard-top">
                           
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Stock Summary</p>

                                <CSVLink
                                    filename='Stock-Summary'
                                    data={csvDataStock}
                                    headers={headers}
                                >

                                    <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />

                                </CSVLink>

                            </div>

                        </div>
                        <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20, marginBottom: 20 }}>
                            <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>

                                <TextField
                                    sx={{ width: 235 }}
                                    defaultValue={this.state.SKU_Stock}
                                    value={this.state.SKU_Stock}
                                    placeholder="SKU"
                                    size={'small'}
                                    onChange={(data) => this.setState({ SKU_Stock: data.target.value })}
                                    id="outlined-basic" label="SKU" variant="outlined"
                                />
                            </div>

                            <button
                                onClick={() =>
                                    this.searchDataStock()
                                    
                                }
                                className="run-btn"><p className="run-text">Search</p></button>
                        </div>

                        <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.csvDataStock} />

                    </div> */}

          <div style={{ display: "flex", width: "100%" }}>
            <div className="dashboard-main">
              <div className="dashboard-top2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <p
                    className="stock-count"
                    style={{ fontSize: "25px", margin: 0 }}
                  >
                    Top 20 Over
                  </p>

                  <CSVLink
                    filename="top-20-over"
                    data={csvDataOvers}
                    headers={csvColNames}
                  >
                    <GrDocumentCsv
                      color="white"
                      fill="white"
                      fontSize={25}
                      style={{ marginRight: 5, cursor: "pointer" }}
                    />
                  </CSVLink>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gridColumnGap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {/* <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>

                                    <TextField
                                        sx={{ width: 235 }}
                                        defaultValue={this.state.SKU_Over}
                                        value={this.state.SKU_Over}
                                        placeholder="SKU"
                                        size={'small'}
                                        onChange={(data) => this.setState({ SKU_Over: data.target.value })}
                                        id="outlined-basic" label="SKU" variant="outlined"
                                    />
                                </div> */}

                {/* <button
                                    onClick={() =>
                                        this.searchDataOver()
                                    }
                                    className="run-btn"><p className="run-text">Search</p></button> */}
              </div>
              <PaginatedItemsOffline
                noAction
                headers={headers}
                colNames={colNames}
                itemsPerPage={this.state.itemsPerPage}
                data={_.orderBy(
                  this.state.csvDataOvers.map((item) => {
                    return {
                      ...item,
                      itemMaster_itemBarcode: item?.product_number,
                      unders: item.SOHQTY - item?.matched,
                      Scanned: item?.SOHQTY + item?.overs,
                      barcodeOriginal: item?.SKU?.replaceAll(";", "-"),
                    };
                  }),
                  ["overs"],
                  ["desc"]
                ).splice(0, 20)}
              />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="dashboard-main">
              <div className="dashboard-top2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <p
                    className="stock-count"
                    style={{ fontSize: "25px", margin: 0 }}
                  >
                    Top 20 Under
                  </p>

                  <CSVLink
                    filename="top-20-under"
                    data={csvDataStock?.map((item) => {
                      return {
                        ...item,
                        itemMaster_itemBarcode: item?.product_number,
                      };
                    })}
                    headers={csvColNames}
                  >
                    <GrDocumentCsv
                      color="white"
                      fill="white"
                      fontSize={25}
                      style={{ marginRight: 5, cursor: "pointer" }}
                    />
                  </CSVLink>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gridColumnGap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {/* <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>

                                    <TextField
                                        sx={{ width: 235 }}
                                        defaultValue={this.state.SKU_Stock}
                                        value={this.state.SKU_Stock}
                                        placeholder="SKU"
                                        size={'small'}
                                        onChange={(data) => this.setState({ SKU_Stock: data.target.value })}
                                        id="outlined-basic" label="SKU" variant="outlined"
                                    />
                                </div>

                                <button
                                    onClick={() =>
                                        this.searchDataStock()
                                    }
                                    className="run-btn"><p className="run-text">Search</p></button> */}
              </div>
              <PaginatedItemsOffline
                noAction
                headers={headers}
                colNames={colNames}
                itemsPerPage={this.state.itemsPerPage}
                data={this.state.csvDataStock.map((item) => {
                  return {
                    ...item,
                    itemMaster_itemBarcode: item?.product_number,
                  };
                })}
              />
            </div>
          </div>
          <div className="dashboard-main">
            <div className="dashboard-top">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p
                  className="stock-count"
                  style={{ fontSize: "25px", margin: 0 }}
                >
                  Real Time Discrepency
                </p>

                <CSVLink
                  filename="real-time-discrepency"
                  data={csvDataReal}
                  headers={headersReaCSV}
                >
                  <GrDocumentCsv
                    color="white"
                    fill="white"
                    fontSize={25}
                    style={{ marginRight: 5, cursor: "pointer" }}
                  />
                </CSVLink>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 20,
                marginBottom: 20,
                padding: "15px",
              }}
            >
              <div className="dashboard-bottom" style={{ width: "100%" }}></div>
            </div>

            <div style={{ overflow: "auto" }}>
              <PaginatedItemsOffline
                noAction
                headers={headersReal}
                colNames={colNamesReal}
                itemsPerPage={20}
                data={this.state.csvDataReal.map((item) => {
                  return {
                    ...item,
                    QTY_Variances: item?.SOHQTY - item?.scanned,
                    itemMaster_itemBarcode:
                      item?.itemMasterDetails?.ItemBarCode,
                    unders: item.SOHQTY - item?.matched,
                    Scanned: item?.scanned,
                    barcodeOriginal: item?.SOHSKU?.replaceAll(";", "-"),
                    stockRoom: item?.zone?.StockRoom
                      ? item?.zone?.StockRoom
                      : 0,
                    salesFloor: item?.zone?.SalesFloor
                      ? item?.zone?.SalesFloor
                      : 0,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
