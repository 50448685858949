import React, { Component } from "react";
import MyInput from "../../components/MyInput";
import { deleteUser, deleteZone, getUsers, getZone } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import { toast } from "react-toastify";
import { PaginatedItems } from "../../components/Paginated";
import Loader from '../../components/Loader';
import { Autocomplete, TextField } from "@mui/material";

class Zones extends Component {
    state = {
        data: [],
        tableFilter: [],
        name: "",
        username: "",
        itemsPerPage: 10,
        loading: false,
    }
    componentDidMount() {

        this.saveNewUsersToState()

    }


    saveNewUsersToState = async () => {
        this.setState({ loading: true })
        const userinfo = await getZone();
        this.setState({ data: userinfo, tableFilter: userinfo })
        this.setState({ loading: false })
    }

    handler = () => {
        this.props.history("/Addzone")
    }

    deleteHandler = async (myID) => {

        try {

            await deleteZone(myID);
            this.saveNewUsersToState();
            toast.success("Zone deleted successfully")
        } catch (err) {
            toast.warn(err.message)

        }
    }

    editHandler = (item) => {

        this.props.history("/Addzone", { state: { item } })
    }


    searchHandler = () => {

        if (!this.state.name && !this.state.username) {
            this.setState({ tableFilter: this.state.data })
            return
        }
        const res = this.state.data.filter(i => i.zone_name.toLowerCase().includes(this.state.name.toLowerCase()))

        const res1 = res.filter(i => i.zoneCode.toLowerCase().includes(this.state.username.toLowerCase()))
        console.log('res1: ', res1);

        this.setState({ tableFilter: res1 })
    }




    render() {
        console.log(this.state.tableFilter);
        const headers = [

            "Zone Name",
            "Zone Code",
            "Store Name"
        ]

        const colNames = [

            "zone_name",
            "zoneCode",
            "siteId.storename"
        ]

        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div div className="dashboard-top" >
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Zones Info</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        <button className="run-btn"><p className="add-text" onClick={() => this.handler()}>Add Zones</p></button>
                    </div>
                    <div className="users-bottom">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "500px" }}>
                            {/* <p className="filter-text">Filters</p> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.zone_name)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null))}
                                onChange={(e, v) => this.setState({ name: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Zone Name" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilter?.map((item => item?.zoneCode)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null))}
                                onChange={(e, v) => this.setState({ username: v === null ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Zone Code" />}
                            />
                        </div>




                        <button className="run-btn"><p className="run-text" onClick={() => this.searchHandler()}>Search</p></button>







                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <PaginatedItems Action headers={headers} editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.deleteHandler(item._id)} noQRCode colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.tableFilter} />
                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(Zones);