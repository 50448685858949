import React, { Component } from "react";


import Dropdown from "../../components/Dropdown";
import MyInput from "../../components/MyInput";


export default class IBTDifferences extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">ZPL</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div className="drop-data" style={{ width: "60%" }}>
                        <MyInput
                            placeholder={"IBT"}
                        />
                        <MyInput
                            type="date"
                        />
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>

                    </div>

                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >ID</th>
                                    <th>Name</th>
                                    <th>ZPL</th>
                                    <th>Store Name</th>
                                    <th>Status</th>
                                    <th>Remarks </th>

                                    <th>Action </th>
                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td> {
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button className="action-btn"><p className="action-txt">View</p></button>
                                                </div>
                                            }
                                            </td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>{
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button className="action-btn"><p className="action-txt">QR code</p></button>
                                                </div>
                                            }</td>

                                            <td>
                                                {
                                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                        <div>
                                                            <button className="action-btn"><p className="action-txt">Edit</p></button>
                                                        </div>
                                                        <div>
                                                            <button className="action-btn"><p className="action-txt">Delete</p></button>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}