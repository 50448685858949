import React, { Component } from "react";

import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import MyInput from "../components/MyInput";
import Dropdown from "../components/Dropdown";
import { addstorepost, adduserPost, editUser, getRoles, getstores, getStores } from "../Path/Path";
import { withRouter } from "../components/withRouter";
import { editstore } from "../Path/Path";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
class Addstore extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            type: "",
            status: "Active",
            showtype: false,
            showStatus: false,
            storeCity: "",
            storeMall: "",
            entity: "",
            company: "",
            loading: true,
            wareHouse: "",

        }
    }

    async componentDidMount() {

        if (this.props.location.state?.item) {

            const item = this.props.location.state.item

            this.setState({
                name: item.Name, storeCity: item.City,
                storeMall: item.Mall, entity: item.Entity, wareHouse: item.Warehouse,

                storeName: item.siteId, type: item.store_type
            })
        }
    }

    sendHanndler = async () => {

        try {



            const body = {


                "Name": this.state.name,
                "City": this.state.storeCity,
                "Mall": this.state.storeMall,
                "Entity": this.state.entity,
                "status": this.state.status === "Active" ? "1" : "0",
                "store_type": this.state.type,
                "Warehouse": this.state.wareHouse,
            }
            if (this.props.location?.state?.item) {


                editstore(body, this.props.location.state.item._id)
                toast.success("Edit store succesfully")
                const userinfo = await getstores();
                this.props.history("/store")
            }

            else {
                addstorepost(body).then((res) => {
                    toast.success("Add store succesfully")
                    this.props.history("/store")
                })
            }

        } catch (error) {
            toast.warn(error.message)
        }





    }

    render() {
        const _id = ""
        const actionText = !!_id ? "Update" : "Add"
        console.log(this.props.location, "locationssssssssss");

        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">{actionText + " Store"}</p></div>
                    <div style={{ display: "flex", width: "13%", justifyContent: "space-between", marginTop: "0.3%" }}>
                        <div >
                            <img src={zoom}></img>
                        </div>
                        <div>
                            <img src={print}></img>
                        </div>
                        <div>
                            <img width={30} height={30} src={download}></img>
                        </div>
                    </div>
                </div>
                <div style={{ paddingInline: 20, marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    {/* <div> <p className="filter-text">Filters</p></div> */}

                    <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.name}
                        value={this.state.name}
                        placeholder="Name"
                        size={'small'}
                        onChange={(data) => this.setState({ name: data.target.value })} id="outlined-basic" label="Name" variant="outlined" />


                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size={'small'}
                        value={this.state.status}
                        options={["Active", "Deactive"]}
                        onChange={(e, v) => this.setState({ status: v === null ? '' : v })}
                        sx={{ width: 235 }}
                        renderInput={(params) => <TextField
                            {...params} label="Status" />}
                    />
                    <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.storeCity}
                        value={this.state.storeCity}
                        placeholder="Store City"
                        size={'small'}
                        onChange={(data) => this.setState({ storeCity: data.target.value })} id="outlined-basic" label="Store City" variant="outlined" />


                </div>

                <div style={{ paddingInline: 20, marginTop: 15, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                    <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.storeMall}
                        placeholder="Store Mall"
                        value={this.state.storeMall}
                        size={'small'}
                        onChange={(data) => this.setState({ storeMall: data.target.value })} id="outlined-basic" label="Store Mall" variant="outlined" />

                    <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.entity}
                        value={this.state.entity}
                        placeholder="Entity"
                        size={'small'}
                        onChange={(data) => this.setState({ entity: data.target.value })} id="outlined-basic" label="Entity" variant="outlined" />
                    {/* <TextField
                        sx={{ width: 235 }}
                        defaultValue={this.state.company}
                        value={this.state.company}
                        placeholder="Company"
                        size={'small'}
                        onChange={(data) => this.setState({ company: data.target.value })} id="outlined-basic" label="Company" variant="outlined" /> */}
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size={'small'}
                        value={this.state.type}
                        options={["Ware House", "Store Front"]}
                        onChange={(e, v) => this.setState({ type: v === null ? '' : v })}
                        sx={{ width: 235 }}
                        renderInput={(params) => <TextField
                            {...params} label="Store Type" />}
                    />

                </div>
                <div style={{ paddingInline: 20, marginTop: 15, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                    <TextField
                        // disabled={this.state.type === "Ware House" ? false : true}
                        sx={{ width: 235 }}
                        defaultValue={this.state.wareHouse}
                        value={this.state.wareHouse}
                        placeholder="WareHouse"
                        size={'small'}
                        onChange={(data) => this.setState({ wareHouse: data.target.value })} id="outlined-basic" label="WareHouse" variant="outlined" />

                </div>
                <div style={{ paddingLeft: 20, marginTop: 20, marginBottom: 20 }} >
                    <button className="run-btn"><p className="run-text" onClick={() => this.sendHanndler()}>{actionText + " Store"}</p></button>
                </div>
            </div>


        )
    }
}

export default withRouter(Addstore);