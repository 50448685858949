import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput"
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
export default class DailyStockCount extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Daily Stock Count Report</p></div>

                </div>
                <div className="users-bottom">
                    <div> <p className="filter-text">Filters</p></div>

                    <MyInput
                        type={"date"}
                    />
                    <MyInput
                        type={"date"}
                    />

                    <button className="run-btn"><p className="run-text">Search</p></button>






                </div>
            </div>


        )
    }
}