import React, { Component, lazy } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import { AiFillTag } from 'react-icons/ai'
import { ImPriceTags } from 'react-icons/im'
import { MdLocationPin } from 'react-icons/md'
import { RiDownload2Line } from 'react-icons/ri'
import { FaBox, FaSearch } from 'react-icons/fa'
import { CgSoftwareUpload } from 'react-icons/cg'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { IoIosBarcode } from 'react-icons/io'
import { withRouter } from '../components/withRouter'

import { DropDownIcons } from '../components/DropDownIcons'
import { editHomeScreen, getHomeScreen } from '../Path/Path'
import { CustomModal } from '../components/Modal'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'


export class MainScreen extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        HomeScreen: [],
        inStoreGi: '',
        firstName: '',
        secondName: '',
        bgColor: '',
        checkedValue: '',
        currentIcon: '',
        HomeScreenChangeable: [],
        btns: [
            {
                bgColor: '#50aed4',
                text: 'Manage',
                subText: 'tags',
                icon: <AiFillTag size={30} />,
            },
            {
                bgColor: '#e44b5d',
                text: 'Cycle Count',
                subText: 'tags',
                icon: <ImPriceTags size={30} />,
            },
            {
                bgColor: '#4d7462',
                text: 'Locate',
                subText: 'Item',
                icon: <MdLocationPin size={30} />,
            },
            {
                bgColor: '#b96fac',
                text: 'Receiving',
                subText: 'Items',
                icon: <RiDownload2Line size={30} />,
            },
            {
                bgColor: '#527183',
                text: 'Packing',
                subText: 'Items',
                icon: <FaBox size={30} />,
            },
            {
                bgColor: '#8d273d',
                text: 'Transfer',
                subText: 'Items',
                icon: <CgSoftwareUpload size={30} />,
            },
            {
                bgColor: '#8d273d',
                text: 'Encode',
                subText: 'tag',
                icon: <ImPriceTags size={30} />,
            },
            {
                bgColor: '#a9ad35',
                text: 'Barcode',
                subText: 'SKU',
                icon: <CgSoftwareUpload size={30} />,
            },
            {
                bgColor: '#a9ad35',
                text: 'Targeted',
                subText: 'Stock',
                icon: <CgSoftwareUpload size={30} />,
            },
        ]
    }
    saveHandler = async (onClose, firstName, secondName, bgColor, indexCurrent, visible, checkedValue) => {

        console.log(this.state.firstName);
        console.log(this.state.secondName);
        console.log(this.state.bgColor);
        console.log(indexCurrent);
        console.log(checkedValue);

        let menu = this.state.HomeScreenChangeable[0]?.menu.filter(function (obj, i) {
            if (obj.hasOwnProperty('_id')) {
                delete obj._id
                return obj;
            } else {
                return obj;
            }
        })
        console.log(menu);
        for (let index = 0; index < menu.length; index++) {
            const element = menu[index];
            if (index === indexCurrent) {
                element.text = this.state.firstName !== '' ? this.state.firstName : element.text
                element.subText = this.state.secondName !== '' ? this.state.secondName : element.subText
                element.bgColor = this.state.bgColor !== '' ? this.state.bgColor : element.bgColor
                element.show = this.state.checkedValue !== '' ? this.state.checkedValue : element.show
            }
        }
        await this.setState({
            HomeScreen:
                [
                    {
                        "input": {
                            "placeholder": this.state.asnValue ? this.state.asnValue : this.state.HomeScreen[0]?.input?.placeholder
                        },
                        "menu": menu
                    }
                ]
        })
        await this.setState({ firstName: '' })
        await this.setState({ secondName: '' })
        await this.setState({ bgColor: '' })
        await this.setState({ checkedValue: '' })
        await this.sendingData()
        onClose()
    }
    closeHandler = (onClose, firstName, secondName, bgColor, indexCurrent, visible, checkedValue) => {
        // this.setState({ [firstName]: '' })
        // this.setState({ [secondName]: '' })
        // this.setState({ [bgColor]: '' })
        // this.setState({ [checkedValue]: visible })
        onClose()
    }
    changeIcon = (iconNameValue) => {
        let value = iconNameValue

        // console.log(iconNameValue   ,'icon Name');

    }
    buttonHandler = (firstName, secondName, bgColor, index, visible, checkedValue) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.saveHandler(onClose, this.state[firstName], this.state[secondName], this.state[bgColor], index, visible, checkedValue)} onClose={() => onClose()} heading={`Change ${firstName + ' ' + secondName}`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                            <React.Fragment>
                                <Form.Label>{'First Heading'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={firstName} placeholder={firstName} defaultValue={this.state.firstName !== '' ? this.state.firstName : firstName} onChange={(e) => this.setState({ firstName: e.target.value })}
                                />
                            </React.Fragment>
                            <React.Fragment>
                                <Form.Label>{'Second Heading'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={secondName} placeholder={secondName} defaultValue={this.state.secondName !== '' ? this.state.secondName : secondName} onChange={(e) => this.setState({ secondName: e.target.value })}
                                />
                            </React.Fragment>
                            <React.Fragment>
                                <Form.Label>{'Background Color'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={bgColor} placeholder={bgColor} defaultValue={this.state.bgColor !== '' ? this.state.bgColor : bgColor} onChange={(e) => this.setState({ bgColor: e.target.value })}
                                />
                            </React.Fragment>
                            <React.Fragment>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.checkedValue !== '' ? this.state.checkedValue : visible}
                                            onChange={(e) => this.setState({ checkedValue: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    iconHandler = (selectedIcon) => {
        this.setState({ currentIcon: selectedIcon })
    }
    async componentDidMount() {
        const receiving = await getHomeScreen()
        this.setState({ HomeScreen: receiving, HomeScreenChangeable: receiving })
        await console.log(receiving, 'receiving');
    }
    // saveHandler = () => {
    //     const body = {
    //         "input": {
    //             "placeholder": this.state?.asnValue
    //         },
    //         "menu": [],
    //     }
    // }
    sendingData = async () => {
        const body = this.state.HomeScreen[0]
        console.log(body);
        await editHomeScreen(body).then(
            () => {
                toast.success("Data Updated")
            }
        ).catch((err) => {
            alert('Something Went Wrong')
            console.log(err);
        })
    }
    render() {

        console.log(this.state);
        return (
            <div className="dashboard-main-mine">
                <div className="dashboard-top-mine">
                    <div> <p className="stock-count">Main Screen (Home Page)</p></div>
                    <div>
                        <button style={{ marginTop: '0%' }} onClick={() => this.sendingData()} className="refresh-btn"><p className="refresh-text">Save</p></button>
                    </div>
                </div>
                <div className='production-main-div'>
                    <div className='production-main-child'>
                        Default Values (Home Page)
                        <div className='production-main-screen' style={{ minHeight: 700 }} >
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginBottom: 20 }}>

                                <input disabled type={'text'} id={'asnValue'} placeholder={this.state.HomeScreen[0]?.input?.placeholder} style={{ minWidth: '290px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div onClick={() => this.changeIcon('BsFillArrowDownLeftSquareFill')} style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    {this.state.currentIcon === '' ?
                                        <FaSearch size={25} />
                                        :
                                        this.state.currentIcon
                                    }
                                </div>
                                {/* <DropDownIconss selectedIcon={(selectedIcon) => this.iconHandler(selectedIcon)} /> */}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>

                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', width: '98%', gridColumnGap: 10, gridRowGap: 10 }}>
                                    {this.state.btns.map((item, index) =>
                                        <button style={{
                                            width: '170px',
                                            backgroundColor: item.bgColor,
                                            padding: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: 110,
                                            borderRadius: 10,
                                            cursor: 'pointer',
                                            justifyContent: 'flex-start'
                                        }}>
                                            <div style={{ color: 'white' }}>
                                                {this.state.btns[index].icon}
                                            </div>
                                            <h1 style={{ fontSize: 15, color: 'white' }}>
                                                {item.text}
                                            </h1>
                                            <h1 style={{ fontSize: 13, color: 'white', fontWeight: 'lighter' }}>
                                                {item.subText}
                                            </h1>
                                        </button>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='production-main-child'>
                        Main Screen (Home Page)
                        <div className='production-main-screen' style={{ minHeight: 700 }} >
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginBottom: 20 }}>

                                <input type={'text'} id={'asnValue'} placeholder={'Enter SKU,Itemcode, EPC or Scan'} style={{ minWidth: '290px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div onClick={() => this.changeIcon('BsFillArrowDownLeftSquareFill')} style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    {this.state.currentIcon === '' ?
                                        <FaSearch size={25} />
                                        :
                                        this.state.currentIcon
                                    }
                                </div>
                                {/* <DropDownIconss selectedIcon={(selectedIcon) => this.iconHandler(selectedIcon)} /> */}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', width: '98%', gridColumnGap: 10, gridRowGap: 10 }}>
                                    {this.state.HomeScreen[0]?.menu.map((item, index) =>
                                        <button onClick={() => this.buttonHandler(item.text, item.subText, item.bgColor, index, item.show, `checked_${index}`)} style={{
                                            width: '170px',
                                            backgroundColor: item.bgColor,
                                            padding: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: 110,
                                            borderRadius: 10,
                                            cursor: 'pointer',
                                            justifyContent: 'flex-start'
                                        }}>
                                            <div style={{ color: 'white' }}>
                                                {this.state.btns[index].icon}
                                            </div>
                                            <h1 style={{ fontSize: 15, color: 'white' }}>
                                                {item.text}
                                            </h1>
                                            <h1 style={{ fontSize: 13, color: 'white', fontWeight: 'lighter' }}>
                                                {item.subText}
                                            </h1>
                                            <h1 style={{ fontSize: 13, color: 'white', fontWeight: 'lighter', marginTop: 10 }}>
                                                {item.show ? 'Visible' : 'Not Visible'}
                                            </h1>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(MainScreen)
