import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import Loader from "../components/Loader";
import { PaginatedItems } from "../components/Paginated";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { localServer } from "../Path/Path";
import { getItemMasterDataNew } from "../Path/Path";
import { toast } from "react-toastify";
import { formatItem } from "./common/FormatItems";

class ItemMaster extends Component {
  state = {
    printingItems: [],
    printingItemsFilter: [],
    itemsPerPage: 0,
    Article_Code: "",
    Article_Uom: "",
    loading: false,
    toDate: null,
    Article_GTIN: "",
    articleNumber: "",
    refresh: false,
    paginationData: {},
    epc: "",
    sku: "",
    users: "",
    po_number: "",
    invoice_number: "",
    toDate: null,
    fromDate: null,
    totalDocuments: 0,
    currentPage: 0,
  };

  validateDate = (date) => {
    const dateFormat = "MM/DD/YYYY";
    if (!date) {
      return true;
    }
    if (!moment(date, dateFormat, true).isValid()) {
      // toast.error("Invalid date format.");
      return false;
    }
    if (moment(date).isAfter(moment())) {
      return false;
    }
    return true;
  };

  getItems = async (pageNumber = 1) => {
    const newFromDate = this.validateDate(this.state.fromDate);
    const newToDate = this.validateDate(this.state.toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }
    const params = {
      pageNo: pageNumber,
      Article_GTIN: this.state.Article_GTIN,
      Article_Code: this.state.Article_Code,
      Article_Uom: this.state.Article_Uom,
      startDate: this.state.fromDate
        ? moment(this.state.fromDate).startOf("day").format("YYYY-MM-DD")
        : "",
      endDate: this.state.toDate
        ? moment(this.state.toDate).endOf("day").format("YYYY-MM-DD")
        : "",
    };
    this.setState({ loading: true });
    const data = await getItemMasterDataNew(params);
    var filtering = data?.data?.data;
    this.setState({
      printingItems: filtering,
      printingItemsFilter: filtering,
      paginationData: data?.data,
      totalDocuments: data?.data?.total,
      currentPage: data?.data?.page,
      itemsPerPage: data?.data?.size,
    });
	console.log("current page",this.state.currentPage)
	console.log("total documents",this.state.totalDocuments)
    this.setState({ loading: false });
    if (data?.data.data.length === 0) {
      toast.error("Data not found");
    }
  };

  async componentDidMount() {
    this.getItems();
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  dateFilter = () => {
    var filtering = this.state.printingItemsFilter;
    filtering = filtering.filter((item) => item?.serialNumber !== undefined);
    filtering = filtering.filter(
      (item) => item?.udfs?.Retail_Product_SKUOriginal?.value !== undefined
    );
    filtering = filtering.filter(
      (item) => item?.udfs?.user?.value !== undefined
    );
    filtering = filtering.filter((item) => item?.createdAt !== undefined);
    if (this.state.toDate === null && this.state.fromDate === null) {
      return filtering.filter(
        (x) =>
          x?.serialNumber
            ?.toLowerCase()
            .includes(this.state.epc.toLowerCase()) &&
          x?.udfs?.Retail_Product_SKUOriginal?.value
            ?.toLowerCase()
            .includes(this.state.sku.toLowerCase()) &&
          x?.udfs?.user?.value
            .toLowerCase()
            .includes(this.state.users.toLowerCase())
      );
    } else {
      return filtering.filter(
        (x) =>
          this.formatDate(x?.createdAt) >= this.formatDate(this.state.toDate) &&
          this.formatDate(x?.createdAt) <=
            this.formatDate(this.state.fromDate) &&
          x?.serialNumber
            ?.toLowerCase()
            .includes(this.state.epc.toLowerCase()) &&
          x?.udfs?.Retail_Product_SKUOriginal?.value
            ?.toLowerCase()
            .includes(this.state.sku.toLowerCase()) &&
          x?.udfs?.user?.value
            .toLowerCase()
            .includes(this.state.users.toLowerCase())
      );
    }
  };
  searchData = async () => {
    this.setState({ loading: true });
    this.setState({ refresh: !this.state.refresh });
    await this.setState({ printingItems: this.dateFilter() });
    this.setState({ loading: false });
  };

  getCSV = async () => {
    const newFromDate = this.validateDate(this.state.fromDate);
    const newToDate = this.validateDate(this.state.toDate);
    if (!newFromDate || !newToDate) {
      toast.error("Please enter valid date ranges.");
      return;
    }

    this.setState({ loading: true });
    try {
      const params = {
        Article_GTIN: this.state.Article_GTIN,
        Article_Code: this.state.Article_Code,
        Article_Uom: this.state.Article_Uom,
        startDate: this.state.fromDate
          ? moment(this.state.fromDate).startOf("day").format("YYYY-MM-DD")
          : "",
        endDate: this.state.toDate
          ? moment(this.state.toDate).endOf("day").format("YYYY-MM-DD")
          : "",
      };
      const response = await axios.get(`${localServer}store/itemMaster/csv`, {
        params,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Item Master_Report.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast.success("File downloaded successfully.");
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Download Failed!");
    }
    this.setState({ loading: false });
  };
  render() {
    const headers = [
      "Created Time",
      "Article Code",
      "Article Description",
      "Article Uom",
      "Article Conv Factor",
      "Article BUOM",
      "Article GTIN",
      "Article MC Category",
      "Article MC Description",
      "Article Brand",
      "Article COO",
      "Article ROO",
      "Article Season Category",
      "Article Season Year",
      "Article Purchase desc",
      "Article Language Key",
      "Article UOM SKU",
    ];
    const colNames = [
      "createdAt",
      "Article_Code",
      "Article_Desc",
      "Article_Uom",
      "Article_Conv_Factor",
      "Article_BUOM",
      "Article_GTIN",
      "Article_MC_Category",
      "Article_MC_Desc",
      "Article_Brand",
      "Article_COO",
      "Article_ROO",
      "Article_Season_Category",
      "Article_Season_Year",
      "Article_Purchase_desc",
      "Article_Language_Key",
      "Article_UOM_SKU",
    ];

    const startItem = this.state.currentPage
      ? (this.state.currentPage - 1) * this.state.itemsPerPage + 1
      : 0;
    const endItem = this.state.currentPage
      ? Math.min(
          this.state.currentPage * this.state.itemsPerPage,
          this.state.totalDocuments
        )
      : 0;

    return (
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <div className="dashboard-main">
          <div
            className="dashboard-top"
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                Item Master 
              </p>
              <GrDocumentCsv
                onClick={() => this.getCSV()}
                color="black"
                fill="white"
                fontSize={25}
                style={{ marginRight: 5, cursor: "pointer" }}
              />
            </div>
            <div>
              <h1 style={{ color: "black", fontSize: "15px" }}>
                {new Date().toLocaleString("hi-IN")}
              </h1>
            </div>
          </div>
          <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <DesktopDatePicker
                  label="From Date"
                  clearable
                  showTodayButton
                  inputFormat="MM/DD/YYYY"
                  size={"small"}
                  maxDate={new Date()}
                  value={this.state.fromDate}
                  onChange={(data) => this.setState({ fromDate: data })}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ width: 170 }} size={"small"} {...params} />
                  )}
                />
                <DesktopDatePicker
                  label="To Date"
                  clearable
                  showTodayButton
                  inputFormat="MM/DD/YYYY"
                  size={"small"}
                  maxDate={new Date()}
                  value={this.state.toDate}
                  onChange={(data) => this.setState({ toDate: data })}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ width: 170 }} size={"small"} {...params} />
                  )}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={this.state.printingItemsFilter
                    ?.filter((item) => item?.Article_GTIN !== "")
                    .map((item) => item?.Article_GTIN)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) =>
                    this.setState({ Article_GTIN: v === null ? "" : v })
                  }
                  onInputChange={(e, v) => this.setState({ Article_GTIN: v })}
                  sx={{ width: 170 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Article_GTIN" />
                  )}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={this.state.printingItemsFilter
                    ?.filter((item) => item?.Article_Code !== "")
                    .map((item) => item?.Article_Code)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) =>
                    this.setState({ Article_Code: v === null ? "" : v })
                  }
                  onInputChange={(e, v) => this.setState({ Article_Code: v })}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Article Code " />
                  )}
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={this.state.printingItemsFilter
                    ?.filter((item) => item?.Article_Uom !== "")
                    .map((item) => item?.Article_Uom)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) =>
                    this.setState({ Article_Uom: v === null ? "" : v })
                  }
                  onInputChange={(e, v) => this.setState({ Article_Uom: v })}
                  sx={{ width: 170 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Article UOM" />
                  )}
                />
              </div>

              <button onClick={() => this.getItems()} className="run-btn">
                <p className="run-text">Search</p>
              </button>
              {/* </div>className="button-custom" >Search</button> */}
            </div>

            <div
              style={{
                marginTop: "10px",
                overflowX: "scroll",
                height: "600px",
                color: "transparent",
              }}
            >
              <PaginatedItems
                pageCountNew={
                  this.state.paginationData?.next +
                  this.state.paginationData?.previous +
                  1
                }
                getData={(pageNumber) => this.getItems(pageNumber)}
                totalLength={this.state.paginationData?.next}
                refresh={this.state.refresh}
                noAction
                headers={headers}
                colNames={colNames}
                forcePage={this.state.paginationData?.previous || 0}
                itemsPerPage={this.state.itemsPerPage}
                data={this.state.printingItems.map((item) => {
                  return {
                    ...formatItem(item),
                    Date: new Date(item?.createdAt).toLocaleString(),
                  };
                })}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
                color: "white",
                padding: "10px",
                gap: "10px",
              }}
            >
              <h5>ROWS PER PAGE: 10</h5>
              <h5>{`${startItem}-${endItem} of ${this.state.totalDocuments}`}</h5>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ItemMaster;
