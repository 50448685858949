import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editDeviceSetup2, editManageTagScreen, getDeviceSetup2, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { IoIosBarcode } from 'react-icons/io';


class DeviceSetup1 extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        scanText: '',
        subText: '',
        text: "",
        setup: "",
        readerSelection: "",
        continueText: '',
        header: '',
        step2Label: '',
        step2Text: '',
        data: [
            {
                "header": {
                    "text": "Device",
                    "subText": "Setup"
                },
                "step2": {
                    "text": "Reader Selection",
                    "label": "Step2:"
                },
                "button": {
                    "scanText": "SCAN",
                    "continueText": "Continue"
                },
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        const usedData = this.state.data[0]
        const bodyStatus = {

            "header": {
                "text": this.state.text !== '' ? this.state.text : usedData.header.text,
                "subText": this.state.setup !== '' ? this.state.setup : usedData.header.subText
            },
            "step2": {
                "text": this.state.readerSelection !== '' ? this.state.readerSelection : usedData.step2.text,
            },
            "button": {
                "scanText": this.state.scanText !== '' ? this.state.scanText : usedData.button.scanText,
                "continueText": this.state.continueText !== '' ? this.state.continueText : usedData.button.continueText
            },
        }


        console.log(bodyStatus);
        const editing = await editDeviceSetup2(bodyStatus)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    continueHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Change Button'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Button Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.continueText === '' ? this.state.data[0].button.continueText : this.state.continueText}
                                    placeholder={this.state.continueText === '' ? this.state.data[0].button.continueText : this.state.continueText}
                                    onChange={(e) => this.setState({ continueText: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    scanHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Change Button'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Button Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.scanText === '' ? this.state.data[0].button.scanText : this.state.scanText}
                                    placeholder={this.state.scanText === '' ? this.state.data[0].button.scanText : this.state.scanText}
                                    onChange={(e) => this.setState({ scanText: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    deviceHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Change Text'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.text === '' ? this.state.data[0].header.text : this.state.text}
                                    placeholder={this.state.text === '' ? this.state.data[0].header.text : this.state.text}
                                    onChange={(e) => this.setState({ text: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    setupHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Change Text'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.setup === '' ? this.state.data[0].header.subText : this.state.setup}
                                    placeholder={this.state.setup === '' ? this.state.data[0].header.subText : this.state.setup}
                                    onChange={(e) => this.setState({ setup: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    readerHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Change Text'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Title'}</Form.Label>
                                <Form.Control
                                    type="text"
                                    id={value}
                                    defaultValue={this.state.readerSelection === '' ? this.state.data[0].step2.text : this.state.readerSelection}
                                    placeholder={this.state.readerSelection === '' ? this.state.data[0].step2.text : this.state.readerSelection}
                                    onChange={(e) => this.setState({ readerSelection: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }

    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getDeviceSetup2()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Device Setup Step 1</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '360px' }}  >
                                <h1 className='screen-heading' style={{ fontSize: '27px' }}> Device</h1>
                                <h1 className='screen-heading' style={{ fontSize: '15px', fontWeight: 'lighter' }} >Setup  </h1>
                                <h1 className='screen-heading' style={{ fontSize: '17px', fontWeight: 'lighter', marginTop: 40 }} >Reader Selection  </h1>
                                <div style={{ width: '100%', minHeight: '300px', backgroundColor: '#3c4554', marginTop: 10, borderRadius: '10px' }}>
                                </div>
                                <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 10 }}>Scan</button>
                                <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 10 }}>Continue </button>

                            </div>
                        </div>
                        <div className='production-main-child'>
                            Device Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '360px' }}  >
                                <h1 className='screen-heading' onClick={() => this.deviceHandler("device")} style={{ fontSize: '27px' }}>{this.state.text !== '' ? this.state.text : this.state.data[0].header.text}</h1>
                                <h1 className='screen-heading' onClick={() => this.setupHandler("setup")} style={{ fontSize: '15px', fontWeight: 'lighter' }} >{this.state.setup !== '' ? this.state.setup : this.state.data[0].header.subText}</h1>
                                <h1 className='screen-heading' onClick={() => this.readerHandler("readerSelection")} style={{ fontSize: '17px', fontWeight: 'lighter', marginTop: 40 }} >{this.state.readerSelection !== '' ? this.state.readerSelection : this.state.data[0].step2.text}</h1>
                                <div style={{ width: '100%', minHeight: '300px', backgroundColor: '#3c4554', marginTop: 10, borderRadius: '10px' }}>
                                </div>
                                <button onClick={() => this.scanHandler('scanText')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 10 }}>{this.state.scanText !== '' ? this.state.scanText : this.state.data[0].button.scanText}</button>
                                <button onClick={() => this.continueHandler('continueText')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px', marginTop: 10 }}>{this.state.continueText !== '' ? this.state.continueText : this.state.data[0].button.continueText} </button>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(DeviceSetup1)