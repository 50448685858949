import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';

import { useRef } from 'react';

export default function PrinterList({ value, width, list, onChangeValue, selectedValue, onClick, show, onClickList, placeholder, blackClass, sendPrinters }) {

    const [data, setData] = useState(list);
    const [inputVal, setinputVal] = useState('');
    const [DevicesFilters, setDevicesFilter] = useState([]);
    const [Devices, setDevices] = useState([]);
    const [visible, setVisible] = useState(false)
    const num = [1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165]
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // alert("You clicked outside of me!");
                    setVisible(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const handler = () => {
        setVisible(true)

    }
    const listData = (data) => {
        onClickList(data)
        setinputVal(data.name)
        console.log(inputVal);
        console.log(data);
    }
    useEffect(() => {
        window.BrowserPrint.getLocalDevices(function (deviceList) {
            setDevices(deviceList.printer)
            setDevicesFilter(deviceList.printer)
        }, (err) => {
            console.log(err)
        })
    }, [])

    return (
        <Autocomplete
            style={{ marginTop: '20px' }}
            disablePortal
            id="combo-box-demo"
            size={'small'}
            options={DevicesFilters}
            getOptionLabel={(option) => option?.name}
            onChange={(e, v) => listData(v === null ? null : v)}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField
                {...params} label="Printer" />} />
                
    )
}
