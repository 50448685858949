import { Autocomplete, createTheme, TextField, ThemeProvider } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { withRouter } from "../components/withRouter";
import {  getStores, localServer } from "../Path/Path";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import BulkPrintingModal from "./BulkPrintingModal";
import { HeadAndRowBulk } from "../components/Helper";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import PermissionsModal from "../components/PermissionsModal";
import PrinterList from "./TagIT/PrinterList";

class BulkPrinting extends Component {
 state = {
        stores: [],
        zplList: [],
        siteList: [],
        selectedSite: '',
        store: '',
        zpl: '',
        userName: '',
        qty: '',
        zplData: null,
        loading: false,
        printer: null,
        printerVal: '',
        printerCount: 1,
        articleInputValue: '',
        articleNumber: [],
        productionDate:null,
        mdseCategory: '',
        EAN: '',
        UOM: '',
        barcodeCategory: '',
        goodsRec:'',
        purchasingDoc: '',
        inBoundary: '',
        outBoundary: '',
        supplier: '',
        productionTag: false,
        innerBox: '',
        allCurrency: true,
        withPrice: true,
        posDescription: false,
        madeInChina: false,
        openModal: null,
        file: null,
        extractedArticles: null,
        articleModal: false,
        permissionArray: null,
        formattedArticles: null,
        isFocused: false,
        numberArray: [],
        activeField: null,
        printer: null,
        printerVal: '',
    }

    printerHandler = (data) => {
        this.setState({ printer: data })
        this.setState({ printerVal: data?.name })
        console.log(data);
    }


    zplHandler = (data) => {
        this.setState({ zpl: data?.zplName })
        this.setState({ zplData: data });
    }

    
  handleFileUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) {
      this.setState({ file: file });
      this.readFile(file);
      e.target.value = null; 
    } else {
      console.error("No file selected.");
    }
  };

  readFile = (file) => {
    try {
        
    const reader = new FileReader();
    const fileExtension = file.name.split('.').pop().toLowerCase(); 

    reader.onload = (e) => {
      const data = e.target.result;

      if (fileExtension === 'csv') {

        Papa.parse(data, {
          header: true,
          complete: (result) => {
            const extractedArticles = result.data
                        .map(row => row.Article?.trim()) 
                        .filter(article => article);
            this.setState({ extractedArticles: extractedArticles, permissionArray: extractedArticles });

          },
        });
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {

        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const extractedArticles = worksheet.map((row) => row.Article);
        this.setState({ extractedArticles: extractedArticles });
      } else {
        console.error("Unsupported file format.");
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
    const artArray = this.state.extractedArticles? this.state.extractedArticles.split(',').map((item) =>item.trim()): []
    this.setState({permissionArray: artArray})

    if (fileExtension === 'csv') {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
    this.setState({activeField : 'extractedArticles'})
    } catch (error) {
        console.error(error)
        toast.error("File to large, contains irrelevant data")
    }
  };

handleArticleInput = async () => {
    const value = this.state.articleInputValue.trim(); 
    const numberArray = value.split(/[\s,]+/).map((item) =>item.trim()); 

      const formatted = await this.formatArticlesForAPI(value);
      this.setState({ formattedArticles: formatted });
      return formatted
   };

   async componentDidMount() {
        try {
        const storeResponse = await getStores();

        this.setState({ 
            stores: storeResponse, 
            siteList: storeResponse.map(site => site.Warehouse) 
        });

        const zplResponse = await axios.get(`${localServer}store/zpl/get`);
        this.setState({ zplList: zplResponse.data });

    } catch (error) {
        console.log(error);
    }
    }

     closeModal =()=>{
        this.setState({openModal : null})
    }

formatArticlesForAPI = async(articlesInput) => {

    let articlesArray = [];
    if (typeof articlesInput === 'string') {

        articlesArray = articlesInput.split(/[\s,]+/).filter(Boolean); 
    } else if (Array.isArray(articlesInput)) {

        articlesArray = articlesInput.filter(Boolean); 
    } else {
    
    }
    if (!articlesArray || articlesArray.length === 0) {
        console.error("articlesArray is either undefined or empty");
        // return {};
    }

    const formatted = {
        articles: articlesArray.map(article => ({
            "articles": article
        }))
    }; 

    return formatted;
};

handleArticlesChange = (e) => {
    const value = e.target.value;
    this.handleFieldChange('extractedArticles',(this.state.articlesArray && !this.state.extractedArticles)??value)
    const articlesArray = value.split(/[\s,]+/).filter(Boolean); 
    this.setState({ extractedArticles: value, permissionArray: articlesArray });
};

 handleFocus = () => {
        this.setState({ isFocused: true });
    };
    handleBlur = () => {
        this.setState({ isFocused: false });
    };

      handleFieldChange = (field, value) => {
    this.setState({
      [field]: value,
      activeField: value !== '' ? field : null,
    });
  };

  handleExtractedArticlesChange = () => {
        const { extractedArticles } = this.state;
        if (extractedArticles.trim() !== '') {
            this.setState({ activeField: 'extractedArticles' });
        } else if (this.state.activeField === 'extractedArticles') {
            this.setState({ activeField: null });
        }
    }

    handleDisabledFieldClick = (fieldName) => {
    if (this.state.activeField && this.state.activeField !== fieldName) {
      toast.error(`Please clear the ${this.state.activeField} field first.`);
    }
    };
    printHandler = async () => {
        let data =  await this.handleArticleInput()
        const { formattedArticles, extractedArticles, selectedSite, purchasingDoc, } = this.state;
        const extArticles = await this.formatArticlesForAPI(extractedArticles)

        if (
            (!formattedArticles || formattedArticles.length === 0) &&
            (!purchasingDoc || purchasingDoc.trim() === '') &&
            (!extArticles || extArticles.length === 0)
        ) {
            toast.error("Please enter at least one valid article, PO number, or upload a file with articles.");
            this.setState({ loading: false });
            return;
        } else if(!selectedSite || selectedSite === '') {
            toast.error('Please select a Site')
            return
        } else {
            this.setState({ loading: true })
            const reqURI = process.env.REACT_APP_SAP_BULK_URL;
            const auth = {
                username: process.env.REACT_APP_SAP_USER,
                password: process.env.REACT_APP_SAP_PASSWORD
            };

            const payload = {
                root: {
                    site: selectedSite || '',  
                    // mdse_category: this.state.mdseCategory || "",
                    articles:   extArticles && extArticles.articles.length > 0 
                                ? extArticles.articles
                                : (formattedArticles.articles && formattedArticles.articles.length > 0)
                                ? formattedArticles.articles
                                    : {}       ,                   
                    // EAN: this.state.EAN || "",
                    UOM: this.state.UOM || "", 
                    // barcode_category: this.state.barcodeCategory || "",            
                    po_number: this.state.purchasingDoc || "",          
                    inbound_delivery: this.state.inBoundary || "",  
                    outbound_delivery: this.state.outBoundary || "", 
                    supplier: this.state.supplier || "",           
                    multi_currency: this.state.allCurrency,                          
                    pos_description: this.state.posDescription || false,                          
                }
            };
            try {
                const response = await axios.post(reqURI, payload, {auth});
                this.setState({openModal: response.data.root})
                this.setState({ loading: false })
            } catch (error) {
                this.setState({ loading: false })
                console.log("error while printing",error)
            }
        }
    }
    render() {
    const { articleInputValue, purchasingDoc, activeField} = this.state;
    
        const darkTheme = createTheme({
            palette: {
                mode: 'light',
            },
        });
        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <ThemeProvider theme={darkTheme} >
                   
                    <div className="dashboard-main">
                        <div className="dashboard-top">
                            <div> <p className="title-style">Bulk Printing</p>
                                <h1 style={{ color: 'black', fontSize: '15px' }}>
                                    {new Date().toLocaleString('hi-IN')}
                                </h1>
                            </div>

                        </div>
                        <div className="bulk-main-div">
                            <div className="bulk-bottom">
                                <div className="bulk-box-1">
                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "3%" }}>
                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                            <p className="total-score-tag2">Label Printing</p>
                                        </div>
                                    </div>
                                    <div className="bulk-row-div">
                                        <div className="bulk-div">
                                            <div >
                                                <Autocomplete
                                                    style={{ marginTop: '20px' }}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size={'small'}
                                                    options={this.state.siteList}  
                                                    getOptionLabel={(option) => option}  
                                                    onChange={(e, v) =>
                                                        this.setState({ selectedSite: v === null ? "" : v })
                                                    }
                                                    sx={{ width: 200 }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Site" />
                                                    )}
                                                />

                                            </div>
                                            <div>
                                                <TextField
                                                     sx={{ width: '100%', marginTop:"20px" }}
                                                    value={this.state.mdseCategory}
                                                    placeholder={this.state.mdseCategory}
                                                    onChange={(e) => this.setState({ mdseCategory: e.target.value })}
                                                    size={'small'}
                                                    id="outlined-basic" label="Mdse Category" variant="outlined" />
                                               
                                            </div> 
                                        </div>

                                    </div>
                                    <div className="bulk-row-div">
                                     <div className="bulk-div">
                                        <div>
                                            <TextField
                                            sx={{ width: '275px', marginTop: "20px" }}
                                            value={this.state.extractedArticles}
                                            size={'small'}
                                            id="outlined-basic"
                                            label="CSV or Excel Sheet"
                                            variant="outlined"
                                            onFocus={this.handleFocus}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleArticlesChange}
                                            InputLabelProps={{
                                                shrink: this.state.isFocused || this.state.extractedArticles !== null && this.state.extractedArticles !== '', 
                                            }}
                                            placeholder="Enter articles" 
                                            disabled={activeField && activeField !== 'extractedArticles'}
                                            onClick={() =>this.handleDisabledFieldClick('extractedArticles')}
                                            />
                                        </div>

                                        <div style={{ marginTop: '20px', display:'flex',flexDirection:'row'}}>
                                            <input
                                                type="file"
                                                accept=".csv, .xls, .xlsx"
                                                onChange={this.handleFileUpload} 
                                                ref={(input) => (this.fileInput = input)} 
                                                style={{ display: 'none' }} 
                                            />

                                            <button 
                                                className="view-btn"
                                                onClick={() => this.setState({articleModal : true})} 
                                            >
                                                <p className="print-text">View</p>
                                            </button>
                                            
                                            <button
                                                className="browse-btn"
                                                onClick={() => this.fileInput.click()} 
                                                disabled={purchasingDoc != '' || articleInputValue !=''}
                                            >
                                                <p className="print-text">Browse</p>
                                            </button>
                                        </div>
                                        </div>
                                    </div>

                                    <div style={{display: "flex", flexDirection: "column", paddingLeft: "3%", paddingRight: "3%" , alignItems:"center"}}>
                                    
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                    size="small"
                                                    id="outlined-basic"
                                                    label="Article (can enter multiple separate by space or comma)"
                                                    variant="outlined"
                                                    value={this.state.articleInputValue}
                                                    onChange={(e) => this.handleFieldChange('articleInputValue',e.target.value)}
                                                    placeholder="Article "
                                                    disabled={activeField && activeField !== 'articleInputValue'}
                                                    onClick={() => this.handleDisabledFieldClick('articleInputValue')}
                                                />
                                            </div>
                                        
                                            <div className="bulk-field">
                                                <TextField
                                                   className="bulk-field"
                                                    onChange={(e) => this.setState({EAN: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'EAN'} variant="outlined" />
                                            </div>
                                        
                                            <div className="bulk-field">
                                                <TextField
                                                   className="bulk-field"
                                                     onChange={(e) => this.setState({UOM: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'UOM'} variant="outlined" />
                                            </div>
                                        
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.setState({barcodeCategory: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Barcode Category'} variant="outlined" />
                                            </div>
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.setState({goodsRec: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Goods Receipt Number'} variant="outlined" />
                                            </div>
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.handleFieldChange('purchasingDoc',e.target.value)}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Purchasing Document'} variant="outlined"
                                                    disabled={activeField && activeField !== 'purchasingDoc'}
                                                    onClick={() => this.handleDisabledFieldClick('purchasingDoc')}
                                                    />
                                            </div>
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.setState({inBoundary: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'InBound Delivery'} variant="outlined" />
                                            </div>
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.setState({outBoundary: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'OutBound Delivery'} variant="outlined" />
                                            </div>
                                            <div className="bulk-field">
                                                <TextField
                                                    className="bulk-field"
                                                     onChange={(e) => this.setState({supplier: e.target.value})}
                                                    size={'small'}
                                                    id="outlined-basic" label={'Supplier'} variant="outlined" />
                                            </div>

                                    </div>
                                    
                                </div>
                            </div>

                            <div className="admin-bottom">
                                <div className="bulk-box-2">
                                    <div className="bulk-container">
                                        <div className="form-group">
                                            <p className="total-score-tag2">Barcode Label Type</p>
                                        </div>
                                    </div>

                                    <div className="bulk-container">
                                        <div className="form-group">
                                            <input className="bulkIconsStyle" type="checkbox" 
                                            checked={this.state.productionTag}
                                            onChange={(e)=> this.setState({productionTag: e.target.checked})}
                                            />
                                            <p className="total-score-tag2">Production Tag</p>
                                        </div>

                                        <div className="form-group">
                                            <input className="bulkIconsStyle" 
                                            type="checkbox"
                                           checked={this.state.innerBox}
                                            onChange={(e)=> this.setState({innerBox: e.target.checked})}
                                            />
                                            <p className="total-score-tag2">Handling Unit Inner box</p>
                                        </div>

                                        <div className="form-group">
                                            <input className="bulkIconsStyle" type="radio" 
                                            checked={this.state.allCurrency === true}
                                            onChange={(e)=> this.setState({allCurrency: true})}
                                            />
                                            <p className="total-score-tag2">All Currency</p>
                                        </div>

                                        <div className="form-group">
                                            <input className="bulkIconsStyle" 
                                            type="radio"
                                           checked={this.state.allCurrency === false}
                                            onChange={(e)=> this.setState({allCurrency: false})}
                                            />
                                            <p className="total-score-tag2">Site Specific Currency</p>
                                        </div>

                                        <div style={{ marginTop: '15px'}}>
                                            <DesktopDatePicker className="bulk-field"
                                            label="Production Date"
                                            clearable
                                            showTodayButton
                                            inputFormat="MM/DD/YYYY"
                                            size={"small"}
                                            maxDate={new Date()}
                                            value={this.state.productionDate}
                                            onChange={(data) => this.setState({ productionDate: data })}
                                            componentsProps={{
                                                actionBar: {
                                                actions: ["clear"],
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField  size={"small"} {...params} />
                                            )}
                                            />
                                        </div>

                                        <Autocomplete className="bulk-field"
                                            disablePortal
                                            id="combo-box-demo"
                                            size={'small'}
                                            options={this.state.zplList}
                                            getOptionLabel={(option) => option?.zplName}
                                            onChange={(e, v) => this.zplHandler(v === null ? null : v)}
                                            renderInput={(params) => <TextField {...params} label="Zpl" />}
                                        />

                                        <PrinterList
                                            className="bulk-field"
                                            value={this.state.printerVal}
                                            blackClass={'drop-input-black'}
                                            placeholder="Printer"
                                            onClickList={(data) => this.printerHandler(data)}
                                        />

                                        <div className="form-group" style={{marginTop:'10px'}}>
                                            <input className="bulkIconsStyle" type="radio" 
                                            checked={this.state.withPrice === false}
                                            onChange={(e)=> this.setState({withPrice: false})}
                                            />
                                            <p className="total-score-tag2">Without Price</p>
                                        </div>

                                        <div className="form-group">
                                            <input className="bulkIconsStyle" type="radio" 
                                            checked={this.state.withPrice === true}
                                            onChange={(e)=> this.setState({withPrice: true})}
                                            />
                                            <p className="total-score-tag2">With Price</p>
                                        </div>
                                        <div className="form-group">
                                            <input className="bulkIconsStyle" type="checkbox" 
                                            checked={this.state.posDescription}
                                            onChange={(e)=> this.setState({posDescription: e.target.checked})}
                                            />
                                            <p className="total-score-tag2">POS Description</p>
                                        </div>
                                        <div className="form-group">
                                            <input className="bulkIconsStyle" type="checkbox" 
                                            checked={this.state.madeInChina}
                                            onChange={(e)=> this.setState({madeInChina: e.target.checked})}
                                            />
                                            <p className="total-score-tag2">Made in China</p>
                                        </div>
                                    <div className="button-container">
                                        <button onClick={() => this.printHandler()} className="print-btn">
                                            <p className="print-text">Print</p>
                                        </button>
                                    </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {this.state.openModal && (
                            <BulkPrintingModal 
                            tableTitle={"Barcode Detail"}
                            modalOpen={!!this.state.openModal}
                            closeHandler={this.closeModal}
                            HeadAndRow={HeadAndRowBulk}
                            data={this.state.openModal}
                            subheading={'Site'}
                            subHeadingValue={this.state.selectedSite}
                            printer={this.state.printer}
                            zplData={this.state.zplData}    
                        />
                        )}

                            <PermissionsModal
                            open={this.state.articleModal}
                            onClick={() => this.setState({articleModal: false})}
                            onClose={() => this.setState({articleModal: false})}
                            permissions={this.state.permissionArray}
                        />
                    </div >
                </ThemeProvider>
            </React.Fragment>

        )
    }
}
export default withRouter(BulkPrinting)