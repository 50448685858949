import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";


const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
]
const COLORS = ["#0088FE", "#00C49F",  "#FF8042"];

const PieCharts = () => {
    return (
        <div style={{ backgroundColor: "#000" ,width:"100%",height:"30rem",marginTop:"20px" }}>
            <ResponsiveContainer width="100%" height="100%"> 
                <PieChart width={800} height={800}>
                    <Pie
                        data={data}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={60}
                        outerRadius={70}
                        fill="#8884d8"
                        paddingAngle={0}
                        dataKey="value"
                        label
                    >
                    <Tooltip/>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default PieCharts;
