import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'
import Dropdown from '../components/Dropdown';
import { getAsnDetail, getPoByOrderId, getPrintingPo, getRtvPo, getRtvPoWithParams, PathCustom } from '../Path/Path';

import { ibtData } from './StaticData/IBT';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Loader from '../components/Loader';
import { Autocomplete, TextField } from '@mui/material';
import { GrDocumentCsv } from 'react-icons/gr';
import moment from 'moment/moment';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import axios from 'axios';


const TransferTwo = () => {
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [poDataList, setPoDataList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [ibtData, setIbtData] = useState([])
    const [ibtDataFilter, setIbtDataFilter] = useState([])
    const [tableData, setTableData] = useState({})
    const [poNumberVal, setpoNumberVal] = useState('')
    const [run, setRun] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [toDate, setToDate] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [sourceVal, setSourceVal] = useState('')
    const realSource = sourceVal?.source;
    const [source, setSource] = useState('')
    const [destination, setDestination] = useState('')
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const [filters, setFitlers] = useState({})
    const [pageData, setPageData] = useState({})
    const [asnData,setAsnData] = useState([]);
    const [filterData,setFilterData] = useState([]);
    const [asnNumber,setAsnNumber] = useState("");
    const itemsPerPage = 10;
    useEffect(() => {
        packingData();
    }, []);


    const packingData = async () =>{
        setLoading(true)
        const data = await getAsnDetail().then((res)=>{
            setAsnData(res)
            setFilterData(res)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)
            toast.error("Something went wrong ",err.message)
        })
    }

    console.log("asnData",asnData);

    async function fetchData(pageNumber = 1) {
        setLoading(true)
        const toDateNew = formatDate(toDate) === '1970-01-01' ? '' : formatDateReverse(toDate) 
        const fromDateNew = formatDate(fromDate) === '1970-01-01' || formatDate(fromDate) === 'NaN-NaN-NaN'   ? '' :  formatDateReverse(fromDate) 
        console.log(toDateNew,'fromDateNew');
        const invoiceNumberValNew = invoiceNumberVal
        const sourceNew = source
        const destinationNew = destination
        const getPo = await axios.get(`${PathCustom}store/supply-chain/po?ASN=${invoiceNumberValNew}&startDate=${toDateNew}&endDate=${fromDateNew}&destination=${destinationNew}&source=${sourceNew}&page=${pageNumber}`)
            .then((async res => {
                setLoading(false)
                console.log(res?.data?.data?.data.filter((item => item.ASN.toLowerCase()?.includes('00125914'))), 'res.data');
                await setIbtData(res.data?.data?.data)
                await setIbtDataFilter(res.data?.data?.data);
                await setPageData(res.data?.data);

            }))
            .catch((err => {
                toast.error('Something Went Wrong')
                console.log(err,'err');
                setLoading(false)
            }))

        // let uniqueArray = []
        // uniqueArray = ibtData?.filter(function (item, pos) {
        //     return ibtData.indexOf(item) == pos;
        // })




    }
    useEffect(() => {

        fetchData();

    }, []);
    async function fetchDataWithParams() {
        setLoading(true)
        function replaceAll(str, find, replace) {
            return str?.replace(new RegExp(find, 'g'), replace);
        }
        console.log(toDate);
        console.log(fromDate);
        const getPo = await getRtvPoWithParams({ toDate: replaceAll(formatDateReverse(toDate), '-', '/'), fromDate: replaceAll(formatDateReverse(fromDate), '-', '/'), asn: invoiceNumberVal })
            .then((async res => {
                setLoading(false)

                await setIbtData(res.data?.data)
            }))
            .catch((err => {
                toast.error('Something Went Wrong')
                setLoading(false)
            }))
        console.log(getPo.data);
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    const formatDateReverse = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = month;
        if (day.length < 2)
            day = day;

        return [month, day, year].join('-');
        // return console.log([year, month, day].join('-'))
    }
    // const dateFilter = () => {
    //     filterData?.map((item => console.log(item.source)))
    //     filterData?.map((item => console.log(item.IBT)))
    //     filterData?.map((item => console.log(item.destination)))
    //     if (toDate === null && toDate === null) {
    //         return filterData?.filter((x =>x?.IBT?.toLowerCase().includes(asnNumber.toLowerCase())&& x?.source?.toLowerCase().includes(realSource.toLowerCase() && x?.destination?.toLowerCase().includes(destination.toLowerCase()))

    //             ))
    //     } else {
    //         let hehe = []
    //         for (let index = 0; index < filterData?.length; index++) {
    //             const element = filterData[index];
    //             if (element?.createdAt === undefined) {
    //                 element.createdAt = ''
    //             }
                
    //             hehe.push(element)
    //         }
            
    //         return hehe.filter((x =>
    //             formatDate(x?.createdAt) >= formatDate(toDate) && formatDate(x?.createdAt) <= formatDate(fromDate)
    //             &&
    //             x?.IBT?.toLowerCase().includes(asnNumber.toLowerCase())
    //             &&
    //             x?.source?.toLowerCase().includes(realSource.toLowerCase())
    //             &&
    //             x?.destination.toLowerCase().includes(destination.toLowerCase())
    //         ))
    //     }

    // }


       const searchData = () => {
       if (realSource) {
            const res = asnData?.filter(x => x.source?.toLowerCase().includes(realSource.toLowerCase()));
            console.log('res: ', res);
            setAsnData(res)
        }else if(destination){
            const res = asnData?.filter(x => x.destination?.toLowerCase().includes(destination.toLowerCase()));
            console.log('res: ', res);
            setAsnData(res)
        }else if(asnNumber){
            const res = asnData?.filter(x => x.IBT?.toLowerCase().includes(asnNumber.toLowerCase()));
            console.log('res: ', res);
            setAsnData(res)
        }else if(toDate && fromDate){
            const res = asnData?.filter(x => formatDate(x?.createdAt) >= formatDate(toDate) && formatDate(x?.createdAt) <= formatDate(fromDate));
            console.log('res: ', res);
            setAsnData(res)
        }else if(realSource && destination && asnNumber && toDate && fromDate){
            const res = asnData.filter((x =>
                            formatDate(x?.createdAt) >= formatDate(toDate) && formatDate(x?.createdAt) <= formatDate(fromDate)
                            &&
                            x?.IBT?.toLowerCase().includes(asnNumber.toLowerCase())
                            &&
                            x?.source?.toLowerCase().includes(realSource.toLowerCase())
                            &&
                            x?.destination?.toLowerCase().includes(destination.toLowerCase())
                        ))
            setAsnData(res)
        }else{
            setAsnData(filterData)
        }
    }

    console.log(asnData);
    const headers = [
        { label: "ASN", key: 'ASN' },
        { label: "Source", key: 'source' },
        { label: "Destination", key: 'destination' },
        { label: "Packed Qty", key: 'packedQTY' },
        { label: "Transfer Qty", key: 'tanfersQTY' },
        { label: "Received Qty", key: 'RecievedQTY' },
        { label: "Status", key: 'status' },
        { label: "Packing Date", key: 'packing_date' },
        { label: "Transfer Date", key: 'shipping_date' },
        { label: "Receiveing Date", key: 'receiving_date' },
        { label: "Packing Remarks", key: 'packing_remarks' },
        { label: "Receiving Remarks Remarks", key: 'receiving_remarks' },
        { label: "Transfer Remarks", key: 'shipping_remarks' },
    ]
    const csvData = asnData?.map((item => {
        return {
            ASN: item?.IBT || '----',
            source: item?.source || '----',
            destination: item?.destination || '----',
            packedQTY: item?.picked_items?.qt || '----',
            tanfersQTY: item?.shipped_items?.qt || '----',
            RecievedQTY: item?.received_items?.qt || '----',
            status: item?.status || '----',
            packing_date: item?.picked_items?.date || '----',
            shipping_date: item?.shipped_items?.date || '----',
            receiving_date: item?.received_items?.date || '----',
            packing_remarks: item?.picked_items.remarks || '----',
            receiving_remarks: item?.received_items.remarks || '----',
            shipping_remarks: item?.shipped_items.remarks || '----',
        }
    }))

    // ------------- Pagination ------------------

    useEffect(()=>{
        const endOffset = itemOffset + itemsPerPage
        setCurrentItems(asnData.slice(itemOffset,endOffset));
        setPageCount(Math.ceil(asnData.length / itemsPerPage))
    },[itemOffset,itemsPerPage,asnData])

    const handlePageClick = (e)=>{
        const newOffset = (e.selected * itemsPerPage) % asnData.length;
        setItemOffset(newOffset)
    }

    console.log(currentItems,"gytredv");

    return (
        <>
            <Loader loading={loading} />
            <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>
            <div className='dashboard-main'>
                <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Transfer</p>
                        <CSVLink
                            filename='SupplyChain/Rtv/PO'
                            data={csvData}
                            headers={headers}
                        >
                            <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />

                        </CSVLink>

                    </div>
                    <div>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>


                </div>
                <div className='dashboard-bottom' style={{ marginTop: "0.5%" }}>
                    <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', gridColumnGap: '10px', gridRowGap: '10px', flexWrap: 'wrap' }}>

                            <DesktopDatePicker
                                label="From Date"
                                clearable
                                showTodayButton
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={toDate}
                                onChange={(data) => setToDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={fromDate}
                                onChange={(data) => setFromDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={filterData?.filter((item => item?.IBT !== '')).map((item => item?.IBT)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setAsnNumber(v === null ? '' : v)}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Asn" />}
                            />

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}
                                options={filterData ?? [] }
                                getOptionLabel={(c) => c.source}
                                onChange={(e, v) => setSourceVal(v === null ? '' : v)}
                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Source" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={filterData?.filter((item => item?.destination !== '')).map((item => item?.destination)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setDestination(v === null ? '' : v)}
                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Destination" />}
                            />


                        </div>

                        <button className="run-btn"><p className="run-text" onClick={() => searchData()}>Search</p></button>
                    </div>
                    <div style={{ marginTop: '10px', overflowX: 'scroll', height: '100%' }}>
                        <table data-table-theme="dark zebra">
                            <thead>
                                <tr style={{ background: "#373E43" }}>
                                <th >ASN</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Packed Qty</th>
                                    <th>Transfer Qty</th>
                                    <th>Received Qty </th>
                                    <th>Status </th>
                                    <th>Packing Date </th>
                                    <th>Packing Remarks </th>
                                    <th>Transfer Date </th>
                                    <th>Transfer Remarks </th>
                                    <th>Receiving Date </th>
                                    <th>Receiving Remarks </th>

                                </tr>
                            </thead>
                            <tbody>
                            {
                                    currentItems?.map((data, index) =>
                                        <tr key={index}>
                                            <td>{data?.IBT}</td>
                                            <td>{data?.source}</td>
                                            <td>{data?.destination}</td>
                                            <td><NavLink to='/viewibtdetails' state={{data : data?.picked_items?.epcIDs}}>{data?.picked_items?.qt}</NavLink></td>
                                            <td><NavLink to='/viewibtdetails' state={{data : data?.shipped_items?.epcIDs}}>{data?.shipped_items?.qt}</NavLink></td>
                                            <td><NavLink to='/viewibtdetails' state={{data : data?.received_items?.epcIDs}}>{data?.received_items?.qt}</NavLink></td>
                                            <td>{data?.status}</td>
                                            <td>{data?.picked_items?.date}</td>
                                            <td>{data?.picked_items?.remarks}</td>
                                            <td>{data?.shipped_items?.date}</td>
                                            <td>{data?.shipped_items?.remarks}</td>
                                            <td>{data?.received_items?.date}</td>
                                            <td>{data?.received_items?.remarks}</td>

                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>


                    </div>
                    <ReactPaginate
                        className='ul-main'
                        activeClassName='active-table-btn'
                        activeLinkClassName='active-table-btn-link'
                        previousClassName='previous-table-btn'
                        pageLinkClassName='page-link-btn'
                        nextClassName='next-table-btn'
                        previousLinkClassName='previous-table-btn-link'
                        nextLinkClassName='previous-table-btn-link'
                        breakLabel="..."
                        nextLabel="Next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="Previous"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </>
    )
}

export default TransferTwo;
