import { TextField, Button,Autocomplete } from "@mui/material";
import React, { useState } from "react";

const PoQuantityDetails = ({ item }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearched, setIsSearched] = useState(false); 


  const itemsArray = item.items || [];

  const displayLength = isSearched ? filteredItems.length : itemsArray.length;

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    color:'#dddddd'
  };

  const thStyle = {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
    color: "white"
  };

  const tdStyle = {
    border: '1px solid #dddddd',
    textAlign: 'center',
    padding: '8px',
    color: 'white'
  };

  const searchArticleCode = () => {
    const result = itemsArray.filter(item => 
      item.Article_Code.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredItems(result);
    setIsSearched(true); 
  };

  return (
    <>
      <div className='dashboard-main'>
        <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
          <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p className='stock-count' style={{ fontSize: '20px', margin: 0 }}>PO Quantity Details</p>
          </div>
        </div>
        <div className='dashboard-bottom' style={{ marginTop: "0.5%" }}>
          <div>
            <p>Filters</p>
            <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', gridColumnGap: '80px', gridRowGap: '10px', flexWrap: 'wrap' }}>
               
                        <div style={{ display: 'flex', gridColumnGap: '10px', gridRowGap: '10px', flexWrap: 'wrap'}}>

                                  <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    freeSolo={true}
                                    size={'small'}
                                    options={itemsArray?.filter((item => item?.Article_Code !== '')).map((item => item?.Article_Code)).reduce((noDupArr, entry) => {
                                        if (noDupArr.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, [])}
                                    onChange={(e, v) => setInputValue (v === null ? '' : v)}
                                    sx={{
                                      width: 200,
                                      '& .MuiInputLabel-root': { color: 'white' },
                                      '& .MuiInputBase-root': { color: 'white' },
                                      '& .MuiLabel-root': { Label: 'white' },
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          borderColor: 'white'
                                        },
                                        '&:hover fieldset': {
                                          borderColor: 'white'
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: 'white'
                                        }
                                      }
                                    }}
                                    renderInput={(params) => <TextField 
                                        {...params} label="Article Code"
                                         />}
                                        />
                                        </div>
              </div>
              <Button
                variant="contained"
                onClick={searchArticleCode}
                sx={{
                  textTransform: 'none',
                  width: 150,
                  borderRadius: '8px'
                }}
                className="run-btn"
              >
                <p className="run-text">Search</p>
              </Button>
            </div>
          </div>
          <div style={{ marginTop: '10px', overflowX: 'scroll', height: '600px' }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                <th style={thStyle}>Article Code</th>
              <th style={thStyle}>Article Desc</th>
              <th style={thStyle}>Article MC Category</th>
              <th style={thStyle}>PO Item Del</th>
              <th style={thStyle}>Add_Char 1</th>
              <th style={thStyle}>Add_Char 2</th>
              <th style={thStyle}>Add_Char 3</th>
              <th style={thStyle}>Add_Char 4</th>
                </tr>
              </thead>
              <tbody>
                {(isSearched ? filteredItems : itemsArray).map((itemDetail, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{itemDetail.Article_Code}</td>
                <td style={tdStyle}>{itemDetail.Article_Desc}</td>
                <td style={tdStyle}>{itemDetail.Article_MC_Category}</td>
                <td style={tdStyle}>{itemDetail.PO_Item_Del ? 'Yes' : 'No'}</td>
                <td style={tdStyle}>{itemDetail.Add_Char1}</td>
                <td style={tdStyle}>{itemDetail.Add_Char2}</td>
                <td style={tdStyle}>{itemDetail.Add_Char3}</td>
                <td style={tdStyle}>{itemDetail.Add_Char4}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1px', color:'white' }}>
            <h3>List Length: {displayLength}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoQuantityDetails;
