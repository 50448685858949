import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import { withRouter } from "../../components/withRouter";
import { Autocomplete, TextField } from "@mui/material";
import MyInput from "../../components/MyInput";
import axios from "axios";
import { Path, localServer } from "../../Path/Path";
import { toast } from "react-toastify";
class AddZpl extends Component {
    state = {
        zplName: '',
        zplBody: '',

    }
    async componentDidMount() {
        const item = this.props.location?.state?.item ? this.props.location?.state?.item : false
        if (item) {
            this.setState({ zplBody: item.zplBody, zplName: item.zplName })
        } else {
            this.setState({ zplBody: '', zplName: '' })
        }

    }
    onAddingZpl = async () => {
        if (this.state.zplBody === '' || this.state.zplName === '') {
            toast.error('Plaese Enter Zpl Name or Zpl Body')
        } else {
            let body = {
                zplName: this.state.zplName,
                zplBody: this.state.zplBody
            }
            const addeding = await axios.post(`${localServer}store/zpl/add`, body)
                .then((res => {
                    toast.success("Zpl Added")
                    window.location.href = 'zpl'
                })).catch((err => {
                    toast.error("Something Went Wrong")
                }))
        }

    }
    onEditingZpl = async () => {
        const { item } = this.props.location?.state ? this.props.location?.state : false
        let body = {
            zplName: this.state.zplName,
            zplBody: this.state.zplBody
        }
        console.log(body);
        const addeding = await axios.put(`${localServer}store/zpl/edit/${item?._id}`, body)
            .then((res => {
                toast.success("Zpl Edited")
                window.location.href = 'zpl'
            })).catch((err => {
                toast.error("Something Went Wrong")
            }))


    }
    render() {
        console.log(this.props.location?.state?.item)
        console.log(this.state.zplName);
        console.log(this.state.zplBody);
        // console.log(this.props.location.state);
        const { item } = this.props.location?.state ? this.props.location?.state : false
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Zpl Report</p></div>

                </div>
                <div className="dashboard-bottom">

                    <div className="drop-data" style={{ justifyContent: 'space-evenly' }}>

                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.zplName}
                            placeholder="Zpl Name"
                            value={this.state.zplName}
                            size={'small'}
                            onChange={(data) => this.setState({ zplName: data.target.value })} id="outlined-basic" label="Zpl Name" variant="outlined" />
                        <TextField
                            sx={{ width: 235 }}
                            multiline
                            rows={10}
                            defaultValue={this.state.zplBody}
                            placeholder="Zpl Body"
                            size={'small'}
                            onChange={(data) => this.setState({ zplBody: data.target.value })} id="outlined-basic" label="Zpl Body" variant="outlined" />
                        <div>
                            {
                                item ?
                                    <button onClick={() => this.onEditingZpl()} className="run-btn"><p className="run-text">Edit Zpl</p></button>
                                    :
                                    <button onClick={() => this.onAddingZpl()} className="run-btn"><p className="run-text">Add Zpl</p></button>
                            }
                        </div>
                    </div>

                </div>
            </div>


        )
    }
}
export default withRouter(AddZpl)
