import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { withRouter } from "../components/withRouter";
import MyInput from "../components/MyInput";
import { goodStockData } from "./StaticData/IBT";

class Warehouse extends Component {
    state = {

    }
    newscreen = () => {
        window.open("/viewibtdetails")
    }


    render() {

        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Goods Stock Ware House</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div className="drop-data">
                        <MyInput
                            type={"date"}
                        />
                        <MyInput
                            type={"date"}
                        />
                        <Dropdown
                            placeholder={"All Destination"}

                        />
                        <MyInput
                            placeholder={"EPC"}
                        ></MyInput>


                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <MyInput
                                placeholder={"SKU"}
                            ></MyInput>
                            <MyInput
                                placeholder={"Supplier Number"}
                            ></MyInput>
                            <MyInput
                                placeholder={"Shippment Number"}
                            ></MyInput>
                            <MyInput
                                placeholder={"Reatail Item Batch ID"}
                            ></MyInput>


                        </div>
                    </div>
                    <div>
                        <button className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                    <div style={{ paddingTop: "2%" ,height:'600px',overflow:'auto'}}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Date</th>
                                    <th>SKU</th>
                                    <th>Retail Item Batch ID</th>
                                    <th>Supplier Number</th>
                                    <th>Shipment Number</th>
                                    <th>Store</th>
                                    <th>Purchase Order </th>
                                    <th>Epc </th>
                                    <th>Remarks </th>
                                    <th>Id</th>



                                </tr>

                            </thead>
                            {
                                goodStockData.aaData.map((data,index) =>
                                    <tbody>
                                        <tr>
                                            <td>{data.date}</td>
                                            <td>{data.refno}</td>
                                            <td>{data.retail_item_batch_id}</td>
                                            <td>{data.supplier_number}</td>
                                            <td>{data.shipment_number}</td>
                                            <td>{data.store}</td>
                                            <td>{data.purchase_order}</td>
                                            <td>{data.epc}</td>
                                            <td>{data.epc}</td>
                                            <td>{data.id}</td>
                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>


        )
    }
}
export default withRouter(Warehouse)