import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editManageTagScreen, editQrScreen, getManageTagScreen, getQrScreen } from '../Path/Path';

import { toast } from 'react-toastify';
import { ImQrcode } from 'react-icons/im';


class QrCode extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        heading: '',
        subHeading: '',
        uuid: '',
        qrButton: '',
        data: [
            {
                "heading": {
                    "text": "Device"
                },
                "subHeading": {
                    "text": "Configuration"
                },
                "qrButton": {
                    "text": "SCAN QR CODE"
                },
                "uuid": {
                    "text": "UUID"
                },
                "_id": "6307a60d30215e0c5c4e2eb9",
                "createdAt": "2022-08-25T16:40:45.473Z",
                "updatedAt": "2022-08-25T16:40:45.473Z",
                "__v": 0
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'heading') {
            this.setState({ heading: '' })
        } else if (value === 'subHeading') {
            this.setState({ subHeading: '' })
        } else if (value === 'qrButton') {
            this.setState({ qrButton: '' })
        } else {
            this.setState({ uudi: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        console.log(this.state.data[0]);
        const bodyStatus = {
            "heading": {
                "text": this.state.heading !== '' ? this.state.heading : this.state.data[0]?.heading.text
            },
            "subHeading": {
                "text": this.state.subHeading !== '' ? this.state.subHeading : this.state.data[0]?.subHeading.text
            },
            "qrButton": {
                "text": this.state.qrButton !== '' ? this.state.qrButton : this.state.data[0]?.qrButton.text
            },
            "uuid": {
                "text": this.state.uuid !== '' ? this.state.uuid : this.state.data[0]?.uuid.text
            },

        }
        console.log(bodyStatus);
        const editing = await editQrScreen(bodyStatus)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal
                        onSave={() => this.sendingData(onClose, value)}
                        onClose={() => this.closeHandler(onClose, value)}
                        heading={
                            value === 'subHeading'
                                ?
                                "Change Sub Heading"
                                :
                                value === 'qrButton'
                                    ?
                                    "Change Qr Code Heading"
                                    :
                                    value === 'heading' ?
                                        "Chain Main Heading"
                                        :
                                        "Change Uuid"
                        }>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {value === 'heading' ?
                                <React.Fragment>
                                    <Form.Label>{'Main Heading'}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id={value}
                                        defaultValue={this.state.heading}
                                        placeholder={this.state.heading === '' ? 'Heading' : this.state.heading}
                                        onChange={(e) => this.setState({ heading: e.target.value })}
                                    />
                                </React.Fragment>
                                :
                                value === 'subHeading' ?
                                    < React.Fragment >
                                        <Form.Label>{'Sub Heading'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id={value}
                                            defaultValue={this.state.subHeading}
                                            placeholder={this.state.subHeading === '' ? 'Sub Heading' : this.state.subHeading}
                                            onChange={(e) => this.setState({ subHeading: e.target.value })}
                                        />
                                    </React.Fragment>
                                    :
                                    value === 'qrButton' ?
                                        < React.Fragment >
                                            <Form.Label>{'Qr Code Heading'}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id={value}
                                                defaultValue={this.state.qrButton}
                                                placeholder={this.state.qrButton === '' ? 'Qr Code ' : this.state.qrButton}
                                                onChange={(e) => this.setState({ qrButton: e.target.value })}
                                            />
                                        </React.Fragment>
                                        :
                                        < React.Fragment >
                                            <Form.Label>{'Uuid Value'}</Form.Label>
                                            <Form.Control
                                                type="text"

                                                id={value}
                                                defaultValue={this.state.uuid}
                                                placeholder={this.state.uuid === '' ? 'uuid' : this.state.uuid}
                                                onChange={(e) => this.setState({ uuid: e.target.value })}
                                            />
                                        </React.Fragment>
                            }
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getQrScreen()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>
                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Qr Code </p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px', display: 'flex', justifyContent: 'space-between', paddingBottom: '100px' }}  >
                                <h1 className='screen-heading' style={{ fontSize: '40px', textAlign: 'center', width: '100%' }}>Device<br /> Configuration</h1>
                                <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{
                                        width: '140px', height: '140px', backgroundColor: '#e44b5d', borderRadius: '10px', border: '7px solid #d12e34', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                                    }}>
                                        <h1 className='screen-heading' style={{ fontSize: '20px', textAlign: 'center', width: '100%', verticalAlign: 'middle' }}>SCAN QR CODE</h1>
                                        <ImQrcode size={35} />
                                    </div>
                                </div>
                                <h1 className='screen-heading' style={{ fontSize: '14px', textAlign: 'center', width: '100%', fontWeight: 'bold' }}>UUID: </h1>
                            </div>
                        </div>
                        <div className='production-main-child'>
                            Qr Code Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px', display: 'flex', justifyContent: 'space-between', paddingBottom: '100px' }}  >
                                <div style={{ width: '100%' }}>
                                    <h1 onClick={() => this.buttonHandler('heading')} className='screen-heading' style={{ fontSize: '40px', textAlign: 'center', width: '100%' }}>{this.state?.heading !== '' ? this.state?.heading : this.state?.data[0]?.heading.text}</h1>
                                    <h1 onClick={() => this.buttonHandler('subHeading')} className='screen-heading' style={{ fontSize: '40px', textAlign: 'center', width: '100%', marginTop: '0px' }}>
                                        {this.state?.subHeading !== '' ? this.state?.subHeading : this.state?.data[0].subHeading.text}</h1>
                                </div>
                                <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                    <div onClick={() => this.buttonHandler('qrButton')} style={{
                                        width: '140px', height: '140px', backgroundColor: '#e44b5d', borderRadius: '10px', border: '7px solid #d12e34', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
                                    }}>
                                        <h1 className='screen-heading' style={{ fontSize: '20px', textAlign: 'center', width: '100%', verticalAlign: 'middle' }}> {this.state?.qrButton !== '' ? this.state?.qrButton : this.state?.data[0].qrButton.text}</h1>
                                        <ImQrcode size={35} />
                                    </div>
                                </div>
                                <h1 onClick={() => this.buttonHandler('uuid')} className='screen-heading' style={{ fontSize: '14px', textAlign: 'center', width: '100%', fontWeight: 'bold' }}>{this.state?.uuid !== '' ? this.state?.uuid : this.state?.data[0].uuid.text}: </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(QrCode)