import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbChristmasTree, TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editLoginScreen, editManageTagScreen, editQrScreen, getLoginScreen, getManageTagScreen, getQrScreen } from '../Path/Path';

import { toast } from 'react-toastify';
import { ImQrcode } from 'react-icons/im';


class LoginProduct extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        heading: '',
        subHeading: '',
        username: '',
        password: '',
        store: '',
        url: '',
        button: '',
        uuid: '',
        checkboxValue: '',
        data: [
            {
                "heading": {
                    "subText": "Welcome Back",
                    "text": "Hello"
                },
                "store": {
                    "text": "Store"
                },
                "input": {
                    "usernamePlaceholder": "Username",
                    "passwordPlaceholder": "Password"
                },
                "rememberCheck": {
                    "text": "Remember me",
                    "value": false
                },
                "button": {
                    "text": "Login"
                },
                "uuid": {
                    "text": "UUID"
                },
                "url": {
                    "text": "URL"
                },
            }
        ]
    }
    closeHandler = (onClose, value) => {
        // if (value === 'heading') {
        //     this.setState({ heading: '' })
        // } else if (value === 'subHeading') {
        //     this.setState({ subHeading: '' })
        // } else if (value === 'qrButton') {
        //     this.setState({ qrButton: '' })
        // } else {
        //     this.setState({ uudi: '' })
        // }
        onClose()
    }
    sendingData = async (onClose, value) => {
        console.log(this.state.data[0]);
        const bodyStatus = {


            "heading": {
                "subText": this.state.subHeading !== '' ? this.state.subHeading : this.state.data[0]?.heading.subText,
                "text": this.state.heading !== '' ? this.state.heading : this.state.data[0]?.heading.text
            },
            "store": {
                "text": this.state.store !== '' ? this.state.store : this.state.data[0]?.store.text
            },
            "input": {
                "usernamePlaceholder": this.state.username !== '' ? this.state.username : this.state.data[0]?.input.usernamePlaceholder,
                "passwordPlaceholder": this.state.password !== '' ? this.state.password : this.state.data[0]?.input.passwordPlaceholder,
            },
            "rememberCheck": {
                "text": "Remember me",
                "value": this.state.checkboxValue !== '' ? this.state.checkboxValue : this.state.data[0]?.rememberCheck.value,
            },
            "button": {
                "text": this.state.button !== '' ? this.state.button : this.state.data[0]?.button.text,
            },
            "uuid": {
                "text": this.state.uuid !== '' ? this.state.uuid : this.state.data[0]?.uuid.text,
            },
            "url": {
                "text": this.state.url !== '' ? this.state.url : this.state.data[0]?.url.text,
            },


        }
        console.log(bodyStatus);
        const editing = await editLoginScreen(bodyStatus)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal
                        onSave={() => this.sendingData(onClose, value)}
                        onClose={() => this.closeHandler(onClose, value)}
                        heading={
                            value === 'subHeading'
                                ?
                                "Change Sub Heading"
                                :
                                value === 'heading'
                                    ?
                                    "Change  Heading"
                                    :
                                    value === 'store' ?
                                        "Chain Store Heading"
                                        :
                                        value === 'checkbox' ?
                                            "Change checkbox"
                                            :

                                            value === 'uuid' ?
                                                'Change Uuid'
                                                :
                                                value === 'button' ?
                                                    'Change Button Text'
                                                    :
                                                    value === 'username' ?
                                                        'Change Username Text'
                                                        :
                                                        value === 'password' ?
                                                            'Change Password Text'
                                                            :
                                                            'Change Url'
                        }>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                            {value === 'heading' ?
                                <React.Fragment>
                                    <Form.Label>{'Main heading'}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Heading"
                                        type={'text'}
                                        id={value}
                                        defaultValue={this.state.heading !== '' ? this.state.heading : this.state.data[0].heading.text}
                                        placeholder={this.state.heading !== '' ? this.state.heading : this.state.data[0].heading.text}
                                        onChange={(e) => this.setState({ heading: e.target.value })}
                                    />
                                </React.Fragment>
                                :
                                value === 'subHeading' ?
                                    < React.Fragment >
                                        <Form.Label>{'Sub Heading'}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Sub Heading"
                                            type={'text'}
                                            id={value}
                                            defaultValue={this.state.subHeading !== '' ? this.state.subHeading : this.state.data[0].heading.subText}
                                            placeholder={this.state.subHeading !== '' ? this.state.subHeading : this.state.data[0].heading.subText}
                                            onChange={(e) => this.setState({ subHeading: e.target.value })}
                                        />
                                    </React.Fragment>
                                    :
                                    value === 'store' ?
                                        < React.Fragment >
                                            <Form.Label>{'Store Heading'}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Store"
                                                type={'text'}
                                                id={value}
                                                defaultValue={this.state.store !== '' ? this.state.store : this.state.data[0].store.text}
                                                placeholder={this.state.store !== '' ? this.state.store : this.state.data[0].store.text}
                                                onChange={(e) => this.setState({ store: e.target.value })}
                                            />
                                        </React.Fragment>
                                        :
                                        value === 'uuid' ?
                                            < React.Fragment >
                                                <Form.Label>{'Uuid Value'}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Uuid"
                                                    type={'text'}
                                                    id={value}
                                                    defaultValue={this.state.uuid}
                                                    placeholder={this.state.uuid !== '' ? this.state.uuid : this.state.data[0].uuid.text}
                                                    onChange={(e) => this.setState({ uuid: e.target.value })}
                                                />
                                            </React.Fragment>
                                            :
                                            value === 'button' ?
                                                < React.Fragment >
                                                    <Form.Label>{'Button Value'}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Uuid"
                                                        type={'text'}
                                                        id={value}
                                                        defaultValue={this.state.button}
                                                        placeholder={this.state.button === '' ? 'button' : this.state.button}
                                                        onChange={(e) => this.setState({ button: e.target.value })}
                                                    />
                                                </React.Fragment>
                                                :
                                                value === 'checkbox' ?
                                                    < React.Fragment >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <label class="switch">
                                                                <input
                                                                    defaultChecked={this.state.checkboxValue !== '' ? this.state.checkboxValue : this.state.data[0].rememberCheck.value}
                                                                    onChange={(e) => this.setState({ checkboxValue: e.target.checked })} type="checkbox" />
                                                                <span class="slider-123  round"></span>

                                                            </label>
                                                            <span>is visible</span>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    value === 'username' ?
                                                        < React.Fragment >
                                                            <Form.Label>{'Username Text'}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Username"
                                                                type={'text'}
                                                                id={value}
                                                                defaultValue={this.state.username !== '' ? this.state.username : this.state.data[0].input.usernamePlaceholder}
                                                                placeholder={this.state.username !== '' ? this.state.username : this.state.data[0].input.usernamePlaceholder}
                                                                onChange={(e) => this.setState({ username: e.target.value })}
                                                            />
                                                        </React.Fragment>
                                                        :
                                                        value === 'password' ?
                                                            < React.Fragment >
                                                                <Form.Label>{'Password Text'}</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Password"
                                                                    type={'text'}
                                                                    id={value}
                                                                    defaultValue={this.state.password !== '' ? this.state.password : this.state.data[0].input.passwordPlaceholder}
                                                                    placeholder={this.state.password !== '' ? this.state.password : this.state.data[0].input.passwordPlaceholder}
                                                                    onChange={(e) => this.setState({ password: e.target.value })}
                                                                />
                                                            </React.Fragment>
                                                            :
                                                            < React.Fragment >
                                                                <Form.Label>{'Url Value'}</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Url"
                                                                    type={'text'}
                                                                    id={value}
                                                                    defaultValue={this.state.url}
                                                                    placeholder={this.state.url === '' ? 'Url' : this.state.url}
                                                                    onChange={(e) => this.setState({ url: e.target.value })}
                                                                />
                                                            </React.Fragment>
                            }
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getLoginScreen()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        const usedData = this.state.data[0]
        return (
            <React.Fragment>
                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Login Screen </p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px', display: 'flex', justifyContent: 'space-evenly', paddingBottom: '100px' }}  >
                                <h1 className='screen-heading' style={{ fontSize: '40px', textAlign: 'left', width: '100%', fontWeight: 'bold' }}> Hello,</h1>
                                <h1 className='screen-heading' style={{ fontSize: '35px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> Welcome Back,</h1>
                                <h1 className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> Store:</h1>
                                <input type="text" defaultValue={'username'} style={{ border: 'none', borderBottom: '1px solid white', width: '100%', outline: 'none', borderRadius: '0px' }} />
                                <input type="text" defaultValue={'password'} style={{ border: 'none', borderBottom: '1px solid white', width: '100%', outline: 'none', borderRadius: '0px' }} />
                                <input style={{ display: 'none' }} id="ckb2" class="ckb ckb-default" type="checkbox" />
                                <label for="ckb2" style={{ color: "white" }}>Remember me</label>
                                <button style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>Login</button>
                                <h1 className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> UUID:</h1>
                                <h1 className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> URL:</h1>
                            </div>
                        </div>
                        <div className='production-main-child'>
                            Login Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px', display: 'flex', justifyContent: 'space-evenly', paddingBottom: '100px' }}  >
                                <h1 onClick={() => this.buttonHandler('heading')} className='screen-heading' style={{ fontSize: '40px', textAlign: 'left', width: '100%', fontWeight: 'bold' }}>{this.state.heading !== '' ? this.state.heading : usedData.heading.text}</h1>
                                <h1 onClick={() => this.buttonHandler('subHeading')} className='screen-heading' style={{ fontSize: '35px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}>{this.state.subHeading !== '' ? this.state.subHeading : usedData.heading.subText}</h1>
                                <h1 onClick={() => this.buttonHandler('store')} className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> {this.state.store !== '' ? this.state.store : usedData.store.text}:</h1>
                                <input type="text" onClick={() => this.buttonHandler('username')} value={this.state.username !== '' ? this.state.username : usedData.input.usernamePlaceholder} style={{ border: 'none', borderBottom: '1px solid white', width: '100%', outline: 'none', borderRadius: '0px' }} />
                                <input type="text" onClick={() => this.buttonHandler('password')} value={this.state.password !== '' ? this.state.password : usedData.input.passwordPlaceholder} style={{ border: 'none', borderBottom: '1px solid white', width: '100%', outline: 'none', borderRadius: '0px' }} />
                                <input disabled checked={this.state.checkboxValue !== '' ? this.state.checkboxValue : usedData.rememberCheck.value} onClick={() => this.buttonHandler('checkbox')} style={{ display: 'none' }} id="ckb4" class="ckb ckb-default" type="checkbox" />
                                <label onClick={() => this.buttonHandler('checkbox')} for="ckb4" style={{ color: "white" }}>Remember me</label>
                                <button onClick={() => this.buttonHandler('button')} style={{ width: '100%', borderRadius: '5px', height: '35px', backgroundColor: '#d54545', color: 'white', fontSize: '17px' }}>{this.state.button !== '' ? this.state.button : usedData.button.text}</button>
                                <h1 onClick={() => this.buttonHandler('uuid')} className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> {this.state.uuid !== '' ? this.state.uuid : usedData.uuid.text}:</h1>
                                <h1 onClick={() => this.buttonHandler('url')} className='screen-heading' style={{ fontSize: '15px', textAlign: 'left', width: '100%', fontWeight: 'lighter' }}> {this.state.url !== '' ? this.state.url : usedData.url.text}:</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default withRouter(LoginProduct)