import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit, TbEmphasis } from 'react-icons/tb'
import { IoIosBarcode } from 'react-icons/io'
import { FaSearch } from 'react-icons/fa'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import receivingLogo from '../assets/recieving.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Slider from 'react-rangeslider'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter'
import { editReceiving, getReceiving } from '../Path/Path'
import { CustomModal } from '../components/Modal'
import { Form } from 'react-bootstrap'
import { toast } from 'react-toastify'


class ReceivingTags extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        value: 100,
        asnValue: '',
        imageLinkBg: '',
        manualHeading: '',
        header: '',
        slider: false,
        barcodeButton: false,
        placeholder: '',
        centerMain: '',
        receiving: [
            {
                "search": {
                    "icon": ""
                },
                "slider": {
                    "show": false,
                    "value": 1
                },
                "barcode": {
                    "icon": "",
                    "value": false
                },
                "input": {
                    "placeholder": ""
                },
                "centerMain": {
                    "text": "",
                    "icon": ""
                },
                "header": {
                    "text": "Receiving"
                },
            }
        ],
    }
    handleChangeStart = () => {
        console.log('Change event started')
    };

    handleChange = value => {
        this.setState({
            value: value
        })
    };

    handleChangeComplete = () => {
        console.log('Change event completed')
    };
    closeHandler = (onClose, value) => {
        this.setState({ [value]: '' })
        onClose()
    }
    closeBarcodeHandler = (onClose, btnName, valueBtn) => {
        this.setState({ [btnName]: valueBtn })
        onClose()
    }
    sliderHandler = (value, visible) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Slider Visibility`}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.slider === false && this.state.receiving[0].slider.value === false ? this.state.receiving[0].slider.value : this.state.slider}
                                            onChange={(e) => this.setState({ slider: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >

                );
            }
        });
    }
    mainHeadingHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Header Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Main Heading'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.header !== '' ? this.state.header : this.state.receiving[0].header.text} defaultValue={this.state.header !== '' ? this.state.header : this.state.receiving[0].header.text} onChange={(e) => this.setState({ header: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    inputHandler = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Placeholder Text `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{'Placeholder Text'}</Form.Label>
                                <Form.Control
                                    type={'text'} id={value} placeholder={this.state.placeholder !== '' ? this.state.placeholder : this.state.receiving[0].input.placeholder} defaultValue={this.state.placeholder !== '' ? this.state.placeholder : this.state.receiving[0].input.placeholder} onChange={(e) => this.setState({ placeholder: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    buttonPowerHandler = (value, visible) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => { this.editScreen() }} onClose={() => onClose()} heading={`Change Header Title `}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label class="switch">
                                        <input
                                            defaultChecked={this.state.barcodeButton === false && this.state.receiving[0].barcode.value === false ? this.state.receiving[0].barcode.value : this.state.barcodeButton}
                                            onChange={(e) => this.setState({ barcodeButton: e.target.checked })} type="checkbox" />
                                        <span class="slider-123  round"></span>

                                    </label>
                                    <span>is visible</span>
                                </div>
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files, stateName) => {
        console.log(files);
        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        await console.log(url);
                        await this.setState({ imageLinkBg: url, percent: 0 })
                        setTimeout(() => {
                             this.editScreen()
                        }, 1000);
                    });
                }
            );
        }

    }
    editScreen = async () => {
        console.log(this.state.imageLinkBg);
        const body =
        {

            "slider": {
                "show": this.state.slider === false && this.state.receiving[0].slider.show === false ? this.state.receiving[0].slider.show : this.state.slider,
            },
            "barcode": {
                "value": this.state.barcodeButton !== '' ? this.state.barcodeButton : this.state.receiving[0].barcode.value
            },
            "input": {
                "placeholder": this.state.placeholder !== '' ? this.state.placeholder : this.state.receiving[0].input.placeholder
            },
            "centerMain": {
                "icon": this.state.imageLinkBg !== '' ? this.state.imageLinkBg : this.state.receiving[0].centerMain.icon
            },
            "header": {
                "text": this.state.header !== '' ? this.state.header : this.state.receiving[0].header.text
            },
        }

        console.log(body);
        await editReceiving(body).then(
            () => {
                toast.success('Data Updated')
            }
        ).catch((err) => {
            toast.success('Something Went Wrong')
            console.log(err);
        })
    }
    async componentDidMount() {
        const receiving = await getReceiving()
        this.setState({ receiving: receiving })
        await console.log(receiving, 'receiving');
    }
    render() {
        const value = this.state.value
        const asnValue = this.state.asnValue
        console.log(this.state);
        return (
            <div className="dashboard-main-mine">
                <div className="dashboard-top-mine">
                    <div> <p className="stock-count">Receiving Items</p></div>
                    {/* <div>
                        <button style={{ marginTop: '0%' }} onClick={() => this.editScreen()} className="refresh-btn"><p className="refresh-text">Save</p></button>
                    </div> */}
                </div>
                <div className='production-main-div'>
                    <div className='production-main-child'>
                        Default Values
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 className='screen-heading'> {this.state?.mainHeading ? this.state?.mainHeading : this.state.receiving[0]?.header?.text}</h1>
                            <div className='slider' style={{ width: '100%', }} >
                                <Slider
                                    className='slider-style'
                                    min={0}
                                    max={100}
                                    disabled={true}
                                    value={value}
                                // onChangeStart={this.handleChangeStart}
                                // onChange={this.handleChange}
                                // onChangeComplete={this.handleChangeComplete}
                                />
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px' }}>
                                <button style={{ fill: 'white', color: 'white', cursor: 'pointer' }} className="barcode-btn">
                                    <IoIosBarcode size={30} />
                                </button>
                                <input disabled type={'text'} id={'asnValue'} placeholder={'Type or Scan Pack Order'} style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    <FaSearch size={25} />
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center', marginTop: 40, position: 'relative' }}>
                                <img height={'200px'} width={'200px'} src={this.state.receiving[0]?.centerMain?.icon !== '' ? this.state.receiving[0]?.centerMain?.icon : receivingLogo} style={{ objectFit: 'contain', cursor: 'pointer' }} />
                                <span style={{ cursor: 'pointer' }}>
                                    {this.state.receiving[0]?.centerMain?.text}
                                </span>

                            </div>

                            <img height={'58px'} width={'168px'} src={this.state.imageLink !== '' ? this.state.imageLink : innoventLogo} style={{ position: 'absolute', top: '80%', left: '50%', transform: "translate(-50%, 0)", objectFit: 'contain' }} />
                            <input id='image' accept="image/*" type={'file'} style={{ display: 'none' }} />

                        </div>
                    </div>
                    <div className='production-main-child'>
                        Receiving Items
                        <div className='production-main-screen' style={{ height: '700px' }} >
                            <h1 onClick={() => this.mainHeadingHandler('header')} className='screen-heading'>{this.state.header !== '' ? this.state.header : this.state.receiving[0]?.header?.text}</h1>
                            <div onClick={() => this.sliderHandler('slider')} className='slider' style={{ width: '100%', }} >
                                {this.state.receiving[0]?.slider?.show === false && this.state.slider === false ?
                                    <h1 style={{ margin: 10, fontSize: '16px', color: 'red' }}>Slider Off</h1>

                                    :
                                    <Slider

                                        className='slider-style'
                                        min={0}
                                        max={100}
                                        // disabled={true}
                                        value={value}
                                    // onChangeStart={this.handleChangeStart}
                                    // onChange={this.handleChange}
                                    // onChangeComplete={this.handleChangeComplete}
                                    />
                                }
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px' }}>
                                <button onClick={() => this.buttonPowerHandler('barcodeButton')} style={{ fill: 'white', color: 'white', cursor: 'pointer', backgroundColor: this.state.receiving[0]?.barcode?.value === false && this.state.barcodeButton === false ? 'red' : null }} className="barcode-btn">
                                    <IoIosBarcode size={30} />
                                </button>
                                <input onClick={() => this.inputHandler('placeholder')} type={'text'} id={'asnValue'} value={this.state.placeholder !== '' ? this.state.placeholder : this.state.receiving[0]?.input?.placeholder} style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                    <FaSearch size={25} />
                                </div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center', marginTop: 40, position: 'relative' }}>
                                <img height={'200px'} width={'200px'} src={this.state.imageLinkBg !== '' ? this.state.imageLinkBg : this.state.receiving[0]?.centerMain?.icon} style={{ objectFit: 'contain', cursor: 'pointer' }} />
                                <input onChange={(e) => this.imageHandler(e.target.files, 'imageLinkBg')} id='imagebg' accept="image/*" type={'file'} style={{ display: 'none' }} />
                                <label htmlFor='imagebg' style={{
                                    position: 'absolute',
                                    top: '110%',
                                    left: '50%',
                                    transform: "translate(-50%, 0)",
                                    padding: 10,
                                    borderRadius: 5,
                                    backgroundColor: 'blue',
                                    color: 'white',
                                    cursor: 'pointer'
                                }} >{this.state.percent === 0 ? 'Choose Image for Logo' : 'loading'}</label>
                            </div>

                            {/* <img height={'58px'} width={'168px'} src={this.state.imageLink !== '' ? this.state.imageLink : innoventLogo} style={{ position: 'absolute', top: '80%', left: '50%', transform: "translate(-50%, 0)", objectFit: 'contain' }} /> */}
                            {/* <input onChange={(e) => this.imageHandler(e.target.files, 'imageLink')} id='image' accept="image/*" type={'file'} style={{ display: 'none' }} />
                            <label htmlFor='image' style={{
                                position: 'absolute',
                                top: '90%',
                                left: '50%',
                                transform: "translate(-50%, 0)",
                                padding: 10,
                                borderRadius: 5,
                                backgroundColor: 'blue',
                                color: 'white',
                                cursor: 'pointer'
                            }} >{this.state.percent === 0 ? 'Choose Image for Logo' : 'loading'}</label> */}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(ReceivingTags)