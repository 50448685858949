import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editCycleCount1, editManageTagScreen, getCycleCount1, getManageTagScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { IoMdArrowDropdown } from "react-icons/io"

class CycleCount1 extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        buttonText: '',
        centerMainText: '',
        mainButton: '',
        header: '',
        data: [
            {
                "centerMain": {
                    "text": "Department",
                    "buttonText": "Select More Filters",
                    "buttonBG": ""
                },
                "header": {
                    "text": "Cycle Count"
                },
                "mainButton": {
                    "text": "Continue",
                    "bgColor": ""
                },
            }
        ]
    }
    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        console.log(this.state.data[0]);
        const body = {
            "centerMain": {
                "text": this.state.centerMainText !== '' ? this.state.centerMainText : this.state?.data[0]?.centerMain?.text,
                "buttonText": this.state.buttonText !== '' ? this.state.buttonText : this.state?.data[0]?.centerMain.buttonText,

            },
            "header": {
                "text": this.state.header !== '' ? this.state.header : this.state?.data[0]?.header
            },
            "mainButton": {
                "text": this.state.mainButton !== '' ? this.state.mainButton : this.state?.data[0]?.mainButton.text,

            },
        }
        const editing = await editCycleCount1(body)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value, currentValue) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => onClose()} heading={'Cycle Count'}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <React.Fragment>
                                <Form.Label>{currentValue}</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    id={value}
                                    defaultValue={this.state[value] !== '' ? this.state[value] : currentValue}
                                    onChange={(e) => this.setState({ [value]: e.target.value })}
                                />
                            </React.Fragment>
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getCycleCount1()
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Cycle Count 1</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <div>
                                    <h1 className='screen-heading' style={{ fontSize: 30 }}>Cycle Count</h1>
                                    <p className='user-locate-item' >User:Admin</p>
                                    <p className='store-locate-item'>Store:0133456</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "40%" }}>
                                        <p style={{ fontSize: "20px" }}>Department</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div>
                                                <p>All</p>
                                            </div>
                                            <div>
                                                <IoMdArrowDropdown ></IoMdArrowDropdown>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <button className='select-more-btn'><p className='select-more-text'>Select More Filters</p></button>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40%" }}>
                                            <button className='select-more-continue'><p className='select-more-text'>Continue</p></button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='production-main-child'>
                            Cycle Count
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <div>
                                    {/* buttonText
                                    centerMainText
                                    mainButton
                                    header */}
                                    <h1 onClick={() => this.buttonHandler('header', this.state.data[0]?.header?.text)} className='screen-heading' style={{ fontSize: 30 }}>{this.state.header !== '' ? this.state.header : this.state?.data[0]?.header?.text}</h1>
                                    <p className='user-locate-item' >User:Admin</p>
                                    <p className='store-locate-item'>Store:0133456</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <div style={{ marginTop: "40%" }}>
                                        <p onClick={() => this.buttonHandler('centerMainText', this.state.data[0]?.centerMain?.text)} style={{ fontSize: "20px" }}>{this.state.centerMainText !== '' ? this.state.centerMainText : this.state?.data[0]?.centerMain?.text}</p>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", flexDirection: "column" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <div>
                                                <p>All</p>
                                            </div>
                                            <div>
                                                <IoMdArrowDropdown ></IoMdArrowDropdown>
                                            </div>
                                        </div>
                                        <div className='line-cycle'></div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                            <button onClick={() => this.buttonHandler('buttonText', this.state.data[0]?.centerMain?.buttonText)} className='select-more-btn'><p className='select-more-text'>{this.state.buttonText !== '' ? this.state.buttonText : this.state?.data[0]?.centerMain?.buttonText}</p></button>
                                        </div>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "40%" }}>
                                            <button onClick={() => this.buttonHandler('mainButton', this.state.data[0]?.mainButton.text)} className='select-more-continue'><p className='select-more-text'>{this.state.mainButton !== '' ? this.state.mainButton : this.state?.data[0]?.mainButton.text}</p></button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CycleCount1)