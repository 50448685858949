import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import { goodStockData, ibtData } from "../StaticData/IBT";
export default class GiCancellation extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">GI Cancellation</p>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                    </div>
                </div>
                <div className="dashboard-bottom">
                    {/* <div> <p className="filter-text">Filters</p></div> */}
                    <div className="drop-data">
                        <MyInput
                            type="date"
                        />
                        <MyInput
                            type="date"
                        />
                        <Dropdown
                            placeholder={"All Stores"}
                        />
                        <MyInput
                            placeholder={"EPC"}
                        ></MyInput>

                        <MyInput
                            placeholder={"SKU"}
                        ></MyInput>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

                            <MyInput
                                placeholder={"Supplier Number"}
                            >

                            </MyInput>
                            <MyInput
                                placeholder={"Shippment Number"}
                            ></MyInput>
                            <MyInput
                                placeholder={"Retail Item Batch ID"}
                            ></MyInput>

                            <div style={{ width: "265px" }}></div>

                            <div >
                                <button className="run-btn"><p className="run-text">Search</p></button>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingTop: "2%", height: '600px', overflow: 'auto' }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Date</th>
                                    <th>SKU</th>
                                    <th>Retail Item Batch ID</th>
                                    <th>Supplier Number</th>
                                    <th>Shipment Number</th>
                                    <th>Store</th>
                                    <th>Purchase Order </th>
                                    <th>Epc </th>
                                    <th>Remarks </th>
                                    <th>Id</th>
                                </tr>

                            </thead>
                            {
                                goodStockData.aaData.map((data, index) =>
                                    <tbody>
                                        <tr>
                                            <td>{data.date}</td>
                                            <td>{data.refno}</td>
                                            <td>{data.retail_item_batch_id}</td>
                                            <td>{data.supplier_number}</td>
                                            <td>{data.shipment_number}</td>
                                            <td>{data.store}</td>
                                            <td>{data.purchase_order}</td>
                                            <td>{data.epc}</td>
                                            <td>{data.epc}</td>
                                            <td>{data.id}</td>
                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>


        )
    }
}