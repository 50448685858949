import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';


const PrintingModal = ({ open, onClick, heading, children,onSave }) => {
    return (
        <Dialog className='printer-modal' style={{backgoundColor:'#363636'}}  aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
            <DialogTitle id="dialog-title">{heading}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} style={{color:'white'}} onClick={onSave}>Print</Button>
                <Button  variant={'outlined'} style={{color:'white'}} onClick={onClick}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PrintingModal
