import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"


export default class StorePayload extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Store Payload</p></div>
                    <div style={{ display: "flex", width: "13%", justifyContent: "space-between", marginTop: "0.3%", alignItems: 'center' }}>
                        <div >
                            <img src={zoom} alt="zoom here" />
                        </div>
                        <div>
                            <img src={print} alt="print here" />
                        </div>
                        <div>
                            <img width={30} height={30} src={download} alt="download here" />
                        </div>
                    </div>
                </div>
                <div className="dashboard-bottom">
                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Store Name</th>

                                    <th>Action </th>
                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>

                                            <td>{
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button className="action-btn"><p className="action-txt">Payload</p></button>
                                                </div>
                                            }</td>


                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>


                </div>
            </div>
        )
    }
}