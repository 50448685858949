import NewBar from "./NewBar";
import MusicChart from "./MusicChart";
import LineCharts from "./LineCharts";
import React from "react";
import ComposedBar from "./ComposedBar";
import PieCharts from "./PieCharts";

const Sample = () =>{
    return(
        <div style={{width:"100%",marginBottom:"20px"}}>
        <NewBar/>
        <LineCharts/>
        <MusicChart/>
        <ComposedBar/>
        <PieCharts/>
        </div>
    )
}

export default Sample;