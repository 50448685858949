import React from 'react'

const MyInput = ({ placeholder, onChange, width, value, type }) => {
    return (
        <div>
            <input style={{ width: width }} className='myinput' type={type} value={value} placeholder={placeholder} onChange={e => onChange(e.target.value)}>

            </input>
        </div >
    )
}

export default MyInput
