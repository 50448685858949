export const HeadAndRowPicking = [
  //   { header: "Received Time", column: "createdAt" },
  { header: "Item Number.", column: "itemNumber" },
  { header: "Material", column: "material" },
  { header: "EAN", column: "EAN" },
  { header: "Short Text", column: "shortText" },
  { header: "New Order Value", column: "netOrderValue" },
  { header: "Order Quantity", column: "orderQuantity" },
  { header: "Order Unit", column: "orderUnit" },
  { header: "Net Price", column: "netPrice" },
  { header: "Quantity in SKU", column: "quantityInSKU" },
  { header: "Stock Keeping Unit", column: "stockkeepingUnit" },
  { header: "Currency", column: "currency" },
  { header: "Price Unit", column: "priceUnit" },
  { header: "Target Quantity", column: "targetQuantity" },
  { header: "Open Target Quantity", column: "openTargetQuantity" },
  { header: "Still To Be Delivered Qty.", column: "stillToBeDeliveredQty" },
  { header: "Still To Be Delivered Val", column: "stillToBeDeliveredVal" },
  { header: "Still To Be Invoice Qty", column: "stillToBeInvoicedQty" },
  { header: "Still TO Be Invoiced Val", column: "stillToBeInvoicedVal" },
  { header: "No of Positions", column: "noOfPositions" },
  { header: "Processed Qty.", column: "processedQuantity" },
  { header: "Process Status", column: "process_status" },
];

export const HeadAndRowPO = [
  //   { header: "Received Time", column: "createdAt" },
  { header: "Order Unit", column: "PO_Order_Unit" },
  { header: "Net Price", column: "PO_Net_Price" },
  { header: "Article Code", column: "Article_Code" },
  { header: "Article GTIN", column: "PO_Article_GTIN" },
  { header: "Price Unit", column: "PO_Price_Unit" },
  { header: "Site", column: "PO_Site" },
  { header: "Site Description", column: "Site_Desc" },
  { header: "Storage Location", column: "PO_Storage_Loc" },
  { header: "Receiving Process Qty.", column: "receivingProcessedQuantity" },
  { header: "Packing Process Qty.", column: "packingProcessedQuantity" },
  { header: "Shipping Process Qty.", column: "shippingProcessedQuantity" },
  { header: "Receiving Date", column: "receiving_date" },
  { header: "Packing Date", column: "packing_date" },
  { header: "Shipping Date", column: "shipping_date" },
  { header: "PO Vend Art", column: "PO_Vend_Art" },
  { header: "Article UOM", column: "Article_Uom" },
  { header: "Item", column: "PO_Item" },
  { header: "Order Qty.", column: "PO_Order_Quantity" },
  { header: "Article MC Category", column: "Article_MC_Category" },
  { header: "Delivery Date", column: "PO_Delivery_Date" },
  { header: "Item Action", column: "Item_Action" },
  { header: "Add Char 1", column: "Add_Char1" },
  { header: "Add Char 2", column: "Add_Char2" },
  { header: "Add Char 3", column: "Add_Char3" },
  { header: "Add Char 4", column: "Add_Char4" },
  { header: "Item Status", column: "Item_Status" },
  { header: "Process Status", column: "process_status" },
];


export const HeadAndRowTO = [
  //   { header: "Received Time", column: "createdAt" },
  { header: "Article Code", column: "Article_Code" },
  { header: "Article Description", column: "Article_Desc" },
  { header: "Original Location", column: "original_location" },
  { header: "Delivery Item", column: "Deliv_Item" },
  { header: "Barcode", column: "Barcode" },
  { header: "Description", column: "Desc" },
  { header: "OBD UOM", column: "OBD_UOM" },
  { header: "Delivery Qty.", column: "Delivery_Qty" },
  { header: "OBD Source Site", column: "OBD_Source_Site" },
  { header: "OBD Storage Location", column: "OBD_Storage_Location" },
  { header: "Process Status", column: "process_status" },
  { header: "Receiving Process Qty.", column: "receivingProcessedQuantity" },
  { header: "Packing Process Qty.", column: "packingProcessedQuantity" },
  { header: "Shipping Process Qty.", column: "shippingProcessedQuantity" },
  { header: "Receiving Date", column: "receiving_date" },
  { header: "Packing Date", column: "packing_date" },
  { header: "Shipping Date", column: "shipping_date" },
  { header: "Add Char 1", column: "Add_Char1" },
  { header: "Add Char 2", column: "Add_Char2" },
  { header: "Add Char 3", column: "Add_Char3" },
  { header: "Add Char 4", column: "Add_Char4" },
  { header: "Item Status", column: "Item_Status" },
];

export const HeadAndRowBulk = [
  { header: "S.No", column: "Item_No" },
  { header: "Reference", column: "Reference" },
  { header: "Article", column: "Article" },
  { header: "Barcode", column: "Barcode" },
  { header: "Quantity", column: "Item_Quantity" },
  { header: "Currency", column: "Currency" },
  { header: "POS Description", column: "Desc_EN" },
  { header: "AED Price", column: "AED_PRICE" },
  { header: "Season Code", column: "Season_code" },
];

export const HeadAndRowPackingList = [
  { header: "PO No", column: "PO_No" },
  { header: "Carton No", column: "Cartoon_No" },
  { header: "Article No", column: "Article_No" },
  { header: "Article Description", column: "Article_Desc" },
  { header: "EAN", column: "EAN" },
  { header: "MC Code", column: "MC_code" },
  { header: "Packed Qty.", column: "packingProcessedQuantity" },
  { header: "Vendor code", column: "Vendor_Code" },
  { header: "Vendor Name", column: "Vendor_Name" },
  { header: "NW", column: "NW" },
];
