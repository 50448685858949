import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput"
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { getInventoryData } from "../Path/Path";
import { InventoryData } from "./StaticData/IBT";
import { PaginatedItems } from "../components/Paginated";
import PermissionsModal from "../components/PermissionsModal";
import { getCustomItem, getSoh } from "../Path/Path";
export default class Inventory extends Component {

    state = {
        data: "",
        storeId: [],
        staticData: [],
        itemsPerPage: 10,
        visible: false,
        dataStatic:"",
        data1:[],
        item:[],
    }

    async componentDidMount() {
        this.setState({ staticData: InventoryData })
        const data = await getInventoryData();
        const data1 = await getSoh();
        const items = await getCustomItem();
        this.setState({ data1: data1, item: items })
        const storeIdArray = data.map((elem) => elem.storename)
        console.log(data);
        this.setState({ data: data, storeId: storeIdArray })
    }

    showPermissionHandler = (item) => {
        this.setState({ visible: true, data: item })
        console.log(item);
    }
    render() {
        const headers = [
            "EPC",
            "SKU",
            "Store",
            "Brand Name",
            "Color",
            "Size",
            "Item Disposition"
        ]

        const colNames = [
            "epc",
            "item_code",
            "store_id",
            "brand_name",
            "color",
            "size",
            "ItemDispostion"
        ]
        console.log(this.state.visible);

        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Inventory</p></div>

                </div>
                <div style={{ width: '95%', margin: '1em auto auto auto' }}>
                    <p className="filter-text">Filters</p>
                </div>
                <div className="inventory-bottom">
                    {/* <div> <p className="filter-text">Filters</p></div> */}

                    <Dropdown
                        placeholder={"Store ID"}
                        list={this.state.storeId}
                    />
                    <MyInput
                        placeholder={"EPC"}
                    />
                    <MyInput
                        placeholder={"SKU"}
                    />
                    <MyInput
                        placeholder={"Department Name"}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "2%" }}>
                    <Dropdown
                        placeholder={"All Brands"}
                    />
                    <Dropdown
                        placeholder={"All Colors"}
                    />
                    <Dropdown
                        placeholder={"All Sizes"}
                    />

                    <div style={{ display: "flex" }}>
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                        <div style={{ width: "70px" }}>

                        </div>
                    </div>
                </div>

                <div style={{ paddingTop: "2%" }}>
                    <PaginatedItems headers={headers} Permissions editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.deleteHandler(item._id)} showPermissionHandler={(item)=> this.showPermissionHandler(item)} noQRCode colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.staticData} />
                </div>
                <PermissionsModal
                data={this.state.data}
                open={this.state.visible ? true : false}
                onClick={() => this.setState({ visible: false })}
            />
            </div>


        )
    }
}