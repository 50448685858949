import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import moment from "moment";
import { capitalizeFirstLetter} from "../screens/common/capitalizeFirstLetter";
import { Autocomplete, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ListViewModal from "./ListViewModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TableModal({
  heading,
  modalOpen,
  data,
  closeHandler,
  csvData,
  tableTitle,
  HeadAndRow,
  links,
  csv,
}) {
  const [visible, setVisible] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([]);
    const [itemsArray, setItemsArray] = React.useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [gtin, setGtin] = React.useState("");
      const [filteredItems, setFilteredItems] = useState([]);
      const [isSearched, setIsSearched] = useState(false);

  const onBarcodeClick = (e, tagIDs) => {
    e.preventDefault();
    if (Array.isArray(tagIDs)) {
      setSelectedTags(tagIDs);
      setVisible(true);
    }
  };

    useEffect(() => {
      setItemsArray(data || []);
    }, [data]);

      useEffect(() => {
        if (inputValue === "" && gtin === "") {
          setFilteredItems(itemsArray);
          setIsSearched(false);
        }
      }, [inputValue, gtin, itemsArray]);


    const searchArticleCode = () => {
        console.log({inputValue})
        console.log({gtin})
      if (inputValue === "" && gtin === "") {
        toast.error("Enter value to search");
      } else {
        const result = itemsArray.filter(
          (item) =>
            (inputValue !== "" && item.Article_Code === inputValue) ||
            (gtin !== "" && item.PO_Article_GTIN === gtin)
        );
        console.log("result", result);
        setFilteredItems(result);
        setIsSearched(true);
      }
    };

  return (
    <div>
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={closeHandler}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {heading}
            </Typography>

            {csvData}
          </Toolbar>
        </AppBar>
        <div className="dashboard-main" style={{ margin: "10px" }}>
          <div className="dashboard-top">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p
                className="stock-count"
                style={{ fontSize: "25px", margin: 0 }}
              >
                {tableTitle}
              </p>
              {csv && (
                <CSVLink
                  filename={`Order-${""}-SKU-Report.csv`}
                  // data={getCSVData()}
                  // headers={headers}
                  style={{ marginRight: 5, cursor: "pointer" }}
                >
                  <GrDocumentCsv fontSize={25} />
                </CSVLink>
              )}
            </div>
          </div>
          {(tableTitle.includes("Transfer") ||
            tableTitle.includes("Purchase")) && (
            <p style={{ margin: "10px 0px 0px 20px" }}>Filters</p>
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                marginLeft: "15px",
                marginTop: "10px",
                width: "calc(80% - 280px)",
              }}
            >
              {(tableTitle.includes("Transfer") ||
                tableTitle.includes("Purchase")) && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={itemsArray
                    .filter((item) => item?.Article_Code !== "")
                    .map((item) => item.Article_Code)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) => setInputValue(v === null ? "" : v)}
                  onInputChange={(e) =>
                    setInputValue(
                      e.target?.value === 0 || e.target?.value === undefined
                        ? ""
                        : e.target?.value
                    )
                  }
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Article Code" />
                  )}
                />
              )}
              {tableTitle.includes("Purchase") && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  freeSolo={true}
                  size={"small"}
                  options={itemsArray
                    .filter((item) => item?.PO_Article_GTIN !== "")
                    .map((item) => item.PO_Article_GTIN)
                    .reduce((noDupArr, entry) => {
                      if (noDupArr.includes(entry)) {
                        return noDupArr;
                      } else {
                        return [...noDupArr, entry];
                      }
                    }, [])}
                  onChange={(e, v) => setGtin(v === null ? "" : v)}
                  onInputChange={(e) =>
                    setGtin(
                      e.target?.value === 0 || e.target?.value === undefined
                        ? ""
                        : e.target?.value
                    )
                  }
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="PO Article GTIN" />
                  )}
                />
              )}
            </div>
            <div>
              {(tableTitle.includes("Transfer") ||
                tableTitle.includes("Purchase")) && (
                <div style={{ marginTop: "10px", marginRight: "15px" }}>
                  <Button
                    variant="contained"
                    onClick={searchArticleCode}
                    //   className="run-btn"
                    style={{
                      background: "#0069D9",
                      padding: "10px",
                      width: "160px",
                      alignItems: "center",
                      height: "35px",
                      borderRadius: "10px",
                      textTransform: "none",
                    }}
                  >
                    <p className="run-text">Search</p>
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table data-table-theme="dark zebra" style={{ marginTop: "20px" }}>
              <thead>
                <tr style={{ background: "#373E43" }}>
                  {HeadAndRow?.length &&
                    HeadAndRow?.map((item, index) => (
                      <th key={index} style={{ background: "#000" }}>
                        {item ? item?.header : "--"}
                      </th>
                    ))}
                </tr>
              </thead>
              {(isSearched ? filteredItems : data)?.map((item, index) => (
                <tbody key={index}>
                  <tr>
                    {HeadAndRow?.map((name, idx) =>
                      links?.includes(name?.column) &&
                      item.process_status !== "created" ? (
                        <td key={idx}>
                          <Link
                            to="#"
                            onClick={(e) => onBarcodeClick(e, item.tagIDs)}
                          >
                            {item[name?.column] || "--"}
                          </Link>
                        </td>
                      ) : (
                        <td key={idx}>
                          {name?.column === "process_status"
                            ? capitalizeFirstLetter(item[name?.column])
                            : item[name?.column] &&
                              (name?.column === "createdAt" ||
                                name?.column === "packing_date" ||
                                name?.column === "shipping_date" ||
                                name?.column === "receiving_date" ||
                                name?.column === "PO_Delivery_Date")
                            ? moment(
                                item[name?.column],
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  item[name?.column],
                                  "YYYYMMDDHHmmss"
                                ).format("MM/DD/YYYY hh:mm:ss A")
                              : "--"
                            : item[name?.column] || "--"}
                        </td>
                      )
                    )}
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px",
              color: "white",
            }}
          >
            <h3>List Length: {data?.length}</h3>
          </div>
        </div>
        {visible && (
          <ListViewModal
            {...{ selectedTags }}
            onClose={() => {
              setVisible(false);
              setSelectedTags([]);
            }}
          />
        )}
      </Dialog>
    </div>
  );
}
