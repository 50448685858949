import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify'
import Dropdown from '../components/Dropdown';
import { getConsignement, getPoByOrderId, getPrintingPo, getRtvPo, getRtvPoWithParams } from '../Path/Path';

import { ibtData } from './StaticData/IBT';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Loader from '../components/Loader';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment/moment';
import { CSVLink } from 'react-csv';
import { GrDocumentCsv } from 'react-icons/gr';


const Consignment = () => {
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [poDataList, setPoDataList] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [ibtData, setIbtData] = useState([])
    const [ibtDataFilter, setIbtDataFilter] = useState([])
    const [tableData, setTableData] = useState({})
    const [poNumberVal, setpoNumberVal] = useState('')
    const [run, setRun] = useState(false)
    const [loading, setLoading] = useState(false)

    const [toDate, setToDate] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [source, setSource] = useState('')
    const [destination, setDestination] = useState('')
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const [filters, setFitlers] = useState({})
    useEffect(() => {
        // 
    }, []);
    useEffect(() => {
        async function fetchData(toDate, fromDate, asn) {
            setLoading(true)
            const getPo = await getConsignement()
                .then(async(res) => {
                    setLoading(false)
                    await setIbtData(res.data)
                    await setIbtDataFilter(res.data);

                })
                .catch((err => {
                    toast.error('Something Went Wrong')
                    setLoading(false)
                }))

            // let uniqueArray = []
            // uniqueArray = ibtData?.filter(function (item, pos) {
            //     return ibtData.indexOf(item) == pos;
            // })




        }
        fetchData();

    }, []);
    async function fetchDataWithParams() {
        setLoading(true)
        function replaceAll(str, find, replace) {
            return str?.replace(new RegExp(find, 'g'), replace);
        }
        console.log(toDate);
        console.log(fromDate);
        const getPo = await getRtvPoWithParams({ toDate: replaceAll(formatDateReverse(toDate), '-', '/'), fromDate: replaceAll(formatDateReverse(fromDate), '-', '/'), asn: invoiceNumberVal })
            .then((async res => {
                setLoading(false)
                await setIbtData(res.data)
            }))
            .catch((err => {
                toast.error('Something Went Wrong')
                setLoading(false)
            }))
        console.log(getPo.data);
    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    const formatDateReverse = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = month;
        if (day.length < 2)
            day = day;

        return [month, day, year].join('-');
        // return console.log([year, month, day].join('-'))
    }
    const dateFilter = () => {
        ibtDataFilter?.map((item => console.log(item.source)))
        ibtDataFilter?.map((item => console.log(item.ASN)))
        ibtDataFilter?.map((item => console.log(item.destination)))
        if (toDate === null && toDate === null) {
            return ibtDataFilter?.filter((x =>x?.consignment_id?.toLowerCase().includes(invoiceNumberVal.toLowerCase())&&x?.order_id?.toLowerCase().includes(source.toLowerCase())

                ))
        } else {
            let hehe = []
            for (let index = 0; index < ibtDataFilter?.length; index++) {
                const element = ibtDataFilter[index];
                if (element?.createdAt === undefined) {
                    element.createdAt = ''
                }
                
                hehe.push(element)
            }
            
            return hehe.filter((x =>
                formatDate(x?.createdAt) >= formatDate(toDate) && formatDate(x?.createdAt) <= formatDate(fromDate)
                &&
                x?.consignment_id?.toLowerCase().includes(invoiceNumberVal.toLowerCase())
                &&
                x?.order_id?.toLowerCase().includes(source.toLowerCase())
            ))
        }

    }
    const searchData = () => {
        setIbtData(dateFilter())
        console.log(dateFilter());

    }
    const poHandler = (data) => {
        setpoNumberVal(data)
        setPoDataList(poData.filter((item => item.po_number === data)))
        console.log(poData.filter((item => item.po_number === data)));
        console.log(data);
    }
    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    };
    const headers = [
        { label: "Consignment id", key: 'consignment_id' },
        { label: "Customer Number", key: 'customer_number' },
        { label: "Order id", key: 'order_id' },
        { label: "Packed Qty", key: 'PackedQTY' },
        { label: "Status", key: 'status' },
        { label: "Packing Date", key: 'createdAt' },
        { label: "Packing Remarks", key: 'event_remarks' },
    ]
    const csvData = ibtData?.map((item => {
        return {
            consignment_id: item?.consignment_id || '----',
            customer_number: item?.customer_number || '----',
            PackedQTY: item?.items?.filter((item => item.process_status === 'PACKED')).length || '----',
            status: item?.event_ref_id?.endsWith('01') ? 'packing' : item?.event_ref_id?.endsWith('02') ? 'shipping' : 'receiving' || '----',
            order_id: item?.order_id || '----',
            createdAt: item?.createdAt || '----',
            event_remarks: item?.event_remarks || '----',
        }
    }))
    return (
        <>
            <Loader loading={loading} />
            <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>
            <div className='dashboard-main'>
                <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Consignment (Report)</p>
                        <CSVLink
                            filename='Count/Consignment'
                            data={csvData}
                            headers={headers}
                        >
                            <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />

                        </CSVLink>

                    </div>
                    <div>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>


                </div>
                <div className='dashboard-bottom' style={{ marginTop: "0.5%" }}>
                    <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>
                            <DesktopDatePicker
                                label="From Date"
                                clearable
                                showTodayButton
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={toDate}
                                onChange={(data) => setToDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="DD/MM/YYYY"
                                size={'small'}
                                value={fromDate}
                                onChange={(data) => setFromDate(data)}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}

                                options={ibtDataFilter?.filter((item => item?.consignment_id !== '')).map((item => item?.consignment_id)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setinvoiceNumberVal(v === null ? '' : v)}
                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Consignment ID" />}
                            />

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}

                                options={ibtDataFilter?.filter((item => item?.order_id !== '')).map((item => item?.order_id)).reduce((noDupArr, entry) => {
                                    if (noDupArr.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, [])}
                                onChange={(e, v) => setSource(v === null ? '' : v)}
                                sx={{ width: 170 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Order ID" />}
                            />


                        </div>

                        <button
                            onClick={() =>
                                searchData()
                                // fetchDataWithParams()
                            }
                            className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                    <div style={{ marginTop: '20px', overflow: 'auto', height: '600px' }}>
                        <table data-table-theme="dark zebra">
                            <thead>
                                <tr style={{ background: "#373E43" }}>
                                    <th >Consignment id</th>
                                    <th>Customer Number</th>
                                    <th>Order id</th>
                                    <th>Packed Qty</th>
                                    <th>Status </th>
                                    <th>Packing Date </th>
                                    <th>Packing Remarks </th>
                                    <th>Source </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ibtData?.map((data, index) =>
                                        <tr>
                                            <td>{data?.consignment_id}</td>
                                            <td>{data?.customer_number}</td>
                                            <td>{data?.order_id}</td>
                                            <td><NavLink to='/viewconsignmentDetails' state={{ data: data?.items ? data?.items : [], asn: data?.po_number, date: data?.event_date }} >{data?.items ? data?.items.length : 'No Items'}</NavLink></td>
                                            <td>{data?.status}</td>
                                            <td>{new Date(data?.createdAt).toLocaleString('hi-IN')}</td>
                                            <td>{data?.event_remarks}</td>
                                            <td>{data?.source}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Consignment;










































