import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";


export default class SohDetail extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Soh Detail</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div className="drop-data" style={{ width: "100%" }}>
                        <Dropdown
                            value={"All Stores"}
                        />
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Check All</th>
                                    <th>Store ID</th>
                                    <th>On Hand Matching Soh Yesterday</th>
                                    <th>On Hand Matching </th>
                                    <th>Counted</th>


                                    <th>Action </th>
                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    <input type="checkbox"></input>
                                                }
                                            </td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>

                                            <td>Cell 5</td>
                                            <td>cell 6</td>

                                            <td>
                                                {
                                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                        <div>
                                                            <button className="action-btn"><p className="action-soh-txt">Consume SOH</p></button>
                                                        </div>
                                                        <div>
                                                            <button className="action-btn"><p className="action-soh-txt">lean SOH</p></button>
                                                        </div>
                                                        <div>
                                                            <button className="action-btn"><p className="action-soh-txt">Run SOH</p></button>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}