import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput"
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { getStoreName } from "../Path/Path";
import { PaginatedItems } from "../components/Paginated";
import BarGraph from "../Graphs/BarGraph";
import { getCustomItem, getSoh } from "../Path/Path";
export default class Operation0 extends Component {
    state = {
        data1: [],
        item: [],
        operationItems: [{
            Store: '00125',
            Department: "750",
            Date: '22-09-13',
            Accuracy: "0.00",
            UIA: "0.00",
            Expected: "39",
            Counted: "0",
            Overs: "0",
            Unders: "-0.39",
            ExpectedSF: "0",
            ExpectedSR: "0",
            Department: "perfume",
            Brand: "136-DG Men",
            Diff: "13"


        }],
        data: "",
        storeName: [],
        dropdata: "",
        filter: [],
        mydate: "",
        visible: false,
        itemsPerPage: 10,
    }



    async componentDidMount() {
        const data1 = await getSoh();
        const items = await getCustomItem();
        this.setState({ data1: data1, item: items })
    }
    // search = () => {
    //     const res = this.state.data.filter(i => i.storename === this.state.dropdata)
    //     this.setState({ filter: res })
    //     console.log(res, "resssssssssssssssss")
    //     this.setState({ visible: true })

    // }
    render() {
        const headers = [
            "Date",
            "Store",
            "Department",
            "Accuracy",
            "UIA",
            "Expected",
            "Counted",
            "Overs",
            "Unders",
            "ExpectedSF",
            "ExpectedSR",

        ]
        const headers2 = [

            "Department",
            "Brand",
            "SKU",
            "Expected",
            "Diff"

        ]
        const headers3 = [

            "SKU",
            "Dept.Name",
            "Brand",
            "Size",
            "Color",
            "Delta",
            "Expected",
            "Counted",
            "Counted SF",
            "Counted SR",


        ]
        const colNames = [

            "Date",
            "Store",
            "Department",
            "Accuracy",
            "UIA",
            "Expected",
            "Counted",
            "Overs",
            "Unders",
            "Expected SF",
            "Expected SR",
        ]
        const colNames2 = [

            "Department",
            "Brand",
            "SKU",
            "Expected",
            "Diff"

        ]
        const colNames3 = [


            "Store",
            "Department",
            "Accuracy",
            "UIA",
            "Expected",
            "Counted",
            "Overs",
            "Unders",
            "Expected SF",
            "Expected SR",
        ]
        return (

            <div>
                <div className="dashboard-main">
                    <div style={{ display: "flex", width: "100%" }}>
                        <BarGraph />
                    </div>
                    <div className="dashboard-top">
                        <div> <p className="stock-count">Stock Summary</p></div>

                    </div>
                    <div className="users-bottom">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="filter-text">Filters</p>
                        </div>
                        <Dropdown placeholder={"All Name"}
                        // list={this.state.storeName}
                        // onClickList={e => this.setState({ dropdata: e })}
                        />
                        <MyInput type={"date"}
                        // onChange={e => this.setState({ mydate: e })}
                        />
                        <button
                            // onClick={() => this.search()} 
                            className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                    <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.operationItems} />

                </div>

                <div style={{ display: "flex", width: "100%" }}>
                    <div className="dashboard-main2">
                        <div className="dashboard-top2">
                            <div> <p className="stock-count">Top 20 Over</p></div>

                        </div>

                        <PaginatedItems noAction headers={headers2} colNames={colNames2} itemsPerPage={this.state.itemsPerPage} data={this.state.operationItems} />
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}><p>Total</p></div>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>   <p>700</p></div>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>   <p>800</p></div>
                        </div>
                    </div>
                    <div className="dashboard-main2">
                        <div className="dashboard-top2">
                            <div> <p className="stock-count">Top 20 Over</p></div>

                        </div>

                        <PaginatedItems noAction headers={headers2} colNames={colNames2} itemsPerPage={this.state.itemsPerPage} data={this.state.operationItems} />
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}><p>Total</p></div>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>   <p>700</p></div>
                            <div style={{ width: "15%", display: "flex", justifyContent: "center" }}>   <p>800</p></div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">Real Time Discrepency</p></div>

                    </div>
                    <div className="users-bottom">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="filter-text">Search SKU : </p>
                            <MyInput type={"SKU Search"}
                            // onChange={e => this.setState({ mydate: e })}
                            />

                        </div>


                    </div>
                    <PaginatedItems noAction headers={headers3} colNames={colNames3} itemsPerPage={this.state.itemsPerPage} data={this.state.operationItems} />

                </div>
            </div>


        )
    }
}