import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyDbLxMRdLEpJM6-4wz50mEA2Ec6Z3z8yhc",
  authDomain: "productization-46274.firebaseapp.com",
  databaseURL: "productization-46274",
  projectId: "productization-46274.appspot.com",
  storageBucket: "gs://productization-46274.appspot.com/",
  messagingSenderId: "1:1026630355635:web:d2c9e0e8d5ec1c20c33ec6",
  appId: "G-VRHZYV2LWH"
})

const storage = getStorage(app);
export default storage;