import { CircularProgress } from '@material-ui/core'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { withRouter } from '../components/withRouter'
import { login } from '../Path/Path'


class Login extends Component {
    state = {
        userName: "",
        password: "",
        laoding: false,
        token: "123456"
    }


    onLogin = async (e) => {
        e.preventDefault()
        if (this.state.userName === "") {
            toast.warn("Enter User Name")
        }
        else if (this.state.password === "") {
            toast.warn("Enter Password")
        }
        else {
            this.setState({ loading: true })
            login({ userName: this.state.userName, password: this.state.password })
                .then(res => {

                    console.log(res)
                    // if (res?.error === "0") {


                    localStorage.setItem("login", true)

                    localStorage.setItem("loginData", JSON.stringify(res))
                    this.props.history("/admin")
                    toast.success("Login Successfully")
                    this.setState({ loading: false })
                    // }
                    // else if (res.error) {
                    //     alert("sh")
                    // }
                })
                .catch(e => {
                    toast.warn("Invalid Username or Password")
                    this.setState({ loading: false })
                })

        }



    }
    render() {
        return (
            <div>
                <div className='main-login' style={{ minHieght: '100vh' }}>

                    <form onSubmit={(e) => this.onLogin(e)} className='center-div'>
                        <div>
                            <p className='welcome'>Welcome Back</p>
                        </div>
                        <div>
                            <p className='user-text-input'>User Name</p>
                            <input value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} className='user-input' ></input>
                        </div>
                        <div>
                            <p className='user-text-input'>Password</p>
                            <input type={'password'} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='user-input'></input>
                        </div>
                        <div>
                            <button type={'submit'} className='login-btn'>

                                {this.state.loading ? (
                                    <CircularProgress
                                        size={15}
                                        style={{
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    />
                                ) : (
                                    <p className='user-login-text'>Login</p>
                                )}
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(Login) 