import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "12:00",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "6:00",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "12:00",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "6:00",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

const LineCharts = () =>{
  return (
        <div className="top-line-chart" style={{ backgroundColor: "#000" ,width:"100%",height:"30rem" }}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="0 1" fill="#000" />
                    <XAxis dataKey="name" tickLine={false} stroke="#fff" />
                    <Tooltip />
                    <Line
                        type="monotone"
                        dataKey="pv"
                        orientation=""
                        stroke="#D96269"
                        strokeWidth={5}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}


export default LineCharts;