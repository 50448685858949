import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "../components/Dropdown";
import {
  getPoByOrderId,
  getPrintingPo,
  getRtvPo,
  getRtvPoWithParams,
  PathCustom,
  PathCustomPOTO,
} from "../Path/Path";

import { ibtData } from "./StaticData/IBT";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Loader from "../components/Loader";
import { Autocomplete, TextField } from "@mui/material";
import { GrDocumentCsv } from "react-icons/gr";
import moment from "moment/moment";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import axios from "axios";

const Transfers = ({ stores }) => {
  const [poList, setPoList] = useState([]);
  const [poData, setPoData] = useState([]);
  const [poDataList, setPoDataList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [ibtData, setIbtData] = useState([]);
  const [ibtDataFilter, setIbtDataFilter] = useState([]);
  const [tableData, setTableData] = useState({});
  const [poNumberVal, setpoNumberVal] = useState("");
  const [run, setRun] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [invoiceNumberVal, setinvoiceNumberVal] = useState("");
  const [filters, setFitlers] = useState({});
  const [pageData, setPageData] = useState({});
  useEffect(() => {}, []);

  async function fetchData(pageNumber = 1) {
    setLoading(true);
    const toDateNew =
      formatDate(toDate) === "1970-01-01" ? "" : formatDateReverse(toDate);
    const fromDateNew =
      formatDate(fromDate) === "1970-01-01" ||
      formatDate(fromDate) === "NaN-NaN-NaN"
        ? ""
        : formatDateReverse(fromDate);
    console.log(toDateNew, "fromDateNew");
    const invoiceNumberValNew = invoiceNumberVal;
    const sourceNew = source;
    const destinationNew = destination;
    // const getPo = await axios.get(`${PathCustom}store/supply-chain/po?ASN=${invoiceNumberValNew}&startDate=${toDateNew}&endDate=${fromDateNew}&destination=${destinationNew}&source=${sourceNew}&page=${pageNumber}`)
    const getPo = await axios
      .get(
        `${PathCustomPOTO}store/supply-chain/po/to?ASN=${invoiceNumberValNew}&startDate=${toDateNew}&endDate=${fromDateNew}&destination=${destinationNew}&source=${sourceNew}&page=${pageNumber}`
      )
      .then(async (res) => {
        setLoading(false);

        if (res.data?.data) {
          // console.log(res?.data?.data?.data.filter((item => item.ASN?.toLowerCase()?.includes('00125914'))), 'res.data');
          await setIbtData(res.data?.data?.data);
          await setIbtDataFilter(res.data?.data?.data);
          await setPageData(res.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something Went Wrong");
        let err = error?.response?.data;
      });

    // let uniqueArray = []
    // uniqueArray = ibtData?.filter(function (item, pos) {
    //     return ibtData.indexOf(item) == pos;
    // })
  }
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchDataWithParams() {
    setLoading(true);
    function replaceAll(str, find, replace) {
      return str?.replace(new RegExp(find, "g"), replace);
    }
    console.log(toDate);
    console.log(fromDate);
    const getPo = await getRtvPoWithParams({
      toDate: replaceAll(formatDateReverse(toDate), "-", "/"),
      fromDate: replaceAll(formatDateReverse(fromDate), "-", "/"),
      asn: invoiceNumberVal,
    })
      .then(async (res) => {
        setLoading(false);

        await setIbtData(res.data?.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
        setLoading(false);
        let err = error?.response?.data;
      });
    console.log(getPo.data);
  }
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
    // return console.log([year, month, day].join('-'))
  };
  const formatDateReverse = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = month;
    if (day.length < 2) day = day;

    return [month, day, year].join("/");
    // return console.log([year, month, day].join('-'))
  };
  const dateFilter = () => {
    ibtDataFilter.map((item) => console.log(item.source));
    ibtDataFilter.map((item) => console.log(item.ASN));
    ibtDataFilter.map((item) => console.log(item.destination));
    if (toDate === null && toDate === null) {
      return ibtDataFilter.filter(
        (x) =>
          x?.ASN?.toLowerCase().includes(invoiceNumberVal.toLowerCase()) &&
          x?.source?.toLowerCase().includes(source.toLowerCase()) &&
          x?.destination?.toLowerCase().includes(destination.toLowerCase())
      );
    } else {
      let hehe = [];
      for (let index = 0; index < ibtDataFilter.length; index++) {
        const element = ibtDataFilter[index];
        if (element?.shipping_date === undefined) {
          element.shipping_date = "";
        }
        if (element?.receiving_date === undefined) {
          element.receiving_date = "";
        }
        if (element?.packing_date === undefined) {
          element.packing_date = "";
        }
        hehe.push(element);
      }
      return hehe.filter(
        (x) =>
          ((formatDate(x?.packing_date) >= formatDate(toDate) &&
            formatDate(x?.packing_date) <= formatDate(fromDate)) ||
            (formatDate(x?.shipping_date) >= formatDate(toDate) &&
              formatDate(x?.shipping_date) <= formatDate(fromDate)) ||
            (formatDate(x?.receiving_date) >= formatDate(toDate) &&
              formatDate(x?.receiving_date) <= formatDate(fromDate))) &&
          x?.ASN?.toLowerCase().includes(invoiceNumberVal.toLowerCase()) &&
          x?.source?.toLowerCase().includes(source.toLowerCase()) &&
          x?.destination?.toLowerCase().includes(destination.toLowerCase())
      );
    }
  };
  const searchData = () => {
    setIbtData(dateFilter());
    console.log(dateFilter());
  };
  const headers = [
    { label: "ASN", key: "ASN" },
    { label: "Source", key: "source" },
    { label: "Destination", key: "destination" },
    { label: "Packed Qty", key: "packedQTY" },
    { label: "Transfer Qty", key: "tanfersQTY" },
    { label: "Received Qty", key: "RecievedQTY" },
    { label: "Status", key: "status" },
    { label: "Packing Date", key: "packing_date" },
    { label: "Shipping Date", key: "shipping_date" },
    { label: "Receiveing Date", key: "receiving_date" },
    { label: "Packing Remarks", key: "packing_remarks" },
    { label: "Receiving Remarks Remarks", key: "receiving_remarks" },
    { label: "Shipping Remarks", key: "shipping_remarks" },
  ];
  const csvData = ibtData.map((item) => {
    return {
      ASN: item?.ASN || "----",
      source: item?.source || "----",
      destination: item?.destination || "----",
      packedQTY:
        item?.items?.filter((item) => item.process_status === "packed")
          .length || "----",
      tanfersQTY:
        item?.items?.filter((item) => item.process_status === "shipped")
          .length || "----",
      RecievedQTY:
        item?.items?.filter((item) => item.process_status === "received")
          .length || "----",
      status: item?.event_ref_id?.endsWith("01")
        ? "packing"
        : item?.event_ref_id?.endsWith("02")
        ? "shipping"
        : "receiving" || "----",
      packing_date: item?.packing_date || "----",
      shipping_date: item?.shipping_date || "----",
      receiving_date: item?.receiving_date || "----",
      packing_remarks: item?.packing_remarks || "----",
      receiving_remarks: item?.receiving_remarks || "----",
      shipping_remarks: item?.shipping_remarks || "----",
    };
  });
  //         source
  // destination
  // packing_date
  // shipping_date
  // receiving_date
  // packing_remarks
  // recieveing_remarks
  // shipping_remarks
  const handlePageClick = (event) => {
    console.log(event.selected);
    const newOffset =
      (event.selected % pageData?.next) + pageData?.previous + 1;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log(`totalLength ${pageData?.next + pageData?.previous + 1}`);
    setItemOffset(newOffset);

    fetchData(event.selected + 1);
  };

  return (
    <>
      <Loader loading={loading} />
      <iframe id="printf" name="printf" style={{ display: "none" }}></iframe>
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="stock-count" style={{ fontSize: "25px", margin: 0 }}>
              Supply Chain (INBOUND)
            </p>
            <CSVLink
              filename="SupplyChain/Rtv/PO"
              data={csvData}
              headers={headers}
            >
              <GrDocumentCsv
                color="white"
                fill="white"
                fontSize={25}
                style={{ marginRight: 5, cursor: "pointer" }}
              />
            </CSVLink>
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              gridColumnGap: "20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                gridColumnGap: "10px",
                gridRowGap: "10px",
                flexWrap: "wrap",
              }}
            >
              <DesktopDatePicker
                label="From Date"
                clearable
                showTodayButton
                inputFormat="DD/MM/YYYY"
                size={"small"}
                value={toDate}
                onChange={(data) => setToDate(data)}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <DesktopDatePicker
                label="To Date"
                inputFormat="DD/MM/YYYY"
                size={"small"}
                value={fromDate}
                onChange={(data) => setFromDate(data)}
                renderInput={(params) => (
                  <TextField sx={{ width: 170 }} size={"small"} {...params} />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={ibtDataFilter
                  ?.filter((item) => item?.po_number !== "")
                  .map((item) => item?.po_number || item?.ASN)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                onInputChange={(e) =>
                  setinvoiceNumberVal(
                    e.target.value === 0 || e.target.value === undefined
                      ? ""
                      : e.target.value
                  )
                }
                onChange={(e, v) =>
                  setinvoiceNumberVal(v === null || v === undefined ? "" : v)
                }
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Asn" />}
              />

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={stores
                  ?.map((item) => item?.storecode)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                // onInputChange={(e) => setSource(e.target.value === 0 || e.target.value === undefined ? '' : e.target.value)}
                onChange={(e, v) =>
                  setSource(v === null || v === undefined ? "" : v)
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="Source" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={"small"}
                options={stores
                  ?.map((item) => item?.storecode)
                  .reduce((noDupArr, entry) => {
                    if (noDupArr.includes(entry)) {
                      return noDupArr;
                    } else {
                      return [...noDupArr, entry];
                    }
                  }, [])}
                // onInputChange={(e) => setDestination(e.target.value === 0 || e.target.value === undefined ? '' : e.target.value)}
                onChange={(e, v) =>
                  setDestination(v === null || v === undefined ? "" : v)
                }
                sx={{ width: 170 }}
                renderInput={(params) => (
                  <TextField {...params} label="Destination" />
                )}
              />
            </div>

            <button
              onClick={() =>
                // searchData()
                // fetchDataWithParams()
                fetchData()
              }
              className="run-btn"
            >
              <p className="run-text">Search</p>
            </button>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table data-table-theme="dark zebra">
              <thead>
                <tr style={{ background: "#373E43" }}>
                  <th>ASN</th>
                  <th>Source</th>
                  <th>Destination</th>
                  {/* <th>Packed Qty</th>
                                    <th>Shipped Qty</th> */}
                  <th>Received Qty </th>
                  <th>Status </th>
                  {/* <th>Packing Date </th>
                                    <th>Packing User </th>
                                    <th>Packing Remarks </th>
                                    <th>Shipping Date </th>
                                    <th>Shipping User </th>
                                    <th>Shipping Remarks </th> */}
                  <th>Receiving Date </th>
                  <th>Receiving User </th>
                  <th>Receiving Remarks </th>
                </tr>
              </thead>
              <tbody>
                {ibtData.map((data, index) => (
                  <tr>
                    {data?.ASN ? (
                      <td>{data?.ASN?.replaceAll("|undefined", "")}</td>
                    ) : (
                      <td>
                        {" "}
                        {data?.po_number ? data?.po_number : ""}
                        {/* {data?.po_number}|{data?.invoice_number} */}
                      </td>
                    )}
                    <td>{data?.source}</td>
                    <td>{data?.destination}</td>
                    <td>
                      <NavLink
                        to="/viewibtdetails"
                        state={{
                          data: data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ),
                          asn: data?.po_number,
                          date: data?.packing_date,
                        }}
                      >
                        {
                          data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ).length
                        }
                      </NavLink>
                    </td>
                    <td>
                      <NavLink
                        to="/viewibtdetails"
                        state={{
                          data: data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ),
                          date: data?.shipping_date,
                        }}
                      >
                        {
                          data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ).length
                        }
                      </NavLink>
                    </td>
                    <td>
                      <NavLink
                        to="/viewibtdetails"
                        state={{
                          data: data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ),
                          asn: data?.po_number,
                          date: data?.receiving_date,
                        }}
                      >
                        {
                          data?.items?.filter(
                            (item) =>
                              item.process_status === "packed" ||
                              item.process_status === "shipped" ||
                              item.process_status === "received"
                          ).length
                        }
                      </NavLink>
                    </td>
                    {/* <td><NavLink to='/viewibtdetails' state={{ data: data?.items?.filter((item => item.process_status === 'shipped')), date: data?.shipping_date }} >{data?.items?.filter((item => item.process_status === 'shipped')).length}</NavLink></td>
                                            <td><NavLink to='/viewibtdetails' state={{ data: data?.items?.filter((item => item.process_status === 'received')), date: data?.receiving_date, asn: data?.po_number }} >{data?.items?.filter((item => item.process_status === 'received')).length}</NavLink></td> */}
                    <td>
                      {data?.items.length !== 0
                        ? data?.items[0]?.process_status
                        : ""}
                    </td>
                    <td>{data?.packing_date}</td>
                    <td>{data?.PackingUser}</td>
                    <td>{data?.packing_remarks}</td>
                    <td>{data?.shipping_date}</td>
                    <td>{data?.ShippingUser}</td>
                    <td>{data?.shipping_remarks}</td>
                    <td>{data?.receiving_date}</td>
                    <td>{data?.ReceivingUser}</td>
                    <td>{data?.receiving_remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            className="ul-main"
            activeClassName="active-table-btn"
            activeLinkClassName="active-table-btn-link"
            previousClassName="previous-table-btn"
            pageLinkClassName="page-link-btn"
            nextClassName="next-table-btn"
            previousLinkClassName="previous-table-btn-link"
            nextLinkClassName="previous-table-btn-link"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageData?.next + pageData?.previous + 1}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default Transfers;
