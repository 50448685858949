import React, { Component } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { AiOutlineMenuUnfold } from "react-icons/ai"
import { withRouter } from "../../components/withRouter";
import { activities, getDevices, getStores, getUsers, totalDevices, totalscore, totalUsers } from "../../Path/Path";
import Loader from "../../components/Loader";
class Admin extends Component {
    state = {
        data: "",
        score: 0,
        users: 0,
        devices: 0,
        activities: [],
        loading: false,
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const score = await getStores();
        const users = await getUsers();
        const devices = await getDevices();
        this.setState({
            score: score?.length, users: users?.length, devices: devices?.length, activities: 'dsfgsdfgsdfgsdfgsdfgsdfgsdfg'
        })

        this.setState({ loading: false })


    }

    render() {
        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="admin-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Dashboard</p></div>

                    </div>
                    <div className="admin-bottom">
                        <div className="box-1">
                            <div>
                                <div onClick={() => window.location.href = '/store'} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                                    <div>  <p className="total-score">Total Score</p></div>
                                    <div className="circle"><p className="plus">+</p></div>
                                </div>
                                <p className="number">{this.state.score}</p>
                            </div>
                        </div>
                        <div className="box-1">
                            <div>
                                <div onClick={() => window.location.href = '/users'} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                                    <div>  <p className="total-score">Total Users</p></div>
                                    <div className="circle"><p className="plus">+</p></div>
                                </div>
                                <p className="number">{this.state.users}</p>
                            </div>
                        </div>
                        <div className="box-1">
                            <div>
                                <div onClick={() => window.location.href = '/devices'} style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
                                    <div>  <p className="total-score">Total Devices</p></div>
                                    <div className="circle"><p className="plus">+</p></div>
                                </div>
                                <p className="number">{this.state.devices}</p>
                            </div>
                        </div>
                    </div>
                    <div className="admin-bottom">
                        <div className="box-2">
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>  <p className="total-score">Activities</p></div>
                                    <AiOutlineMenuUnfold color="white" size={35}></AiOutlineMenuUnfold>
                                </div>
                                {
                                    Array(8).fill().map(e =>
                                        <p className="long-data">22-8-17 01:01:39:55--admin--Login succesfully--loginType--login--Device ID--StoreID--RetailCycleCountID</p>
                                    )
                                    // this.state.activities.map(e =>
                                    //     <p className="long-data">{Object.values(e).filter(Boolean).toString()}</p>
                                    // )
                                }
                            </div>
                        </div>
                        <div className="box-3">
                            <p className="last">Last Scan</p>
                            <div style={{ paddingLeft: "3%" }}>
                                <button className="select-store-btn"><p className="select-store-text">Select Store ID</p></button>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div >
                                        <p className="matching">Matching</p>
                                    </div>
                                    <div>
                                        <p className="zero">0%</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div >
                                        <p className="matching">Overs</p>
                                    </div>
                                    <div>
                                        <p className="zero">0%</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div >
                                        <p className="matching">Unders</p>
                                    </div>
                                    <div>
                                        <p className="zero">0%</p>
                                    </div>
                                </div>
                                <div className="red-line"></div>
                                <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div>
                                            <p className="small-text">Stock in Hnad</p>
                                        </div>
                                        <div>
                                            <p className="small-text">Back Store</p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                                        <div>
                                            <p className="small-text-zero">0</p>
                                        </div>
                                        <div>
                                            <p className="small-text-zero">0</p>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ paddingLeft: "8%", paddingRight: "8%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div>
                                            <p className="small-text">Stock in Hnad</p>
                                        </div>
                                        <div>
                                            <p className="small-text">Back Store</p>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <div>
                                            <p className="small-text-zero">0</p>
                                        </div>
                                        <div>
                                            <p className="small-text-zero">0</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(Admin)