import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export function CustomModal({ children, heading, onClose, onSave,open }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal size={'xxl'} centered show={open} onHide={handleClose}>
                <Modal.Header style={{ backgroundColor: '#D3D8DB' }} closeButton={true}>
                    <Modal.Title style={{ color: 'black' }}>{heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: '300px' }}>
                    <Form>
                        {children}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

