import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getBinLocation } from "../Path/Path";
import _ from "lodash";
import Papa from "papaparse";
import { withRouter } from "../components/withRouter";
import moment from "moment";
import { ImCross } from "react-icons/im";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../components/Loader";

const BinLocations = () => {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [orderDataFilter, setOrderDataFilter] = useState(null);
  const [binLocationData, setBinLocationData] = useState(null);
  const [itemsData, setItemsData] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    gettingOrders();
  }, []);

  const gettingOrders = async (page = 1) => {
    setLoading(true);
    let resp = await getBinLocation(page);
    setOrderData(resp?.data);
    setOrderDataFilter(resp?.data);
    setLoading(false);
  };

  const onShowM = (data) => {
    setBinLocationData(data?.bins);
    setItemsData(data?.items);
    setOrderNumber(data?.orderNumber);
  };

  const handleDownloadCSV = () => {
    if (!orderNumber || !itemsData) return;
    const csvData = itemsData?.map((item) => {
      return {
        "Date and Time": moment(item?.createdAt).format(
          "MM-DD-YYYY hh:mm:ss A"
        ),
        Order: orderNumber,
        Bin: item?.binNumber?.toUpperCase(),
        Barcode: `="${item.SKU}"`,
        Quantity: item.Quantity,
        Pallet: item?.pallet ?? "N/A",
        ASN: `="${item?.warehouseASN}"` ?? "N/A",
        User: item?.PackingUser ?? "N/A",
      };
    });
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `Order-${orderNumber}-SKU-Report.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onSearchOrderData = (e) => {
    if (e.length > 0) {
      let data = orderDataFilter?.filter((f) =>
        f?.orderNumber?.toLowerCase()?.includes(e?.toLowerCase())
      );
      setOrderData(data);
    } else {
      setOrderData(orderDataFilter);
    }
  };
  return (
    <>
      <Loader loading={loading} />
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="stock-count" style={{ fontSize: "25px", margin: 0 }}>
              Bin Location
            </p>
          </div>
          <div>
            <h1 style={{ color: "black", fontSize: "15px" }}>
              {new Date().toLocaleString("hi-IN")}
            </h1>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          overflowX: "scroll",
          height: "100%",
          width: "95%",
          marginLeft: "30px",
        }}
      >
        {orderData && !binLocationData && (
          <>
            <TextField
              sx={{ width: 235, marginTop: 5 }}
              defaultValue={searchText}
              value={searchText}
              placeholder="Search"
              size={"small"}
              onChange={(data) => {
                setSearchText(data.target.value);
                onSearchOrderData(data.target.value);
              }}
              id="outlined-basic"
              label="Search"
              variant="outlined"
            />
            <table style={{ marginTop: 20 }} data-table-theme="dark zebra">
              <thead>
                <tr style={{ background: "#373E43" }}>
                  <th>Order Number</th>
                  <th>Total Number of Items</th>
                </tr>
              </thead>
              <tbody>
                {orderData?.map((data, index) => (
                  <tr key={index}>
                    <td>{data?.orderNumber}</td>
                    <td>
                      <NavLink
                        onClick={() => {
                          onShowM(data);
                        }}
                      >
                        {data?.totalItems}
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {binLocationData && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                className="run-text"
                disabled={itemsData?.length === 0 || !itemsData}
                style={{
                  fontSize: "1.2em",
                  backgroundColor: "#0069D9",
                  color: "white",
                  padding: "10px 20px",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
                onClick={handleDownloadCSV}
              >
                <p className="run-text">Download CSV</p>
              </button>

              <ImCross
                onClick={() => setBinLocationData(null)}
                style={{}}
                size={26}
              ></ImCross>
            </div>
            <table data-table-theme="dark zebra">
              <thead>
                <tr style={{ background: "#373E43" }}>
                  <th>Bin Number</th>
                  <th>Total Number of Items in Bin</th>
                </tr>
              </thead>
              <tbody>
                {binLocationData?.map((bin, index) => (
                  <tr key={index}>
                    <td>{bin?.binNumber?.toUpperCase()}</td>
                    <td>{bin?.totalNumberOfItemsInBin}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(BinLocations);
