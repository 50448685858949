import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';


const PermissionsModal = ({ open, onClose, onClick, roles_name, permissions = [], data }) => {
    return (
      <Dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="dialog-title">{roles_name}</DialogTitle>
        <DialogContent>
          <div style={{ minHeight: "400px", width: "400px" }}>
            {permissions.map((elem) => (
              <p style={{ color: "#000" }}>{elem}</p>
            ))}

            {/* <p style={{ color: "#000" }}>{`EPC : ${data?.epc}`}</p>
                    <p style={{ color: "#000" }}>{`Item_Code : ${data?.item_code}`}</p>
                    <p style={{ color: "#000" }}>{`Store : ${data?.store_id}`}</p>
                    <p style={{ color: "#000" }}>{`Brand Name : ${data?.brand_name}`}</p>
                    <p style={{ color: "#000" }}>{`Color : ${data?.color}`}</p>
                    <p style={{ color: "#000" }}>{`Size : ${data?.size}`}</p>
                    <p style={{ color: "#000" }}>{`Item Dispostion : ${data?.ItemDispostion}`}</p> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick}>Close</Button>
        </DialogActions>
      </Dialog>
    );
}

export default PermissionsModal
