import React, { Component } from "react";
import { deleteUser, getUsers } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import { toast } from "react-toastify";
import Loader from '../../components/Loader';
import { Autocomplete, TextField } from "@mui/material";
import jwtDecode from "jwt-decode";
import AlertDialog from "../../components/AlertDialog";
import { PaginatedItemsOffline } from "../../components/PaginatedOffline";

class Users extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            tableFilter: [],
            tableFilterFilters: [],
            name: "",
            username: "",
            itemsPerPage: 10,
            forceData: false,
            loading: false,
            deleteModal: null,
            userName: ""
        }
    }

    async componentDidMount() {
        const user = await localStorage.getItem('loginData')
        const parseData = JSON.parse(user)
        let data = jwtDecode(parseData.token)
        this.setState({ userName: data?.payload?.userName }, () => {
            this.saveNewUsersToState()
        })
        this.setState({ forceData: true })


    }


    saveNewUsersToState = async () => {
        let { userName } = this.state
        const user = await localStorage.getItem('loginData')
        const parseData = JSON.parse(user)
        let data = jwtDecode(parseData.token)
        this.setState({ loading: true })
        const userinfo = await getUsers();
        console.log(userinfo, "userinfo")
        await this.setState({
            data: userinfo,
            userinfo,
            tableFilter: userinfo?.reverse(),
            tableFilterFilters: userinfo
        })
        await this.setState({ loading: false })
    }

    handler = () => {
        this.props.history("/adduser")
    }

    deleteHandler = async (myID) => {

        try {

            await deleteUser(myID);
            this.saveNewUsersToState();
            toast.success("User deleted successfully")
            this.setState({ deleteModal: null })
        } catch (err) {
            toast.warn(err.message)

        }
    }

    editHandler = (item) => {

        this.props.history("/adduser", { state: { item } })
    }


    searchHandler = () => {

        this.setState({ forceData: false, forceData: !this.state.forceData })
        if (!this.state.name && !this.state.username) {
            this.setState({ tableFilter: this.state.data })
            this.setState({ forceData: true })
            return
        }
        const res = this.state.data.filter(i => i.name.toLowerCase().includes(this.state.name.toLowerCase()))

        const res1 = res.filter(i => i.userName.toLowerCase().includes(this.state.username.toLowerCase()))

        this.setState({ tableFilter: res1 })
        if (res1?.length === 0) {
            toast.error("Data not found")
            this.setState({ forceData: true })
        }

    }




    render() {
        const headers = [

            "Name",
            "Username",
            "Sites",
            "Role",
        ]

        const colNames = [

            "name",
            "userName",
            "multipleSites",
            "roleId.role_name",
        ]

        return (
            <>
                <AlertDialog
                    deleteThis="User"
                    onDeletePress={() => this.deleteHandler(this.state?.deleteModal)}
                    open={this.state.deleteModal ? true : false}
                    handleClose={() => this.setState({ deleteModal: null })}
                />
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div div className="dashboard-top" >
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>Users Info</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        <button onClick={() => this.handler()} className="run-btn"><p className="add-text" >Add Users</p></button>
                    </div>
                    <div className="users-bottom">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "500px" }}>
                            {/* <p className="filter-text">Filters</p> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilterFilters?.map((item => item?.name)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null))}
                                onChange={(e, v) => this.setState({ name: v === null || v === undefined ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Name" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.tableFilterFilters?.map((item => item?.userName)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null))}
                                onChange={(e, v) => this.setState({ username: v === null || v === undefined ? '' : v })}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Username" />}
                            />
                        </div>
                        <button onClick={() => this.searchHandler()} className="run-btn"><p className="run-text" >Search</p></button>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        {/* {this.state.forceData ? */}

                        <PaginatedItemsOffline pageChanged={this.state.forceData} Action headers={headers} editHandler={(item) => this.editHandler(item)} deleteHandler={(item) => this.setState({ deleteModal: item._id })} noQRCode colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.tableFilter?.map((item => { return { ...item, multipleSites: JSON.parse(item?.status)?.toString() === "1" || JSON.parse(item?.status)?.toString() === "0" ? item?.siteId?.storename : JSON.parse(item?.status)?.toString() } }))} />
                        {/* : null */}
                        {/* } */}

                    </div>
                </div >
            </>
        )
    }
}

export default withRouter(Users);