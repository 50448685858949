import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput"
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { getCountedPerStore, getSohPerStore, getStoreName, localServer } from "../Path/Path";
import { PaginatedItems } from "../components/Paginated";
import BarGraph from "../Graphs/BarGraph";
import { getCustomItem, getSoh } from "../Path/Path";
import _ from "lodash";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { TextField } from "@mui/material";
import axios from "axios";
import { withRouter } from "../components/withRouter";
  class OverDetails extends Component {
    state = {
        data1: [],
        item: [],
        operationItems: [{
            Store: '00125',
            Department: "750",
            Date: '22-09-13',
            Accuracy: "0.00",
            UIA: "0.00",
            Expected: "39",
            Counted: "0",
            Overs: "0",
            Unders: "-0.39",
            ExpectedSF: "0",
            ExpectedSR: "0",
            Department: "perfume",
            Brand: "136-DG Men",
            Diff: "13"
        }],
        loading: false,
        SKU_Over: '',
        SKU_Real: '',
        SKU_Stock: '',
        data: "",
        storeName: [],
        dropdata: "",
        filter: [],
        mydate: "",
        visible: false,
        itemsPerPage: 10,
        csvDataReal: [],
        csvDataOvers: [],
        csvDataStock: [],
        csvDataRealFake: [],
        csvDataOversFake: [],
        csvDataStockFake: [],
        stats: {
            groupedSKU: [],
            dataOrderWise: [],
            uniqueArray: [],
        }
    }
    searchDataReal = () => {
        console.log("workign");
        const filterArray = this.state.csvDataRealFake.filter((item => item?.SKU?.includes(this.state.SKU_Real)))
        console.log(filterArray);
        this.setState({ csvDataReal: filterArray })
    }
    async componentDidMount() {
        this.setState({ loading: true })
        const getDescripency = await this.props?.location?.state?.data?.oversData ||  []
       
        this.setState({ csvDataReal: getDescripency, csvDataRealFake: getDescripency })
        this.setState({ loading: false })
    }
    replaceAll = (str, find, replace) => {
        var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }
    render() {
    
        const headers = [
            "Product Number",
            "Barcode",
            "Department Id",
            "Description",
            "Expected",
            "Overs",
            'Matched',
            "Scanned"


        ]
        const colNames = [
            "SOHSKU",
            "barcode",
            "DepartmentID",
            "Description",
            "SOHQTY",
            "overs",
            'matched',
            "Scanned"

        ]
        const csvDataReal = this.state.csvDataReal.map((item => {
            return { ...item,  Scanned: item?.SOHQTY + item?.overs, barcode: item?.SKU?.replaceAll(";", '-') }
        })) || []
        const csvDataOvers = this.state.csvDataOvers || []
        const csvDataStock = this.state.csvDataStock || []
        return (
            <>
                <Loader loading={this.state.loading} />
                <div>

                    <div className="dashboard-main">
                        <div className="dashboard-top">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>{this.props?.location?.state?.heading}</p>

                                <CSVLink
                                    filename='Overs'
                                    data={csvDataReal}
                                    headers={colNames}
                                >

                                    <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />

                                </CSVLink>

                            </div>


                        </div>
                        <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 20, marginBottom: 20, padding: '15px' }}>
                            <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>

                                <TextField
                                    sx={{ width: 235 }}
                                    defaultValue={this.state.SKU_Real}
                                    value={this.state.SKU_Real}
                                    placeholder="SKU"
                                    size={'small'}
                                    onChange={(data) => this.setState({ SKU_Real: data.target.value })}
                                    id="outlined-basic" label="SKU" variant="outlined"
                                />
                            </div>

                            <button
                                onClick={() =>
                                    this.searchDataReal()
                                    // fetchDataWithParams()
                                }
                                className="run-btn"><p className="run-text">Search</p></button>
                        </div>

                        <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={100} data={this.state.csvDataReal.map((item => {
                            return { ...item,  Scanned: item?.SOHQTY + item?.overs, barcode: item?.SKU?.replaceAll(";", '-') }
                        }))} />

                    </div>
                </div>

            </>
        )
    }
}
export default withRouter(OverDetails)