import React, { useState } from "react";
import reactlogo from "../assets/react.png";
import { withRouter } from "./withRouter";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { FiHome } from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineCalculate } from "react-icons/md";
import { AiTwotoneTag } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { RiShutDownLine } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { IoIosFlower } from "react-icons/io";
import { FaTshirt } from "react-icons/fa";
import { MdOutlineTransferWithinAStation } from "react-icons/md";
import "../css/sidebar.css";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSub, setIsOpenSub] = useState(false);
  const [isOpenSub1, setIsOpenSub1] = useState(false);
  const [isOpenSub2, setIsOpenSub2] = useState(false);
  const [isOpenSub3, setIsOpenSub3] = useState(false);
  const [isOpenSub4, setIsOpenSub4] = useState(false);
  const [isOpenSub5, setIsOpenSub5] = useState(false);
  const [isOpenHome, setIsOpenHome] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggle2 = () => {
    setIsOpenSub(!isOpenSub);
    setIsOpenSub1(false);
    setIsOpenSub2(false);
    setIsOpenHome(false);
    setIsOpenSub3(false);
    setIsOpenSub4(false);
    setIsOpenSub5(false);
  };
  const toggle3 = () => {
    setIsOpenSub1(!isOpenSub1);
    setIsOpenSub(false);
    setIsOpenSub2(false);
    setIsOpenHome(false);
    setIsOpenSub3(false);
    setIsOpenSub4(false);
    setIsOpenSub5(false);
  };
  const toggle4 = () => {
    setIsOpenSub2(!isOpenSub2);
    setIsOpenSub(false);
    setIsOpenSub1(false);
    setIsOpenHome(false);
    setIsOpenSub3(false);
    setIsOpenSub4(false);
    setIsOpenSub5(false);
  };
  const toggleHome = () => {
    setIsOpenHome(!isOpenHome);
    setIsOpenSub(false);
    setIsOpenSub1(false);
    setIsOpenSub2(false);
    setIsOpenSub3(false);
    setIsOpenSub4(false);
    setIsOpenSub5(false);
  };
  const toggle5 = () => {
    setIsOpenSub3(!isOpenSub3);
    setIsOpenSub(false);
    setIsOpenSub1(false);
    setIsOpenSub2(false);
    setIsOpenHome(false);
    setIsOpenSub4(false);
    setIsOpenSub5(false);
  };
  const toggle6 = () => {
    setIsOpenSub4(!isOpenSub4);
    setIsOpenSub(false);
    setIsOpenSub1(false);
    setIsOpenSub2(false);
    setIsOpenHome(false);
    setIsOpenSub3(false);
    setIsOpenSub5(false);
  };
  const toggle7 = () => {
    setIsOpenSub5(!isOpenSub5);
    setIsOpenSub(false);
    setIsOpenSub1(false);
    setIsOpenSub2(false);
    setIsOpenHome(false);
    setIsOpenSub3(false);
    setIsOpenSub4(false);
  };

  const [permissions, setPermissions] = useState([]);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    async function getLoginData(params) {
      const data = await localStorage.getItem("loginData");
      const parseData = JSON.parse(data);
      const token = parseData?.token;
      if (token) {
        const decodetoken = jwtDecode(token);
        console.log(decodetoken?.payload);
        setPermissions(
          decodetoken?.payload?.roleId?.permissions
            ? decodetoken?.payload?.roleId?.permissions
            : []
        );
        setUserName(decodetoken?.payload?.name);
      }
    }
    getLoginData();
  }, []);

  const path = window.location.pathname;
  const logOut = async () => {
    await localStorage.setItem("login", null);
    await localStorage.setItem("loginData", null);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div className="container-2" style={{ width: isOpen ? "300px" : "80px" }}>
      <div
        className="sidebar-menu"
        style={{ width: isOpen ? "300px" : "80px" }}
      >
        <div className="top-section">
          <div className="logo">
            <img src={reactlogo} alt="logo" onClick={toggle} />
          </div>

          <div className="bar animation" ani={isOpen ? 0 : 1}>
            {isOpen && (
              <RiBarChartHorizontalLine
                className="bars"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <h4 style={{ color: "white", fontSize: "14px", textAlign: "center" }}>
            {userName}
          </h4>
        </div>
        <div className="main-menu">
          <ul>
            <li>
              <div className="routes" onClick={() => props.history("/admin")}>
                <div
                  className="menu-item"
                  ani={isOpen ? 0 : 1}
                  style={{ color: isOpenHome ? "#0069D9" : "#fff" }}
                  onClick={toggleHome}
                >
                  <div className="flex">
                    <div className="menu-icon">
                      <FiHome />
                    </div>
                    {isOpen && (
                      <span
                        className="adjust animation-1"
                        ani-1={isOpen ? 0 : 1}
                      >
                        Home
                      </span>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </li>
            <li>
              <div className="routes">
                <div
                  className="menu-item"
                  style={{ color: isOpenSub ? "#0069D9" : "#fff" }}
                  onClick={isOpen ? toggle2 : null}
                >
                  <div className="flex">
                    <div className="menu-icon" onClick={toggle2}>
                      <RiAdminLine />
                    </div>
                    {isOpen && (
                      <span
                        className="adjust animation-1"
                        ani-1={isOpen ? 0 : 1}
                      >
                        Admin
                      </span>
                    )}
                  </div>
                  {isOpen && (
                    <span className="animation-1" ani-1={isOpen ? 0 : 1}>
                      <AiFillCaretDown />
                    </span>
                  )}
                </div>
              </div>
              {isOpenSub && isOpen && (
                <ul
                  className="sub-menu animation-2"
                  ani-2={isOpenSub ? 0 : 1}
                  style={{
                    borderLeft: isOpen
                      ? "1px solid rgba(206, 196, 196, 0.223)"
                      : "0px",
                    height: isOpen ? "100%" : "0%",
                  }}
                >
                  {/* <li onClick={() => props.history('/dashboard')}>
                                        <div className={path === "/dashboard" ? "menu-item-1-active" : "menu-item-1"}  >
                                            <span>Dashboard</span>
                                        </div>
                                    </li> */}
                  {/* {permissions.includes("Users") ? */}
                  <li onClick={() => props.history("/users")}>
                    <div
                      className={
                        path === "/users" ? "menu-item-1-active" : "menu-item-1"
                      }
                    >
                      <span>Users</span>
                    </div>
                  </li>
                  {/* : null} */}
                  {/* {permissions.includes("Devices") ? */}
                  <li onClick={() => props.history("/devices")}>
                    <div
                      className={
                        path === "/devices"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span>Devices</span>
                    </div>
                  </li>
                  {/* : null} */}
                  {/* <li onClick={() => props.history('/transfercancellation')}>
                                        <div className={path === "/transfercancellation" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Transfer Cancellation</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/gicancellation')}>
                                        <div className={path === "/gicancellation" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Gi Cancellation</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/manualrecivingibt')}>
                                        <div className={path === "/manualrecivingibt" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Manual Reciving Ibt</span>
                                        </div>
                                    </li> */}
                  {/* {permissions.includes("Stores") ? */}
                  <li onClick={() => props.history("/store")}>
                    <div
                      className={
                        path === "/store" ? "menu-item-1-active" : "menu-item-1"
                      }
                    >
                      <span>Stores</span>
                    </div>
                  </li>
                  {/* : null} */}
                  {/* {permissions.includes("Zones") ?
                                        <li onClick={() => props.history('/zones')}>
                                            <div className={path === "/zones" ? "menu-item-1-active" : "menu-item-1"}>
                                                <span>Zones</span>
                                            </div>
                                        </li>
                                        : null} */}
                  {/* <li onClick={() => props.history('/printerinfo')}>
                                        <div className={path === "/printerinfo" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Printer</span>
                                        </div>
                                    </li> */}
                  {/* {permissions.includes("ZPL") ?
                                        <li onClick={() => props.history('/zpl')}>
                                            <div className={path === "/zpl" ? "menu-item-1-active" : "menu-item-1"}>
                                                <span>ZPL</span>
                                            </div>
                                        </li>
                                        : null} */}
                  {/* <div onClick={() => props.history('/asnCancelation')} style={{ width: "200px", cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <TbUsers size={20} color="white"></TbUsers>
                                        <p className='header-text'>Asn Cancelation</p>
                                    </div> */}
                  {/* {permissions.includes("AsnCancelation") ?
                                        <li onClick={() => props.history('/asnCancelation')}>
                                            <div className={path === "/asnCancelation" ? "menu-item-1-active" : "menu-item-1"}>
                                                <span>Asn Cancelation</span>
                                            </div>
                                        </li>
                                        : null} */}
                  {/* <li onClick={() => props.history('/audit')}>
                                        <div className={path === "/audit" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Audit</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/ibtdifferences')}>
                                        <div className={path === "/ibtdifferences" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>IBT Differences</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/adminmenu')}>
                                        <div className={path === "/adminmenu" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>Admin Menu</span>
                                        </div>
                                    </li> */}
                  {/* {permissions.includes("Roles") ? */}
                  <li onClick={() => props.history("/roles")}>
                    <div
                      className={
                        path === "/roles" ? "menu-item-1-active" : "menu-item-1"
                      }
                    >
                      <span>Roles</span>
                    </div>
                  </li>
                  {/* : null} */}
                </ul>
              )}
            </li>
            <li>
              {/* <div className='routes'  >
                                <div className="menu-item" style={{ color: isOpenSub1 ? "#0069D9" : "#fff" }} onClick={isOpen ? toggle3 : null}>
                                    <div className='flex'>
                                        <div className='menu-icon' onClick={toggle3}><MdOutlineCalculate /></div>
                                        {isOpen && <span className="adjust animation-1" ani-1={isOpen ? 0 : 1}>Count</span>}
                                    </div>
                                    {isOpen && <span className='animation-1' ani-1={isOpen ? 0 : 1}><AiFillCaretDown /></span>}
                                </div>
                            </div> */}

              {isOpenSub1 && isOpen && (
                <ul
                  className="sub-menu animation-2"
                  ani-2={isOpenSub1 ? 0 : 1}
                  style={{
                    borderLeft: isOpen
                      ? "1px solid rgba(206, 196, 196, 0.223)"
                      : "",
                  }}
                >
                  {permissions.includes("Count Dashboard") ? (
                    <li onClick={() => props.history("/supplychain")}>
                      <div
                        className={
                          path === "/supplychain"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span className="left">Dashboard</span>
                      </div>
                    </li>
                  ) : null}

                  {/* <li onClick={() => props.history('/varience')}>
                                        <div className={path === "/varience" ? "menu-item-1-active" : "menu-item-1"}>
                                           
                                            <span className='left'>Varience Report</span>
                                        </div>
                                    </li> */}
                  {permissions.includes("Operation") ? (
                    <li onClick={() => props.history("/operation")}>
                      <div
                        className={
                          path === "/operation"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span className="left">Operation</span>
                      </div>
                    </li>
                  ) : null}
                  {/* <li onClick={() => props.history('/operation0')}>
                                        <div className={path === "/operation0" ? "menu-item-1-active" : "menu-item-1"}>
                                            <IoIosFlower />
                                            <span className='left'>Operation 0</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/inventory')}>
                                        <div className={path === "/inventory" ? "menu-item-1-active" : "menu-item-1"}>
                                            <FaTshirt />
                                            <span className='left'>Inventory(EPC)</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/dailystockcountreport')}>
                                        <div className={path === "/dailystockcountreport" ? "menu-item-1-active" : "menu-item-1"}>
                                            <FaTshirt />
                                            <span className='left'>Daily Stock Count</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/overallunderover')}>
                                        <div className={path === "/overallunderover" ? "menu-item-1-active" : "menu-item-1"}>
                                            <FaTshirt />
                                            <span className='left'>Over all Under Over</span>
                                        </div>
                                    </li> */}
                </ul>
              )}
            </li>
            <li>
              <div className="routes">
                <div
                  className="menu-item"
                  style={{ color: isOpenSub4 ? "#0069D9" : "#fff" }}
                  onClick={isOpen ? toggle6 : null}
                >
                  <div className="flex">
                    <div className="menu-icon" onClick={toggle6}>
                      <MdOutlineTransferWithinAStation />
                    </div>
                    {isOpen && (
                      <span
                        className="adjust animation-1"
                        ani-1={isOpen ? 0 : 1}
                      >
                        Supply Chain
                      </span>
                    )}
                  </div>
                  {isOpen && (
                    <span className="animation-1" ani-1={isOpen ? 0 : 1}>
                      <AiFillCaretDown />
                    </span>
                  )}
                </div>
              </div>

              {isOpenSub4 && isOpen && (
                <ul
                  className="sub-menu animation-2"
                  ani-2={isOpenSub4 ? 0 : 1}
                  style={{
                    borderLeft: isOpen
                      ? "1px solid rgba(206, 196, 196, 0.223)"
                      : "",
                  }}
                >
                  {permissions.includes("PO Report") ? (
                    <li onClick={() => props.history("/transfers")}>
                      <div
                        className={
                          path === "/transfers"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span className="left">INBOUND</span>
                      </div>
                    </li>
                  ) : null}
                  {/* {permissions.includes("Consignment") ?
                                        <li onClick={() => props.history('/consignement')}>
                                            <div className={path === "/consignement" ? "menu-item-1-active" : "menu-item-1"}>

                                                <span className='left'>Consignement</span>
                                            </div>
                                        </li>
                                        : null} */}
                  {/* {permissions.includes("To Report") ? (
                    <li onClick={() => props.history("/receiving")}>
                      <div
                        className={
                          path === "/receiving"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span className="left">Receiving</span>
                      </div>
                    </li>
                  ) : null}
                  {permissions.includes("To Report") ? (
                    <li onClick={() => props.history("/transfersRep")}>
                      <div
                        className={
                          path === "/transfersRep"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span className="left">Tranfers</span>
                      </div>
                    </li>
                  ) : null} */}
                  {/* {permissions.includes("To Report") ?
                                        <li onClick={() => props.history('/shipping')}>
                                            <div className={path === "/shipping" ? "menu-item-1-active" : "menu-item-1"}>

                                                <span className='left'>Shipping</span>
                                            </div>
                                        </li>
                                        : null} */}
                  {/* <li onClick={() => props.history('/transfers')}>
                                        <div className={path === "/transfers" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span className='left'>Transfers</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/instoregi')} >
                                        <div className={path === "/instoregi" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span className='left'>IN-Store GI</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/gisummary')} >
                                        <div className={path === "/gisummary" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span className='left'>Gi Summary</span>
                                        </div>
                                    </li> */}
                  {/* <li onClick={() => props.history('/warehouse')}>
                                        <div className={path === "/warehouse" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span className='left'>Warehouse GI</span>
                                        </div>
                                    </li> */}
                </ul>
              )}
            </li>
            <li>
              {/* <div className='routes'>
                                <div className="menu-item" style={{ color: isOpenSub2 ? "#0069D9" : "#fff" }} onClick={isOpen ? toggle4 : null}>
                                    <div className='flex'>
                                        <div className='menu-icon' onClick={toggle4}><AiTwotoneTag /></div>
                                        {isOpen && <span className="adjust animation-1" ani-1={isOpen ? 0 : 1}>TagIT</span>}
                                    </div>
                                    {isOpen && <span className='animation-1' ani-1={isOpen ? 0 : 1}><AiFillCaretDown /></span>}
                                </div>
                            </div> */}
              {isOpenSub2 && isOpen && (
                <ul
                  className="sub-menu animation-2"
                  ani-2={isOpenSub2 ? 0 : 1}
                  style={{
                    borderLeft: isOpen
                      ? "1px solid rgba(206, 196, 196, 0.223)"
                      : "",
                  }}
                >
                  {permissions.includes("ZPL Printer") ? (
                    <li onClick={() => props.history("/tagit")}>
                      <div
                        className={
                          path === "/tagit"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span>Tag IT</span>
                      </div>
                    </li>
                  ) : null}
                  {permissions.includes("ZPL Report") ? (
                    <li onClick={() => props.history("/zplreport")}>
                      <div
                        className={
                          path === "/zplreportsku"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span>Printed Items</span>
                      </div>
                    </li>
                  ) : null}
                  {/* <li onClick={() => props.history('/zplreportsku')}>
                                        <div className={path === "/zplreportsku" ? "menu-item-1-active" : "menu-item-1"}>
                                            <span>ZPL Report(SKU)</span>
                                        </div>
                                    </li> */}
                  {permissions.includes("Tag Printing") ? (
                    <li onClick={() => props.history("/tagprinting")}>
                      <div
                        className={
                          path === "/tagprinting"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span>Tag Printing</span>
                      </div>
                    </li>
                  ) : null}
                  {permissions.includes("Tag Printing") ? (
                    <li onClick={() => props.history("/tagprintingRtv")}>
                      <div
                        className={
                          path === "/tagprinting"
                            ? "menu-item-1-active"
                            : "menu-item-1"
                        }
                      >
                        <span>To Number Printing</span>
                      </div>
                    </li>
                  ) : null}
                </ul>
              )}
            </li>
            <li>
              {/* <div className='routes' onClick={() => props.history('/production')}>
                                <div className="menu-item" style={{ color: isOpenSub3 ? "#0069D9" : "#fff" }} onClick={isOpen ? toggle5 : null}>
                                    <div className='flex'>
                                        <div className="menu-icon" onClick={toggle5}><AiTwotoneTag /></div>
                                        {isOpen && <span className="adjust animation-1" ani-1={isOpen ? 0 : 1}>Productionzation</span>}
                                    </div>
                                    {isOpen && <span className='animation-1' ani-1={isOpen ? 0 : 1}><AiFillCaretDown /></span>}
                                </div>
                            </div> */}
              {isOpenSub3 && isOpen && (
                <ul
                  className="sub-menu animation-2"
                  ani-2={isOpenSub3 ? 0 : 1}
                  style={{
                    borderLeft: isOpen
                      ? "1px solid rgba(206, 196, 196, 0.223)"
                      : "",
                  }}
                >
                  <li onClick={() => props.history("/production/qrcode")}>
                    <div
                      className={
                        path === "/production/qrcode"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">QR Code</span>
                    </div>
                  </li>
                  <li
                    onClick={() => props.history("/production/loginProduction")}
                  >
                    <div
                      className={
                        path === "/production/loginProduction"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Login</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/mainscreen")}>
                    <div
                      className={
                        path === "/production/mainscreen"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Main Screen</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/targetstock")}>
                    <div
                      className={
                        path === "/production/targetstock"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Target Stock</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/moreFilters")}>
                    <div
                      className={
                        path === "/production/moreFilters"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">More Filters (Cycle Count)</span>
                    </div>
                  </li>
                  <li
                    onClick={() => props.history("/production/receivingtags")}
                  >
                    <div
                      className={
                        path === "/production/receivingtags"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Receive Items</span>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      props.history("/production/receivingdetailTag")
                    }
                  >
                    <div
                      className={
                        path === "/production/receivingdetailTag"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Receive Items Details</span>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      props.history("/production/transferdetailTag")
                    }
                  >
                    <div
                      className={
                        path === "/production/transferdetailTag"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Transfer Items Details</span>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      props.history("/production/packingdetailTag")
                    }
                  >
                    <div
                      className={
                        path === "/production/packingdetailTag"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Packing Items Details</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/packingtags")}>
                    <div
                      className={
                        path === "/production/packingtags"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Packing Items</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/transfertags")}>
                    <div
                      className={
                        path === "/production/transfertags"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Transfer Out</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/encodetag")}>
                    <div
                      className={
                        path === "/production/encodetag"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Encode Tag</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/managetags")}>
                    <div
                      className={
                        path === "/production/managetags"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Manage Tag</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/devicesetup1")}>
                    <div
                      className={
                        path === "/production/devicesetup1"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Device Setup Step 1</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/cyclecount1")}>
                    <div
                      className={
                        path === "/production/cyclecount1"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Cycle Count 1</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/cyclecount2")}>
                    <div
                      className={
                        path === "/production/cyclecount2"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Cycle Count 2</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/locateitem")}>
                    <div
                      className={
                        path === "/production/locateitem"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Locate Item</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/devicesetup2")}>
                    <div
                      className={
                        path === "/production/devicesetup2"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Device Setup</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/changestatus")}>
                    <div
                      className={
                        path === "/production/changestatus"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Change Status</span>
                    </div>
                  </li>
                  <li onClick={() => props.history("/production/gistatus")}>
                    <div
                      className={
                        path === "/production/gistatus"
                          ? "menu-item-1-active"
                          : "menu-item-1"
                      }
                    >
                      <span className="left">Gi Status</span>
                    </div>
                  </li>
                </ul>
              )}
            </li>
            {/* adsfsaf */}
            <li>
              <div className="routes">
                <div
                  className="menu-item"
                  style={{ color: isOpenSub5 ? "#0069D9" : "#fff" }}
                  onClick={() => logOut()}
                >
                  <div className="flex" style={{ color: "white" }}>
                    <div
                      className="menu-icon"
                      style={{ color: "white" }}
                      onClick={toggle7}
                    >
                      <RiShutDownLine
                        color={"white"}
                        fill={"white"}
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
