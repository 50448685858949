import { TextField, Autocomplete, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { withRouter } from "../components/withRouter";
import moment from "moment";
import PermissionsModal from "../components/PermissionsModal";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";
import { toast } from "react-toastify";

const PurchaseOrderItems = () => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [gtin, setGtin] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { item, filter } = location.state || {};

  useEffect(() => {
    setItemsArray(item || []);
  }, [item]);

  const displayLength = isSearched ? filteredItems.length : itemsArray.length;

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    color: "#dddddd",
  };

  const thStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    backgroundColor: "black",
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const tdStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    color: "white",
  };

   useEffect(() => {
     if (inputValue === "" && gtin === "") {
       setFilteredItems(itemsArray);
       setIsSearched(false);
     } 
    //  else {
    //    const result = itemsArray.filter(
    //      (item) =>
    //        (inputValue !== "" && item.Article_Code === inputValue) ||
    //        (gtin !== "" && item.PO_Article_GTIN === gtin)
    //    );
    //    setFilteredItems(result);
    //    setIsSearched(true);

    //    if (result.length === 0) {
    //      toast.error("No items found.");
    //    }
    //  }
   }, [inputValue, gtin, itemsArray]);


  const searchArticleCode = () => {
    if (inputValue === "" && gtin === "") {
      toast.error("Enter value to search");
    } else {
      const result = itemsArray.filter(
        (item) =>
          (inputValue !== "" && item.Article_Code === inputValue) ||
          (gtin !== "" && item.PO_Article_GTIN === gtin)
      );
      console.log("result", result);
      setFilteredItems(result);
      setIsSearched(true);
    }
  };

  const onBarcodeClick = (e, barcode) => {
    e.preventDefault();
    const item = itemsArray.find((item) => item.PO_Article_GTIN === barcode);
    if (item && item.tagIDs) {
      setSelectedTags(item.tagIDs);
    } else {
      setSelectedTags([]);
    }
    setVisible(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleBack}
              sx={{
                backgroundColor: "blue",
                width: "50px",
                borderRadius: "5px",
                color: "white",
                textTransform: "none",
              }}
            >
              Back
            </Button>
            <p
              className="stock-count"
              style={{ fontSize: "20px", margin: "10px" }}
            >
              PO Item Details
            </p>
          </div>
        </div>
        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div>
            <p>Filters</p>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between", 
                  alignItems: "center", 
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px", 
                    flexWrap: "wrap",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    freeSolo={true}
                    size={"small"}
                    options={itemsArray
                      .filter((item) => item?.Article_Code !== "")
                      .map((item) => item.Article_Code)
                      .reduce((noDupArr, entry) => {
                        if (noDupArr.includes(entry)) {
                          return noDupArr;
                        } else {
                          return [...noDupArr, entry];
                        }
                      }, [])}
                    onChange={(e, v) => setInputValue(v === null ? "" : v)}
                    onInputChange={(e) =>
                      setInputValue(
                        e.target?.value === 0 || e.target?.value === undefined
                          ? ""
                          : e.target?.value
                      )
                    }
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Article Code" />
                    )}
                  />
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    freeSolo={true}
                    size={"small"}
                    options={itemsArray
                      .filter((item) => item?.PO_Article_GTIN !== "")
                      .map((item) => item.PO_Article_GTIN)
                      .reduce((noDupArr, entry) => {
                        if (noDupArr.includes(entry)) {
                          return noDupArr;
                        } else {
                          return [...noDupArr, entry];
                        }
                      }, [])}
                    onChange={(e, v) => setGtin(v === null ? "" : v)}
                    onInputChange={(e) =>
                      setGtin(
                        e.target?.value === 0 || e.target?.value === undefined
                          ? ""
                          : e.target?.value
                      )
                    }
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="PO Article GTIN" />
                    )}
                  />
                </div>
                </div>
                <Button
                  variant="contained"
                  onClick={searchArticleCode}
                  sx={{
                    textTransform: "none",
                    width: 150,
                    borderRadius: "8px",
                  }}
                  className="run-btn"
                >
                  <p className="run-text">Search</p>
                </Button>
            </div>
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Process Status</th>
                  <th style={thStyle}>Order Unit</th>
                  <th style={thStyle}>Net Price</th>
                  <th style={thStyle}>Article Code</th>
                  <th style={thStyle}>Article GTIN</th>
                  <th style={thStyle}>Price Unit</th>
                  <th style={thStyle}>Site</th>
                  <th style={thStyle}>Site Description</th>
                  <th style={thStyle}>Storage Location</th>
                  {filter == "received" && (
                    <th style={thStyle}>Receiving Processed Qty.</th>
                  )}
                  {filter == "packed" && (
                    <th style={thStyle}>Packing Processed Qty.</th>
                  )}
                  {filter == "shipped" && (
                    <th style={thStyle}>Shipping Processed Qty.</th>
                  )}
                  {filter == "received" && (
                    <th style={thStyle}>Receiving Date</th>
                  )}
                  {filter == "packed" && <th style={thStyle}>Packing Date</th>}
                  {filter == "shipped" && (
                    <th style={thStyle}>Shipping Date</th>
                  )}
                  <th style={thStyle}>Vend Art</th>
                  <th style={thStyle}>Article UOM</th>
                  <th style={thStyle}>Item</th>
                  <th style={thStyle}>Order Quantity</th>
                  <th style={thStyle}>Article Description</th>
                  <th style={thStyle}>Article MC Category</th>
                  <th style={thStyle}>Item Delivery</th>
                  <th style={thStyle}>Delivery Date</th>
                  <th style={thStyle}>Item Action</th>
                  <th style={thStyle}>Add Char 1</th>
                  <th style={thStyle}>Add Char 2</th>
                  <th style={thStyle}>Add Char 3</th>
                  <th style={thStyle}>Add Char 4</th>
                  <th style={thStyle}>Item Status</th>
                </tr>
              </thead>
              <tbody>
                {(isSearched ? filteredItems : itemsArray).map(
                  (itemDetail, index) => (
                    <tr key={index}>
                      <td style={tdStyle}>
                        {capitalizeFirstLetter(
                          itemDetail.process_status || "--"
                        )}
                      </td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Order_Unit || "--"}
                      </td>
                      <td style={tdStyle}>{itemDetail.PO_Net_Price || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Article_Code || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Article_GTIN || "--"}
                      </td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Price_Unit || "--"}
                      </td>
                      <td style={tdStyle}>{itemDetail.PO_Site || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Site_Desc || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Storage_Loc || "--"}
                      </td>
                      {filter == "received" && (
                        <td style={tdStyle}>
                          {itemDetail.receivingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.PO_Article_GTIN)
                              }
                            >
                              {itemDetail.receivingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}

                      {filter == "packed" && (
                        <td style={tdStyle}>
                          {itemDetail.packingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.PO_Article_GTIN)
                              }
                            >
                              {itemDetail.packingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}

                      {filter == "shipped" && (
                        <td style={tdStyle}>
                          {itemDetail.shippingProcessedQuantity ? (
                            <Link
                              to="#"
                              onClick={(e) =>
                                onBarcodeClick(e, itemDetail.PO_Article_GTIN)
                              }
                            >
                              {itemDetail.shippingProcessedQuantity}
                            </Link>
                          ) : (
                            "--"
                          )}
                        </td>
                      )}
                      {filter == "received" && (
                        <td style={tdStyle}>
                          {itemDetail.receiving_date
                            ? moment(
                                itemDetail.receiving_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.receiving_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      {filter == "packed" && (
                        <td style={tdStyle}>
                          {itemDetail.packing_date
                            ? moment(
                                itemDetail.packing_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.packing_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      {filter == "shipped" && (
                        <td style={tdStyle}>
                          {itemDetail.shipping_date
                            ? moment(
                                itemDetail.shipping_date,
                                "YYYYMMDDHHmmss"
                              ).isValid()
                              ? moment(
                                  itemDetail.shipping_date,
                                  "YYYYMMDDHHmmss"
                                ).format("DD-MM-YYYY HH:mm:ss A")
                              : "--"
                            : "--"}
                        </td>
                      )}
                      <td style={tdStyle}>{itemDetail.PO_Vend_Art || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Article_Uom || "--"}</td>
                      <td style={tdStyle}>{itemDetail.PO_Item || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Order_Quantity || "--"}
                      </td>
                      <td style={tdStyle}>{itemDetail.Article_Desc || "--"}</td>
                      <td style={tdStyle}>
                        {itemDetail.Article_MC_Category || "--"}
                      </td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Item_Del ? "Yes" : "No"}
                      </td>
                      <td style={tdStyle}>
                        {itemDetail.PO_Delivery_Date
                          ? moment(
                              itemDetail.PO_Delivery_Date,
                              "DD.MM.YYYY"
                            ).isValid()
                            ? moment(
                                itemDetail.PO_Delivery_Date,
                                "DD.MM.YYYY"
                              ).format("DD-MM-YYYY")
                            : "--"
                          : "--"}
                      </td>
                      <td style={tdStyle}>{itemDetail.Item_Action || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char1 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char2 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char3 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Add_Char4 || "--"}</td>
                      <td style={tdStyle}>{itemDetail.Item_Status || "--"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1px",
              color: "white",
            }}
          >
            <h3>List Length: {displayLength}</h3>
          </div>
        </div>
      </div>
      <PermissionsModal
        open={visible}
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        permissions={selectedTags}
      />
    </>
  );
};

export default withRouter(PurchaseOrderItems);
