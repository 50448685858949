import React from 'react'

export default function Colorbtn({ background, value, onClick }) {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                    <button onClick={onClick} style={{ background: background }} className="admin-menu-btn"><p value={value} className="admin-menu-txt">{value}</p></button>
                </div>


            </div>

        </div>
    )
}
