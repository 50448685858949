import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import axios from "axios";
import { PaginatedItems } from "../../components/Paginated";
import Loader from "../../components/Loader";
import { Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { PathCustom } from "../../Path/Path";
export default class ZplReport extends Component {
    state = {
        printingItems: [],
        printingItemsFilter: [],
        itemsPerPage: 10,
        loading: false,
        toDate: null,
        fromDate: null,
        epc: "",
        refresh: 0,
        sku: '',
        users: '',
    }
    async componentDidMount() {
        this.setState({ loading: true })
        const { data } = await axios.get(`${PathCustom}store/printed-items/get`)
        console.log(data.data, 'asdfsdfa');
        var filtering = data?.data
        filtering = filtering.filter((item => item?.serialNumber !== undefined))
        filtering = filtering.filter((item => item?.udfs?.Retail_Product_SKUOriginal?.value !== undefined))
        // console.log(filtering.filter((item => item?.udfs?.user?.value === undefined)), 'item?.udfs?.user?.value');
        filtering = filtering.filter((item => item?.udfs?.user?.value !== undefined))
        // console.log(filtering.filter((item => item?.createdAt === undefined)), 'filtering.filter((item => item?.createdAt !== undefined))');
        filtering = filtering.filter((item => item?.createdAt !== undefined))
        console.log(filtering, 'filtering');

        this.setState({ printingItems: filtering, printingItemsFilter: filtering })
        this.setState({ loading: false })
    }
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    dateFilter = () => {
        var filtering = this.state.printingItemsFilter
        filtering = filtering.filter((item => item?.serialNumber !== undefined))
        filtering = filtering.filter((item => item?.udfs?.Retail_Product_SKUOriginal?.value !== undefined))
        console.log(this.state.users, 'users');
        filtering = filtering.filter((item => item?.udfs?.user?.value !== undefined))
        filtering = filtering.filter((item => item?.createdAt !== undefined))
        if (this.state.toDate === null && this.state.fromDate === null) {
            return filtering.filter((x =>
                x?.serialNumber?.toLowerCase()?.includes(this.state.epc.toLowerCase())
                &&
                x?.udfs?.Retail_Product_SKUOriginal?.value?.toLowerCase().includes(this.state.sku.toLowerCase())
                &&
                x?.udfs?.user?.value?.toLowerCase()?.includes(this.state.users.toLowerCase())
            ))
        } else {
            return filtering.filter((x =>
                this.formatDate(x?.createdAt) >= this.formatDate(this.state.toDate) && this.formatDate(x?.createdAt) <= this.formatDate(this.state.fromDate)
                &&
                x?.serialNumber?.toLowerCase().includes(this.state.epc.toLowerCase())
                &&
                x?.udfs?.Retail_Product_SKUOriginal?.value?.toLowerCase().includes(this.state.sku.toLowerCase())
                &&
                x?.udfs?.user?.value.toLowerCase().includes(this.state.users.toLowerCase())
            ))
        }

    }
    searchData = async () => {
        this.setState({ refresh: !this.state.refresh })
        this.setState({ loading: true })
        await this.setState({ printingItems: this.dateFilter() })
        console.log(this.dateFilter());
        this.setState({ loading: false })

    }
    render() {
        const headers = [
            "Group",
            "Serial Number",
            "Thing Type Code",
            "Product Number",
            "Retail ZPL",
            "Po Number",
            "User",
        ]
        const colNames = [
            "group",
            "serialNumber",
            "thingTypeCode",
            "udfs.Retail_Product_SKUOriginal.value",
            "udfs.Retail_ZPL.value",
            "udfs.po_number.value",
            "udfs.user.value"
        ]
        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">Printed Items</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>
                                {new Date().toLocaleString('hi-IN')}
                            </h1>
                        </div>

                    </div>
                    <div className="dashboard-bottom">
                        <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="DD/MM/YYYY"
                                    size={'small'}
                                    value={this.state.toDate}
                                    onChange={(data) => this.setState({ toDate: data })}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                />
                                <DesktopDatePicker
                                    label="To Date"
                                    inputFormat="DD/MM/YYYY"
                                    size={'small'}
                                    value={this.state.fromDate}
                                    onChange={(data) => this.setState({ fromDate: data })}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                />

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    options={this.state.printingItemsFilter?.map((item => item?.serialNumber)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== null))}
                                    onChange={(e, v) => this.setState({ epc: v === null ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Epc" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    options={this.state.printingItemsFilter?.map((item => item?.udfs?.Retail_Product_SKUOriginal?.value)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== undefined))}
                                    onChange={(e, v) => this.setState({ sku: v === null ? '' : v })}
                                    sx={{ width: 150 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Product Number" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    options={this.state.printingItemsFilter?.map((item => item?.udfs?.user?.value)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== undefined))}
                                    onChange={(e, v) => this.setState({ users: v === null ? '' : v })}
                                    sx={{ width: 150 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="User" />}
                                />





                            </div>

                            <button onClick={() => this.searchData()} className="button-custom" >Search</button>
                        </div>

                        <div style={{ paddingTop: "2%" }}>

                            <PaginatedItems refresh={this.state.refresh} noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.printingItems} />
                        </div>
                    </div>

                </div>


            </React.Fragment>
        )
    }
}
