import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../../components/Dropdown";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import axios from "axios";
import { PaginatedItems } from "../../components/Paginated";
import Loader from "../../components/Loader";
import { Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { PathCustom } from "../../Path/Path";
export default class ZplReport extends Component {
    state = {
        printingItems: [],
        printingItemsFilter: [],
        itemsPerPage: 10,
        loading: false,
        toDate: null,
        fromDate: null,
        refresh: false,
        paginationData: {},
        epc: "",
        sku: '',
        users: '',
        po_number: '',
        invoice_number: ''
    }
    getPrintedItems = async (pageNumber = 1) => {
        const epc = this.state.epc
        const sku = this.state.sku
        const users = this.state.users
        const po_number = this.state.po_number
        const invoice_number = this.state.invoice_number
        const toDate = this.state.toDate === null ? '' : this.formatDate(this.state.toDate)
        const fromDate = this.state.fromDate === null ? '' : this.formatDate(this.state.fromDate)
        console.log(pageNumber, 'pageNumber');
        this.setState({ loading: true })
        const { data } = await axios.get(`${PathCustom}store/printed-items/get?pageNo=${pageNumber}&tagId=${epc}&startDate=${toDate}&endDate=${fromDate}`)
        console.log(data.data, 'asdfsdfa');
        var filtering = data?.data?.data
        filtering = filtering.map((item => {
            return {
                ...item,
                createdAt: item?.createdAt ? new Date(item?.createdAt).toLocaleString() : '----',
            }
        }))
        // filtering = filtering.filter((item => item?.serialNumber !== undefined))
        // filtering = filtering.filter((item => item?.udfs?.Retail_Product_SKUOriginal?.value !== undefined))
        // filtering = filtering.filter((item => item?.udfs?.user?.value !== undefined))
        // filtering = filtering.filter((item => item?.createdAt !== undefined))
        this.setState({ printingItems: filtering, printingItemsFilter: filtering, paginationData: data?.data })
        this.setState({ loading: false })
    }
    async componentDidMount() {
        this.getPrintedItems()
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }
    dateFilter = () => {
        var filtering = this.state.printingItemsFilter
        filtering = filtering.filter((item => item?.serialNumber !== undefined))
        filtering = filtering.filter((item => item?.udfs?.Retail_Product_SKUOriginal?.value !== undefined))
        filtering = filtering.filter((item => item?.udfs?.user?.value !== undefined))
        filtering = filtering.filter((item => item?.createdAt !== undefined))
        if (this.state.toDate === null && this.state.fromDate === null) {
            return filtering.filter((x =>
                x?.serialNumber?.toLowerCase().includes(this.state.epc.toLowerCase())
                &&
                x?.udfs?.Retail_Product_SKUOriginal?.value?.toLowerCase().includes(this.state.sku.toLowerCase())
                &&
                x?.udfs?.user?.value.toLowerCase().includes(this.state.users.toLowerCase())
            ))
        } else {
            return filtering.filter((x =>
                this.formatDate(x?.createdAt) >= this.formatDate(this.state.toDate) && this.formatDate(x?.createdAt) <= this.formatDate(this.state.fromDate)
                &&
                x?.serialNumber?.toLowerCase().includes(this.state.epc.toLowerCase())
                &&
                x?.udfs?.Retail_Product_SKUOriginal?.value?.toLowerCase().includes(this.state.sku.toLowerCase())
                &&
                x?.udfs?.user?.value.toLowerCase().includes(this.state.users.toLowerCase())
            ))
        }
    }
    searchData = async () => {
        this.setState({ loading: true })
        this.setState({ refresh: !this.state.refresh })
        await this.setState({ printingItems: this.dateFilter() })
        console.log(this.dateFilter());
        this.setState({ loading: false })

    }
    render() {
        const headers = [
            "Date",
            "EPC",
            "SAB_Product Name",
            "Barcode",
            "lulu lemon ZPL",
            "User",
        ]
        const colNames = [
            'createdAt',
            "tagId",
            "SAB_ProductName",
            "barcode",
            "zpl",
            "userName",
        ]
        const headersCSV = [
            { label: "Created Date", key: 'createdAt' },
            { label: "Tag Id", key: 'tagId' },
            { label: "SAB_Product Name", key: 'SAB_ProductName' },
            { label: "Barcode", key: 'barcode' },
            { label: "Zpl", key: 'zpl' },
            { label: "UserName", key: 'userName' },
        ]
        const csvData = this.state.printingItems.map((item => {
            return {
                createdAt: item?.createdAt ? new Date(item?.createdAt).toLocaleString() : '----',
                tagId: item?.tagId || '----',
                SAB_ProductName: item?.SAB_ProductName || '----',
                barcode: item?.barcode || '----',
                zpl: item?.zpl || '----',
                userName: item?.userName || '----',

            }
        }))
        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Printed Items (Report)</p>
                            <CSVLink
                                filename='Tagit/Printed Items'
                                data={csvData}
                                headers={headersCSV}
                            >
                                <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />

                            </CSVLink>
                        </div>
                        <div>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>
                                {new Date().toLocaleString('hi-IN')}
                            </h1>
                        </div>
                    </div>
                    <div className="dashboard-bottom">
                        <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', gridColumnGap: '10px', flexWrap: 'wrap' }}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="DD/MM/YYYY"
                                    clearable
                                    size={'small'}
                                    value={this.state.toDate}
                                    onChange={(data) => this.setState({ toDate: data })}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                />
                                <DesktopDatePicker
                                    label="To Date"
                                    inputFormat="DD/MM/YYYY"
                                    size={'small'}
                                    value={this.state.fromDate}
                                    onChange={(data) => this.setState({ fromDate: data })}
                                    renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                                />

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    freeSolo={true}
                                    options={this.state.printingItemsFilter?.map((item => item?.tagId)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== null))}
                                    onChange={(e, v) => this.setState({ epc: v === null ? '' : v })}
                                    sx={{ width: 235 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Epc" />}
                                />
                                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size={'small'}
                                    freeSolo={true}
                                    options={this.state.printingItemsFilter?.map((item => item?.articleNumber)).reduce((noDupArr, entry) => {
                                        if (noDupArr?.includes(entry)) {
                                            return noDupArr;
                                        } else {
                                            return [...noDupArr, entry];
                                        }
                                    }, []).filter((item => item !== undefined))}
                                    onChange={(e, v) => this.setState({ sku: v === null ? '' : v })}
                                    sx={{ width: 150 }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Article Number" />}
                                /> */}
                            </div>

                            <button onClick={() => this.getPrintedItems()} className="button-custom" >Search</button>
                        </div>

                        <div style={{ paddingTop: "2%" }}>
                            <PaginatedItems
                                pageCountNew={this.state.paginationData?.next + this.state.paginationData?.previous + 1}
                                getData={(pageNumber) => this.getPrintedItems(pageNumber)}
                                totalLength={this.state.paginationData?.next}
                                refresh={this.state.refresh}
                                noAction
                                headers={headers}
                                colNames={colNames}
                                itemsPerPage={this.state.itemsPerPage}
                                data={this.state.printingItems.map((item => {
                                    return { ...item, Date: new Date(item?.createdAt).toLocaleString() }
                                }))}
                            />
                        </div>
                    </div>

                </div>


            </React.Fragment>
        )
    }
}
















