import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput";


export default class ProblemAsn extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Problem IBT Data</p></div>

                </div>
                <div className="dashboard-bottom">

                    <div className="drop-data" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div> <p className="filter-text">Filters</p></div>
                            <MyInput
                                type="date"
                            />
                            <MyInput
                                placeholder={"IBT"}
                            ></MyInput>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" size={30}></input>
                            </div>

                        </div>
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}