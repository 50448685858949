import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { withRouter } from "../components/withRouter";
import MyInput from "../components/MyInput";
import { ibtDetail } from "./StaticData/IBT";
import { TextField } from "@mui/material";

class ViewConsignmentDetails extends Component {
    state = {
        poNumbers: ["POAE-00046315",

            "POAE-00043888",

            "POAE-00043872",

            "TOAE-00017533",

            "TOAE-00015537",
            "TOAE-00012535",

            "TOAE-00018537",
            "TOAE-00011535",

            "TOAE-00012537"],
        detail: [],
        detailFilter: [],
        epc: '',
    }

    async componentDidMount() {
        const detail = this.props?.location?.state?.data.length !== 0 ? this.props.location.state.data : []
        console.log(detail, 'asdads');
        this.setState({ detail: detail, detailFilter: detail })
    }
    searchFilter = async () => {
        console.log(this.state.detail, 'asdasd');
        const filterData = await this.state.detailFilter.filter((item => item?.tag_id?.toLowerCase()?.includes(this.state.epc?.toLowerCase())))
        await this.setState({ detail: filterData })
    }

    render() {
        const detail = this.props?.location?.state?.data?.length !== 0 ? this.props?.location?.state?.data : false
        const asn = this.props?.location?.state?.asn ? this.props?.location?.state?.asn : false
        const date = this.props?.location?.state?.date ? this.props.location?.state?.date : false

        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">View Consignment Details</p></div>
                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                        <div style={{ display: 'flex', gridColumnGap: '15px', marginBottom: '10px' }}>

                            {/* <input
                                className='drop-input'
                                // defaultValue={invoiceNumberVal}
                                placeholder="date"
                                type={'date'}
                                // onChange={(data) => setinvoiceNumberVal(data.target.value)}
                            /> */}
                            {/* <input
                                className='drop-input'
                                defaultValue={epc}
                                placeholder="EPC"
                                onChange={(data) => this.setState({ epc: data.target.value })}
                            /> */}
                            <TextField
                                sx={{ width: 300 }}
                                defaultValue={this.state.epc}
                                value={this.state.epc}
                                placeholder="EPC"
                                size={'small'}
                                onChange={(data) => this.setState({ epc: data.target.value })}
                                // disabled
                                id="outlined-basic" label="EPC" variant="outlined" />
                        </div>
                        <button onClick={() => this.searchFilter()} className="run-btn"><p className="run-text">Search</p></button>
                    </div>


                    <div >
                        <table data-table-theme="dark zebra">
                            {
                                this.state?.detail.length !== 0 ?
                                    <React.Fragment>
                                        <thead>
                                            <tr style={{ background: "#373E43" }}>
                                                <th >Date</th>
                                                <th>EPC</th>
                                                <th>Barcode</th>
                                                <th>Product Number</th>
                                                <th>Store </th>
                                                <th>Process Status </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.detail?.map((data, index) =>
                                                <tr>
                                                    <td>{date ? date : new Date().toLocaleString('hi-IN')}</td>

                                                    <td>{data?.tag_id}</td>
                                                    <td>{data?.SKU}</td>
                                                    <td>{data?.SKU_original}</td>
                                                    <td>{data?.store_id}</td>
                                                    <td>{data?.process_status}</td>

                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <thead>
                                            <tr style={{ background: "#373E43" }}>
                                                <th >No Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{"No Data"}</td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                            }
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <h1 style={{ color: 'white', fontSize: '15px', marginTop: '10px' }}>
                                ASN length ({detail?.length})
                            </h1>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
export default withRouter(ViewConsignmentDetails)