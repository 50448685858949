import logo from './logo.svg';
import './App.css';
import "./css/Dashboard.css"
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './screens/Dashboard';
import Dropdown from './components/Dropdown';
import Admin from './screens/Admin/Admin';
import Tagit from './screens/TagIT/Tagit';
import SupplyChain from './screens/SupplyChain';
import ZplReport from './screens/TagIT/ZplReport';
import { Route, Routes } from 'react-router-dom';
import ManageTags from './screens/ManageTags';
import ReceivingTags from './screens/ReceivingTags';
import PackingTags from './screens/PackingTags';
import TransferTags from './screens/TransferTags';
import MainScreen from './screens/MainScreen';
import Users from './screens/Admin/Users';
import Devices from './screens/Admin/Devices';
import TransferCancellation from './screens/Admin/TransferCancellation';
import GiCancellation from './screens/Admin/GiCancellation';
import Store from './screens/Admin/Store';
import Printerinfo from './screens/Admin/Printerinfo';
import PackingItemDetail from './screens/PackingItemDetail';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LookUp from './screens/LookUp';
import LocateItem from './screens/LocateItem';

import Zpl from './screens/Admin/Zpl';
import Audit from './screens/Admin/Audit';

import Adminmenu from './screens/Admin/Adminmenu';
import Roles from './screens/Admin/Roles';
import ManualRecivingIbt from './screens/Admin/ManualRecivingIbt';
import Zplreportsku from './screens/TagIT/Zplreportsku';
import Operation from './screens/Operation';
import Inventory from './screens/Inventory';
import DailyStockCount from './screens/DailyStockCount';
import { withRouter } from './components/withRouter';
import HandleCronJobs from './screens/HandleCronJobs';
import SohDetail from './screens/SohDetail';
import AdminErrorLog from './screens/AdminErrorLog';
import ProblemAsn from './screens/ProblemAsn';
import StockSummaryExtras from './screens/StockSummaryExtras';
import EnterpriseReportOld from './screens/EnterpriseReportOld';
import DailyStockCountReport from './screens/DailyStockCountReport';
import StorePayload from './screens/StorePayload';
import EnterpriseReport from './screens/EnterpriseReport';
import TagPrinting from './screens/TagIT/TagPrinting'
import React, { useEffect, useState } from 'react';
import Login from './screens/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddUser from './screens/Admin/AddUser';
import QrCode from './screens/QrCode';
import LoginProduct from './screens/LoginProduct';
import TargetStock from './screens/TargetStock';
import EncodeTag from './screens/EncodeTag';
import LookUpOffline from './screens/LookUpOffline';
import DeviceSetup1 from './screens/DeviceSetup1';
import Destination from './screens/Destination';
import ReceivingDetailTag from './screens/ReceivingDetailTag';
import TransferDetailTag from './screens/TransferDetailTag';

import LocateItem1 from './screens/LocateItem1';
import CycleCount2 from './screens/CycleCount2';
import CycleCount1 from './screens/CycleCount1';
import DeviceSetup2 from './screens/DeviceSetup2';
import GiStatus from './screens/GiStatus';
import ChangeStatus from './screens/ChangeStatus';
import MoreFilters from './screens/MoreFilters';
import RtvPo from './screens/RtvPo';
import To from './screens/To';
import Po from './screens/Po';
import IBTDifferences from './screens/Admin/IbtDifferences';
import Warehouse from './screens/Warehouse';
import InStoreGI from './screens/InStoreGI';
import ViewIBTDetails from './screens/ViewIBTDetails';
import AddZpl from './screens/TagIT/AddZpl';
import GiSummary from './screens/GiSummary';
import Transfers from './screens/Transfers';
import AddDevice from './screens/Admin/AddDevice';
import Addstore from './screens/Addstore';
import AddRole from './screens/Admin/AddRole';
import BarGraph from './Graphs/BarGraph';
import Varience from './screens/Varience';
import OverallUnderOver from './screens/OverallUnderOver';
import Sample from './sample/Sample';
import Consignment from './screens/Consignment';
import Operation0 from './screens/Operation0';
import TagPrintingPo from './screens/TagIT/TagPrintingPo';
import { createTheme, ThemeProvider } from '@mui/material';
import AddZone from './screens/Admin/AddZone';
import Zones from './screens/Admin/Zones';
import ViewConsignmentDetails from './screens/ViewConsignmentDetails';
import CountDetails from './screens/CountDetails';
import OverDetails from './screens/OverDetails';
import OutofStock from './screens/OutofStock';
import TotalSOH from './screens/TotalSOH';
import AsnCancelation from './screens/asnCancelation';
import TransferTwo from './screens/TransferTwo';
import Shipping from './screens/Shipping';
import PutAway from './screens/PutAway';
import GoodsIn from './screens/GoodsIn';
import BinMaster from './screens/BinMaster';
import ItemMaster from './screens/ItemMaster';
import PurchaseOrder from './screens/PurchaseOrder'
import TransferOrder from './screens/TransferOrder';
import BinToBin from './screens/BinToBin';
import PurchaseReturn from './screens/PurchaseReturn';
import Transfer from './screens/Transfer';
import Picking from './screens/Picking';
import TargetedStockCount from './screens/TargetedStockCount';
import ViewIBTDetails2 from './screens/ViewIBTDetails2';
import Receiving from './screens/Receiving';
import POCreated from './screens/POCreated';
import TOCreated from './screens/TOCreated';
import PLCreated from './screens/PLCreated';
import POCreatedDetail from './screens/POCreatedDetail';
import TOCreatedDetail from './screens/TOCreatedDetail';
import PLCreatedDetail from './screens/PLCreatedDetail';
import PLNotCreated from './screens/PLNotCreated';
import PLNotCreatedDetail from './screens/PLNotCreatedDetail';
import BinLocations from './screens/BinLocations';
import TargetStockCountDashboard from './screens/TargetStockCountDashboard';
import TargetStockCountOperation from './screens/TargetStockCountOperation';
import PoQuantityDetails from './screens/POQuantityDetails';
import StockOnHand from './screens/stockOnHand';
import PickingItems from './screens/PickingItems';
import PurchaseOrderItems from './screens/PurchaseOrderItems';
import TransferOrderItems from './screens/TransferOrderItems';
import BulkPrinting from './screens/BulkPrinting';
import POPackingList from './screens/PoPackingList';
import PackingListDetail from './screens/PackingListDetail';

function App(props) {
  const [toggle, setToggle] = useState(false)
  const [newToggle, setNewToggle] = useState(false)
  const [CountToggle, setCountToggle] = useState(false)
  useEffect(() => {
    async function getLocalData() {
      let res = await localStorage.getItem("login")
      console.log(res)
      if (res === null || res === 'null') {
        props.history("/login")
      }
    }
    getLocalData()

  }, [])
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  return (
    <React.Fragment>
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ToastContainer />
          <div style={{ background: "#373E43" }}>
            <div style={{ display: "flex", height: "min-content" }}>
              <div>
                {window.location.pathname === '/login' ?
                  null :
                  <Sidebar />}
              </div>
              <div style={{ width: window.location.pathname === "/login" ? "100%" : "93.5%", marginLeft: window.location.pathname === "/login" ? "0" : "80px", minHeight: "100vh" }}>
                {
                  window.location.pathname === "/login" ?
                    null :
                    <Header onToggle={e => setToggle(s => e)}
                      onTagToggle={e => setNewToggle(s => e)}
                      onCountToggle={e => setCountToggle(s => e)}
                    />

                }
                <Routes>
                  {
                    !localStorage.getItem("login") || localStorage.getItem("login") === null || localStorage.getItem("login") === "null" ?
                      <>
                        <Route path="/login" element={<Login />} />
                        <Route
                          path="*"
                          element={
                            <main style={{ padding: "1rem" }}>
                              <p>WELCOME BACK !</p>
                            </main>
                          }
                        />
                      </>
                      :
                      <>

                        <Route path="/POCreated" element={<POCreated />} />
                        <Route path="/POCreatedDetail" element={<POCreatedDetail />} />
                        <Route path="/TOCreated" element={<TOCreated />} />
                        <Route path="/TOCreatedDetail" element={<TOCreatedDetail />} />
                        <Route path="/PLCreated" element={<PLCreated/>} />
                        <Route path="/PLCreatedDetail" element={<PLCreatedDetail/>} />
                        <Route path="/PLNotCreated" element={<PLNotCreated/>} />
                        <Route path="/PLNotCreatedDetail" element={<PLNotCreatedDetail/>} />

                        <Route path="/targetedstockcount" element={<TargetedStockCount />} />
                        <Route path="/targetDashboard" element={<TargetStockCountDashboard />} />
                        <Route
                          path="/TargetStockCountOperation"
                          element={<TargetStockCountOperation />}
                        />
                        <Route path="/users" element={<Users />} />
                        <Route path="/devices" element={<Devices />} />
                        <Route path="/CountDetails" element={<CountDetails />} />
                        <Route path="/OverDetails" element={<OverDetails />} />
                        <Route path="/OutOfStock" element={<OutofStock />} />
                        <Route path="/totalSOH" element={<TotalSOH />} />
                        <Route path="/asnCancelation" element={<AsnCancelation />} />
                        <Route path="/transfercancellation" element={<TransferCancellation />} />
                        <Route path="/manualrecivingibt" element={<ManualRecivingIbt />} />
                        <Route path="/gicancellation" element={<GiCancellation />} />
                        <Route path="/store" element={<Store />} />
                        <Route path="/printerinfo" element={<Printerinfo />} />
                        <Route path="/roles" element={<Roles />} />
                        <Route path="/manualReceiving" element={<Receiving />} />
                        <Route path="/BinLocations" element={<BinLocations />} />
                        <Route path="/audit" element={<Audit />} />
                        <Route path="/zplreportsku" element={<Zplreportsku />} />
                        <Route path="/operation" element={<Operation />} />
                        <Route path="/adduser" element={<AddUser />} />
                        <Route path="/inventory" element={<Inventory />} />
                        <Route path="/varience" element={<Varience />} />
                        <Route path="/adminmenu" element={<Adminmenu />} />
                        <Route path="/zpl" element={<Zpl />} />
                        <Route path="/Addzone" element={<AddZone />} />
                        <Route path="/zones" element={<Zones />} />
                        <Route path="/tagit" element={<Tagit />} />
                        <Route path="/addZpl" element={<AddZpl />} />
                        <Route path="/consignement" element={<Consignment />} />
                        <Route path="/dailystockcount" element={<DailyStockCount />} />
                        <Route path="/dailystockcountreport" element={<DailyStockCountReport />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/storepayload" element={<StorePayload />} />
                        <Route path="/enterprisereport" element={<EnterpriseReport />} />
                        <Route path="/admin" element={<Admin />} />
                        <Route path="/adduser" element={<AddUser />} />
                        <Route path="/supplychain" element={<SupplyChain />} />
                        <Route path="/enterprisereportold" element={<EnterpriseReportOld />} />
                        <Route path="/stocksummaryextra" element={<StockSummaryExtras />} />
                        <Route path="/gisummary" element={<GiSummary />} />
                        <Route path="/packing" element={<Transfers />} />
                        <Route path="/zplreport" element={<ZplReport />} />
                        <Route path="/handlecornjob" element={<HandleCronJobs />} />
                        <Route path="/sohdetail" element={<SohDetail />} />
                        <Route path="/adminerrorlog" element={<AdminErrorLog />} />
                        <Route path="/problemasn" element={<ProblemAsn />} />
                        <Route path="/production/managetags" element={<ManageTags />} />
                        <Route path="/transfers" element={<Transfers />} />
                        <Route path="/production/receivingtags" element={<ReceivingTags />} />
                        <Route path="/production/packingtags" element={<PackingTags />} />
                        <Route path="/production/transfertags" element={<TransferTags />} />
                        <Route path="/production/mainscreen" element={<MainScreen />} />
                        <Route path="/tagprinting" element={<TagPrinting />} />
                        <Route path="/tagprintingRtv" element={<TagPrintingPo />} />
                        <Route path="/production/qrcode" element={<QrCode />} />
                        <Route path="/production/loginProduction" element={<LoginProduct />} />
                        <Route path="/production/targetstock" element={<TargetStock />} />
                        <Route path="/production/encodetag" element={<EncodeTag />} />
                        <Route path="/production/lookupoffline" element={<LookUpOffline />} />
                        <Route path="/production/devicesetup1" element={<DeviceSetup1 />} />
                        <Route path="/production/destination" element={<Destination />} />
                        {/* /////////////////////////////new new ew  */}
                        <Route path="/production/lookup" element={<LookUp />} />
                        <Route path="/production/locateitem" element={<LocateItem />} />
                        <Route path="/production/receivingdetailTag" element={<ReceivingDetailTag />} />
                        <Route path="/production/transferdetailTag" element={<TransferDetailTag />} />
                        <Route path="/production/packingdetailTag" element={<PackingItemDetail />} />
                        <Route path="/production/locateitem1" element={<LocateItem1 />} />
                        <Route path="/production/cyclecount2" element={<CycleCount2 />} />
                        <Route path="/production/cyclecount1" element={<CycleCount1 />} />
                        <Route path="/production/devicesetup2" element={<DeviceSetup2 />} />
                        <Route path="/production/changestatus" element={<ChangeStatus />} />
                        <Route path="/production/gistatus" element={<GiStatus />} />
                        <Route path="/production/moreFilters" element={<MoreFilters />} />
                        <Route path="/rtvPo" element={<RtvPo />} />
                        <Route path="/receiving" element={<To />} />
                        <Route path="/transfersRep" element={<TransferTwo />} />
                        <Route path="/shipping" element={<Shipping />} />
                        <Route path="/Po" element={<Po />} />
                        <Route path="/ibtdifferences" element={<IBTDifferences />} />
                        {/* /////////////////////////////new new supplychain  */}
                        <Route path="/warehouse" element={<Warehouse />} />
                        <Route path="/instoregi" element={<InStoreGI />} />
                        <Route path="/viewibtdetails" element={<ViewIBTDetails />} />
                        <Route path="/viewibtdetails2" element={<ViewIBTDetails2 />} />
                        <Route path="/viewconsignmentDetails" element={<ViewConsignmentDetails />} />
                        <Route path="/adddevices" element={<AddDevice />} />
                        <Route path="/addstore" element={<Addstore />} />
                        <Route path="/addrole" element={<AddRole />} />
                        <Route path="/overallunderover" element={<OverallUnderOver />} />
                        <Route path="/graph" element={<Sample />} />
                        <Route path="/operation0" element={<Operation0 />} />
                        <Route path="/putAway" element={<PutAway />} />
                        <Route path="/goodsIn" element={<GoodsIn />} />
                        <Route path="/binMaster" element={<BinMaster />} />
                        <Route path="/itemMaster" element={<ItemMaster />} />
                        <Route path="/purchaseOrder" element={<PurchaseOrder />} />
                        <Route path="/trasnsferOrder" element={<TransferOrder />} />
                        <Route path="/bintobin" element={<BinToBin />} />
                        <Route path="/perchasereturn" element={<PurchaseReturn />} />
                        <Route path="/tranferreport" element={<Transfer />} />
                        <Route path="/pickingreport" element={<Picking />} />
                        <Route path="/poquantitydetails" element={<PoQuantityDetails/>} />
                        <Route path="/soh" element={<StockOnHand/>} />
                        <Route path="/pickingItemsDetail" element={<PickingItems/>} />
                        <Route path="/purchaseOrderItems" element={<PurchaseOrderItems />} />
                        <Route path="/transferOrderItems" element={<TransferOrderItems />} />
                        <Route path="/bulk-printing" element={<BulkPrinting />} />
                        <Route path="/po-packing-list" element={<POPackingList />} />
                        <Route path="/packing-list-detail" element={<PackingListDetail />} />

                        <Route
                          path="*"
                          element={
                            <main style={{ padding: "1rem", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', height: "90vh" }}>
                              <p style={{ fontSize: '40px', letterSpacing: '4px' }} >WELCOME BACK !</p>
                            </main>
                          }
                        />
                      </>
                  }

                </Routes>
              </div>
            </div>
          </div>
        </LocalizationProvider>
      </ThemeProvider>

    </React.Fragment>

  );
}

export default withRouter(App);

