import React, { Component } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { TbDoorExit } from 'react-icons/tb'
import '../css/mine.css';
import innoventLogo from '../assets/InnoventLogo.png'
import storage from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { withRouter } from '../components/withRouter';
import { CustomModal } from '../components/Modal';
import { Form, Toast } from 'react-bootstrap';
import { editManageTagScreen, editTargetStockScreen, getManageTagScreen, getTargetStockScreen } from '../Path/Path';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { IoIosBarcode } from 'react-icons/io';


class TargetStock extends Component {
    state = {
        percent: 0,
        imageLink: '',
        status: '',
        inStoreGi: '',
        showModal: false,
        header: '',
        data: [
            {
                "header": {
                    "text": "Target Stock"
                },
                "input": {
                    "placeholder": "Type Reference Number"
                }
            }
        ],
        referenceNumber: ""
    }
    componentDidMount() {

    }

    closeHandler = (onClose, value) => {
        if (value === 'status') {
            this.setState({ status: '' })
        } else if (value === 'inStoreGi') {
            this.setState({ inStoreGi: '' })
        } else {
            this.setState({ header: '' })
        }
        onClose()
    }
    sendingData = async (onClose, value) => {
        console.log(this.state.data[0]);
        const bodyInputPlaceholder =  {
            input:{
                "placeholder": this.state.referenceNumber
            }
        }
        const bodyHeader = {
            header: {
                text: this.state.header
            }
        }
        
        const editing = await editTargetStockScreen(value === 'referenceNumber' ? bodyInputPlaceholder : value === 'header' && bodyHeader)
            .then((res => {
                return toast.success("Data Updated")
            })).catch((err => toast.error("Something went wrong")))

        onClose()
    }
    buttonHandler = (value) => {
        this.setState({ showModal: true })
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomModal onSave={() => this.sendingData(onClose, value)} onClose={() => this.closeHandler(onClose, value)} heading={value === 'status' ? "Change Status" : value === 'inStoreGi' ? "Change In Store Gi" : "Change Header"}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            {value === 'referenceNumber' ?
                                <React.Fragment>
                                    <Form.Label>{'Reference Number Placeholder'}</Form.Label>
                                    <Form.Control
                                        type="text"


                                        id={value}
                                        defaultValue={this.state.referenceNumber === "" ? this.state.data[0].input?.placeholder : this.state.referenceNumber}
                                        placeholder={this.state.referenceNumber === '' ? 'Type Reference Number' : this.state.referenceNumber}
                                        onChange={(e) => this.setState({ referenceNumber: e.target.value })}
                                    />
                                </React.Fragment>
                                :

                                < React.Fragment >
                                    <Form.Label>{'Header'}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id={value}
                                        defaultValue={this.state.header === '' ? this.state.data[0].header.text : this.state.header}
                                        placeholder={this.state.header === '' ? 'Header' : this.state.header}
                                        onChange={(e) => this.setState({ header: e.target.value })}
                                    />
                                </React.Fragment>
                            }
                        </Form.Group>
                    </CustomModal >
                );
            }
        });
    }
    imageHandler = (files) => {

        if (!files[0]) {
            alert("Please choose a file first!")
        } else {
            console.log(files[0], 'files');
            const file = files[0]
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    this.setState({ percent })
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                        this.setState({ imageLink: url, percent: 0 })
                    });
                }
            );
        }

    }
    async componentDidMount() {
        const data = await getTargetStockScreen()
        console.log(data, "data")
        this.setState({ data: data })
    }
    render() {
        console.log(this.state);
        return (
            <React.Fragment>

                <div className="dashboard-main-mine">
                    <div className="dashboard-top-mine">
                        <div> <p className="stock-count">Target Stock</p></div>
                    </div>
                    <div className='production-main-div'>
                        <div className='production-main-child'>
                            Default Screen
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 className='screen-heading'>Target Stock</h1>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: 20 }}>
                                    <input disabled type={'text'} id={'asnValue'} placeholder={'Type Reference Number'} style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ asnValue: e.target.value })} />
                                    <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                        <FaSearch size={25} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='production-main-child'>
                            Target Stock
                            <div className='production-main-screen' style={{ height: '690px', width: '350px' }}  >
                                <h1 style={{ cursor: 'pointer' }} onClick={() => this.buttonHandler('header')} className='screen-heading'>{this.state.header !== "" ? this.state.header : this.state.data[0]?.header?.text}</h1>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridColumnGap: '10px', marginTop: 20 }}>
                                    <input onClick={() => this.buttonHandler('referenceNumber')} type={'text'} value={this.state.referenceNumber !== "" ? this.state.referenceNumber : this.state.data[0].input?.placeholder} id={'asnValue'} placeholder="" style={{ minWidth: '250px' }} className="custom-input" onChange={(e) => this.setState({ referenceNumber: e.target.value })} />
                                    <div style={{ fill: 'white', color: 'white', cursor: 'pointer' }} >
                                        <FaSearch size={25} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(TargetStock)