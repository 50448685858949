import React from 'react'
import { MoonLoader } from 'react-spinners'

function Loader({ loading }) {
    return (
        <div style={{ position: 'fixed', width: '100%', height: '100%', top: '0px', left: '0px', display: loading ? 'block' : 'none', backgroundColor: "	rgb(0, 0, 0,0.5)",zIndex:2342354123423 }}>
            <div className='loading-center'> 
                <MoonLoader color='#ffffff' loading={loading} />
            </div>
        </div>
    )
}

export default Loader
