import { TextField, Autocomplete, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withRouter } from "../components/withRouter";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { capitalizeFirstLetter } from "./common/capitalizeFirstLetter";


const PickingItems = () => {
  const [filteredItems, setFilteredItems] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {};
  console.log({item})

  useEffect(() => {
    setItemsArray(item || []);
  }, [item]);

  const displayLength = isSearched ? filteredItems.length : itemsArray.length;

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
    color: "#dddddd",
  };

  const thStyle = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
    color: "white",
    backgroundColor: "black",
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const tdStyle = {
    border: "1px solid #dddddd",
    textAlign: "center",
    padding: "8px",
    color: "white",
  };

  // const searchArticleCode = () => {
  //   const result = itemsArray.filter((item) =>
  //     item.Article_Code.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  //   setFilteredItems(result);
  //   setIsSearched(true);
  // };

  const handleBack = () => {
    navigate(-1);
  };

  const headers = [
    "Item Number",
    "Material",
    "EAN",
    "Short Text",
    "Net Order Value",
    "Order Quantity",
    "Order Unit",
    "Net Price",
    "Quantity In SKU",
    "Stockkeeping Unit",
    "Currency",
    "Price Unit",
    "Target Quantity",
    "Open Target Quantity",
    "Still To Be Delivered Qty",
    "Still To Be Delivered Val",
    "Still To Be Invoiced Qty",
    "Still To Be Invoiced Val",
    "No Of Positions",
    "Processed Qty.",
    "Process Status",
  ];
  const colNames = [
    "itemNumber",
    "material",
    "EAN",
    "shortText",
    "netOrderValue",
    "orderQuantity",
    "orderUnit",
    "netPrice",
    "quantityInSKU",
    "stockkeepingUnit",
    "currency",
    "priceUnit",
    "targetQuantity",
    "openTargetQuantity",
    "stillToBeDeliveredQty",
    "stillToBeDeliveredVal",
    "stillToBeInvoicedQty",
    "stillToBeInvoicedVal",
    "noOfPositions",
    "processedQuantity",
    "process_status",
  ];


  const headersCSV = colNames.map((item) => ({ key: item, label: headers }));
	const csvData = itemsArray.map((item) => ({
    ...item,
    process_status: capitalizeFirstLetter(item.process_status),
  }));


  return (
    <>
      <div className="dashboard-main">
        <div
          className="dashboard-top"
          style={{ display: "flex", flexDirection: "column", padding: "10px" }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={handleBack}
                sx={{
                  backgroundColor: "blue",
                  width: "50px",
                  borderRadius: "5px",
                  color: "white",
                  textTransform: "none",
                }}
              >
                Back
              </Button>
              <p
                className="stock-count"
                style={{ fontSize: "20px", margin: "10px" }}
              >
                Picking Item Details
              </p>
            </div>
            <CSVLink
              filename="Picking Items.csv"
              data={csvData}
              headers={headersCSV}
              style={{ textDecoration: "none" }}
            >
              <GrDocumentCsv
                color="black"
                fontSize={25}
                style={{ marginRight: 5, cursor: "pointer" }}
              />
            </CSVLink>
          </div>
        </div>

        <div className="dashboard-bottom" style={{ marginTop: "0.5%" }}>
          <div>
            {/* Uncomment and adjust the search functionality if needed */}
            {/* <p>Filters</p>
            <div style={{ display: "flex", width: '100%', gridColumnGap: '20px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', gridColumnGap: '80px', gridRowGap: '10px', flexWrap: 'wrap' }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                freeSolo={true}
                size={'small'}
                options={itemsArray.filter(item => item?.Article_Code !== '').map(item => item.Article_Code).reduce((noDupArr, entry) => {
                  if (noDupArr.includes(entry)) {
                    return noDupArr;
                  } else {
                    return [...noDupArr, entry];
                  }
                }, [])}
                onChange={(e, v) => setInputValue(v === null ? '' : v)}
                sx={{
                  width: 200,
                  '& .MuiInputLabel-root': { color: 'white' },
                  '& .MuiInputBase-root': { color: 'white' },
                  '& .MuiLabel-root': { Label: 'white' },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white'
                    },
                    '&:hover fieldset': {
                      borderColor: 'white'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white'
                    }
                  }
                }}
                renderInput={(params) => <TextField {...params} label="Article Code" />}
              />
              </div>
              <Button
                variant="contained"
                onClick={searchArticleCode}
                sx={{
                  textTransform: 'none',
                  width: 150,
                  borderRadius: '8px'
                }}
                className="run-btn"
              >
                <p className="run-text">Search</p>
              </Button>
            </div> */}
          </div>
          <div
            style={{ marginTop: "10px", overflowX: "scroll", height: "600px" }}
          >
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Item Number</th>
                  <th style={thStyle}>Material</th>
                  <th style={thStyle}>EAN</th>
                  <th style={thStyle}>Short Text</th>
                  <th style={thStyle}>Net Order Value</th>
                  <th style={thStyle}>Order Quantity</th>
                  <th style={thStyle}>Order Unit</th>
                  <th style={thStyle}>Net Price</th>
                  <th style={thStyle}>Qty. In SKU</th>
                  <th style={thStyle}>Stock Keeping Unit</th>
                  <th style={thStyle}>Currency</th>
                  <th style={thStyle}>Price Unit</th>
                  <th style={thStyle}>Target Qty.</th>
                  <th style={thStyle}>Open Target Qty.</th>
                  <th style={thStyle}>Still To Be Delivered Qty</th>
                  <th style={thStyle}>Still To Be Delivered Val</th>
                  <th style={thStyle}>Still To Be Invoiced Qty</th>
                  <th style={thStyle}>Still To Be Invoiced Val</th>
                  <th style={thStyle}>No Of Positions</th>
                  <th style={thStyle}>Processed Qty.</th>
                  <th style={thStyle}>Process Status</th>
                </tr>
              </thead>
              <tbody>
                {itemsArray.map((itemDetail, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{itemDetail.itemNumber || "--"}</td>
                    <td style={tdStyle}>{itemDetail.material || "--"}</td>
                    <td style={tdStyle}>{itemDetail.EAN || "--"}</td>
                    <td style={tdStyle}>{itemDetail.shortText || "--"}</td>
                    <td style={tdStyle}>{itemDetail.netOrderValue || "--"}</td>
                    <td style={tdStyle}>{itemDetail.orderQuantity || "--"}</td>
                    <td style={tdStyle}>{itemDetail.orderUnit || "--"}</td>
                    <td style={tdStyle}>{itemDetail.netPrice || "--"}</td>
                    <td style={tdStyle}>{itemDetail.quantityInSKU || "--"}</td>
                    <td style={tdStyle}>
                      {itemDetail.stockkeepingUnit || "--"}
                    </td>
                    <td style={tdStyle}>{itemDetail.currency || "--"}</td>
                    <td style={tdStyle}>{itemDetail.priceUnit || "--"}</td>
                    <td style={tdStyle}>{itemDetail.targetQuantity || "--"}</td>
                    <td style={tdStyle}>
                      {itemDetail.openTargetQuantity || "--"}
                    </td>
                    <td style={tdStyle}>
                      {itemDetail.stillToBeDeliveredQty || "--"}
                    </td>
                    <td style={tdStyle}>
                      {itemDetail.stillToBeDeliveredVal || "--"}
                    </td>
                    <td style={tdStyle}>
                      {itemDetail.stillToBeInvoicedQty || "--"}
                    </td>
                    <td style={tdStyle}>
                      {itemDetail.stillToBeInvoicedVal || "--"}
                    </td>
                    <td style={tdStyle}>{itemDetail.noOfPositions || "--"}</td>
                    <td style={tdStyle}>
                      {itemDetail.processedQuantity || "--"}
                    </td>
                    <td style={tdStyle}>{capitalizeFirstLetter(itemDetail.process_status || "--")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1px",
              color: "white",
            }}
          >
            <h3>List Length: {displayLength}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PickingItems);
