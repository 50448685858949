export const ibtData = {
    "aaData": [
        {
            "asn": "TRA112",
            "source": "0001130",
            "destination": "0002115",
            "packed_item_new": "<button type=\"button\" asn_id=TRA112 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=TRA112 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9</button>",
            "received_item_new": "<button type=\"button\" asn_id=TRA112 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9</button>",
            "status": "receiving",
            "packing_date": "2021-05-09 08:27:31",
            "packing_remarks": "Admin ",
            "shipping_date": "2021-05-09 08:40:53",
            "shipping_remarks": "Transfer ",
            "receiving_date": "2021-05-09 09:00:24",
            "receiving_remarks": "Ø±ÙˆØ¨Ø§Ù‰Ø§Øª"
        },
        {
            "asn": "TRA111",
            "source": "0001130",
            "destination": "0002115",
            "packed_item_new": "<button type=\"button\" asn_id=TRA111 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=TRA111 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=TRA111 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2021-05-09 08:28:01",
            "packing_remarks": "Test ",
            "shipping_date": "2021-05-09 08:43:26",
            "shipping_remarks": "ØªØ±Ø§Ù†Ø³ÙØ± Ø§ÙˆÙ‰Øª",
            "receiving_date": "2021-05-09 08:57:50",
            "receiving_remarks": "Ø±Ù‰Ø³Ù‰ÙˆØ§"
        },
        {
            "asn": "TRA110",
            "source": "0001130",
            "destination": "0002115",
            "packed_item_new": "<button type=\"button\" asn_id=TRA110 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=TRA110 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "received_item_new": "<button type=\"button\" asn_id=TRA110 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "status": "receiving",
            "packing_date": "2021-05-09 08:30:05",
            "packing_remarks": "Packing ",
            "shipping_date": "2021-05-09 08:37:48",
            "shipping_remarks": "Tr",
            "receiving_date": "2021-05-09 09:01:57",
            "receiving_remarks": "Ù‰Ø®Ù„Ù„Ø¨ÙŠÙŠ"
        },
        {
            "asn": "test1782",
            "source": "0001002",
            "destination": "0001150",
            "packed_item_new": "<button type=\"button\" asn_id=test1782 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=test1782 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "received_item_new": "<button type=\"button\" asn_id=test1782 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "status": "shipping",
            "packing_date": "2022-08-18 15:52:53",
            "packing_remarks": "",
            "shipping_date": "2022-08-18 20:54:55",
            "shipping_remarks": "",
            "receiving_date": "2022-05-09 21:11:40",
            "receiving_remarks": "Receiving by zeeshan "
        },
        {
            "asn": "TEST-1234567",
            "source": "0000112",
            "destination": "0000015",
            "packed_item_new": "<button type=\"button\" asn_id=TEST-1234567 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=TEST-1234567 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">6</button>",
            "received_item_new": "<button type=\"button\" asn_id=TEST-1234567 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "status": "shipping",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "2021-10-02 10:03:02",
            "shipping_remarks": "",
            "receiving_date": "-",
            "receiving_remarks": null
        },
        {
            "asn": "TEST-123456",
            "source": "0000112",
            "destination": "0000015",
            "packed_item_new": "<button type=\"button\" asn_id=TEST-123456 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=TEST-123456 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">3</button>",
            "received_item_new": "<button type=\"button\" asn_id=TEST-123456 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "status": "shipping",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "2021-10-02 10:03:02",
            "shipping_remarks": "",
            "receiving_date": "-",
            "receiving_remarks": null
        },
        {
            "asn": "Test",
            "source": "0002150",
            "destination": "",
            "packed_item_new": "<button type=\"button\" asn_id=Test process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Test process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "received_item_new": "<button type=\"button\" asn_id=Test process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "status": "packing",
            "packing_date": "2022-08-09 18:52:08",
            "packing_remarks": "Testing ",
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "-",
            "receiving_remarks": null
        },
        {
            "asn": "Tag5",
            "source": "0002171",
            "destination": "0002150",
            "packed_item_new": "<button type=\"button\" asn_id=Tag5 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag5 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag5 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-08-02 21:45:09",
            "packing_remarks": "Tag5 pack done ",
            "shipping_date": "2022-08-02 21:50:33",
            "shipping_remarks": "Tag5 shipped",
            "receiving_date": "2022-08-02 21:52:19",
            "receiving_remarks": "Tag5 Received "
        },
        {
            "asn": "Tag47",
            "source": "0002171",
            "destination": "0002150",
            "packed_item_new": "<button type=\"button\" asn_id=Tag47 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag47 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag47 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-08-01 17:32:00",
            "packing_remarks": "Tag47 ",
            "shipping_date": "2022-08-01 17:33:06",
            "shipping_remarks": "Tag47 shipped ",
            "receiving_date": "2022-08-01 18:11:02",
            "receiving_remarks": "Tag47 done"
        },
        {
            "asn": "Tag4",
            "source": "0002171",
            "destination": "0002150",
            "packed_item_new": "<button type=\"button\" asn_id=Tag4 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag4 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag4 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-08-02 21:44:28",
            "packing_remarks": "Tag4 pack done ",
            "shipping_date": "2022-08-02 21:50:02",
            "shipping_remarks": "Tag4 shipped",
            "receiving_date": "2022-08-02 21:53:07",
            "receiving_remarks": "Tag4 Received "
        },
        {
            "asn": "Tag3",
            "source": "0002171",
            "destination": "0002150",
            "packed_item_new": "<button type=\"button\" asn_id=Tag3 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag3 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag3 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-08-02 21:43:52",
            "packing_remarks": "Tag3 pack done ",
            "shipping_date": "2022-08-02 21:48:43",
            "shipping_remarks": "Tag3 shipped",
            "receiving_date": "2022-08-02 21:53:40",
            "receiving_remarks": "Tag3  Received "
        },
        {
            "asn": "Tag2",
            "source": "0002171",
            "destination": "0002150",
            "packed_item_new": "<button type=\"button\" asn_id=Tag2 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag2 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag2 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "status": "receiving",
            "packing_date": "2022-08-02 21:43:00",
            "packing_remarks": "Tag2 pack done ",
            "shipping_date": "2022-08-02 21:47:35",
            "shipping_remarks": "Tag2 shipped ",
            "receiving_date": "2022-08-02 21:54:12",
            "receiving_remarks": "Tag2 Received "
        },
        {
            "asn": "Tag1",
            "source": "0002171",
            "destination": "0002115",
            "packed_item_new": "<button type=\"button\" asn_id=Tag1 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=Tag1 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=Tag1 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-08-01 23:08:02",
            "packing_remarks": "Tag1 pack done",
            "shipping_date": "2022-08-01 23:08:29",
            "shipping_remarks": "Tag1 shipped ",
            "receiving_date": "2022-08-01 23:15:27",
            "receiving_remarks": ""
        },
        {
            "asn": "s1267",
            "source": "0001147",
            "destination": "0001152",
            "packed_item_new": "<button type=\"button\" asn_id=s1267 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=s1267 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=s1267 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "2022-05-11 21:38:02",
            "shipping_remarks": "",
            "receiving_date": "2022-05-11 21:43:44",
            "receiving_remarks": ""
        },
        {
            "asn": "s123",
            "source": "0001147",
            "destination": "0002171",
            "packed_item_new": "<button type=\"button\" asn_id=s123 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=s123 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=s123 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "receiving",
            "packing_date": "2022-05-11 13:47:39",
            "packing_remarks": "",
            "shipping_date": "2022-05-11 13:49:47",
            "shipping_remarks": "",
            "receiving_date": "2022-07-17 12:09:57",
            "receiving_remarks": "zzz222"
        },
        {
            "asn": "s12",
            "source": "0001147",
            "destination": "0001152",
            "packed_item_new": "<button type=\"button\" asn_id=s12 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=s12 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "received_item_new": "<button type=\"button\" asn_id=s12 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "status": "receiving",
            "packing_date": "2022-05-11 13:55:44",
            "packing_remarks": "",
            "shipping_date": "2022-05-11 13:55:58",
            "shipping_remarks": "",
            "receiving_date": "2022-05-11 13:56:12",
            "receiving_remarks": ""
        },
        {
            "asn": "rubaiyat",
            "source": "0001130",
            "destination": "0002139",
            "packed_item_new": "<button type=\"button\" asn_id=rubaiyat process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">61</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=rubaiyat process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">60</button>",
            "received_item_new": "<button type=\"button\" asn_id=rubaiyat process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">-</button>",
            "status": "shipping",
            "packing_date": "2021-10-04 23:50:02",
            "packing_remarks": "",
            "shipping_date": "2021-10-04 23:50:42",
            "shipping_remarks": "",
            "receiving_date": "-",
            "receiving_remarks": null
        },
        {
            "asn": "RECEIVING_1620860386523",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620860386523 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620860386523 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620860386523 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">16</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-05-08 19:49:25",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620858701666",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858701666 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858701666 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858701666 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-05-11 17:57:07",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620858142071",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142071 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142071 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142071 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-04-25 19:05:31",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620858142069",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142069 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142069 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620858142069 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-05-06 18:06:43",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620851356637",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620851356637 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620851356637 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620851356637 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-05-08 20:12:59",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620849780092",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620849780092 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620849780092 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620849780092 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">5</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-05-06 18:06:43",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620846820055",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620846820055 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620846820055 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620846820055 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-04-09 01:15:09",
            "receiving_remarks": ""
        },
        {
            "asn": "RECEIVING_1620824443620",
            "source": null,
            "destination": "0002146",
            "packed_item_new": "<button type=\"button\" asn_id=RECEIVING_1620824443620 process_status=\"packing\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "transferred_item_new": "<button type=\"button\" asn_id=RECEIVING_1620824443620 process_status=\"shipping\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0</button>",
            "received_item_new": "<button type=\"button\" asn_id=RECEIVING_1620824443620 process_status=\"receiving\" class=\"btn asn_info_model btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2</button>",
            "status": "closed",
            "packing_date": "-",
            "packing_remarks": null,
            "shipping_date": "-",
            "shipping_remarks": null,
            "receiving_date": "2021-04-14 18:54:31",
            "receiving_remarks": ""
        }
    ],
    "iTotalRecords": "4976",
    "iTotalDisplayRecords": "4976"
}

export const ibtDetail = {
    "aaData": [
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "3034000044435E800000204F",
            "sku": "17689861",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444361C00000203A",
            "sku": "17689991",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444361C00000203D",
            "sku": "17689991",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444361C000002055",
            "sku": "17689991",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444362C000002045",
            "sku": "17690034",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444363400000204A",
            "sku": "17690058",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "30340000444363400000204B",
            "sku": "17690058",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "3034000044436500000027C3",
            "sku": "17690126",
            "department": "911 - WALLET",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "950 - D.S. BRANDS",
            "shipment_number": "null"
        },
        {
            "date": "2021-05-09",
            "asn": "TRA112",
            "tag_id": "3034000044440A00000135F5",
            "sku": "17696722",
            "department": "280 - BLOUSON",
            "original_location": "0001130",
            "destination": null,
            "process_status": "open",
            "brand": "233 - NEIL BARRET MAN",
            "shipment_number": "null"
        }
    ],
    "iTotalRecords": "9",
    "iTotalDisplayRecords": "9"
}

export const goodStockData = {
    "aaData": [
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20857615",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400005053C0400026E2ED",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20987565",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000050607100002800A8",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20696382",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000504401800027783E",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "16693159",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400004043B0C000289CE8",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21196157",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000541327C000286BDC",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "19201290",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400004C13A8400006049C",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "19367514",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400004C23E3C000064B00",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20309299",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000501E344000060CE1",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "18844566",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000048527A0000030F7D",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21177040",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000054114A0000285D50",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20910334",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400005058E6400027392E",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21167034",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000054104FC00028931E",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "18274509",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000481ACE80002746F7",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21011306",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000054011A80002807F3",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20619565",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000503C81000007BA00",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20444709",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000502B6D8000264742",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20255558",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400005018F4C0000668E5",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21179990",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000541193C000287C95",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20700010",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "3034000050445C40002705B8",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "15287946",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400003C1C1E8000289CE9",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "21007514",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400005400BBC00027F600",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "19551869",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400004C35E4800000F1AE",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "18843101",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000485255800003155D",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "20611446",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "30340000503BB60000274289",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        },
        {
            "date": "2022-09-13 23:36:36",
            "refno": "18849417",
            "retail_item_batch_id": "426879074427771",
            "supplier_number": "",
            "shipment_number": "",
            "store": "0002151",
            "purchase_order": "",
            "epc": "303400004852F3400002E78F",
            "remarks": "",
            "id": "store2151",
            "comments": ""
        }
    ],
    "iTotalRecords": "115015",
    "iTotalDisplayRecords": "115015"
}
export const goodSummaryData = {
    "aaData": [
        {
            "store": "0001002",
            "retail_item_batch_id": "<button type=\"button\" store_id=0001002 retail_item_batch_id=6290529215473090 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">6290529215473090</button>",
            "item_count": "<button type=\"button\" store_id=0001002 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-05-13 21:01:16"
        },
        {
            "store": "0001002",
            "retail_item_batch_id": "<button type=\"button\" store_id=0001002 retail_item_batch_id=8089109125762740 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">8089109125762740</button>",
            "item_count": "<button type=\"button\" store_id=0001002 item_count=450 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">450</button>",
            "date": "2022-05-13 21:02:37"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=3300320604616732 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">3300320604616732</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=2412 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2412</button>",
            "date": "2022-05-25 17:32:56"
        },
        {
            "store": "0002146",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002146 retail_item_batch_id=4787411213319556 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">4787411213319556</button>",
            "item_count": "<button type=\"button\" store_id=0002146 item_count=35735 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">35735</button>",
            "date": "2022-05-25 18:10:34"
        },
        {
            "store": "0002146",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002146 retail_item_batch_id=4854386194960870 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">4854386194960870</button>",
            "item_count": "<button type=\"button\" store_id=0002146 item_count=4106 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">4106</button>",
            "date": "2022-05-26 19:25:26"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=6027395387489470 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">6027395387489470</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=4106 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">4106</button>",
            "date": "2022-05-31 13:56:00"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=8163153474773700 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">8163153474773700</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=4106 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">4106</button>",
            "date": "2022-07-20 02:28:32"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=2504924658995420 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2504924658995420</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-20 02:53:33"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=8838848560095830 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">8838848560095830</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-21 03:12:49"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=0599086183689640 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">0599086183689640</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-21 03:19:09"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=3553293988737180 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">3553293988737180</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-21 04:24:10"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=9336307267778260 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9336307267778260</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 00:03:39"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=5344643494074190 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">5344643494074190</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=3 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">3</button>",
            "date": "2022-07-22 00:11:29"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=2572587549262320 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2572587549262320</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 00:23:06"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=7668023539132250 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">7668023539132250</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 00:26:07"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=2615478383469470 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">2615478383469470</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 03:32:16"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=6764304314563730 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">6764304314563730</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 03:47:33"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=6517524692577368 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">6517524692577368</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-22 16:37:30"
        },
        {
            "store": "0002171",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002171 retail_item_batch_id=9837419726919092 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9837419726919092</button>",
            "item_count": "<button type=\"button\" store_id=0002171 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-23 17:01:06"
        },
        {
            "store": "0002150",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002150 retail_item_batch_id=3715478549012410 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">3715478549012410</button>",
            "item_count": "<button type=\"button\" store_id=0002150 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-07-27 18:53:35"
        },
        {
            "store": "0002171",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002171 retail_item_batch_id=7143004765250340 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">7143004765250340</button>",
            "item_count": "<button type=\"button\" store_id=0002171 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-08-01 16:24:26"
        },
        {
            "store": "0002171",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002171 retail_item_batch_id=712354648931957.5 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">712354648931957.5</button>",
            "item_count": "<button type=\"button\" store_id=0002171 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-08-01 22:03:15"
        },
        {
            "store": "0002115",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002115 retail_item_batch_id=7871324287015290 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">7871324287015290</button>",
            "item_count": "<button type=\"button\" store_id=0002115 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-08-01 22:18:11"
        },
        {
            "store": "0002171",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002171 retail_item_batch_id=1315634056077336 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1315634056077336</button>",
            "item_count": "<button type=\"button\" store_id=0002171 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-08-02 03:30:37"
        },
        {
            "store": "0002171",
            "retail_item_batch_id": "<button type=\"button\" store_id=0002171 retail_item_batch_id=9952768553985280 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">9952768553985280</button>",
            "item_count": "<button type=\"button\" store_id=0002171 item_count=1 class=\"btn goods_info btn-default\" style=\"color:#fff;border:0;border-radius:0px;background:transparent;width:50%;border-bottom: 1px solid blue;\">1</button>",
            "date": "2022-08-02 20:17:09"
        }
    ],
    "iTotalRecords": "35",
    "iTotalDisplayRecords": "35"
}
export const goodDetails = {
    "aaData": [
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303395",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA0C0002976E3",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303401",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA100002976FE",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304873",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DC5C0002976D8",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304569",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBE0000297701",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21305207",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DCE0000297691",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303685",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA800002976FD",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304019",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DB040002976CF",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304538",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBD40002976EC",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303623",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA68000297718",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304835",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DC4C000297714",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304866",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DC580002976F1",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304033",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DB0C000297689",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304859",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DC5400029770A",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303999",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DAFC0002976ED",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304514",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBCC000297682",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304248",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DB600002976EA",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304569",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBE0000297703",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303975",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DAF40002976F7",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304231",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DB5C0002976DD",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304057",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DB1400029770E",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303425",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA18000297709",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304521",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBD00002976E8",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303982",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DAF80002976F8",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21304545",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DBD80002976E7",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        },
        {
            "date": "2022-05-13 21:08:38",
            "refno": "21303425",
            "retail_item_batch_id": "8089109125762740",
            "supplier_number": "2157",
            "shipment_number": "FRAF0530-22",
            "store": "0001002",
            "purchase_order": "",
            "epc": "30340000541DA18000297708",
            "remarks": "",
            "id": "store1002",
            "comments": "RTW"
        }
    ],
    "iTotalRecords": "451",
    "iTotalDisplayRecords": "451"
}

export const InventoryData = [
        {
            "ItemDispostion":null,
            "brand_name":"950 - D.S. BRANDS",
            "color":"MISC",
            "epc":"303400002C1391C000000B27",
            "item_code":"11200390",
            "size":"180ML",
            "store_id":"0002150"
        },
        {
            "ItemDispostion":null,
            "brand_name":"950 - D.S. BRANDS",
            "color":"MISC",
            "epc":"303400002C1391C000000B27",
            "item_code":"11200390",
            "size":"180ML",
            "store_id":"0002150"
        },
        {
            "ItemDispostion":null,
            "brand_name":"950 - D.S. BRANDS",
            "color":"MISC",
            "epc":"303400002C1391C000000B27",
            "item_code":"11200390",
            "size":"180ML",
            "store_id":"0002150"
        },
        {
            "ItemDispostion":null,
            "brand_name":"950 - D.S. BRANDS",
            "color":"MISC",
            "epc":"303400002C1391C000000B27",
            "item_code":"11200390",
            "size":"180ML",
            "store_id":"0002150"
        },
    ]