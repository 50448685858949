import React, { Component } from "react";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import Colorbtn from "../../components/Colorbtn";
import { withRouter } from "../../components/withRouter";
class Adminmenu extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Admin Menu</p></div>
                    
                </div>
                <div className="admin-menu-bottom">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/handlecornjob')}
                                background={"#0069D9"}
                                value={"Handle CornJob"}
                            />
                        </div>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/sohdetail')}
                                background={"#545B62"}
                                value={"Soh Handle "}
                            />
                        </div>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/zpl')}
                                background={"#218838"}
                                value={"Notification "}
                            />
                        </div>


                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/adminerrorlog')}
                                background={"#C82333"}
                                value={"Error Log"}
                            />
                        </div>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/problemasn')}
                                background={"#E0A800"}
                                value={"Problem Asn "}
                            />
                        </div>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/stocksummaryextra')}
                                background={"#138496"}
                                value={"Stock Summary Extra "}
                            />
                        </div>


                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/enterprisereportold')}
                                background={"#0069D9"}
                                value={"Enterprise Report old"}
                            />
                        </div>

                        <div>
                            <Colorbtn

                                background={"#5A6268"}
                                value={"Run Cronjob "}
                            />
                        </div>
                        <Colorbtn
                            onClick={() => this.props.history('/dailystockcountreport')}
                            background={"#218838"}
                            value={"Enterprise Report"}
                        />

                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/storepayload')}
                                background={"#218838"}
                                value={"Store Payload"}
                            />
                        </div>
                        <div>
                            <Colorbtn
                                onClick={() => this.props.history('/enterprisereport')}
                                background={"#0069D9"}
                                value={"Enterprise Report "}
                            />
                        </div>
                        <div style={{ width: "250px" }}>

                        </div>



                    </div>









                </div>
            </div>


        )
    }
}
export default withRouter(Adminmenu)