import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"


export default class AdminErrorLog extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Admin Error Log</p></div>
                    <button className="run-btn"><p className="run-text">Clear</p></button>
                </div>
                <div className="dashboard-bottom">
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Date</th>
                                    <th>File Name</th>
                                    <th>View File</th>

                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>


                                            <td>{
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <button className="action-btn"><p className="action-txt">View</p></button>
                                                </div>
                                            }</td>


                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}