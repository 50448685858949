import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput";
import { PaginatedItems } from "../components/Paginated";
import { withRouter } from "../components/withRouter";
import { getCustomItem, getSoh } from "../Path/Path";


class OverallUnderOver extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:[],
            item:[],
            OverallUnderOver: [
                {
                    Department: "uans",
                    Brand: "mix",
                    SKU: "5",
                    Expected: "5",
                    Diff: "0",
    
    
                }
            ],
            itemsPerPage: 10
        }
    }

    async componentDidMount(){
        const data = await getSoh();
        const items = await getCustomItem();
        this.setState({data:data, item:items})
    }

    render() {
        const headers = [
            "Department",
            "Brand",
            "SKU",
            "Expected",
            "Diff",

        ]
        const colNames = [

            "Department",
            "Brand",
            "SKU",
            "Expected",
            "Diff",

        ]
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">All Under Over</p></div>
                    <div style={{ display: "flex", width: "13%", justifyContent: "space-between", marginTop: "0.3%", alignItems: 'center' }}>
                        <div >
                            <img src={zoom} alt="zoom here" />
                        </div>
                        <div>
                            <img src={print} alt="print here" />
                        </div>
                        <div>
                            <img width={30} height={30} src={download} alt="download here" />
                        </div>
                    </div>
                </div>
                <div className="dashboard-bottom">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="filter-text">Filters</p>
                    </div>
                    <div className="drop-data" style={{ width: "65%" }}>
                        <MyInput
                            type="date"
                        />
                        <MyInput
                            type="date"
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p className="show">Show Overs</p>
                            <input type="checkbox" size={30}></input>
                        </div>
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>

                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div className="dashboard-main2">
                            <div className="dashboard-top2">
                                <div> <p className="stock-count">All Unders</p></div>

                            </div>

                            <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.OverallUnderOver} />

                        </div>
                        <div className="dashboard-main2">
                            <div className="dashboard-top2">
                                <div> <p className="stock-count">All Over</p></div>

                            </div>

                            <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.OverallUnderOver} />

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default withRouter(OverallUnderOver);