
import React from 'react';
import {
    BsFillAlarmFill,
    BsFillArchiveFill,
    BsFillArrowDownCircleFill,
    BsFillArrowDownLeftCircleFill,
    BsFillArrowDownLeftSquareFill,
    BsFillArrowDownRightCircleFill,
    BsFillArrowDownRightSquareFill,
    BsFillArrowDownSquareFill,
    BsFillArrowLeftCircleFill,
    BsFillArrowLeftSquareFill,
    BsFillArrowRightCircleFill,
    BsFillArrowRightSquareFill,
    BsFillArrowUpCircleFill,
    BsFillArrowUpLeftCircleFill,
    BsFillArrowUpLeftSquareFill,
    BsFillArrowUpRightCircleFill,
    BsFillArrowUpRightSquareFill,
    BsFillArrowUpSquareFill,
    BsFillAspectRatioFill,
    BsFillAwardFill,
    BsFillBackspaceFill,
    BsFillBackspaceReverseFill,
    BsFillBadge3DFill,
    BsFillBadge4KFill,
    BsFillBadge8KFill,
    BsFillBadgeAdFill,
    BsFillBadgeArFill,
    BsFillBadgeCcFill,
    BsFillBadgeHdFill,
    BsFillBadgeTmFill,
    BsFillBadgeVoFill,
    BsFillBadgeVrFill,
    BsFillBadgeWcFill,
    BsFillBagCheckFill,
    BsFillBagDashFill,
    BsFillBagFill,
    BsFillBagPlusFill,
    BsFillBagXFill,
    BsFillBarChartFill,
    BsFillBarChartLineFill,
    BsFillBasketFill,
    BsFillBasket2Fill,
    BsFillBasket3Fill,
    BsFillBellFill,
    BsFillBellSlashFill,
    BsFillBinocularsFill,
    BsFillBookFill,
    BsFillBookmarkCheckFill,
    BsFillBookmarkDashFill,
    BsFillBookmarkFill,
    BsFillBookmarkHeartFill,
    BsFillBookmarkPlusFill,
    BsFillBookmarkStarFill,
    BsFillBookmarkXFill,
    BsFillBookmarksFill,
    BsFillBootstrapFill,
    BsFillBriefcaseFill,
    BsFillBrightnessAltHighFill,
    BsFillBrightnessAltLowFill,
    BsFillBrightnessHighFill,
    BsFillBrightnessLowFill,
    BsFillBrushFill,
    BsFillBucketFill,
    BsFillBugFill,
    BsFillCalculatorFill,
    BsFillCalendarCheckFill,
    BsFillCalendarDateFill,
    BsFillCalendarDayFill,
    BsFillCalendarEventFill,
    BsFillCalendarFill,
    BsFillCalendarMinusFill,
    BsFillCalendarMonthFill,
    BsFillCalendarPlusFill,
    BsFillCalendarRangeFill,
    BsFillCalendarWeekFill,
    BsFillCalendarXFill,
    BsFillCalendar2CheckFill,
    BsFillCalendar2DateFill,
    BsFillCalendar2DayFill,
    BsFillCalendar2EventFill,
    BsFillCalendar2Fill,
    BsFillCalendar2MinusFill,
    BsFillCalendar2MonthFill,
    BsFillCalendar2PlusFill,
    BsFillCalendar2RangeFill,
    BsFillCalendar2WeekFill,
    BsFillCalendar2XFill,
    BsFillCalendar3EventFill,
    BsFillCalendar3Fill,
    BsFillCalendar3RangeFill,
    BsFillCalendar3WeekFill,
    BsFillCameraFill,
    BsFillCameraReelsFill,
    BsFillCameraVideoFill,
    BsFillCameraVideoOffFill,
    BsFillCapslockFill,
    BsFillCaretDownFill,
    BsFillCaretDownSquareFill,
    BsFillCaretLeftFill,
    BsFillCaretLeftSquareFill,
    BsFillCaretRightFill,
    BsFillCaretRightSquareFill,
    BsFillCaretUpFill,
    BsFillCaretUpSquareFill,
    BsFillCartCheckFill,
    BsFillCartDashFill,
    BsFillCartFill,
    BsFillCartPlusFill,
    BsFillCartXFill,
    BsFillChatDotsFill,
    BsFillChatFill,
    BsFillChatLeftDotsFill,
    BsFillChatLeftFill,
    BsFillChatLeftQuoteFill,
    BsFillChatLeftTextFill,
    BsFillChatQuoteFill,
    BsFillChatRightDotsFill,
    BsFillChatRightFill,
    BsFillChatRightQuoteFill,
    BsFillChatRightTextFill,
    BsFillChatSquareDotsFill,
    BsFillChatSquareFill,
    BsFillChatSquareQuoteFill,
    BsFillChatSquareTextFill,
    BsFillChatTextFill,
    BsFillCheckCircleFill,
    BsFillCheckSquareFill,
    BsFillCircleFill,
    BsFillClockFill,
    BsFillCloudArrowDownFill,
    BsFillCloudArrowUpFill,
    BsFillCloudCheckFill,
    BsFillCloudDownloadFill,
    BsFillCloudDrizzleFill,
    BsFillCloudFill,
    BsFillCloudFogFill,
    BsFillCloudFog2Fill,
    BsFillCloudHailFill,
    BsFillCloudHazeFill,
    BsFillCloudHaze2Fill,
    BsFillCloudLightningFill,
    BsFillCloudLightningRainFill,
    BsFillCloudMinusFill,
    BsFillCloudMoonFill,
    BsFillCloudPlusFill,
    BsFillCloudRainFill,
    BsFillCloudRainHeavyFill,
    BsFillCloudSlashFill,
    BsFillCloudSleetFill,
    BsFillCloudSnowFill,
    BsFillCloudSunFill,
    BsFillCloudUploadFill,
    BsFillCloudsFill,
    BsFillCloudyFill,
    BsFillCollectionFill,
    BsFillCollectionPlayFill,
    BsFillCompassFill,
    BsFillCpuFill,
    BsFillCreditCard2BackFill,
    BsFillCreditCard2FrontFill,
    BsFillCreditCardFill,
    BsFillCupFill,
    BsFillCursorFill,
    BsFillDashCircleFill,
    BsFillDashSquareFill,
    BsFillDiagram2Fill,
    BsFillDiagram3Fill,
    BsFillDiamondFill,
    BsFillDice1Fill,
    BsFillDice2Fill,
    BsFillDice3Fill,
    BsFillDice4Fill,
    BsFillDice5Fill,
    BsFillDice6Fill,
    BsFillDiscFill,
    BsFillDisplayFill,
    BsFillDoorClosedFill,
    BsFillDoorOpenFill,
    BsFillDropletFill,
    BsFillEaselFill,
    BsFillEggFill,
    BsFillEjectFill,
    BsFillEmojiAngryFill,
    BsFillEmojiDizzyFill,
    BsFillEmojiExpressionlessFill,
    BsFillEmojiFrownFill,
    BsFillEmojiHeartEyesFill,
    BsFillEmojiLaughingFill,
    BsFillEmojiNeutralFill,
    BsFillEmojiSmileFill,
    BsFillEmojiSmileUpsideDownFill,
    BsFillEmojiSunglassesFill,
    BsFillEmojiWinkFill,
    BsFillEnvelopeFill,
    BsFillEnvelopeOpenFill,
    BsFillEraserFill,
    BsFillExclamationCircleFill,
    BsFillExclamationDiamondFill,
    BsFillExclamationOctagonFill,
    BsFillExclamationSquareFill,
    BsFillExclamationTriangleFill,
    BsFillEyeFill,
    BsFillEyeSlashFill,
    BsFillFileArrowDownFill,
    BsFillFileArrowUpFill,
    BsFillFileBarGraphFill,
    BsFillFileBinaryFill,
    BsFillFileBreakFill,
    BsFillFileCheckFill,
    BsFillFileCodeFill,
    BsFillFileDiffFill,
    BsFillFileEarmarkArrowDownFill,
    BsFillFileEarmarkArrowUpFill,
    BsFillFileEarmarkBarGraphFill,
    BsFillFileEarmarkBinaryFill,
    BsFillFileEarmarkBreakFill,
    BsFillFileEarmarkCheckFill,
    BsFillFileEarmarkCodeFill,
    BsFillFileEarmarkDiffFill,
    BsFillFileEarmarkEaselFill,
    BsFillFileEarmarkExcelFill,
    BsFillFileEarmarkFill,
    BsFillFileEarmarkFontFill,
    BsFillFileEarmarkImageFill,
    BsFillFileEarmarkLockFill,
    BsFillFileEarmarkLock2Fill,
    BsFillFileEarmarkMedicalFill,
    BsFillFileEarmarkMinusFill,
    BsFillFileEarmarkMusicFill,
    BsFillFileEarmarkPdfFill,
    BsFillFileEarmarkPersonFill,
    BsFillFileEarmarkPlayFill,
    BsFillFileEarmarkPlusFill,
    BsFillFileEarmarkPostFill,
    BsFillFileEarmarkPptFill,
    BsFillFileEarmarkRichtextFill,
    BsFillFileEarmarkRuledFill,
    BsFillFileEarmarkSlidesFill,
    BsFillFileEarmarkSpreadsheetFill,
    BsFillFileEarmarkTextFill,
    BsFillFileEarmarkWordFill,
    BsFillFileEarmarkXFill,
    BsFillFileEarmarkZipFill,
    BsFillFileEaselFill,
    BsFillFileExcelFill,
    BsFillFileFill,
    BsFillFileFontFill,
    BsFillFileImageFill,
    BsFillFileLockFill,
    BsFillFileLock2Fill,
    BsFillFileMedicalFill,
    BsFillFileMinusFill,
    BsFillFileMusicFill,
    BsFillFilePdfFill,
    BsFillFilePersonFill,
    BsFillFilePlayFill,
    BsFillFilePlusFill,
    BsFillFilePostFill,
    BsFillFilePptFill,
    BsFillFileRichtextFill,
    BsFillFileRuledFill,
    BsFillFileSlidesFill,
    BsFillFileSpreadsheetFill,
    BsFillFileTextFill,
    BsFillFileWordFill,
    BsFillFileXFill,
    BsFillFileZipFill,
    BsFillFilterCircleFill,
    BsFillFilterSquareFill,
    BsFillFlagFill,
    BsFillFolderFill,
    BsFillFolderSymlinkFill,
    BsFillForwardFill,
    BsFillFunnelFill,
    BsFillGearFill,
    BsFillGeoAltFill,
    BsFillGeoFill,
    BsZoomIn,

} from 'react-icons/bs'
const iconsList = [
    "BsFillAlarmFill",
    "BsFillArchiveFill",
    "BsFillArrowDownCircleFill",
    "BsFillArrowDownLeftCircleFill",
    "BsFillArrowDownLeftSquareFill",
    "BsFillArrowDownRightCircleFill",
    "BsFillArrowDownRightSquareFill",
    "BsFillArrowDownSquareFill",
    "BsFillArrowLeftCircleFill",
    "BsFillArrowLeftSquareFill",
    "BsFillArrowRightCircleFill",
    "BsFillArrowRightSquareFill",
    "BsFillArrowUpCircleFill",
    "BsFillArrowUpLeftCircleFill",
    "BsFillArrowUpLeftSquareFill",
    "BsFillArrowUpRightCircleFill",
    "BsFillArrowUpRightSquareFill",
    "BsFillArrowUpSquareFill",
    "BsFillAspectRatioFill",
    "BsFillAwardFill",
    "BsFillBackspaceFill",
    "BsFillBackspaceReverseFill",
    "BsFillBadge3DFill",
    "BsFillBadge4KFill",
    "BsFillBadge8KFill",
    "BsFillBadgeAdFill",
    "BsFillBadgeArFill",
    "BsFillBadgeCcFill",
    "BsFillBadgeHdFill",
    "BsFillBadgeTmFill",
    "BsFillBadgeVoFill",
    "BsFillBadgeVrFill",
    "BsFillBadgeWcFill",
    "BsFillBagCheckFill",
    "BsFillBagDashFill",
    "BsFillBagFill",
    "BsFillBagPlusFill",
    "BsFillBagXFill",
    "BsFillBarChartFill",
    "BsFillBarChartLineFill",
    "BsFillBasketFill",
    "BsFillBasket2Fill",
    "BsFillBasket3Fill",
    "BsFillBellFill",
    "BsFillBellSlashFill",
    "BsFillBinocularsFill",
    "BsFillBookFill",
    "BsFillBookmarkCheckFill",
    "BsFillBookmarkDashFill",
    "BsFillBookmarkFill",
    "BsFillBookmarkHeartFill",
    "BsFillBookmarkPlusFill",
    "BsFillBookmarkStarFill",
    "BsFillBookmarkXFill",
    "BsFillBookmarksFill",
    "BsFillBootstrapFill",
    "BsFillBriefcaseFill",
    "BsFillBrightnessAltHighFill",
    "BsFillBrightnessAltLowFill",
    "BsFillBrightnessHighFill",
    "BsFillBrightnessLowFill",
    "BsFillBrushFill",
    "BsFillBucketFill",
    "BsFillBugFill",
    "BsFillCalculatorFill",
    "BsFillCalendarCheckFill",
    "BsFillCalendarDateFill",
    "BsFillCalendarDayFill",
    "BsFillCalendarEventFill",
    "BsFillCalendarFill",
    "BsFillCalendarMinusFill",
    "BsFillCalendarMonthFill",
    "BsFillCalendarPlusFill",
    "BsFillCalendarRangeFill",
    "BsFillCalendarWeekFill",
    "BsFillCalendarXFill",
    "BsFillCalendar2CheckFill",
    "BsFillCalendar2DateFill",
    "BsFillCalendar2DayFill",
    "BsFillCalendar2EventFill",
    "BsFillCalendar2Fill",
    "BsFillCalendar2MinusFill",
    "BsFillCalendar2MonthFill",
    "BsFillCalendar2PlusFill",
    "BsFillCalendar2RangeFill",
    "BsFillCalendar2WeekFill",
    "BsFillCalendar2XFill",
    "BsFillCalendar3EventFill",
    "BsFillCalendar3Fill",
    "BsFillCalendar3RangeFill",
    "BsFillCalendar3WeekFill",
    "BsFillCameraFill",
    "BsFillCameraReelsFill",
    "BsFillCameraVideoFill",
    "BsFillCameraVideoOffFill",
    "BsFillCapslockFill",
    "BsFillCaretDownFill",
    "BsFillCaretDownSquareFill",
    "BsFillCaretLeftFill",
    "BsFillCaretLeftSquareFill",
    "BsFillCaretRightFill",
    "BsFillCaretRightSquareFill",
    "BsFillCaretUpFill",
    "BsFillCaretUpSquareFill",
    "BsFillCartCheckFill",
    "BsFillCartDashFill",
    "BsFillCartFill",
    "BsFillCartPlusFill",
    "BsFillCartXFill",
    "BsFillChatDotsFill",
    "BsFillChatFill",
    "BsFillChatLeftDotsFill",
    "BsFillChatLeftFill",
    "BsFillChatLeftQuoteFill",
    "BsFillChatLeftTextFill",
    "BsFillChatQuoteFill",
    "BsFillChatRightDotsFill",
    "BsFillChatRightFill",
    "BsFillChatRightQuoteFill",
    "BsFillChatRightTextFill",
    "BsFillChatSquareDotsFill",
    "BsFillChatSquareFill",
    "BsFillChatSquareQuoteFill",
    "BsFillChatSquareTextFill",
    "BsFillChatTextFill",
    "BsFillCheckCircleFill",
    "BsFillCheckSquareFill",
    "BsFillCircleFill",
    "BsFillClockFill",
    "BsFillCloudArrowDownFill",
    "BsFillCloudArrowUpFill",
    "BsFillCloudCheckFill",
    "BsFillCloudDownloadFill",
    "BsFillCloudDrizzleFill",
    "BsFillCloudFill",
    "BsFillCloudFogFill",
    "BsFillCloudFog2Fill",
    "BsFillCloudHailFill",
    "BsFillCloudHazeFill",
    "BsFillCloudHaze2Fill",
    "BsFillCloudLightningFill",
    "BsFillCloudLightningRainFill",
    "BsFillCloudMinusFill",
    "BsFillCloudMoonFill",
    "BsFillCloudPlusFill",
    "BsFillCloudRainFill",
    "BsFillCloudRainHeavyFill",
    "BsFillCloudSlashFill",
    "BsFillCloudSleetFill",
    "BsFillCloudSnowFill",
    "BsFillCloudSunFill",
    "BsFillCloudUploadFill",
    "BsFillCloudsFill",
    "BsFillCloudyFill",
    "BsFillCollectionFill",
    "BsFillCollectionPlayFill",
    "BsFillCompassFill",
    "BsFillCpuFill",
    "BsFillCreditCard2BackFill",
    "BsFillCreditCard2FrontFill",
    "BsFillCreditCardFill",
    "BsFillCupFill",
    "BsFillCursorFill",
    "BsFillDashCircleFill",
    "BsFillDashSquareFill",
    "BsFillDiagram2Fill",
    "BsFillDiagram3Fill",
    "BsFillDiamondFill",
    "BsFillDice1Fill",
    "BsFillDice2Fill",
    "BsFillDice3Fill",
    "BsFillDice4Fill",
    "BsFillDice5Fill",
    "BsFillDice6Fill",
    "BsFillDiscFill",
    "BsFillDisplayFill",
    "BsFillDoorClosedFill",
    "BsFillDoorOpenFill",
    "BsFillDropletFill",
    "BsFillEaselFill",
    "BsFillEggFill",
    "BsFillEjectFill",
    "BsFillEmojiAngryFill",
    "BsFillEmojiDizzyFill",
    "BsFillEmojiExpressionlessFill",
    "BsFillEmojiFrownFill",
    "BsFillEmojiHeartEyesFill",
    "BsFillEmojiLaughingFill",
    "BsFillEmojiNeutralFill",
    "BsFillEmojiSmileFill",
    "BsFillEmojiSmileUpsideDownFill",
    "BsFillEmojiSunglassesFill",
    "BsFillEmojiWinkFill",
    "BsFillEnvelopeFill",
    "BsFillEnvelopeOpenFill",
    "BsFillEraserFill",
    "BsFillExclamationCircleFill",
    "BsFillExclamationDiamondFill",
    "BsFillExclamationOctagonFill",
    "BsFillExclamationSquareFill",
    "BsFillExclamationTriangleFill",
    "BsFillEyeFill",
    "BsFillEyeSlashFill",
    "BsFillFileArrowDownFill",
    "BsFillFileArrowUpFill",
    "BsFillFileBarGraphFill",
    "BsFillFileBinaryFill",
    "BsFillFileBreakFill",
    "BsFillFileCheckFill",
    "BsFillFileCodeFill",
    "BsFillFileDiffFill",
    "BsFillFileEarmarkArrowDownFill",
    "BsFillFileEarmarkArrowUpFill",
    "BsFillFileEarmarkBarGraphFill",
    "BsFillFileEarmarkBinaryFill",
    "BsFillFileEarmarkBreakFill",
    "BsFillFileEarmarkCheckFill",
    "BsFillFileEarmarkCodeFill",
    "BsFillFileEarmarkDiffFill",
    "BsFillFileEarmarkEaselFill",
    "BsFillFileEarmarkExcelFill",
    "BsFillFileEarmarkFill",
    "BsFillFileEarmarkFontFill",
    "BsFillFileEarmarkImageFill",
    "BsFillFileEarmarkLockFill",
    "BsFillFileEarmarkLock2Fill",
    "BsFillFileEarmarkMedicalFill",
    "BsFillFileEarmarkMinusFill",
    "BsFillFileEarmarkMusicFill",
    "BsFillFileEarmarkPdfFill",
    "BsFillFileEarmarkPersonFill",
    "BsFillFileEarmarkPlayFill",
    "BsFillFileEarmarkPlusFill",
    "BsFillFileEarmarkPostFill",
    "BsFillFileEarmarkPptFill",
    "BsFillFileEarmarkRichtextFill",
    "BsFillFileEarmarkRuledFill",
    "BsFillFileEarmarkSlidesFill",
    "BsFillFileEarmarkSpreadsheetFill",
    "BsFillFileEarmarkTextFill",
    "BsFillFileEarmarkWordFill",
    "BsFillFileEarmarkXFill",
    "BsFillFileEarmarkZipFill",
    "BsFillFileEaselFill",
    "BsFillFileExcelFill",
    "BsFillFileFill",
    "BsFillFileFontFill",
    "BsFillFileImageFill",
    "BsFillFileLockFill",
    "BsFillFileLock2Fill",
    "BsFillFileMedicalFill",
    "BsFillFileMinusFill",
    "BsFillFileMusicFill",
    "BsFillFilePdfFill",
    "BsFillFilePersonFill",
    "BsFillFilePlayFill",
    "BsFillFilePlusFill",
    "BsFillFilePostFill",
    "BsFillFilePptFill",
    "BsFillFileRichtextFill",
    "BsFillFileRuledFill",
    "BsFillFileSlidesFill",
    "BsFillFileSpreadsheetFill",
    "BsFillFileTextFill",
    "BsFillFileWordFill",
    "BsFillFileXFill",
    "BsFillFileZipFill",
    "BsFillFilterCircleFill",
    "BsFillFilterSquareFill",
    "BsFillFlagFill",
    "BsFillFolderFill",
    "BsFillFolderSymlinkFill",
    "BsFillForwardFill",
    "BsFillFunnelFill",
    "BsFillGearFill",
    "BsFillGeoAltFill",
    "BsFillGeoFill",
    "BsZoomIn",
]
export class DropDownIcons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            menuItems: [
                {
                    icon: <BsFillAlarmFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArchiveFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownLeftCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownLeftSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownRightCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownRightSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowDownSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowLeftCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowLeftSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowRightCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowRightSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpLeftCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpLeftSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpRightCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpRightSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillArrowUpSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillAspectRatioFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillAwardFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBackspaceFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBackspaceReverseFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadge3DFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadge4KFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadge8KFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeAdFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeArFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeCcFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeHdFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeTmFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeVoFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeVrFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBadgeWcFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBagCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBagDashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBagFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBagPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBagXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBarChartFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBarChartLineFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBasketFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBasket2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBasket3Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBellFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBellSlashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBinocularsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkDashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkHeartFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkStarFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarkXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBookmarksFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBootstrapFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBriefcaseFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBrightnessAltHighFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBrightnessAltLowFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBrightnessHighFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBrightnessLowFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBrushFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBucketFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillBugFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalculatorFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarDateFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarDayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarEventFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarMinusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarMonthFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarRangeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarWeekFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendarXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2CheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2DateFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2DayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2EventFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2MinusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2MonthFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2PlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2RangeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2WeekFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar2XFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar3EventFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar3Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar3RangeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCalendar3WeekFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCameraFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCameraReelsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCameraVideoFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCameraVideoOffFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCapslockFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretDownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretDownSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretLeftFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretLeftSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretRightFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretRightSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretUpFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCaretUpSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCartCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCartDashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCartFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCartPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCartXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatDotsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatLeftDotsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatLeftFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatLeftQuoteFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatLeftTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatQuoteFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatRightDotsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatRightFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatRightQuoteFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatRightTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatSquareDotsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatSquareQuoteFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatSquareTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillChatTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCheckCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCheckSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillClockFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudArrowDownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudArrowUpFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudDownloadFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudDrizzleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudFogFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudFog2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudHailFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudHazeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudHaze2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudLightningFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudLightningRainFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudMinusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudMoonFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudRainFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudRainHeavyFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudSlashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudSleetFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudSnowFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudSunFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudUploadFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudsFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCloudyFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCollectionFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCollectionPlayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCompassFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCpuFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCreditCard2BackFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCreditCard2FrontFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCreditCardFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCupFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillCursorFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDashCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDashSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDiagram2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDiagram3Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDiamondFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice1Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice3Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice4Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice5Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDice6Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDiscFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDisplayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDoorClosedFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDoorOpenFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillDropletFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEaselFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEggFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEjectFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiAngryFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiDizzyFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiExpressionlessFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiFrownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiHeartEyesFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiLaughingFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiNeutralFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiSmileFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiSmileUpsideDownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiSunglassesFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEmojiWinkFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEnvelopeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEnvelopeOpenFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEraserFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillExclamationCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillExclamationDiamondFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillExclamationOctagonFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillExclamationSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillExclamationTriangleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEyeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillEyeSlashFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileArrowDownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileArrowUpFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileBarGraphFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileBinaryFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileBreakFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileCodeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileDiffFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkArrowDownFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkArrowUpFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkBarGraphFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkBinaryFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkBreakFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkCheckFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkCodeFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkDiffFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkEaselFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkExcelFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkFontFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkImageFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkLockFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkLock2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkMedicalFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkMinusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkMusicFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPdfFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPersonFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPlayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPostFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkPptFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkRichtextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkRuledFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkSlidesFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkSpreadsheetFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkWordFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEarmarkZipFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileEaselFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileExcelFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileFontFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileImageFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileLockFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileLock2Fill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileMedicalFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileMinusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileMusicFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePdfFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePersonFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePlayFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePlusFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePostFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilePptFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileRichtextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileRuledFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileSlidesFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileSpreadsheetFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileTextFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileWordFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileXFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFileZipFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilterCircleFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFilterSquareFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFlagFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFolderFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFolderSymlinkFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillForwardFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillFunnelFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillGearFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillGeoAltFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsFillGeoFill size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },
                {
                    icon: <BsZoomIn size={30} />,
                    onClick: (index, selectedIcon) => this.changeHandler(index, selectedIcon)
                },

            ]
        };

        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
    }

    toggle() {
        this.setState(previous => ({
            active: !previous.active
        }));
    }

    close() {
        this.setState({
            active: false
        });
    }
    changeHandler = (indexVal, selectedIcon) => {
        for (let index = 0; index < this.state.menuItems.length; index++) {
            const element = this.state.menuItems[index];
            if (index === indexVal) {
                this.props.selectedIcon(element.icon)
            }

        }

    }

    render() {
        const items = this.props.items;
        const active = this.state.active;


        return (

            //   <div className={`menu ${active ? 'expanded' : 'collapsed'}`}>
            <div style={{ padding: '10px', backgroundColor: 'black', height: 150, overflowY: 'auto ', width: 50 }}>
                {this.state.menuItems.map((i, index) => (
                    <li style={{ listStyle: 'none', color: 'white', width: '30px', marginTop: 10,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column' }} key={index} onClick={() => i.onClick(index, i.icons)}>
                        {i.icon}
                    </li>
                ))}
            </div>

        );
    }
}