import React, { Component } from "react";
import { deletestore, getTOWithStatus, getstores, getConsignmentWithStatus } from "../Path/Path";
import { withRouter } from "../components/withRouter";
import { toast } from 'react-toastify';
import { PaginatedItems } from "../components/Paginated";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../components/Loader";
import { DesktopDatePicker } from "@mui/x-date-pickers";
class PLCreated extends Component {
    state = {


        storename: "",
        storecountry: "",
        storecompany: "",
        tableFilter: [],
        itemsPerPage: 10,
        loading: false,


        allPOs: [],
        allPOsFilter: [],
        poPaginated: null,
        createdAt: null,
        storedata: [],
        selectedSource: "",

        enteredOrder_id: "",
        enteredConsignment_id: ""
    }
    componentDidMount() {
        this.getTO()
    }
    handler = () => {
        this.props.history("/addstore")
    }
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
        // return console.log([year, month, day].join('-'))
    }

    getTO = async (page = 1, date = "", source = "", order_id = "", enteredConsignment_id = "") => {
        this.setState({ loading: true })
        const userinfo = await getstores();
        this.setState({ storedata: userinfo, })
        const resp = await getConsignmentWithStatus(page, date, source, order_id, enteredConsignment_id);
        if (resp.success) {
            this.setState({ allPOs: resp?.data, allPOsFilter: resp?.data, poPaginated: resp })
        }

        this.setState({ loading: false })
    }

    searchHandler = () => {
        let date = this.state.createdAt ? `&createdAt[gte]=${this.formatDate(this.state.createdAt)}` : ""
        let source = this.state.selectedSource ? `&source=${this.state.selectedSource}` : ""

        let order_id = this.state.enteredOrder_id ? `&order_id=${this.state.enteredOrder_id}` : ""
        let enteredConsignment_id = this.state.enteredConsignment_id ? `&consignment_id=${this.state.enteredConsignment_id}` : ""

        this.getTO(this.state.poPaginated?.page, date, source, order_id, enteredConsignment_id)
    }
    render() {
        const headers = [

            "order_id",
            "consignment_id",
            "source",
            "OrderType",
            "status",
            "QTY",
            "createdAt",

        ]

        const colNames = [

            "order_id",
            "consignment_id",
            "source",
            "OrderType",
            "status",
            "created QTY",
            "createdAt",


        ]

        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count" style={{ fontSize: '25px', margin: 0 }}>PL (Created)</p>
                            <h1 style={{ color: 'black', fontSize: '15px' }}>{new Date().toLocaleString('hi-IN')} </h1>
                        </div>
                        {/* <button className="run-btn"><p className="add-text" onClick={() => this.handler()}>Add Store</p></button> */}
                    </div>
                    <div className="store-bottom">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", width: "720px" }}>
                            {/* <p className="filter-text">Filters</p> */}
                            <DesktopDatePicker
                                label="Created At"
                                clearable
                                showTodayButton
                                inputFormat="YYYY/MM/DD"
                                size={'small'}
                                value={this.state.createdAt}
                                onChange={(data) => { this.setState({ createdAt: data }); console.log(data, "data") }}
                                renderInput={(params) => <TextField sx={{ width: 170 }} size={'small'} {...params} />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size={'small'}
                                options={this.state.storedata?.map((item => item?.Warehouse)).reduce((noDupArr, entry) => {
                                    if (noDupArr?.includes(entry)) {
                                        return noDupArr;
                                    } else {
                                        return [...noDupArr, entry];
                                    }
                                }, []).filter((item => item !== null))}
                                onChange={(e, v) => this.setState({ selectedSource: v === null ? '' : v })}
                                onInputChange={(e) => this.setState({selectedSource:  e.target.value === 0 || e.target.value === undefined ? '' : e.target.value})}
                                sx={{ width: 235 }}
                                renderInput={(params) => <TextField
                                    {...params} label="Source" />}
                            />

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={[]}
                                onInputChange={(e) => this.setState({enteredOrder_id:  e.target.value === 0 || e.target.value === undefined ? '' : e.target.value})}
                                // onChange={(e, v) => setinvoiceNumberVal(v === null || v === undefined ? '' : v)}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField
                                    onChange={e => this.setState({ enteredOrder_id: e.target.value })}
                                    value={this.state.enteredOrder_id}
                                    {...params} label="Order_id" />}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                freeSolo={true}
                                size={'small'}

                                options={[]}
                                onInputChange={(e) => this.setState({enteredConsignment_id:  e.target.value === 0 || e.target.value === undefined ? '' : e.target.value})}
                                // onChange={(e, v) => setinvoiceNumberVal(v === null || v === undefined ? '' : v)}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField
                                    onChange={e => this.setState({ enteredConsignment_id: e.target.value })}
                                    value={this.state.enteredConsignment_id}
                                    {...params} label="Consignment_id" />}
                            />
                        </div>



                        <button className="run-btn" onClick={() => this.searchHandler()}><p className="run-text">Run</p></button>








                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <PaginatedItems navigation={"/PLCreatedDetail"} headers={headers} getData={(pageNumber) => this.getTO(pageNumber)} pageCountNew={this.state.poPaginated?.next + this.state.poPaginated?.previous + 1} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.allPOsFilter} />
                    </div>
                </div>
            </>

        )
    }
}
export default withRouter(PLCreated);
