import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";

// Example items, to simulate fetching from another resources.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({
  navigation,
  currentItems,
  headers,
  tableBody,
  colNames,
  Action,
  Permissions,
  editHandler,
  deleteHandler,
  QRCode,
  modalopen,
  showPermissionHandler,
  OnclickQTY,
}) {
  const getNested = function (theObject, path, separator) {
    try {
      separator = separator || ".";

      return path
        .replace(/\[/gi, separator)
        .replace(/\]/gi, "")
        .split(separator)
        .reduce(function (obj, property) {
          return obj[property];
        }, theObject);
    } catch (err) {
      return undefined;
    }
  };
  return (
    <>
      <table data-table-theme="dark zebra">
        <thead>
          <tr style={{ background: "#000" }}>
            {headers.map((item) => (
              <th style={{ background: "#000" }}>{item ? item : "--"}</th>
            ))}
            {QRCode && <th style={{ background: "#000" }}>QR Code</th>}
            {Permissions && <th style={{ background: "#000" }}>Permissions</th>}
            {Action && <th style={{ background: "#000" }}>Action</th>}
          </tr>
        </thead>
        {currentItems &&
          currentItems.map((item) => (
            <tbody>
              <tr style={{ background: "#373E43" }}>
                {colNames.map((name, index) => {
                  return name === "created QTY" ? (
                    <td onClick={() => OnclickQTY(item?.items)}>
                      {" "}
                      <NavLink to={navigation} state={{ data: item?.items }}>
                        {item?.items?.length}
                      </NavLink>
                    </td>
                  ) : (
                    <td>
                      {getNested(item, name) === undefined
                        ? "--"
                        : name === "createdAt"
                        ? moment(getNested(item, name))?.format(
                            "YYYY-MM-DD hh:mm:ss A"
                          )
                        : getNested(item, name)}
                    </td>
                  );
                })}
                {QRCode && (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {
                      <div>
                        <button
                          className="action-btn"
                          onClick={() => (modalopen ? modalopen(item) : null)}
                          style={{
                            backgroundColor: "transparent",
                            border: "1 px solid white",
                            borderRadius: "10px",
                          }}
                        >
                          <p className="action-txt">QR code</p>
                        </button>
                      </div>
                    }
                  </td>
                )}
                {Permissions && (
                  <td style={{ width: "200px" }}>
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="action-btn"
                          style={{
                            backgroundColor: "#0069D9",
                            border: "none",
                            borderRadius: "10px",
                          }}
                          onClick={() =>
                            showPermissionHandler
                              ? showPermissionHandler(item)
                              : null
                          }
                        >
                          <p className="action-txt">View</p>
                        </button>
                      </div>
                    }
                  </td>
                )}

                {Action && (
                  <td style={{ width: "200px" }}>
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <button
                            className="action-btn"
                            style={{
                              backgroundColor: "#0069D9",
                              border: "none",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              editHandler ? editHandler(item) : null
                            }
                          >
                            <p className="action-txt">Edit</p>
                          </button>
                        </div>
                        <div>
                          <button
                            className="action-btn"
                            style={{
                              backgroundColor: "#E41119",
                              border: "none",
                              borderRadius: "10px",
                            }}
                            onClick={() =>
                              deleteHandler ? deleteHandler(item) : null
                            }
                          >
                            <p className="action-txt">Delete</p>
                          </button>
                        </div>
                      </div>
                    }
                  </td>
                )}
              </tr>
            </tbody>
          ))}
      </table>
      {/* {currentItems &&
                currentItems.map((item) => (
                    <div>
                        <h3>Item #{item.serialNumber}</h3>
                    </div>
                ))} */}
    </>
  );
}

export const PaginatedItems = ({
  forcePage,
  itemsPerPage,
  data,
  headers,
  pageCountNew,
  colNames,
  Action,
  Permissions,
  editHandler,
  deleteHandler,
  getData,
  QRCode,
  modalopen,
  showPermissionHandler,
  refresh,
  totalLength = 10,
  OnclickQTY,
  navigation,
}) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(endOffset, "end");
    console.log(itemOffset, "itemOffset");
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(0, 10));
    setPageCount(pageCountNew);
  }, [itemOffset, itemsPerPage, data]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log(event.selected);
    const newOffset = event.selected % totalLength;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    console.log(`totalLength ${totalLength}`);
    setItemOffset(newOffset);

    getData(event.selected + 1);
  };
  useEffect(() => {
    const newOffset = 1;
    console.log(
      `User requested page number ${0}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  }, [refresh, data, itemsPerPage]);

  return (
    <>
      <Items
        {...{ navigation }}
        headers={headers}
        colNames={colNames}
        {...{ deleteHandler, editHandler, modalopen, showPermissionHandler }}
        Action={Action}
        Permissions={Permissions}
        currentItems={currentItems}
        QRCode={QRCode}
        OnclickQTY={(e) => OnclickQTY(e)}
      />
      <ReactPaginate
        className="ul-main"
        activeClassName="active-table-btn"
        activeLinkClassName="active-table-btn-link"
        previousClassName="previous-table-btn"
        pageLinkClassName="page-link-btn"
        nextClassName="next-table-btn"
        previousLinkClassName="previous-table-btn-link"
        nextLinkClassName="previous-table-btn-link"
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCountNew}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        forcePage={forcePage || 0}
      />
    </>
  );
};
