import React, { Component } from "react";
import { Calendar } from "react-calendar";
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput"
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import { getStoreName } from "../Path/Path";
import { PaginatedItems } from "../components/Paginated";
export default class Varience extends Component {
    state = {
        operationItems: [
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
            {
            Store: '00125',
            ProductNumber: '1GAS23-213ASD',
            Barcode: '2010230012',
            SKU: '123124421',
            Department: 'FASHION',
        },
    ],
        data: "",
        storeName: [],
        dropdata: "",
        filter: [],
        mydate: "",
        visible: false,
        itemsPerPage: 10,
    }



    // async componentDidMount() {
    //     const data = await getStoreName();
    //     const storeNameArray = data.map((elem) => elem.storename)
    //     this.setState({ data: data, storeName: storeNameArray })
    //     console.log(data, "umarrrrrrrr")
    // }
    // search = () => {
    //     const res = this.state.data.filter(i => i.storename === this.state.dropdata)
    //     this.setState({ filter: res })
    //     console.log(res, "resssssssssssssssss")
    //     this.setState({ visible: true })

    // }
    render() {
        const headers = [
            "Store",
            "Product Number (variant)",
            "Barcode",
            "SKU",
            "Department",
        ]

        const colNames = [
            "Store",
            "ProductNumber",
            "Barcode",
            "SKU",
            "Department",
        ]

        return (

            <div>
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">Varience Report</p></div>

                    </div>
                    <div className="users-bottom">
                        {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="filter-text">Filters</p>
                        </div> */}

                        {/* <button
                            // onClick={() => this.search()} 
                            className="run-btn"><p className="run-text">Search</p></button> */}
                    </div>
                    <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.operationItems} />

                </div>


            </div>


        )
    }
}