import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput";


export default class HandleCronJobs extends Component {
    render() {
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Cron Jobs</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div> <p className="filter-text">Filters</p></div>
                    <div className="drop-data">
                        <Dropdown
                            value={"Retail Cyclecount"}
                        />
                        <MyInput
                            type="date"
                        />
                        <Dropdown
                            value={"Select Status"}
                        />
                        <Dropdown
                            value={"All Procs"}
                        />
                        <Dropdown
                            value={"All Stores"}
                        />

                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <button className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                    <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >ID</th>
                                    <th>Retail Cycle Count ID</th>
                                    <th>DateTime</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Status </th>
                                    <th>Process </th>

                                    <th>Action </th>
                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>Cell 6</td>
                                            <td>cell 7</td>

                                            <td>
                                                {
                                                    <div style={{ display: "flex", justifyContent: "space-around" }}>

                                                        <div>
                                                            <button className="action-btn"><p className="action-txt">Run</p></button>
                                                        </div>
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}