import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useState } from 'react'
import { encode } from '../../components/EncodeTag'
import { getItemMaster, PathCustom, postPrintingPo } from '../../Path/Path'
import { v4 as uuidv4 } from 'uuid';
import "./css/tagPrinting.css"
import { toast } from 'react-toastify';
import axios from 'axios';
import QRCode from 'react-qr-code';
import { Print_Service } from '../../components/printService';
import { TextField } from '@mui/material';

const Capture = forwardRef(({ sku, poqty, invoiceqty, skuOriginal, description, username, setNewIndex, storecode, gettingIndexs, setIndex, setInvoiceqtyValParent, skuList, vendor_name, actual_qty, getPrintedEPCABC, remainingEPC, getInvoiceqtyValParent, receivedqty, delta, linenumber, gettingValue, printValue, loadingFalse, gettingIndex, indexData, poNumberVal, invoiceNumberVal, children, zplBody, changeCount, loopValue, changeCountChecking, selectedPrinter, loading }, ref) => {
    const [receivedqtyVal, setReceivedqtyVal] = useState('')
    const [invoiceqtyVal, setInvoiceqtyVal] = useState('')
    const [printedModal, setPrintedModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [currentVal, setCurrentVal] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [counted, setCounted] = useState(0)
    const [itemMaster, setItemMaster] = useState([])
    const [itemMasterDuplicate, setItemMasterDuplicate] = useState([])
    const sendingData = (e) => {
        // gettingValue(e)
        setReceivedqtyVal(e)

    }
    const sendingDataTwo = (e) => {
        // gettingValue(e)
        const val = Number(e)
        const poVal = Number(poqty)
        // console.log(val);
        // console.log(poVal);

        // console.log('weaweasdasdasd');
        setInvoiceqtyValParent(e, indexData ? indexData?.index : null)
        setCurrentVal(e)



    }
    // useEffect(() => {
    //     setInvoiceqtyVal(invoiceqtyVal)
    // }, [loopValue, printValue, zplBody])
    function remainingEPCFunction(params) {
        if (indexData?.index === indexData?.inputIndex) {
            const remain = remainingEPC[0]?.count === undefined ? 0 : remainingEPC[0]?.count
            if (remain === 0) {
                return getInvoiceqtyValParent
            } else {
                return remain - getInvoiceqtyValParent
            }
            // console.log(getInvoiceqtyValParent, 'getInvoiceqtyValParent');

            //             remainingEPC[0]?.count
            //             :
            // ((remainingEPC[0]?.count ? (Number(getInvoiceqtyValParent - remainingEPC[0]?.count)) : getInvoiceqtyValParent)
        }
    }
    useEffect(() => {
        setRefresh(!refresh)
    }, [currentVal, getInvoiceqtyValParent, setInvoiceqtyValParent])
    useEffect(() => {
        setRefresh(!refresh)
        console.log(itemMaster, 'itemMaster');
        setItemMasterDuplicate(itemMaster)
    }, [itemMaster])
    useEffect(() => {
        setRefresh(!refresh)
        console.log(itemMaster, 'itemMaster');
        console.log(itemMasterDuplicate);
    }, [itemMasterDuplicate])

    const invoiceNumberHandler = async (first, second) => {
        await setIndex(indexData?.index, currentVal)
        await setNewIndex(gettingIndexs)
        await setInvoiceqtyValParent(currentVal)
        const invoiceValue = await Number(currentVal)
        console.log(currentVal, 'second');
        console.log(invoiceValue, 'invoiceValue');
        const poQuantity = Number(poqty)
        const deltaQuantity = Number(delta)
        // console.log(invoiceValue, 'invoiceValue');
        if (invoiceNumberVal === '') {
            toast.error("Please Enter  Invoice Number")
        } else if (getInvoiceqtyValParent === 0) {
            toast.error("Please Enter  Invoice Qty")
        } else if (invoiceValue > poQuantity && invoiceNumberVal > deltaQuantity) {
            toast.error("Invoice Qty should be Less than Po Qty and Delta Qty")
        }
        else {
            gettingIndex(true)
        }
        console.log(getInvoiceqtyValParent, 'getInvoiceqtyValParent');
        console.log(invoiceNumberVal, 'invoiceNumberVal');
        // const variableDaa = await skuList.map((item => item.SKU))
        // const newSKU = await variableDaa[gettingIndexs]?.substring(0, 13);
        // await getItemMaster(newSKU).then((async res => {
        //     console.log(res, 'asdasd');
        //     await SetItemMaster(res.data)
        // }))

        console.log(itemMaster, 'ItemMaster');
    }    // function values(params) {
    //     if (indexData?.index === indexData?.inputIndex) {
    //         console.log(getInvoiceqtyValParent, 'invoiceqtyVal');
    //         console.log(invoiceNumberVal, 'invoiceNumberVal');
    //         console.log(poqty, 'invoiceNumberVal');
    //         console.log(zplBody, 'zplBody');
    //     }

    // }
    // values()

    // console.log(selectedPrinter, 'selectedPrinter');
    const printBarcode = async () => {

        // const data = await encode("889698588461", specificVal)
        // console.log(data);


        const variableDaa = skuList.map((item => item.SKU))
        const newSKU = variableDaa[gettingIndexs]?.substring(0, 13);
        const productNumber = skuList.map((item => item.SKU_original))
        const productNumberData = productNumber[gettingIndexs]
        const { data } = await getItemMaster(newSKU)
        await setItemMaster(data)
        console.log(itemMaster, 'ItemMasterItemMaster');
        let printingItemMaster = []
        printingItemMaster = await data
        if (getInvoiceqtyValParent !== '' || getInvoiceqtyValParent !== 0) {
            const val = Number(getInvoiceqtyValParent)
            // console.log(val);
            const poVal = Number(poqty)
            // console.log(poVal);
            if (selectedPrinter === null) {
                toast.error("Please  Select Printer Before Printing")
            }
            //  else if (loopValue > delta || loopValue === 0) {
            //     toast.error("Print Count should be less than delta")

            // } 
            // else if (selectedPrinter === null) {
            //     toast.error("Please  Select Printer Before Printing")
            // }
            else if (zplBody === '') {
                toast.error("Please Select Zpl  Before Printing")
            } else {
                setPrintedModal(true)
                const count = await Number(localStorage.getItem('count'))
                const countStart = await Number(localStorage.getItem('countStart'))
                let countedStarting = countStart
                // console.log(count, 'count');
                // console.log(countStart, 'countStart');
                // loading()

                console.log(gettingIndexs, 'gettingIndexs');
                for (let index = 0; index < getInvoiceqtyValParent; index++) {
                    setCounted(index + 1)
                    if (countedStarting !== count) {
                        countedStarting = countedStarting + 1
                        await localStorage.setItem('countStart', countedStarting)
                        const specificVal = countedStarting + Math.floor(100000 + Math.random() * 900000)
                        console.log(specificVal, 'specificVal');
                        let leCo = newSKU?.length
                        // Saqib's remarkable contribution
                        var skuEdited = ''
                        skuEdited = newSKU
                        if (leCo < 14) {
                            for (var i = 0; i < (14 - leCo); i++) {
                                skuEdited = "0" + newSKU
                            }
                        }

                        const epc = await encode(skuEdited, specificVal)

                        console.log(skuEdited, 'skuEdited');
                        console.log(epc, 'epc');
                        console.log(specificVal, 'sec');
                        // console.log(epc);
                        // console.log(skuEdited);
                        function replaceAll(str, find, replace) {
                            var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                            return str.replace(new RegExp(escapedFind, 'g'), replace);
                        }

                        var replacingVars24 = await replaceAll(zplBody, "${serialNumber}", epc);
                        var replacingVars25 = await replaceAll(replacingVars24, "${new Date()}", new Date());
                        var replacingVars26 = await replaceAll(replacingVars25, "${Retail_Product_UniqueID}", specificVal);
                        var replacingVars27 = await replaceAll(replacingVars26, "${Retail_Product_SKUOriginal}", printingItemMaster[0]?.skuOriginal);
                        var replacingVars28 = await replaceAll(replacingVars27, "${Retail_Product_SupplierItemNum}", printingItemMaster[0]?.Retail_Product_SupplierItemNum);
                        var replacingVars29 = await replaceAll(replacingVars28, "${Retail_Product_Gender}", printingItemMaster[0]?.Retail_Product_Gender);
                        var replacingVars30 = await replaceAll(replacingVars29, "${Retail_Product_Size}", printingItemMaster[0]?.Retail_Product_Size);
                        var replacingVars31 = await replaceAll(replacingVars30, "${Retail_Product_Color}", printingItemMaster[0]?.Retail_Product_Color);
                        var replacingVars32 = await replaceAll(replacingVars31, "${Retail_Product_SupplierItemNum}", printingItemMaster[0]?.Retail_Product_SupplierItemNum);
                        var replacingVars33 = await replaceAll(replacingVars32, "${Retail_Product_Level2Name}", printingItemMaster[0]?.Retail_Product_Level2Name);
                        var replacingVars34 = await replaceAll(replacingVars33, "${skucode}", printingItemMaster[0]?.skucode);
                        var replacingVars35 = await replaceAll(replacingVars34, "${SKU_original}", printingItemMaster[0]?.SKU_original);
                        var replacingVars36 = await replaceAll(replacingVars35, "${GTINOriginal}", printingItemMaster[0]?.GTINOriginal);
                        var replacingVars37 = await replaceAll(replacingVars36, "${GTIN}", printingItemMaster[0]?.GTIN);
                        var replacingVars38 = await replaceAll(replacingVars37, "${product_des}", printingItemMaster[0]?.product_des);
                        var replacingVars39 = await replaceAll(replacingVars38, "${brand}", printingItemMaster[0]?.brand);
                        var replacingVars40 = await replaceAll(replacingVars39, "${color}", printingItemMaster[0]?.color);
                        var replacingVars41 = await replaceAll(replacingVars40, "${size}", printingItemMaster[0]?.size);
                        var replacingVars42 = await replaceAll(replacingVars41, "${barcode}", printingItemMaster[0]?.barcode);
                        var replacingVars43 = await replaceAll(replacingVars42, "${ean_no}", printingItemMaster[0]?.GTINOriginal);
                        var replacingVars44 = await replaceAll(replacingVars43, "${item_code}", printingItemMaster[0]?.item_code);
                        var replacingVars45 = await replaceAll(replacingVars44, "${supplier_item_no}", printingItemMaster[0]?.supplier_item_no);
                        var replacingVars46 = await replaceAll(replacingVars45, "${style}", printingItemMaster[0]?.style);
                        var replacingVars47 = await replaceAll(replacingVars46, "${GroupCode}", printingItemMaster[0]?.GroupCode);
                        var replacingVars48 = await replaceAll(replacingVars47, "${company}", printingItemMaster[0]?.company);
                        var replacingVars49 = await replaceAll(replacingVars48, "${SupplierNum}", printingItemMaster[0]?.SupplierNum);
                        var replacingVars50 = await replaceAll(replacingVars49, "${TotalPrice}", printingItemMaster[0]?.TotalPrice);
                        var replacingVars51 = await replaceAll(replacingVars50, "${SubdepartmentID}", printingItemMaster[0]?.SubdepartmentID);
                        var replacingVars52 = await replaceAll(replacingVars51, "${variant}", printingItemMaster[0]?.variant);
                        var replacingVars53 = await replaceAll(replacingVars52, "${artist}", printingItemMaster[0]?.artist);
                        var replacingVars54 = await replaceAll(replacingVars53, "${author}", printingItemMaster[0]?.author);
                        var replacingVars55 = await replaceAll(replacingVars54, "${configuration}", printingItemMaster[0]?.configuration);
                        var replacingVars56 = await replaceAll(replacingVars55, "${release_date}", printingItemMaster[0]?.release_date);
                        var replacingVars57 = await replaceAll(replacingVars56, "${serial_number}", printingItemMaster[0]?.serial_number);
                        var replacingVars58 = await replaceAll(replacingVars57, "${active_sales_price}", printingItemMaster[0]?.active_sales_price);
                        var replacingVars59 = await replaceAll(replacingVars58, "${purchase_price}", printingItemMaster[0]?.purchase_price);
                        var replacingVars60 = await replaceAll(replacingVars59, "${latest_cost_price}", printingItemMaster[0]?.latest_cost_price);
                        var replacingVars61 = await replaceAll(replacingVars60, "${unit}", printingItemMaster[0]?.unit);
                        var replacingVars62 = await replaceAll(replacingVars61, "${purchase_unit}", printingItemMaster[0]?.purchase_unit);
                        var replacingVars63 = await replaceAll(replacingVars62, "${unit_conversion}", printingItemMaster[0]?.unit_conversion);
                        var replacingVars64 = await replaceAll(replacingVars63, "${vat}", printingItemMaster[0]?.vat);
                        var replacingVars65 = await replaceAll(replacingVars64, "${timestamp}", printingItemMaster[0]?.timestamp);
                        var replacingVars66 = await replaceAll(replacingVars65, "${departmentname}", printingItemMaster[0]?.departmentname);
                        var replacingVars67 = await replaceAll(replacingVars66, "${specificVal}", specificVal);
                        var replacingVars68 = await replaceAll(replacingVars67, "${SKU}", skuEdited);
                        var replacingVars69 = await replaceAll(replacingVars68, "${vendor_name}", vendor_name);
                        var replacingVars70 = await replaceAll(replacingVars69, "${actual_qty}", actual_qty);
                        console.log(epc, 'epc');
                        console.log(skuEdited, 'sku');
                        let body = [
                            {
                                "group": ">VIRGIN",
                                "thingTypeCode": "ITEM",
                                "serialNumber": epc,
                                "udfs": {
                                    "Retail_Bizlocation": { "value": `0000${storecode ? storecode : '490'}` },
                                    "deviceId": { "value": specificVal },
                                    "sourceModule": { "value": "Printing" },
                                    "Retail_Printer": { "value": "PrinterID" },
                                    "Retail_Product_SKU": { "value": skuEdited },
                                    "Retail_Product_SKUOriginal": { "value": productNumberData },
                                    "Retail_Product_UniqueID": { "value": specificVal },
                                    "Retail_Product_UPC": { "value": skuEdited },
                                    "Retail_ZPL": { "value": "VIRGINzpl_EN" },
                                    "source": { "value": "PRINTING_APP" },
                                    "user": { "value": `store${storecode ? storecode : '490'}` },
                                    "zone": { "value": `0000${storecode ? storecode : '490'}.00101.1` },
                                    "po_number": { "value": poNumberVal },
                                    "comments": { "value": "" },
                                    "invoice_number": { "value": invoiceNumberVal },
                                    "username": username
                                }
                            }
                        ]

                        let sendingPrintingVal = await postPrintingPo(body)
                        let sendingPrintingVal2 = await axios.post(`${PathCustom}store/printed-items`, body[0])
                        console.log(sendingPrintingVal2);
                        console.log(sendingPrintingVal);
                        console.log(body, "body");
                        console.log(specificVal, 'specificVal');
                        console.log(epc, 'epc');
                        console.log(printingItemMaster, 'asd');
                        await Print_Service.print(selectedPrinter, replacingVars70)

                        changeCountChecking()
                    } else {
                        await changeCount()
                        loading()
                        countedStarting = countedStarting + 1
                        await localStorage.setItem('countStart', countedStarting)
                        const specificVal = countedStarting + Math.floor(100000 + Math.random() * 900000)
                        console.log(specificVal, 'specificVal');
                        let leCo = newSKU?.length
                        // Saqib's remarkable contribution
                        var skuEdited = ''
                        skuEdited = newSKU
                        if (leCo < 14) {
                            for (var i = 0; i < (14 - leCo); i++) {
                                skuEdited = "0" + newSKU
                            }
                        }
                        const epc = await encode(skuEdited, specificVal)
                        console.log(epc);
                        console.log(skuEdited);
                        function replaceAll(str, find, replace) {
                            var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                            return str.replace(new RegExp(escapedFind, 'g'), replace);
                        }
                        var replacingVars24 = await replaceAll(zplBody, "${serialNumber}", epc);
                        var replacingVars25 = await replaceAll(replacingVars24, "${new Date()}", new Date());
                        var replacingVars26 = await replaceAll(replacingVars25, "${Retail_Product_UniqueID}", specificVal);
                        var replacingVars27 = await replaceAll(replacingVars26, "${Retail_Product_SKUOriginal}", printingItemMaster[0]?.skuOriginal);
                        var replacingVars28 = await replaceAll(replacingVars27, "${Retail_Product_SupplierItemNum}", printingItemMaster[0]?.Retail_Product_SupplierItemNum);
                        var replacingVars29 = await replaceAll(replacingVars28, "${Retail_Product_Gender}", printingItemMaster[0]?.Retail_Product_Gender);
                        var replacingVars30 = await replaceAll(replacingVars29, "${Retail_Product_Size}", printingItemMaster[0]?.Retail_Product_Size);
                        var replacingVars31 = await replaceAll(replacingVars30, "${Retail_Product_Color}", printingItemMaster[0]?.Retail_Product_Color);
                        var replacingVars32 = await replaceAll(replacingVars31, "${Retail_Product_SupplierItemNum}", printingItemMaster[0]?.Retail_Product_SupplierItemNum);
                        var replacingVars33 = await replaceAll(replacingVars32, "${Retail_Product_Level2Name}", printingItemMaster[0]?.Retail_Product_Level2Name);
                        var replacingVars34 = await replaceAll(replacingVars33, "${skucode}", printingItemMaster[0]?.skucode);
                        var replacingVars35 = await replaceAll(replacingVars34, "${SKU_original}", printingItemMaster[0]?.SKU_original);
                        var replacingVars36 = await replaceAll(replacingVars35, "${GTINOriginal}", printingItemMaster[0]?.GTINOriginal);
                        var replacingVars37 = await replaceAll(replacingVars36, "${GTIN}", printingItemMaster[0]?.GTIN);
                        var replacingVars38 = await replaceAll(replacingVars37, "${product_des}", printingItemMaster[0]?.product_des);
                        var replacingVars39 = await replaceAll(replacingVars38, "${brand}", printingItemMaster[0]?.brand);
                        var replacingVars40 = await replaceAll(replacingVars39, "${color}", printingItemMaster[0]?.color);
                        var replacingVars41 = await replaceAll(replacingVars40, "${size}", printingItemMaster[0]?.size);
                        var replacingVars42 = await replaceAll(replacingVars41, "${barcode}", printingItemMaster[0]?.barcode);
                        var replacingVars43 = await replaceAll(replacingVars42, "${ean_no}", printingItemMaster[0]?.GTINOriginal);
                        var replacingVars44 = await replaceAll(replacingVars43, "${item_code}", printingItemMaster[0]?.item_code);
                        var replacingVars45 = await replaceAll(replacingVars44, "${supplier_item_no}", printingItemMaster[0]?.supplier_item_no);
                        var replacingVars46 = await replaceAll(replacingVars45, "${style}", printingItemMaster[0]?.style);
                        var replacingVars47 = await replaceAll(replacingVars46, "${GroupCode}", printingItemMaster[0]?.GroupCode);
                        var replacingVars48 = await replaceAll(replacingVars47, "${company}", printingItemMaster[0]?.company);
                        var replacingVars49 = await replaceAll(replacingVars48, "${SupplierNum}", printingItemMaster[0]?.SupplierNum);
                        var replacingVars50 = await replaceAll(replacingVars49, "${TotalPrice}", printingItemMaster[0]?.TotalPrice);
                        var replacingVars51 = await replaceAll(replacingVars50, "${SubdepartmentID}", printingItemMaster[0]?.SubdepartmentID);
                        var replacingVars52 = await replaceAll(replacingVars51, "${variant}", printingItemMaster[0]?.variant);
                        var replacingVars53 = await replaceAll(replacingVars52, "${artist}", printingItemMaster[0]?.artist);
                        var replacingVars54 = await replaceAll(replacingVars53, "${author}", printingItemMaster[0]?.author);
                        var replacingVars55 = await replaceAll(replacingVars54, "${configuration}", printingItemMaster[0]?.configuration);
                        var replacingVars56 = await replaceAll(replacingVars55, "${release_date}", printingItemMaster[0]?.release_date);
                        var replacingVars57 = await replaceAll(replacingVars56, "${serial_number}", printingItemMaster[0]?.serial_number);
                        var replacingVars58 = await replaceAll(replacingVars57, "${active_sales_price}", printingItemMaster[0]?.active_sales_price);
                        var replacingVars59 = await replaceAll(replacingVars58, "${purchase_price}", printingItemMaster[0]?.purchase_price);
                        var replacingVars60 = await replaceAll(replacingVars59, "${latest_cost_price}", printingItemMaster[0]?.latest_cost_price);
                        var replacingVars61 = await replaceAll(replacingVars60, "${unit}", printingItemMaster[0]?.unit);
                        var replacingVars62 = await replaceAll(replacingVars61, "${purchase_unit}", printingItemMaster[0]?.purchase_unit);
                        var replacingVars63 = await replaceAll(replacingVars62, "${unit_conversion}", printingItemMaster[0]?.unit_conversion);
                        var replacingVars64 = await replaceAll(replacingVars63, "${vat}", printingItemMaster[0]?.vat);
                        var replacingVars65 = await replaceAll(replacingVars64, "${timestamp}", printingItemMaster[0]?.timestamp);
                        var replacingVars66 = await replaceAll(replacingVars65, "${departmentname}", printingItemMaster[0]?.departmentname);
                        var replacingVars67 = await replaceAll(replacingVars66, "${specificVal}", specificVal);
                        var replacingVars68 = await replaceAll(replacingVars67, "${SKU}", skuEdited);
                        var replacingVars69 = await replaceAll(replacingVars68, "${vendor_name}", vendor_name);
                        var replacingVars70 = await replaceAll(replacingVars69, "${actual_qty}", actual_qty);
                        console.log(epc, 'epc');

                        console.log(epc, 'epc');
                        console.log(skuEdited, 'sku');
                        let body = [
                            {
                                "group": ">VIRGIN",
                                "thingTypeCode": "ITEM",
                                "serialNumber": epc,
                                "udfs": {
                                    "Retail_Bizlocation": { "value": "0000490" },
                                    "deviceId": { "value": specificVal },
                                    "sourceModule": { "value": "Printing" },
                                    "Retail_Printer": { "value": "PrinterID" },
                                    "Retail_Product_SKU": { "value": skuEdited },
                                    "Retail_Product_SKUOriginal": { "value": productNumberData },
                                    "Retail_Product_UniqueID": { "value": specificVal },
                                    "Retail_Product_UPC": { "value": skuEdited },
                                    "Retail_ZPL": { "value": "VIRGINzpl_EN" },
                                    "source": { "value": "PRINTING_APP" },
                                    "user": { "value": "store490" },
                                    "zone": { "value": "0000490.00101.1" },
                                    "po_number": { "value": poNumberVal },
                                    "comments": { "value": "" },
                                    "invoice_number": { "value": invoiceNumberVal },
                                    "username": username
                                }
                            }
                        ]

                        let sendingPrintingVal = await postPrintingPo(body)
                        let sendingPrintingVal2 = await axios.post(`${PathCustom}store/printed-items`, body[0])
                        console.log(sendingPrintingVal2);
                        console.log(sendingPrintingVal);
                        console.log(body, "body");
                        console.log(specificVal, 'specificVal');
                        console.log(epc, 'epc');
                        console.log(printingItemMaster, 'asd');
                        await Print_Service.print(selectedPrinter, replacingVars70)
                    }

                }
                loadingFalse()
                getPrintedEPCABC()
                setPrintedModal(false)
            }

        } else {
            toast.error("Please Enter Invoice QTY, Received QTY and Invoice Number")
        }
    }
    useImperativeHandle(ref, () => ({

        getAlert() {
            printBarcode()
        }

    }));
    // console.log(remainingEPC[0]?.count, 'remainingEPC');
    return (
        <>

            <div style={{ visibility: printedModal ? 'visible' : 'hidden', position: 'fixed', top: 54, backgroundColor: 'black', width: '300px', height: '76px', right: '0px', borderRadius: '6px', color: 'white', display: 'flex', justifyContent: 'center' }}>
                <h4 style={{ marginRight: '20px' }}>{counted}</h4>
                <h4> Printed Items Done</h4>
            </div>
            <iframe id="printf" name="printf" style={{ display: 'none' }} ></iframe>

            <div style={{ gridRowGap: '20px', borderBottom: '1px solid white', display: 'flex', gridColumnGap: 10, justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', paddingBottom: 30, marginTop: 30, marginLeft: 20 }}>


                <TextField
                    sx={{ width: 150 }}
                    defaultValue={skuOriginal}
                    value={skuOriginal}
                    placeholder="Product Number"
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="Product Number" variant="outlined" />
                {/* <div className='data' style={{ overflow: 'hidden' }}>{skuOriginal}</div> */}


                <TextField
                    sx={{ width: 150 }}
                    defaultValue={sku}
                    value={sku}
                    placeholder="Barcode Number"
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="Barcode Number" variant="outlined" />


                <TextField
                    sx={{ width: 150 }}
                    defaultValue={poqty}
                    value={poqty}
                    placeholder="PO Qty"
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="PO Qty" variant="outlined" />



                <TextField
                    onChange={(e) => sendingDataTwo(e.target.value)}
                    sx={{ width: 150 }}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={indexData?.index === indexData?.inputIndex ? getInvoiceqtyValParent : null}
                    value={indexData?.index === indexData?.inputIndex ? getInvoiceqtyValParent : null}
                    placeholder={indexData?.index === indexData?.inputIndex ? getInvoiceqtyValParent : null}
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="Invoice Qty" variant="outlined" />

                <TextField

                    sx={{ width: 350 }}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={description}
                    value={description}
                    placeholder={description}
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="Description" variant="outlined" />

                {/* 
                <TextField

                    sx={{ width: 200 }}
                    defaultValue={remainingEPCFunction()}
                    value={remainingEPCFunction()}
                    InputLabelProps={{ shrink: true }}
                    placeholder={remainingEPCFunction()}
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="Remaining To Print" variant="outlined" /> */}




                {/* <p className='colorSet'>PO Delta</p> */}
                {/* <div className='data' style={{ overflow: 'hidden' }}>{delta}</div> */}
                <TextField
                    sx={{ width: 150 }}
                    defaultValue={delta}
                    value={delta}
                    placeholder={delta}
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="PO Delta" variant="outlined" />

                <TextField
                    sx={{ width: 150 }}
                    defaultValue={linenumber}
                    value={linenumber}
                    placeholder={linenumber}
                    size={'small'}
                    // disabled
                    id="outlined-basic" label="PO Line Number" variant="outlined" />


                <button style={{ width: 100 }} onClick={() => invoiceNumberHandler(true, indexData?.index === indexData?.inputIndex ? getInvoiceqtyValParent : null)} className="button-custom" >Print</button>
            </div>
        </>
    )
})

export default Capture;