import React, { useState } from "react";
import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"

export default function ManualRecivingIbt() {
    const [visible, setVisible] = useState(false);
    const [visibleTwo, setVisibleTwo] = useState(false);
    const num = [1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165]
    const handler = () => { setVisible(!visible) }
    const handlerTwo = () => { setVisibleTwo(!visibleTwo) }
    return (
        <div className="dashboard-main">
            <div className="dashboard-top">
                <div><p className="stock-count">Receive IBT</p></div>
                
            </div>
            <div style={{ display: 'flex', }}>
                <div style={{ padding: '2%', width: '70%' }}>
                    <form>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <p className="ibt-text">IBT*</p>
                                    <input className="forminput" type={"text"} placeholder="IBT" />
                                </div>
                                <div>
                                    <p className="ibt-text">Retail Bizlocation Original*</p>
                                    <div onClick={() => handler()} className='drop-input-form'>
                                        <p className='store' >Select Store</p>
                                    </div>
                                    {
                                        visible ?
                                        <div style={{position:'relative'}}>
                                            <div className='dropdown-list-form'>

                                                {
                                                    num.map(data =>
                                                        <React.Fragment>
                                                            <div className="dropdown-para-wrapper">
                                                                <p>{data}</p>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </div> </div>:
                                            null
                                    }
                                </div>
                            </div>
                            <div>
                                <p className="ibt-text">Retail Bizlocation *</p>
                                <div onClick={() => handlerTwo()} className='drop-input-form-2'>
                                    <p className='store-2' >Select Store</p>
                                </div>
                                {
                                    visibleTwo ?
                                    <div style={{position:'relative'}}>
                                        <div className='dropdown-list-form-2'>

                                            {
                                                num.map(data =>
                                                    <React.Fragment>
                                                        <div className="dropdown-para-wrapper">
                                                            <p>{data}</p>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        </div></div> :
                                        null
                                }
                            </div>
                            <div>
                                <p className="ibt-text">Remarks*</p>
                                <textarea className="textarea-form" placeholder="Description"></textarea>
                            </div>
                            <div>
                                <button className="receiving-btn">Receive</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div style={{ padding: '4% 2% 2% 2%' }}>
                    <button className="get-detail-btn">Get Details</button>
                    <p style={{ color: '#fff', fontSize: 15, marginTop: 30 }}>IBT Details*</p>
                </div>

            </div>



        </div>
    )
}