import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getstores, Innovent_Path, Path, PathCustom, PathCustomPOTO, reqInstance } from '../Path/Path';
import parser from "../components/epc-parser"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import jwtDecode from 'jwt-decode';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import _ from "lodash";
import { NavLink } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

import { withRouter } from '../components/withRouter';

const Receiving = ({ stores }) => {
    const [userName, setUserName] = useState('')
    const [ASN, setASN] = useState("")
    const [ASNDataDisplay, setASNDataDisplay] = useState(null)
    const [ASNData, setASNData] = useState(null)
    const [storeData, setStoreData] = useState([])
    const [store, setStore] = useState("")
    const [open, setOpen] = useState(false)
    const [canReceive, setCanReceive] = useState(false)
    const [ShowMoreData, setShowMoreData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [remarks, setRemarks] = useState("")
    useEffect(() => {

        gettingStores()
    }, []);
    const gettingStores = async () => {


        setLoading(true)


        const userinfo = await getstores();

        setStoreData(userinfo)
        setLoading(false)


        const dataa = await localStorage.getItem('loginData')
        const parseData = JSON.parse(dataa)
        const token = parseData?.token
        if (token) {
            const decodetoken = jwtDecode(token)
            setUserName(decodetoken?.payload.userName)
        }
        return
        let data = null
        await reqInstance.get(`${Innovent_Path}STORES`)
            .then((async res => {

                data = await res?.data?.results
            }))

            .catch((err => {
                console.log(err);
            }))

        await setStoreData(data)
        setLoading(false)

    }
    const getData = async () => {
        // if (ASN === '' && store === '') {
        //     toast.error("Please Enter ASN and Store Code")

        // } else
        //     if (ASN === '') {
        //         toast.error("Please Enter ASN")
        //     } else if (store === '') {
        //         toast.error("Please Enter Store Code ")
        //     } else 
        if (ASN === '') {
            toast.error("Please Enter ASN")
        }
        else {
            if (ASN.toLowerCase().startsWith("to")) {
                getDataTO()
                return
            }


            if (ASN) {
                setLoading(true)
                var myHeaders = new Headers();
                myHeaders.append("apikey", "mKbPeShVmYp3s6v9");
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "po_number": ASN,
                    "process": "receiving"
                });
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                await fetch(PathCustomPOTO + "store/innovent/SUPPLYCHAINVERIFY/po-for-receiving", requestOptions)
                    .then(response => response.text())
                    .then(result1 => {
                        let result = JSON.parse(result1)
                        setLoading(false)
                        console.log(result, "resultvfet")


                        if (result?.success) {

                            setCanReceive(true)

                            setASNData(result)

                            setASNDataDisplay(result)


                        }
                        else {
                            toast.warn(result?.message)
                            setCanReceive(false)
                            setOpen(false)
                            setASNData(null)
                            setASNDataDisplay(null)
                            // alert("ASN is processed or does not exists")
                        }
                        setLoading(false)
                    })
                    .catch(error => {
                        console.log('error', error)

                        setLoading(false)
                    });
            }
        }



    }
    const getDataTO = async () => {
        if (ASN) {


            var myHeaders = new Headers();
            myHeaders.append("apikey", "mKbPeShVmYp3s6v9");
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                // "ASN": "TOFUAE-042345.05"
                "ASN": ASN
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            setLoading(true)
            fetch(PathCustomPOTO + "store/innovent/api/picking-orderid/to-for-receiving", requestOptions)
                .then(response => response.text())
                .then(result1 => {
                    let result = JSON.parse(result1)
                    console.log(result, "rfgrhef")
                    if (result?.status !== 200) {
                        toast.warn(result?.message)
                        setCanReceive(false)
                        setOpen(false)
                        setASNData(null)
                        setASNDataDisplay(null)
                    }

                    if (result?.items.length) {


                        setCanReceive(true)
                        setASNData(result)
                        setASNDataDisplay(result)


                        // this.props.navigation.navigate('PoDetails', {
                        //     result: result, name: result.po_number, found: result, process: this.state.departmentName,
                        //     TextInputValueHolder: this.state.TextInputValueHolder
                        // })


                    }



                    setLoading(false)
                })
                .catch(error => {
                    console.log('error', error)
                    setLoading(false)
                });
        }
    }

    const onReceiving = async () => {
        let data = ASNData?.items
        let serverData = [];
        for (var k = 0; k < data?.length; k++) {
            console.log(data[k], "tagIDstagIDs")
            let obj = {
                "serialNumber": "",
                "image": "Base64",
                "udfs": {
                    "Retail_BizTransactionId": {
                        "value": ASN
                    },
                    "product_number": {
                        "value": data[k]?.SKU
                    },
                    "LocationDetails": {
                        "value": "MC5"
                    },
                    "StorageLocation": {
                        "value": "T5GD"
                    },
                    "BinLocation": {
                        "value": "A18"
                    },
                    "Lane": {
                        "value": "B"
                    },
                    "Material": {
                        "value": "A00GEST#ZBR.X0F"
                    },
                    "Batch": {
                        "value": "R5AN15R0SA"
                    },
                    "User": {
                        "value": userName
                    },
                    "UUIDMobile": {
                        "value": "C2A0622C-CB02-41E9-9465-9946B282B38F"
                    }
                }
            }
            serverData.push(obj);
        }





        setLoading(true)
        await fetch(PathCustom + "store/innovent/SUPPLYCHAINVERIFY/po-for-receiving", {
            method: 'put',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // 'Connection': 'keep-alive',
                // 'apikey': 'mKbPeShVmYp3s6v9',
            },
            body: JSON.stringify(serverData)

        }).then((response) => response.json())
            .then(responseJson => {
                console.log('responseJson: ', responseJson);

                if (responseJson?.status === 200) {
                    toast.success(responseJson?.message)
                    setCanReceive(false)
                    setOpen(false)
                    setASNData(null)
                    setASNDataDisplay(null)
                    // setASN("")
                } else {

                    toast.success(responseJson?.message)
                }
                setLoading(false)

            }).catch((error) => {
                setLoading(false)
                console.log(error)
                setOpen(false)
                alert('data not pushed')
            })
    }
    const onReceivingTO = async () => {
        let data = ASNData?.items
        console.log(data.length, "data")
        let serverData = [];




        for (var k = 0; k < data.length; k++) {
            let obj = {
                "serialNumber": data[k].tagIDs[0],
                "image": "Base64",
                "udfs": {
                    "remarks": { "value": remarks },
                    "Retail_BizTransactionId": {
                        "value": ASN
                    },
                    "product_number": {
                        "value": data[k]?.SKU
                    },
                    "LocationDetails": {
                        "value": "MC5"
                    },
                    "StorageLocation": {
                        "value": "T5GD"
                    },
                    "BinLocation": {
                        "value": "A18"
                    },
                    "Lane": {
                        "value": "B"
                    },
                    "Material": {
                        "value": "A00GEST#ZBR.X0F"
                    },
                    "Batch": {
                        "value": "R5AN15R0SA"
                    },
                    "User": {
                        "value": userName
                    },
                    "UUIDMobile": {
                        "value": "C2A0622C-CB02-41E9-9465-9946B282B38F"
                    }
                }
            }
            serverData.push(obj);
        }

        // await fetch("https://virgin.innovent.site" + "/innovent/api/picking-scan", {
        console.log(Innovent_Path + "SUPPLYCHAIN")
        setLoading(true)
        await fetch(PathCustom + "store/innovent/SUPPLYCHAIN/to-for-receiving", {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // 'Connection': 'keep-alive',
                // 'apikey': 'mKbPeShVmYp3s6v9',
            },
            body: JSON.stringify(serverData)

        }).then((response) => response.json())
            .then(responseJson => {
                console.log('responseJson: ', responseJson);
                if (responseJson.error) {
                    alert(responseJson.error);

                }
                if (responseJson?.status === 200) {
                    toast.success(responseJson?.message)
                    setCanReceive(false)
                    setOpen(false)
                    setASNData(null)
                    setASNDataDisplay(null)
                    setASN("")
                }
                else {

                    toast.success(responseJson?.message)
                }
                setLoading(false)

            }).catch((error) => {
                console.log(error, "error")
                setLoading(false)
                setOpen(false)
                alert('data not pushed')
            })
    }

    const checkBeforeReceiving = () => {
        if (ASN.length === 0)
            alert("Please enter ASN")
        else if (remarks.length === 0)
            alert("Please enter Remarks")
        else {
            ASN?.toLowerCase()?.startsWith("po") ? onReceiving() : onReceivingTO()
        }

    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to do Manual Receiving ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>No</Button>
                    <Button onClick={() => checkBeforeReceiving()} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div className='dashboard-main'   >
                <div className='dashboard-top' style={{ display: 'flex', flexDirection: 'column', padding: '10px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='stock-count' style={{ fontSize: '25px', margin: 0 }}>Manual Receiving</p>
                        {/* <CSVLink
                        filename='SupplyChain/Rtv/PO'
                        data={csvData}
                        headers={headers}
                        >
                        <GrDocumentCsv color='white' fill='white' fontSize={25} style={{ marginRight: 5, cursor: 'pointer' }} />
                        
                    </CSVLink> */}
                        {canReceive &&
                            <div className='check-box' style={{ marginLeft: 20 }} >

                                <button onClick={() => setOpen(true)} className="run-btn"><p className="run-text">Receiving</p></button>
                            </div>
                        }

                    </div>
                    <div>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>


                </div>
                <div style={{ padding: 20 }} >
                    <div style={{ marginTop: 30, display: "flex", flexDirection: "row", alignItems: "center" }} >
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            getOptionLabel={(option) => option?.Warehouse}
                            options={storeData?.map((item => { return { Warehouse: item.Warehouse } }))?.reduce((noDupArr, entry) => {
                                if (noDupArr?.includes(entry)) {
                                    return noDupArr;
                                } else {
                                    return [...noDupArr, entry];
                                }
                            }, [])}
                            onChange={(e, v) => setStore(v === null || v === undefined ? '' : v)}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Store Code" />}
                        /> */}

                        <Autocomplete
                            style={{ marginLeft: 20 }}
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            freeSolo={true}
                            options={[]}
                            value={ASN}
                            onInputChange={(e) => setASN(e.target.value === 0 || e.target.value === undefined ? '' : e.target.value)}
                            onChange={(e, v) => setASN(v === null || v === undefined ? '' : v)}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField
                                {...params} label="ASN" />}
                        />
                        <div className='check-box' style={{ marginLeft: 20 }} >
                            <button onClick={() => getData()} className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    </div>
                    {
                        ASNData &&
                        <div style={{ marginTop: 30 }} >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                <p style={{ fontSize: 16, color: "white", fontWeight: "normal" }} >{ASNData?.items[0]?.ASN ? "ASN: " : "po_number: "} </p>
                                <p style={{ fontSize: 16, color: "white", fontWeight: "bold", marginLeft: 10 }} > {ASNData?.po_number ? ASNData?.po_number : ASNData?.items[0]?.ASN}</p>
                            </div>
                            {ASNData?.invoice_number &&
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                    <p style={{ fontSize: 16, color: "white", fontWeight: "normal" }} >invoice_number: </p>
                                    <p style={{ fontSize: 16, color: "white", fontWeight: "bold", marginLeft: 10 }} > {ASNData?.invoice_number}</p>
                                </div>}

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                <p style={{ fontSize: 16, color: "white", fontWeight: "normal" }} >store: </p>
                                <p style={{ fontSize: 16, color: "white", fontWeight: "bold", marginLeft: 10 }} > {ASNData?.store ? ASNData?.store : ASNData?.items[0]?.store_id}</p>
                            </div>
                            {ASNData?.purchase_type &&
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                    <p style={{ fontSize: 16, color: "white", fontWeight: "normal" }} >purchase_type: </p>
                                    <p style={{ fontSize: 16, color: "white", fontWeight: "bold", marginLeft: 10 }} > {ASNData?.purchase_type}</p>
                                </div>}
                            {ASNData?.vendor_name && <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                <p style={{ fontSize: 16, color: "white", fontWeight: "normal" }} >vendor_name: </p>
                                <p style={{ fontSize: 16, color: "white", fontWeight: "bold", marginLeft: 10 }} > {ASNData?.vendor_name}</p>
                            </div>}

                        </div>
                    }
                    {
                        ShowMoreData &&
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", }} >
                            <FaTimes onClick={() => setShowMoreData(null)} size={20} />
                        </div>
                    }
                    <div style={{ marginTop: '20px', overflow: 'auto', height: '600px', }}>
                        <table data-table-theme="dark zebra">
                            <thead>
                                {
                                    ASNDataDisplay && (
                                        ASN?.startsWith("PO" || "po") ?
                                            <tr style={{ background: "#373E43" }}>
                                                <th >SKU</th>
                                                <th>source</th>
                                                <th>original_location</th>
                                                <th>process_status</th>
                                                <th>QTY</th>

                                            </tr>
                                            :
                                            <tr style={{ background: "#373E43" }}>
                                                <th >SKU</th>
                                                <th>source</th>
                                                <th>original_location</th>
                                                <th>process_status</th>
                                                <th>QTY</th>

                                            </tr>
                                    )

                                }

                            </thead>
                            <tbody>
                                {
                                    ShowMoreData === null &&
                                    (
                                        ASN?.startsWith("PO" || "po") ?
                                            ASNDataDisplay?.items?.map((data, index) =>
                                                <tr key={index} >
                                                    <td>{data?.SKU}</td>
                                                    <td>{data?.store_id}</td>
                                                    <td>{data?.original_location}</td>
                                                    <td>{data?.process_status}</td>
                                                    <td><NavLink     >{data?.Quantity} </NavLink></td>

                                                </tr>
                                            )
                                            :
                                            ASNDataDisplay?.items?.map((data, index) =>
                                                <tr key={index} >
                                                    <td>{data?.SKU}</td>
                                                    <td>{data?.store_id}</td>
                                                    <td>{data?.original_location}</td>
                                                    <td>{data?.process_status}</td>
                                                    <td> {data?.Quantity} </td>

                                                </tr>
                                            )
                                    )

                                }
                                {
                                    ShowMoreData &&
                                    ShowMoreData?.map((data, index) =>
                                        <tr key={index} >
                                            <td>{data?.SKU}</td>
                                            <td>{data?.store_id}</td>
                                            <td>{data?.original_location}</td>
                                            <td>{data?.process_status}</td>
                                            <td>{"--"}</td>

                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>


                    </div>
                    {canReceive &&
                        <Autocomplete
                            style={{ marginLeft: 20 }}
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            freeSolo={true}
                            options={[]}
                            onInputChange={(e) => setRemarks(e.target.value === 0 || e.target.value === undefined ? '' : e.target.value)}
                            onChange={(e, v) => setRemarks(v === null || v === undefined ? '' : v)}
                            sx={{ width: 250 }}
                            renderInput={(params) => <TextField
                                {...params} label="Remarks" />}
                        />}
                </div>

                <Loader loading={loading} />
            </div>
        </>
    );
}

export default withRouter(Receiving);
