import axios from "axios";
export const luluPath = "https://lulu-lemon-server.herokuapp.com/";
export const Path = luluPath;
// export const localServer = 'https://lululemon-backend1.innovent.site/api/v1/';
export const localServer = 'https://nestorfid.com/api/v1/';
// export const localServer = 'https://staging.nestorfid.com/api/v1/';
// export const localServer = "http://localhost:5000/api/v1/";
// export const Path = 'http://localhost:8082/'
// export const Path = 'http://192.168.10.8:8082/'
// export const Innovent_Path = 'https://virgin.innovent.site/innovent/'
export const Innovent_Path = "https://virgin-test.innovent.site/innovent/";
// export const PathCustom = 'https://vigin-ba.herokuapp.com/api/v1/';
// export const PathCustom = 'https://virgin-backend1.innovent.site/api/v1/';
// export const PathCustom = 'https://virgin-test-backend1.innovent.site/api/v1/';
// export const PathCustom = 'https://lululemon-backend1.innovent.site/api/v1/';
export const PathCustom = localServer;
export const PathCustomCycleCount = localServer;
// export const PathCustomCycleCount = 'https://lululemon-backend1.innovent.site/api/v1/';
// export const PathCustomPOTO = 'https://lululemon-backend1.innovent.site/api/v1/';
export const PathCustomPOTO = localServer;

export const SupplyPath = "https://supply-chain-server.herokuapp.com/";
// export const luluPath = "https://lulu-lemon-server.herokuapp.com/";
// export const headers = {
//     "Content-Type": "application/json",
//     "x-api-key": "mKbPeShVmYp3s6v9",
// }
// export const Path = 'http://192.168.18.246:5000/'
export const reqInstance = axios.create({
  headers: {
    "x-api-key": "mKbPeShVmYp3s6v9",
    apikey: "mKbPeShVmYp3s6v9",
  },
});
export const getPOWithStatus = async (page, date, source, po_number) => {
  const { data } = await axios.get(
    `${PathCustom}store/innovent/po?status=created&limit=10&page=${page}` +
      date +
      source +
      po_number
  );
  return data;
};
export const getBinLocation = async (page) => {
  const { data } = await axios.get(
    PathCustom + `store/bins?limit=10&page=${page}`
  );
  return data;
};
export const getTOWithStatus = async (
  page,
  date,
  source,
  to_number,
  destination
) => {
  const { data } = await axios.get(
    `${PathCustom}store/innovent/to?status=created&limit=10&page=${page}` +
      date +
      source +
      to_number +
      destination
  );
  return data;
};
export const getConsignmentWithStatus = async (
  page,
  date,
  source,
  order_id,
  enteredConsignment_id
) => {
  const { data } = await axios.get(
    `${PathCustom}store/innovent/consignment?status=created&limit=10&page=${page}` +
      date +
      source +
      order_id +
      enteredConsignment_id
  );
  return data;
};
export const getConsignmentWithNotCreated = async (
  page,
  date,
  source,
  order_id,
  enteredConsignment_id
) => {
  const { data } = await axios.get(
    `${PathCustom}store/innovent/consignment?status[in]=partially-packed,packed,partially-shipped,shipped,received,partially-received&limit=10&page=${page}` +
      date +
      source +
      order_id +
      enteredConsignment_id
  );
  return data;
};

const getReceiving = async (payLoad) => {
  const { data } = await axios.get(`${Path}receivingScreen/get`, payLoad);
  return data;
};
const getTransferOut = async (payLoad) => {
  const { data } = await axios.get(`${Path}transferOutScreen/get`, payLoad);
  return data;
};
const getPacking = async (payLoad) => {
  const { data } = await axios.get(`${Path}packingScreen/get`, payLoad);
  return data;
};
const getManageTagScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}manageTagScreen/get`, payLoad);
  return data;
};
const getLocateItem2 = async (payLoad) => {
  const { data } = await axios.get(`${Path}locateItem2/get`, payLoad);
  return data;
};
const getTargetStockScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}targetStockScreen/get`, payLoad);
  return data;
};
const getQrScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}qr/get`, payLoad);
  return data;
};
const getHomeScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}homeScreen/get`, payLoad);
  return data;
};
const getLoginScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}login/get`, payLoad);
  return data;
};
const getEncodeScreen = async (payLoad) => {
  const { data } = await axios.get(`${Path}EncodeScreen/get`, payLoad);
  return data;
};
const getDeviceSetup2 = async (payLoad) => {
  const { data } = await axios.get(`${Path}deviceSetup2/get`, payLoad);
  return data;
};
const getTransferOutDetail = async (payLoad) => {
  const { data } = await axios.get(`${Path}transferOutDetail/get`, payLoad);
  return data;
};
const getPackingItemDetail = async (payLoad) => {
  const { data } = await axios.get(`${Path}packingItemDetail/get`, payLoad);
  return data;
};
const getReceivingDetail = async (payLoad) => {
  const { data } = await axios.get(`${Path}receivingDetail/get`, payLoad);
  return data;
};
const getCycleCount2 = async (payLoad) => {
  const { data } = await axios.get(`${Path}cycleCount2/get`, payLoad);
  return data;
};
const getCycleCount1 = async (payLoad) => {
  const { data } = await axios.get(`${Path}cycleCountScreen/get`, payLoad);
  return data;
};

const getChangeStatus = async (payLoad) => {
  const { data } = await axios.get(`${Path}changeStatusScreen/get`, payLoad);
  return data;
};

const getGiStatus = async (payLoad) => {
  const { data } = await axios.get(`${Path}GIStatusScreen/get`, payLoad);
  return data;
};
const getMoreFilter = async (payLoad) => {
  const { data } = await axios.get(`${Path}moreFilter/get`, payLoad);
  return data;
};
const getPrintingPo = async (payLoad) => {
  const { data } = await reqInstance.get(`${Innovent_Path}TAGIT`, payLoad);
  return data;
};
const getSohPerStore = async (payLoad) => {
  const { data } = await reqInstance.post(
    `${Innovent_Path}SOHPERSTORE`,
    payLoad
  );
  return data;
};
const getCountedPerStore = async (payLoad) => {
  const { data } = await reqInstance.post(
    `${Innovent_Path}GETCOUNTEDITEMS`,
    payLoad
  );
  return data;
};
const getPoOrders = async (payLoad) => {
  const { data } = await reqInstance.post(`${Innovent_Path}api/picking-list/`, {
    process: "packing",
  });
  return data;
};
const getToOrders = async (payLoad) => {
  const { data } = await reqInstance.get(
    `${Innovent_Path}OPENSUPPLYCHAIN`,
    payLoad
  );
  return data;
};
const postPrintingPo = async (payLoad) => {
  const { data } = await reqInstance.post(`${Innovent_Path}TAGIT`, payLoad);
  return data;
};
const getPoByOrderId = async (payLoad) => {
  const { data } = await reqInstance.post(
    `${Innovent_Path}api/picking-orderid`,
    payLoad
  );
  return data;
};

const editReceiving = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}receivingScreen/edit/6300988699ea9d28816156b3`,
    payLoad
  );
  return data;
};
const editHomeScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}homeScreen/edit/630090671384df2365a46dc6`,
    payLoad
  );
  return data;
};
const editPacking = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}packingScreen/edit/6300985999ea9d28816156b2`,
    payLoad
  );
  return data;
};
const editTransferOut = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}transferOutScreen/edit/630098bd99ea9d28816156b4`,
    payLoad
  );
  return data;
};
const editManageTagScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}manageTagScreen/edit/6300966d16495227e0c9554c`,
    payLoad
  );
  return data;
};
const editLocateItem2 = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}locateItem2/edit/6307a538704e5c0bed5565ba`,
    payLoad
  );
  return data;
};
const editTargetStockScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}targetStockScreen/edit/6304887a819546226c28c531`,
    payLoad
  );
  return data;
};
const editQrScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}qr/edit/6307a60d30215e0c5c4e2eb9`,
    payLoad
  );
  return data;
};
const editLoginScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}login/edit/6307ac388a94310fd4ee1383`,
    payLoad
  );
  return data;
};
const editEncodeScreen = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}EncodeScreen/edit/63030a4d912e0105e9df1fc0`,
    payLoad
  );
  return data;
};
const editTransferOutDetail = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}transferOutDetail/edit/63086c1424c291143e7fd7e5`,
    payLoad
  );
  return data;
};
const editDeviceSetup2 = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}deviceSetup2/edit/6307a537704e5c0bed5565b7`,
    payLoad
  );
  return data;
};
const editPackingItemDetail = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}packingItemDetail/edit/6307a60d30215e0c5c4e2eb8`,
    payLoad
  );
  return data;
};
const editReceivingDetail = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}receivingDetail/edit/63087b431169742947b894d4`,
    payLoad
  );
  return data;
};
const editCycleCount2 = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}cycleCount2/edit/6307a537704e5c0bed5565b6`,
    payLoad
  );
  return data;
};

const editChangeStatus = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}changeStatusScreen/edit/6300afca096f3436368ed48c`,
    payLoad
  );
  return data;
};

const editGiStatus = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}GIStatusScreen/edit/6300afaf096f3436368ed48b`,
    payLoad
  );
  return data;
};
const editMoreFilter = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}moreFilter/edit/6308a266fe86b145f85d84b7`,
    payLoad
  );
  return data;
};
const editCycleCount1 = async (payLoad) => {
  const { data } = await axios.put(
    `${Path}cycleCountScreen/edit/6300b0bc7bbec737e6210884`,
    payLoad
  );
  return data;
};
const login = async (payLoad) => {
  const { data } = await axios.post(`${localServer}store/user/login`, payLoad);
  return data;
};
const totalscore = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/totalstore`
  );
  return data;
};
const totalUsers = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/totalusers`
  );
  return data;
};
const getStoreName = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/getStoreName`
  );
  return data;
};

const getUserInfo1 = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/getusersinfo/`
  );
  return data;
};

const getInventoryData = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/getStoreName`
  );
  return data;
};
const totalDevices = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/inventoryData/TotalDevicesHandheldDevices`
  );
  return data;
};

const activities = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/inventoryData/Activities`
  );
  return data;
};
const getHandheldDevice = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/gethandhelddevice`
  );
  return data;
};
const getAsnDestination = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/getAsnDestination`
  );
  return data;
};
const getAsnStatus = async () => {
  const { data } = await axios.post(
    `${Path}api/1.0.0/stockCountRecords/getAsnStatus`
  );
  return data;
};

const getUsers = async () => {
  const { data } = await axios.get(`${localServer}store/user/get/all`);
  return data;
};
const getRoles = async () => {
  const { data } = await axios.get(`${localServer}store/role/get`);
  return data;
};

const getStores = async () => {
  const { data } = await axios.get(`${localServer}store/site/get`);
  return data;
};

const adduserPost = async (payload) => {
  const { data } = await axios.post(`${localServer}store/user/signup`, payload);
  return data;
};

const deleteUser = async (id) => {
  const { data } = await axios.delete(`${localServer}store/user/delete/${id}`);
  return data;
};

const editUser = async (body, id) => {
  const { data } = await axios.patch(`${localServer}store/user/edit/${id}`, body);
  return data;
};
const getDevices = async () => {
  const { data } = await axios.get(`${localServer}store/handHeld/get`);
  return data;
};
const getstores = async () => {
  const { data } = await axios.get(`${localServer}store/site/get`);
  return data;
};
const deletestore = async (id) => {
  const { data } = await axios.delete(`${localServer}store/site/delete/${id}`);
  return data;
};
const deleteZone = async (id) => {
  const { data } = await axios.delete(`${Path}zone/delete/${id}`);
  return data;
};
const editstore = async (body, id) => {
  const { data } = await axios.put(`${localServer}store/site/edit/${id}`, body);
  return data;
};
const editZone = async (body, id) => {
  const { data } = await axios.put(`${Path}zone/edit/${id}`, body);
  return data;
};
const addstorepost = async (payload) => {
  const { data } = await axios.post(`${localServer}store/site/add`, payload);
  return data;
};
const addZone = async (payload) => {
  const { data } = await axios.post(`${Path}zone/add`, payload);
  return data;
};
const getZone = async (payload) => {
  const { data } = await axios.get(`${Path}zone/get`, payload);
  return data;
};

const addDevices = async (payload) => {
  const { data } = await axios.post(`${localServer}store/handHeld/add`, payload);
  return data;
};
const editdevice = async (body, id) => {
  const { data } = await axios.put(`${localServer}store/handHeld/edit/${id}`, body);
  return data;
};
const editZpl = async (body, id) => {
  const { data } = await axios.put(`${localServer}store/zpl/edit/${id}`, body);
  return data;
};
const deletedevice = async (id) => {
  const { data } = await axios.delete(`${localServer}store/HandHeld/delete/${id}`);
  return data;
};
const zplDelete = async (id) => {
  const { data } = await axios.delete(`${localServer}store/zpl/delete/${id}`);
  return data;
};

const addRole = async (payload) => {
  const { data } = await axios.post(`${localServer}store/role/add`, payload);
  return data;
};
const getItemMaster = async (payload) => {
  const { data } = await axios.get(
    `${localServer}store/item-master?ItemBarCode=${payload}`
  );
  return data;
};
const getPurchaseOrder = async (payload) => {
  const { data } = await axios.get(
    `${localServer}store/purchase-order?ItemBarCode=${payload}`
  );
  return data;
};
const getRtvPo = async (payload) => {
  const { data } = await axios.get(
    `${PathCustom}store/supply-chain/po`,
    payload
  );
  return data;
};
const getRtvPoWithParams = async (payload) => {
  const { data } = await axios.get(
    `${PathCustom}store/supply-chain/po?startDate=${payload.toDate}&endDate=${payload.fromDate}&ASN=${payload.asn}`,
    payload
  );
  return data;
};
const getConsignement = async (payload) => {
  const { data } = await axios.get(
    `${PathCustom}store/consignment/get`,
    payload
  );
  return data;
};
const getCronStatus = async (payload) => {
  const { data } = await axios.get(
    `${PathCustom}store/get/cronStatus`,
    payload
  );
  return data;
};
const getRtvTo = async (payload) => {
  const { data } = await axios.get(
    `${PathCustom}store/supply-chain/to`,
    payload
  );
  return data;
};

const deleteRoles = async (id) => {
  const { data } = await axios.delete(`${localServer}store/role/delete/${id}`);
  return data;
};

const editRoles = async (payload, id) => {
  const { data } = await axios.put(`${localServer}store/role/edit/${id}`, payload);
  return data;
};

const getSoh = async () => {
  const { data } = await axios.get(`${PathCustom}store/soh`);
  return data;
};
const getPrintedEPC = async (payload) => {
  const { data } = await axios.post(
    `${PathCustom}store/printedECP/get`,
    payload
  );
  return data;
};

const getCustomItem = async () => {
  const { data } = await axios.get(`${PathCustom}store/counted-items`);
  return data;
};

const getAsnDetail = async () => {
  const { data } = await axios.get(`${SupplyPath}asn/get`);
  return data;
};

const epcDetail = async (payload) => {
  const { data } = await axios.post(
    `${SupplyPath}assetDetailRoutes/get/AssetDetail/By/Multi/EPC`,
    payload
  );
  return data;
};

const uploaddata = async (payload) => {
  const { data } = await axios.post(
    `${PathCustom}store/target-stock-count`,
    payload
  );
  return data;
};






const targetedStockCountName = async () => {
 
  let data = null
  await axios.get(`${PathCustom}store/targetStockCount/name`).then(res => {
      if (res?.data?.success === false && res?.data?.message === "A token is required for authentication") {
 
      }
      data = res.data
  }).catch(error => {
      let err = error?.response?.data
      if (err?.success === false && err?.message === "Invalid Token") {
 
      }
      data = error
  })

  return data;
}
const getCountedTarget = async (department) => {

  const { data } = await axios.get(`${PathCustom}store/countedTarget/${department}`,)
  return data;
}

const getTargetedStockCountByDep = async (department) => {
  const { data } = await axios.get(`${PathCustom}store/target-stock-count/${department}`)
  return data;
}

const getPicking = async (params) => {
	const { data } = await axios.get(`${PathCustom}store/picking`, { params });
	return data;
};
 
const getPO = async (params) => {
	const { data } = await axios.get(`${PathCustom}store/supply-chain/po`, {
    params,
  });
	return data;
};
const getTO = async (params) => {
	const { data } = await axios.get(`${PathCustom}store/supply-chain/to`, {
    params,
  });
	return data;
};
 
const getItemMasterDataNew = async (params) => {
  const { data } = await axios.get(`${PathCustom}store/itemMasterNew`, {
    params,
  });
  return data;
};
const getSOH = async (params) => {
  const { data } = await axios.get(`${PathCustom}store/get/soh`, {
    params,
  });
  return data;
};
const getPackingDetail = async (params) => {
  const { data } = await axios.get(`${PathCustom}store/packing-list/all`, {
    params,
  });
  return data;
};


export {
  getAsnDestination,
  getAsnStatus,
  getHandheldDevice,
  activities,
  totalDevices,
  totalUsers,
  totalscore,
  getUserInfo1,
  getInventoryData,
  getStoreName,
  getReceiving,
  editReceiving,
  editTransferOut,
  getTransferOut,
  getPacking,
  editPacking,
  getHomeScreen,
  editHomeScreen,
  editManageTagScreen,
  editLocateItem2,
  editTargetStockScreen,
  getManageTagScreen,
  getLocateItem2,
  getTargetStockScreen,
  getQrScreen,
  editQrScreen,
  editLoginScreen,
  getLoginScreen,
  getEncodeScreen,
  editEncodeScreen,
  getTransferOutDetail,
  editTransferOutDetail,
  login,
  getDeviceSetup2,
  editDeviceSetup2,
  getPackingItemDetail,
  editPackingItemDetail,
  editReceivingDetail,
  getReceivingDetail,
  getCycleCount2,
  editCycleCount2,
  getChangeStatus,
  editChangeStatus,
  getGiStatus,
  editGiStatus,
  editMoreFilter,
  getMoreFilter,
  getPrintingPo,
  editCycleCount1,
  getCycleCount1,
  getPoByOrderId,
  postPrintingPo,
  getUsers,
  getRoles,
  getStores,
  adduserPost,
  deleteUser,
  editUser,
  getDevices,
  addDevices,
  addstorepost,
  getstores,
  deletestore,
  editstore,
  editdevice,
  deletedevice,
  addRole,
  zplDelete,
  editZpl,
  getItemMaster,
  getPurchaseOrder,
  getRtvPo,
  deleteRoles,
  editRoles,
  getRtvTo,
  getCustomItem,
  getSoh,
  getConsignement,
  getPoOrders,
  getToOrders,
  getRtvPoWithParams,
  getSohPerStore,
  getCountedPerStore,
  getPrintedEPC,
  getZone,
  addZone,
  deleteZone,
  editZone,
  getCronStatus,
  getAsnDetail,
  epcDetail,
  uploaddata,
  getPicking,
  targetedStockCountName,
  getCountedTarget,
  getTargetedStockCountByDep,
  getPO,
  getTO,
  getItemMasterDataNew,
  getSOH,
  getPackingDetail,
};
