import React from 'react'
import QRCode from 'react-qr-code'

export default function Qrmodal({ close, value }) {

    return (
        <div>
            <div className="modal-div">
                <div className="modal0">
                    <div className="qrcode">
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <p style={{ fontSize: 22, fontWeight: "bold" }} ></p>
                            <p onClick={() => close()} style={{ color: "white", fontSize: 22, fontWeight: "bold", cursor: "pointer" }} >X</p>
                        </div>
                        <QRCode
                            size={250}
                            value={value}
                        />
                        <div>
                            <p style={{ color: "White", fontSize: "30px", fontWeight: "bold" }}>Scan QR Code</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
