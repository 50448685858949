import React, { useEffect, useState } from 'react'
import zoom from "../../assets/zoom.png"
import print from "../../assets/print.png"
import download from "../../assets/download.png"
import "./css/tagPrinting.css"
import Capture from './Capture'
import Dropdown from '../../components/Dropdown'
import { getPoOrders, getPrintingPo, getToOrders, Innovent_Path, Path, PathCustom, reqInstance } from '../../Path/Path'
import { toast } from 'react-toastify'
import Loader from '../../components/Loader'
import DropdownZpl from '../../components/DropDownZpl'
import axios from 'axios'
import QRCode from 'react-qr-code'
import Barcode from 'react-barcode';
import virgniImage from '../../assets/virginLogi.png'
import { Autocomplete, TextField } from '@mui/material'
import jwtDecode from 'jwt-decode'

const TagPrintingPo = () => {
    const [poNumberVal, setpoNumberVal] = useState('')
    const [zplVal, setZplVal] = useState('')
    const [loading, setLoading] = useState(false)
    const [poList, setPoList] = useState([])
    const [poData, setPoData] = useState([])
    const [zplList, setZplList] = useState([])
    const [zplBody, setZplBody] = useState('')
    const [poDataList, setPoDataList] = useState([])
    const [countAgain, setCountAgain] = useState(true)
    const [invoiceList, setInvoiceList] = useState([])
    const [remarks, setRemarks] = useState('')
    const [userData, setUserData] = React.useState({});
    const [storeArray, setStoreArray] = React.useState([])
    const [datadata, setDataData] = React.useState({});
    const [invoiceNumberVal, setinvoiceNumberVal] = useState('')
    const poNumber = (e) => {
        console.log(e.target.value);
    }
    useEffect(() => {
        async function getLoginData(params) {
            const data = await localStorage.getItem('loginData')
            const parseData = JSON.parse(data)
            const token = parseData?.token
            if (token) {
                const decodetoken = jwtDecode(token)
                setUserData(decodetoken?.payload?.siteId?.storecode)
                setDataData(decodetoken?.payload?.siteId)
            }

        }
        getLoginData()
    }, [])

    const invoiceNumber = (e) => {
        console.log(e.target.value);
    }
    const gettingStores = async () => {
        let data = null
        await reqInstance.get(`${Innovent_Path}STORES`)
            .then((async res => {
                data = await res?.data?.results
            }))

            .catch((err => {
                console.log(err);
            }))

        await setStoreArray(data)
    }

    const checkBox = (e) => {
        console.log(e.target.value);
    }
    useEffect(() => {
        setLoading(true)
        async function fetchData() {
            const getPo = await getPoOrders()
            const getTo = await getToOrders()
            let poListFull = getPo?.map((item => { return { po_number: item?.order_id } }))
            let toListFull = getTo?.map((item => { return { po_number: item?.ASN, destination: item?.destination, source: item?.source } }))
            let poList = getPo?.map((item => item?.order_id))
            let inList = getTo
            var completeListData = []
            completeListData = toListFull
            var completeList = []
            completeList = inList
            console.log(completeList, 'completeList');
            await setPoList(completeList)
            await setPoData(completeListData)

            // const uniqueVal = await axios.post(`${PathCustom}/store/sequence`, { "count": 100 })
            // console.log(uniqueVal?.data?.data, 'uniqueVal');
            setLoading(false)

        }

        fetchData();
        gettingStores()
    }, []);

    const poHandler = (data) => {
        setpoNumberVal(data?.ASN)
        setPoDataList(poData.filter((item => item.po_number === data?.ASN)))
        console.log(poData.filter((item => item.po_number === data?.ASN)));
        console.log(data);
    }

    const printBarcode = async () => {
        if (poNumberVal === '') {
            toast.error("Please Add Po Number and Invoice Number")
        } else {

            let printContents = document.getElementById('printffff').innerHTML;
            // let originalContents = document.body.innerHTML;
            // document.body.innerHTML = printContents;
            // window.print();
            // document.body.innerHTML = originalContents;
            var doc = await document.getElementById('printff').contentWindow.document;
            doc.open();
            doc.write(printContents);
            doc.close()
            window.frames["printff"].focus()
            window.frames["printff"].print()
        }
    }
    return (
        <>
            <Loader loading={loading} />
            <iframe id="printff" name="printff" style={{ display: 'none', justifyContent: 'center' }} >

                {/* <h1 style={{ fontFamily: 'monospace', fontSize: '20px' }}>{poNumberVal}</h1> */}
            </iframe>
            <div id="printffff" name="printffff" style={{ display: 'none', justifyContent: 'center' }} >



                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={virgniImage} height={300} width={300} />
                    <h1 style={{ fontWeight: 'bold' }}>Product Receipt </h1>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Tranfers Order</h3>
                        <h3>{poNumberVal}</h3>
                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Date</h3>
                        <h3>{new Date().toLocaleString()}</h3>

                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Source</h3>
                        <h3>{storeArray.filter((item => item?.StoreID?.includes(poList?.filter((item => item?.ASN === poNumberVal))[0]?.source)))[0]?.StoreID + " " + storeArray.filter((item => item?.StoreID?.includes(poList?.filter((item => item?.ASN === poNumberVal))[0]?.source)))[0]?.StoreName}</h3>

                    </div>
                    <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                        <h3>Destination</h3>
                        <h3>{storeArray.filter((item => item?.StoreID?.includes(poList?.filter((item => item?.ASN === poNumberVal))[0]?.destination)))[0]?.StoreID + " " + storeArray.filter((item => item?.StoreID?.includes(poList?.filter((item => item?.ASN === poNumberVal))[0]?.destination)))[0]?.StoreName}</h3>

                    </div>


                </div>
                <Barcode width={1} value={`${poNumberVal}`} />
                <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                    <h2>{remarks}</h2>

                </div>

                {/* <h1 style={{ fontFamily: 'monospace', fontSize: '20px' }}>{poNumberVal}</h1> */}
            </div>
            <div style={{ minHeight: '90vh' }} className='dashboard-main'>
                <div className='dashboard-top'>
                    <div>
                        <p className='stock-count'>TO Number Printing Report</p>
                        <h1 style={{ color: 'black', fontSize: '15px' }}>
                            {new Date().toLocaleString('hi-IN')}
                        </h1>
                    </div>
                </div>
                <div className='dashboard-bottom' style={{ marginTop: "1%" }}>
                    {/* <input type="text" placeholder='PO Number' onChange={poNumber} style={{ width: "25%", height: "35px", marginBottom: "1%" }} /> */}
                    {/* <input type="text" placeholder='Invoice Number' onChange={invoiceNumber} style={{ width: "25%", height: "35px" }} /> */}
                    <div style={{ display: 'flex', width: '100%', gridColumnGap: '2rem', justifyContent: 'space-between' }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            getOptionLabel={(option) => option?.ASN}
                            options={poList?.filter((item => item?.to_status === 'Created'))}
                            onChange={(e, v) => poHandler(v === null ? '' : v)}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField
                                {...params} label="TO Number" />}
                        />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={remarks}
                            value={remarks}
                            placeholder="Remarks"
                            InputLabelProps={{ shrink: true }}
                            size={'small'}
                            onChange={(data) => setRemarks(data.target.value)} id="outlined-basic" label="Remarks" variant="outlined" />

                        <div style={{ width: "180px" }}></div>
                        <div className='check-box'>
                            <button onClick={() => printBarcode()} className="run-btn"><p className="run-text">Print Product Receipt</p></button>
                        </div>
                    </div>



                </div>

            </div>
        </>
    )
}

export default TagPrintingPo;
