import React, { Component } from "react";
import print from "../assets/print.png"
import zoom from "../assets/zoom.png"
import download from "../assets/download.png"
import Dropdown from "../components/Dropdown";
import MyInput from "../components/MyInput";
import { PaginatedItems } from "../components/Paginated";
import { withRouter } from "../components/withRouter";
import { getCustomItem, getSoh } from "../Path/Path";


class DailyStockCountReport extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:[],
            item:[],
            dailyStockCount: [
                {
                    Date: "12-09-22",
                    OnHand: "123",
                    StoreName: "meta",
                    Count: "5",
                    ItemCountAccuracy: "8",
                    OperationalAccuracy: "78",
                    OnHandMatching: "777",
                    Unders: "8",
                    Overs: "9",
                    CriticalOutofStock: "777",
                    FrontStore: "warehouse",
                    FrontStore: "back"
    
    
                }
            ],
            itemsPerPage: 10
        }

    }

    async componentDidMount() {
        const data = await getSoh();
        const items = await getCustomItem();
        this.setState({ data: data, item: items })
    }

    render() {
        const headers = [
            "Date",
            "Store Name",
            "OnHand",
            "Count",
            "Item Count Accuracy",
            "Operational Accuracy",
            "On Hand Matching",
            "Unders",
            "Overs",
            "Critical Out of Stock",
            "Front Store",
            "Back Store"

        ]
        const colNames = [

            "Date",
            "StoreName",
            "OnHand",
            "Count",
            "ItemCountAccuracy",
            "OperationalAccuracy",
            "OnHandMatching",
            "Unders",
            "Overs",
            "CriticalOutofStock",
            "FrontStore",
            "BackStore"
        ]
        return (
            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div><p className="stock-count">Daily Stock Count Report</p></div>

                </div>
                <div className="dashboard-bottom">
                    <div className="drop-data" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "40%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div> <p className="filter-text">Filters</p></div>
                            <MyInput
                                type="date"
                            />
                            <MyInput
                                type="date"
                            ></MyInput>


                        </div>
                        <div>
                            <button className="run-btn"><p className="run-text">Search</p></button>
                        </div>
                    </div>
                    {/* <div style={{ paddingTop: "2%" }}>
                        <table data-table-theme="dark zebra">

                            <thead>

                                <tr style={{ background: "#373E43" }}>
                                    <th >Date</th>
                                    <th>Store Name</th>
                                    <th>on Hand</th>
                                    <th>Count</th>
                                    <th>Item Count Accuracy</th>
                                    <th>Operational Accuracy</th>
                                    <th>On Hand Matching</th>
                                    <th>Unders</th>
                                    <th>Overs</th>
                                    <th>Critical Out of Stock</th>
                                    <th>Front Store</th>
                                    <th>Back Store</th>


                                </tr>

                            </thead>
                            {
                                Array(9).fill().map(e =>
                                    <tbody>
                                        <tr>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>Cell 1</td>
                                            <td>Cell 2</td>
                                            <td>Cell 3</td>
                                            <td>Cell 4</td>
                                            <td>Cell 5</td>
                                            <td>Cell 5</td>
                                            <td>Cell 5</td>



                                        </tr>

                                    </tbody>
                                )
                            }
                        </table>
                    </div> */}
                    <PaginatedItems noAction headers={headers} colNames={colNames} itemsPerPage={this.state.itemsPerPage} data={this.state.dailyStockCount} />
                </div>
            </div>
        )
    }
}
export default withRouter(DailyStockCountReport);