
import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";


const BarGraph = ({ data, totalLength }) => {
    console.log(data, 'unique');
    const headings = Object.keys(data) ? Object.keys(data) : []
    const datadata = headings?.map((item => {
        return {
            name: item,
            Count: data[item]?.length
        }
    }))
    // console.log(datadata);
    return (
        <div className="count-progess" style={{ width: "100%", height: "700px", marginLeft: "20px", padding: "20px" }}>
            <p className="grap-para" style={{ fontSize: "20px", marginBottom: "50px" }}>Count Progress</p>
            <div className="graph" style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height="80%">
                    <BarChart
                        data={datadata}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 0" stroke="#000" />
                        <XAxis offset={0} dy={100} height={130} dataKey="name" angle={300} interval={0} tick={{ fill: '#fff', fontSize: "10px" }} tickCount={47} />
                        <YAxis
                            dataKey={totalLength}
                            name="stature"
                            ticks={[totalLength]}
                            type="number"
                            tick={{ fill: '#fff', fontSize: "10px" }}
                        />
                        <Tooltip cursor={{ fill: "transparent" }} />
                        <Legend />
                        <Bar dataKey="Count" fill="#E60000" background={{ fill: "#eee" }} barSize={15} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}


export default BarGraph;