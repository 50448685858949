import React, { Component } from "react";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
import Dropdown from "../../components/Dropdown";
import { addstorepost, adduserPost, addZone, editUser, editZone, getRoles, getStores } from "../../Path/Path";
import { withRouter } from "../../components/withRouter";
import { toast } from "react-toastify"
import Loader from "../../components/Loader";
import { Autocomplete, TextField } from '@mui/material'
class AddZone extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: "",
            userName: "",
            password: "",
            status: "",
            role: [],
            selectRole: "",
            storeName: "",
            storeNameList: [],
            dropStore: "",
            dropRole: "",
            data: [],
            allStores: [],
            showStatus: false,
            showRole: false,
            showStoreId: false,
            loading: false,
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const data = await getRoles();
        const store = await getStores();
        const storesName = store.map((elem) => elem.storename)
        const roleName = data.map((elem) => elem.role_name)
        this.setState({ storeNameList: storesName, data: data, role: roleName, allStores: store })
        // const fil = data.find((i) => i._id === this.props.location.state.item.roleId)

        // this.setState({ slectrole: fil?.role_name })
        // this.setState({ storeName: storesName })
        this.setState({ loading: false })
        if (this.props.location.state?.item) {

            const item = this.props.location.state.item
            console.log('item: ', item);
            this.setState({
                name: item?.name,
                userName: item?.userName,
                password: item?.password,
                selectRole: item?.roleId?.role_name,
                storeName: item?.siteId?.storename,
                status: item?.status === "1" ? "Active" : "De-Active"
            })
        }

    }

    sendHandler = async () => {

        try {

            const roleId = this.state.data.find(i => i.role_name === this.state.selectRole)
            console.log('roleId: ', roleId);
            const storeId = this.state.allStores.find(i => i.storename === this.state.storeName)
            console.log('storeId: ', storeId);

            const body = {
                "zone_name": this.state.name,
                "zoneCode": this.state.userName,
                "siteId": storeId?._id,
                "status": this.state.status === "Active" ? "1" : "0",
            }
            if (this.state.name === "") {
                toast.warn("Enter Name")
            }
            else if (this.state.userName === "") {
                toast.warn("Enter User Name")
            }



            else if (this.state.storeName === "") {
                toast.warn("Select Store")
            }
            else if (this.props.location?.state?.item) {


                editZone(body, this.props.location.state.item._id)
                this.props.history("/zones");
                toast.success("Zone edit successfully")
                // console.log();
            }

            else {
                const res = await addZone(body)
                if (res?.error) {
                    toast.warn("Something went wrong")
                } else {
                    toast.success("Zone added succesfully")
                    this.props.history("/zones");
                }
            }

        } catch (error) {
            alert(error.message)
        }





    }

    render() {

        const { name, userName, password, allStores = [] } = this.state
        console.log('name: ', name);
        console.log(this.state.storeName);


        const editMode = !!this.props.location?.state?.item

        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="dashboard-main">
                    <div className="dashboard-top">
                        <div> <p className="stock-count">{editMode ? "Edit Zone" : "Add Zone"}</p></div>
                        <div style={{ display: "flex", width: "13%", justifyContent: "space-between", marginTop: "0.3%" }}>
                            <img src={zoom}></img>

                            <div>
                                <img src={print}></img>
                            </div>
                            <div>
                                <img width={30} height={30} src={download}></img>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingInline: 20, marginTop: 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        {/* <div> <p className="filter-text">Filters</p></div> */}

                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.name}
                            value={name}
                            placeholder="Zone Name"
                            size={'small'}
                            onChange={(data) => this.setState({ name: data.target.value })} id="outlined-basic" label="Zone Name" variant="outlined" />
                        <TextField
                            sx={{ width: 235 }}
                            defaultValue={this.state.userName}
                            value={userName}
                            placeholder="Zone Code"
                            size={'small'}
                            onChange={(data) => this.setState({ userName: data.target.value })} id="outlined-basic" label="Zone Code" variant="outlined" />


                    </div>

                    <div style={{ paddingInline: 20, marginTop: 15, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            options={["Active", "Deactive"]}
                            value={this.state.status}
                            onChange={(e, v) => this.setState({ status: v === null ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Status" />}
                        />

                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size={'small'}
                            value={this.state.storeName}
                            options={this.state.allStores?.map((item => item?.storename)).reduce((noDupArr, entry) => {
                                if (noDupArr?.includes(entry)) {
                                    return noDupArr;
                                } else {
                                    return [...noDupArr, entry];
                                }
                            }, []).filter((item => item !== null))}
                            onChange={(e, v) => this.setState({ storeName: v === null ? '' : v })}
                            sx={{ width: 235 }}
                            renderInput={(params) => <TextField
                                {...params} label="Stores" />}
                        />

                    </div>
                    <div style={{ paddingLeft: 20, marginTop: 20, marginBottom: 20 }} >
                        <button className="run-btn"><p className="run-text" onClick={() => this.sendHandler()}>{editMode ? "Edit Zone" : "Add Zone"}</p></button>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(AddZone);