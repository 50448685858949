import React, { useState } from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';

export default function Dropdown({ value, width, list, onChangeValue, selectedValue = '', onClick, show, onClickList, placeholder }) {
    useEffect(() => {
        setinputVal(selectedValue)
    }, [selectedValue])
    const [data, setData] = useState(list);
    const [inputVal, setinputVal] = useState(selectedValue);
    const [visible, setVisible] = useState(false)
    const num = [1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165, 1232, 5165, 615498, 65165]
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // alert("You clicked outside of me!");
                    setVisible(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    const handler = () => {
        setVisible(true)

    }
    const listData = (data) => {
        onClickList(data)
        setinputVal(data)
        console.log(inputVal);
        console.log(data);
    }

    return (
        <div style={{ width: '200px' }} ref={wrapperRef}>
            <div onClick={() => handler()} >
                <input className='drop-input' onChange={(e) => setinputVal(e.target.value)} value={inputVal} placeholder={placeholder} />
            </div>
            {
                visible ?
                    <div style={{ paddingLeft: '0px' }}>
                        <div className='picker-list drop-input' style={{ paddingLeft: '0px' }}>

                            {
                                list ?
                                    list
                                        .filter((item => String(item)?.toLowerCase()?.includes(inputVal?.toLowerCase())))
                                        .map(data =>
                                            <React.Fragment>
                                                <div style={{ paddingLeft: '0px' }} key={data} className='dropdown-para-wrapper'>
                                                    <p onClick={() => listData(data)}>{data}</p>
                                                </div>
                                            </React.Fragment>
                                        )
                                    :
                                    num.map(data =>
                                        <React.Fragment>
                                            <div style={{ paddingLeft: '0px' }} key={data} className='dropdown-para-wrapper'>
                                                <p onClick={() => listData(data)}>{data}</p>
                                            </div>
                                        </React.Fragment>
                                    )
                            }
                        </div> </div> :
                    null
            }

        </div>
    )
}
