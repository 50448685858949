import React, { Component } from "react";

import Dropdown from "../../components/Dropdown";

import print from "../../assets/print.png"
import zoom from "../../assets/zoom.png"
import download from "../../assets/download.png"
import MyInput from "../../components/MyInput";
export default class Audit extends Component {
    render() {
        return (

            <div className="dashboard-main">
                <div className="dashboard-top">
                    <div> <p className="stock-count">Audit Info</p></div>

                </div>
                <div className="audit-bottom">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="filter-text">Filters</p>
                    </div>

                    <MyInput width={"185px"}
                        placeholder={"Retail Cycle Count"}
                    ></MyInput>
                    <Dropdown width={"185px"}
                        placeholder={"All Log"}
                    />
                    <Dropdown width={"185px"}
                        placeholder={"Cycle Count"}
                    />
                    <MyInput width={"185px"}
                        type="date"
                    />

                </div>

                <div style={{ padding: "2%", paddingTop: "0px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ paddingTop: "0px", display: "flex", justifyContent: "space-between", width: "18%" }} >
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <p style={{ color: "white", margin: "0 5px 0 0" }}>UAE Time</p>
                            <input type="checkbox" />
                        </div>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <p style={{ color: "white", margin: "0 5px 0 0" }}>UTC Time</p>
                            <input type="checkbox" />
                        </div>
                    </div>
                    <div>
                        <button className="run-btn"><p className="run-text">Search</p></button>
                    </div>
                </div>
                <div style={{ padding: "2%", paddingTop: "0px", display: "flex", justifyContent: "flex-end", width: "100%" }}>

                </div>
                <div style={{ paddingTop: "2%" }}>
                    <table data-table-theme="dark zebra">

                        <thead>

                            <tr style={{ background: "#373E43" }}>
                                <th >Audit ID</th>
                                <th>Audit Text</th>
                                <th>Date</th>
                                <th>Log Type</th>
                                <th>Store Name</th>
                                <th>Reatail Cycle Count</th>
                                <th>Audit Json</th>
                                <th>Device ID</th>
                            </tr>

                        </thead>
                        {
                            Array(9).fill().map(e =>
                                <tbody>
                                    <tr>
                                        <td>Cell 1</td>
                                        <td>Cell 2</td>
                                        <td>Cell 3</td>
                                        <td>Cell 4</td>
                                        <td>Cell 5</td>
                                        <td>Cell 3</td>
                                        <td>Cell 4</td>
                                        <td>Cell 5</td>
                                    </tr>

                                </tbody>
                            )
                        }
                    </table>
                </div>
            </div>


        )
    }
}