import React, { Component } from "react";
import { PaginatedItems } from "../components/Paginated";
import Loader from "../components/Loader";
import { CSVLink } from "react-csv";
import { GrDocumentCsv } from "react-icons/gr";
import { TextField } from "@mui/material";
import { withRouter } from "../components/withRouter";
import Autocomplete from "@mui/material/Autocomplete";

class CountDetails2 extends Component {
  state = {
    data1: [],
    item: [],
    operationItems: [
      {
        Store: "00125",
        Department: "750",
        Date: "22-09-13",
        Accuracy: "0.00",
        UIA: "0.00",
        Expected: "39",
        Counted: "0",
        Overs: "0",
        Unders: "-0.39",
        ExpectedSF: "0",
        ExpectedSR: "0",
        Department: "perfume",
        Brand: "136-DG Men",
        Diff: "13",
      },
    ],
    loading: false,
    SKU_Over: "",
    SKU_Real: "",
    SKU_Stock: "",
    data: "",
    storeName: [],
    dropdata: "",
    filter: [],
    mydate: "",
    visible: false,
    itemsPerPage: 10,
    csvDataReal: [],
    csvDataOvers: [],
    csvDataStock: [],
    csvDataRealFake: [],
    csvDataOversFake: [],
    csvDataStockFake: [],
    stats: {
      groupedSKU: [],
      dataOrderWise: [],
      uniqueArray: [],
    },
  };
  searchDataReal = () => {
    console.log("working");

    const { SKU_Real, csvDataRealFake } = this.state;

    // Check if SKU_Real is null or an empty string
    if (!SKU_Real) {
      // Return the whole data if SKU_Real is null or empty
      this.setState({ csvDataReal: csvDataRealFake });
    } else {
      // Filter the array for an exact match
      const filterArray = csvDataRealFake.filter(
        (item) => item?.sku === SKU_Real
      );
      console.log(filterArray);
      this.setState({ csvDataReal: filterArray });
    }
  };
  async componentDidMount() {
    this.setState({ loading: true });
    const getDescripency = (await this.props?.data) || [];

    this.setState({
      csvDataReal: getDescripency,
      csvDataRealFake: getDescripency,
    });
    this.setState({ loading: false });
  }
  replaceAll = (str, find, replace) => {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, "g"), replace);
  };
  render() {
    const headers = ["sku", "name", "qty", "barcode", "type"];

    const colNames = ["barcode", "name", "qty", "sku", "type"];

    const csvDataReal = this.state.csvDataReal || [];
    const csvDataOvers = this.state.csvDataOvers || [];
    const csvDataStock = this.state.csvDataStock || [];
    return (
      <>
        <Loader loading={this.state.loading} />
        <div>
          <div className="dashboard-main">
            <div className="dashboard-top">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p
                  className="stock-count"
                  style={{ fontSize: "25px", margin: 0 }}
                >
                  {this.props?.heading}
                </p>

                <CSVLink
                  filename={this.props?.heading}
                  data={csvDataReal}
                  headers={colNames}
                >
                  <GrDocumentCsv
                    color="white"
                    fill="white"
                    fontSize={25}
                    style={{ marginRight: 5, cursor: "pointer" }}
                  />
                </CSVLink>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gridColumnGap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: 20,
                marginBottom: 20,
                padding: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gridColumnGap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <Autocomplete
                  freeSolo
                  options={this.state.csvDataRealFake.map((item) => item.sku)} // Array of SKU options
                  inputValue={this.state.SKU_Real}
                  onInputChange={(event, newInputValue) => {
                    this.setState({ SKU_Real: newInputValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: 235 }}
                      placeholder="Barcode"
                      size="small"
                      label="Barcode"
                      variant="outlined"
                    />
                  )}
                />
              </div>

              <button
                onClick={
                  () => this.searchDataReal()
                  // fetchDataWithParams()
                }
                className="run-btn"
              >
                <p className="run-text">Search</p>
              </button>
            </div>

            <div style={{ height: "72vh", overflowY: "scroll" }}>
              <PaginatedItems
                noAction
                headers={headers}
                colNames={colNames}
                itemsPerPage={100}
                data={this.state.csvDataReal}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(CountDetails2);
